import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { makeStyles } from "@material-ui/core/styles";
import Backdrop from "@material-ui/core/Backdrop";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import InputAdornment from "@material-ui/core/InputAdornment";
import TextField from "@material-ui/core/TextField";
import Card from "components/Card/Card.js";
import { useHistory } from "react-router-dom";
import Table from "components/Table/Table.js";
import CardHeader from "components/Card/CardHeader.js";
import CardBody from "components/Card/CardBody.js";
import Button from "components/CustomButtons/Button.js";
import ArrowBackIcon from "@material-ui/icons/ArrowBack";
import CircularProgress from "@material-ui/core/CircularProgress";
import TableRow from "@material-ui/core/TableRow";
import TableCell from "@material-ui/core/TableCell";

const styles = {
  backdrop: {
    zIndex: 5,
    color: "#fff",
  },
  typo: {
    paddingLeft: "25%",
    marginBottom: "40px",
    position: "relative",
  },

  tableCell: {
    textAlign: "center",
  },
  note: {
    fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
    bottom: "10px",
    color: "#c0c1c2",
    display: "block",
    fontWeight: "400",
    fontSize: "13px",
    lineHeight: "13px",
    left: "0",
    marginLeft: "20px",
    position: "absolute",
    width: "260px",
  },
  cardCategoryWhite: {
    color: "rgba(255,255,255,.62)",
    margin: "0",
    fontSize: "14px",
    marginTop: "0",
    marginBottom: "0",
  },
  cardTitleWhite: {
    color: "#FFFFFF",
    marginTop: "0px",
    minHeight: "auto",
    fontWeight: "300",
    fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif",
    marginBottom: "3px",
    textDecoration: "none",
  },
};

const useStyles = makeStyles(styles);

export default function ItemShipping({ match, url, exit }) {
  toast.configure();
  const {
    params: { zid },
  } = match;
  let history = useHistory();
  const [rates, setRates] = useState([]);
  const [update, setUpdate] = useState(false);
  const [zonedata, setZonedata] = useState([
    {
      name: "",
    },
  ]);
  const [loading, setLoading] = useState(true);
  const classes = useStyles();
  const getRelations = async () => {
    let response = await fetch(
      url + `/get-international-zone-item-rates/${zid}`,
      { credentials: "include" }
    );
    let data = await response.json();
    if (data.success) {
      setRates(data.data);
    } else {
      if( data.message == 'unauthenticated'){
        window.location.replace(exit);
        return;
      }
      toast.error(data.message);
    }
  };
  const getZonedata = async () => {
    let response = await fetch(url + `/get-zone-data/${zid}`, {
      credentials: "include",
    });
    let data = await response.json();
    if (data.success) {
      setZonedata({ name: data.data[0] });
      setLoading(false);
    } else {
      if( data.message == 'unauthenticated'){
        window.location.replace(exit);
        return;
      }
      toast.error(data.message);
    }
  };
  const UpdateRates = async (e) => {
    setLoading(true);
    e.preventDefault();
    let response = await fetch(
      url + `/update-international-zone-item-rates/${zid}`,
      {
        method: "PUT",
        headers: {
          "Content-type": "application/json",
        },
        body: JSON.stringify({ rates: rates }),
        credentials: "include",
      }
    );
    let data = await response.json();
    if (data.success) {
      setRates(data.data);
      setUpdate(false);
      setLoading(false);
      toast.success("Rate updated");
    } else {
      if( data.message == 'unauthenticated'){
        window.location.replace(exit);
        return;
      }
      toast.error(data.message);
    }
  };

  useEffect(() => {
    getRelations();
    getZonedata();
  }, []);

  const handlepricingchange = (event, zoneid) => {
    setRates(
      rates.map((rate) =>
        rate[0] === zoneid
          ? [rate[0], [rate[1][0], parseInt(event.target.value)]]
          : rate
      )
    );
    setUpdate(true);
  };
  return (
    <Card>
      {loading && (
        <Backdrop className={classes.backdrop} open>
          <CircularProgress color="inherit" />
        </Backdrop>
      )}
      <CardHeader color="danger">
        <h4 className={classes.cardTitleWhite}>
          {zonedata.name} International Shipping Rates{" "}
        </h4>
      </CardHeader>
      <CardBody>
        <Button
          onClick={history.goBack}
          style={{ float: "left" }}
          color="danger"
        >
          <ArrowBackIcon />
          Back
        </Button>
        <Table
          tableHeaderColor="black"
          tableHead={["Item", "Shipping Price"]}
          tableData={rates.map((prop) => (
            <TableRow key={prop[0]} className={classes.tableBodyRow}>
              <TableCell className={classes.tableCell} key={prop[0]}>
                {prop[1][0]}
              </TableCell>
              <TableCell className={classes.tableCell}>
                <TextField
                  style={{ width: "100%" }}
                  value={prop[1][1]}
                  onChange={(e) => handlepricingchange(e, prop[0])}
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">GMD</InputAdornment>
                    ),
                  }}
                  type="number"
                  id="standard-start-adornment"
                />
              </TableCell>
            </TableRow>
          ))}
          search={false}
        />
        {update && (
          <Button
            onClick={UpdateRates}
            style={{ float: "right" }}
            color="danger"
          >
            Save Changes
          </Button>
        )}
      </CardBody>
    </Card>
  );
}
ItemShipping.propTypes = {
  url: PropTypes.string,
  imgurl: PropTypes.string,
  exit: PropTypes.string,
  match: PropTypes.shape({
    params: PropTypes.shape({
      zid: PropTypes.number.isRequired,
      veh: PropTypes.number.isRequired,
    }),
  }),
};
