import React, { useEffect, useState } from "react";
import Chip from "@material-ui/core/Chip";
import PersonOutlineIcon from "@material-ui/icons/PersonOutline";
import CallIcon from "@material-ui/icons/Call";
import DialogContent from "@material-ui/core/DialogContent";
import { makeStyles } from "@material-ui/core/styles";
import ListAltIcon from "@material-ui/icons/ListAlt";
import TablePagination from '@material-ui/core/TablePagination';
import Typography from "@material-ui/core/Typography";
import PropTypes from "prop-types";
import DoneAllIcon from "@material-ui/icons/DoneAll";
import EmailIcon from "@material-ui/icons/Email";
import VisibilityOffIcon from "@material-ui/icons/VisibilityOff";
import CheckCircle from "@material-ui/icons/CheckCircleOutline";
import PhoneIcon from "@material-ui/icons/Phone";
import LockOpenIcon from "@material-ui/icons/LockOpen";
import MailOutlineIcon from "@material-ui/icons/MailOutline";
import InputAdornment from "@material-ui/core/InputAdornment";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faBoxOpen } from "@fortawesome/free-solid-svg-icons";
import clsx from "clsx";
import { Link } from "react-router-dom";
import DialogTitle from "@material-ui/core/DialogTitle";
import Divider from "@material-ui/core/Divider";
import Drawer from "@material-ui/core/Drawer";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText";
import Details from "@material-ui/icons/Visibility";
import Close from "@material-ui/icons/Close";
import Arrow from "@material-ui/icons/DoubleArrow";
import Avatar from "@material-ui/core/Avatar";
import HourglassEmptyIcon from "@material-ui/icons/HourglassEmpty";
import CircularProgress from "@material-ui/core/CircularProgress";
import Danger from "components/Typography/Danger.js";
import TableRow from "@material-ui/core/TableRow";
import View from "@material-ui/icons/Visibility";
import TableCell from "@material-ui/core/TableCell";
import TextField from "@material-ui/core/TextField";
import Table from "components/Table/Table.js";
import Card from "components/Card/Card.js";
import CardHeader from "components/Card/CardHeader.js";
import Backdrop from "@material-ui/core/Backdrop";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import CardBody from "components/Card/CardBody.js";
import Edit from "@material-ui/icons/Edit";
import Delete from "@material-ui/icons/DeleteOutline";
import Button from "components/CustomButtons/Button.js";
import Add from "@material-ui/icons/Add";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContentText from "@material-ui/core/DialogContentText";
import GridItem from "components/Grid/GridItem.js";
import GridContainer from "components/Grid/GridContainer.js";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import IconButton from "@material-ui/core/IconButton";
import CloseIcon from "@material-ui/icons/Close";
import Slide from "@material-ui/core/Slide";
import ChartistGraph from "react-chartist";
import { monthlyDeliveriesChart } from "variables/charts";

const styles = {
  appBar: {
    position: "relative",
    background: "#ec4c48",
  },
  scrollPaper: {
    alignItems: "baseline", // default center
  },

  detailcell: {
    display: "flex",
    alignItems: "center",
    flexWrap: "wrap",
    marginBottom: "15px",
  },
  avatarimg: {
    display: "block",
    marginLeft: "auto",
    marginRight: "auto",
  },
  centertext: {
    textAlign: "center",
  },
  flexContainer: {
    display: "flex",
    flexFlow: "row wrap",
    justifyContent: "space-around",
    padding: 0,
    margin: 0,
    listStyle: "none",
  },
  flexItem: {
    position: "relative",
    width: "150px",
    height: "150px",
    marginTop: "5px",
    lineHeight: "150px",
    textAlign: "center",
  },
  vehicleimg: {
    display: "block",
    height: 50,
    width: 50,
    marginLeft: "auto",
    marginRight: "auto",
  },
  vehimg: {
    width: "100%",
    height: "100%",
  },

  overlay: {
    display: "none",
  },
  playIcon: {
    position: "absolute",
    fontSize: "50px",
    color: "green",
    cursor: "pointer",
    left: "0",
    right: "0",
    marginLeft: "auto",
    marginRight: "auto",
    top: "0",
    bottom: "0",
    marginTop: "auto",
    marginBottom: "auto",
    width: "150px",
  },
  selected: {
    position: "absolute",
    opacity: "1",
    color: "green",
    top: "0",
    bottom: "0",
    left: "0",
    right: "0",
  },
  list: {
    width: "100%",
    position: "relative",
  },
  detailhead: {
    marginTop: "10",
    marginBottom: "10",
    fontWeight: '900',
  },

  backdrop: {
    zIndex: 9999,
    color: "#fff",
  },
  tableCell: {
    textAlign: "center",
  },
  cardCategoryWhite: {
    "&,& a,& a:hover,& a:focus": {
      color: "rgba(255,255,255,.62)",
      margin: "0",
      fontSize: "14px",
      marginTop: "0",
      marginBottom: "0",
    },
    "& a,& a:hover,& a:focus": {
      color: "#FFFFFF",
    },
  },
  cardTitleWhite: {
    color: "#FFFFFF",
    marginTop: "0px",
    minHeight: "auto",
    fontWeight: "300",
    fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif",
    marginBottom: "3px",
    textDecoration: "none",
    "& small": {
      color: "#777",
      fontSize: "65%",
      fontWeight: "400",
      lineHeight: "1",
    },
  },
  cardTitleBlack: {
    color: "#000000",
    marginTop: "0px",
    minHeight: "auto",
    fontWeight: "300",
    fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif",
    marginBottom: "3px",
    textDecoration: "none",
    "& small": {
      color: "#777",
      fontSize: "65%",
      fontWeight: "400",
      lineHeight: "1",
    },
  },
};



const useStyles = makeStyles(styles);
const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="down" ref={ref} {...props} />;
});
const Transition2 = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

export default function Drivers(props) {
  toast.configure();
  const classes = useStyles();
  const [open, setOpen] = useState(false);
  const [drivdelopen, setdrivdelopen] = useState(false);
  const [addopen, setaddOpen] = React.useState(false);
  const [editid, setEditid] = useState(0);
  const [selveh, setselveh] = useState([]);
  const [monthlydel, setMonthlydel] = useState([
    0,
    0,
    0,
    0,
    0,
    0,
    0,
    0,
    0,
    0,
    0,
    0,
  ]);
  const [packages, setPackages] = useState([]);
  const [opendrawer, setOpendrawer] = React.useState(false);
  const [searchTerm, setSearchTerm] = useState("");
  const [searchTerm2, setSearchTerm2] = useState("");
  const [loading, setLoading] = useState(true);
  const [drivers, setDrivers] = useState([]);
  const [driverdel, setDriverdel] = useState([]);
  const [driver_id, setDriver_id] = useState(0);
  const [vehicles, setVehicles] = useState([]);
  const [addloading, setAddloading] = useState(false);
  const [driverdetopen, setdriverdetopen] = useState(false);
  const [userData, setUserData] = useState({
    firstname: "",
    lastname: "",
    email: "",
    phonenumber: "",
    password: "",
    confirm: "",
  });
  const [errors, seterrors] = useState([]);
  const [deleteid, setDeleteid] = useState(0);
  const [openconfirm, setOpenconfirm] = useState(false);
  const [page1, setPage1] = React.useState(0);
  const [totalcount1, setTotalcount1] = React.useState(0);
  const [rowsPerPage1, setRowsPerPage1] = React.useState(10);
  const [page3, setPage3] = React.useState(0);
  const [totalcount3, setTotalcount3] = React.useState(0);
  const [rowsPerPage3, setRowsPerPage3] = React.useState(10);

  const handleChangePage1 = (event, newPage) => {
    setLoading(true)
    setPage1(newPage);
  };

  const handleChangeRowsPerPage1 = (event) => {
    setLoading(true)
    setRowsPerPage1(parseInt(event.target.value, 10));
    setPage1(0);
  };


  const handleChangePage3 = (event, newPage) => {
    setLoading(true)
    setPage3(newPage);
    getDriverDeliveries(driver_id,newPage+1,rowsPerPage3)
  };

  const handleChangeRowsPerPage3 = (event) => {
    setLoading(true)
    setRowsPerPage3(parseInt(event.target.value, 10));
    setPage3(0);
    getDriverDeliveries(driver_id,1,parseInt(event.target.value, 10))
  };


  const handleconfirmClose = () => {
    setDeleteid(0);
    setOpenconfirm(false);
  };

  
  const getData = async () => {
    let response = await fetch(props.url + `/get-drivers-vehicle-data`, {
      credentials: "include",
    });
    let data = await response.json();
    if (data.success) {
      setVehicles(data.data);
    } else {
      if( data.message == 'unauthenticated'){
        window.location.replace(props.exit);
        return;
      }
      toast.error(data.message);
    }
  };

  const getApprovedDrivers = async (paged=0) => {
    if(paged){
      setPage1(paged-1)
    }
    let response = await fetch(props.url + `/get-approved-drivers?${searchTerm && 'search='+searchTerm+'&'}page=${paged?paged-1:page1+1}&perpage=${rowsPerPage1}`, {
      credentials: "include",
    });
    let data = await response.json();
    if (data.success) {
      setDrivers(data.data.data);
      setPage1(data.data.current_page - 1)
      setTotalcount1(data.data.total)
      setLoading(false);
    } else {
      setLoading(false);
      if( data.message == 'unauthenticated'){
        window.location.replace(props.exit);
        return;
      }
      toast.error(data.message);
    }
  };
  useEffect(() => {
    getApprovedDrivers();
    getData();
  }, [page1,rowsPerPage1,page3,rowsPerPage3]);

  const handleChange = (event) => {
    setUserData({ ...userData, [event.target.id]: event.target.value });
  };

  const handleSelevtedVehicleChange = (val) => {
    if (selveh.includes(val)) {
      setselveh(selveh.filter((curr) => curr !== val));
    } else {
      setselveh([...selveh, val]);
    }
  };


  const validate = () => {
    let temp = [];
    temp.firstname = userData.firstname ? "" : "This field is required";
    temp.lastname = userData.lastname ? "" : "This field is required";
    temp.selectedveh =
      selveh.length != 0 ? "" : "Please select one or more vehicles";
    if (!userData.email) {
      temp.email = "This field is required";
    } else if (!/\S+@\S+\.\S+/.test(userData.email)) {
      temp.email = "Email address is invalid";
    }
    if (!userData.phonenumber) {
      temp.phonenumber = "This field is required";
    } else if (userData.phonenumber.length < 7) {
      temp.phonenumber = "Phone number should be at least 7 digits";
    }
    temp.password = userData.password ? "" : "This field is required";
    if (!userData.confirm) {
      temp.confirm = "This field is required";
    } else if (userData.confirm !== userData.password) {
      temp.confirm = "Passwords dont match";
    }
    seterrors({ ...temp });
    return Object.values(temp).every((x) => x == "");
  };

  const updatevalidate = () => {
    let temp = [];
    temp.firstname = userData.firstname ? "" : "This field is required";
    temp.lastname = userData.lastname ? "" : "This field is required";
    temp.selectedveh =
      selveh.length != 0 ? "" : "Please select one or more vehicles";
    if (!userData.email) {
      temp.email = "This field is required";
    } else if (!/\S+@\S+\.\S+/.test(userData.email)) {
      temp.email = "Email address is invalid";
    }
    if (!userData.phonenumber) {
      temp.phonenumber = "This field is required";
    } else if (userData.phonenumber.length < 7) {
      temp.phonenumber = "Phone number should be at least 7 digits";
    }

    if (userData.password) {
      if (!userData.confirm) {
        temp.confirm = "This field is required";
      } else {
        if (userData.confirm !== userData.password) {
          temp.confirm = "Passwords dont match";
        }
      }
    }
    seterrors({ ...temp });
    return Object.values(temp).every((x) => x == "");
  };

  const toggleDraweropen = async (event, $id) => {
    if (
      event.type === "keydown" &&
      (event.key === "Tab" || event.key === "Shift")
    ) {
      return;
    }
    setLoading(true);
    let response = await fetch(props.url + `/get-delivery-packages/${$id}`, {
      credentials: "include",
    });
    let res = await response.json();
    if (res) {
      setLoading(false);
      if (res.success) {
        setPackages(res.data);
        console.log(packages);
        setOpendrawer(true);
      } else {
        if( res.message == 'unauthenticated'){
          window.location.replace(props.exit);
          return;
        }
        toast.error(res.message);
      }
    }
  };

  const toggleDrawerclose = (event) => {
    if (
      event.type === "keydown" &&
      (event.key === "Tab" || event.key === "Shift")
    ) {
      return;
    }
    setPackages([]);
    setOpendrawer(false);
  };

  const handledrivdelClose = () => {
    setdrivdelopen(false);
  };

  const handledrivdelOpen = () => {
    setdrivdelopen(true);
  };

  const addhandleClose = () => {
    setUserData({
      firstname: "",
      lastname: "",
      email: "",
      phonenumber: "",
      password: "",
      confirm: "",
    });
    seterrors([]);
    setaddOpen(false);
    setselveh([]);
    setAddloading(false);
  };

  const searchHandler = async () => {
    getApprovedDrivers(1)
  };


  const searchHandler2 = async () => {
    getDriverDeliveries(driver_id,1)
  };

  const handleaddClose = async (event) => {
    event.preventDefault();
    if (validate()) {
      setAddloading(true);
      let response = await fetch(props.url + "/create-driver", {
        method: "POST",
        headers: {
          "Content-type": "application/json",
        },
        body: JSON.stringify({ ...userData, vehicleid: selveh }),
        credentials: "include",
      });
      let res = await response.json();
      if (res) {
        if (res.success) {
          setDrivers([...drivers, { ...res.data }]);
          addhandleClose();
          toast.success("Driver added");
        } else {
          if( res.message == 'unauthenticated'){
            window.location.replace(props.exit);
            return;
          }
          else if (res.message.email[0]) {
            setAddloading(false);
            seterrors({ ...errors, email: res.message.email[0] });
          } else {
            addhandleClose();
            toast.error(res.message);
          }
        }
      }
    }
  };

  const handleeditClose = async (event) => {
    event.preventDefault();
    if (updatevalidate()) {
      setAddloading(true);
      let response = await fetch(props.url + `/update-driver/${editid}`, {
        method: "PUT",
        headers: {
          "Content-type": "application/json",
        },
        body: JSON.stringify({ ...userData, vehicleid: selveh }),
        credentials: "include",
      });
      let res = await response.json();
      if (res) {
        if (res.success) {
          setDrivers(
            drivers.map((prop) => (prop.id === editid ? { ...res.data } : prop))
          );
          handleClose();
          toast.success("Driver Updated");
        } else {
          if( res.message == 'unauthenticated'){
            window.location.replace(props.exit);
            return;
          }
          else if (res.message.email[0]) {
            setAddloading(false);
            seterrors({ ...errors, email: res.message.email[0] });
          } else {
            addhandleClose();
            toast.error(res.message);
          }
        }
      }
    }
  };

  const handleClickOpen = async (id) => {
    setLoading(true);
    let response = await fetch(props.url + `/get-driver/${id}`, {
      credentials: "include",
    });
    let res = await response.json();
    if (res) {
      setLoading(false);
      if (res.success) {
        setUserData({
          firstname: res.data.first_name,
          lastname: res.data.last_name,
          email: res.data.email,
          phonenumber: res.data.phone_number,
          password: "",
          confirm: "",
        });
        const opt = [];
        res.data.vehicle.forEach((veh) => opt.push(veh.id));
        setselveh(opt);
        setEditid(id);
        setOpen(true);
      } else {
        if( res.message == 'unauthenticated'){
          window.location.replace(props.exit);
          return;
        }
        toast.error(res.message);
      }
    }
  };

  const confirmdeleteDriver = (id) => {
    setDeleteid(id);
    setOpenconfirm(true);
  };

  const deleteDriver = async () => {
    setLoading(true);
    const cid = deleteid;
    let response = await fetch(props.url + "/delete-driver", {
      method: "DELETE",
      headers: {
        "Content-type": "application/json",
      },
      body: JSON.stringify({ id: deleteid }),
      credentials: "include",
    });
    let res = await response.json();
    if (res) {
      setDeleteid(0);
      setOpenconfirm(false);
      setLoading(false);
      if (res.success) {
        setDrivers(drivers.filter((current) => cid !== current.id));
        toast.success("Driver deleted");
      } else {
        if( res.message == 'unauthenticated'){
          window.location.replace(props.exit);
          return;
        }
        toast.error(res.message);
      }
    }
  };

  const handledetailsClose = () => {
    setDriverdel([]);
    setUserData({
      firstname: "",
      lastname: "",
      email: "",
      phonenumber: "",
      password: "",
      confirm: "",
    });
    setDriver_id(0)
    setdriverdetopen(false);
  };

  const getDriverDeliveries = async (id,paged=0,rowed=0) => {
    if(paged){
      setPage3(paged-1)
    }
    setLoading(true);
    let response = await fetch(props.url + `/get-driver-delivery-requests/${id}?${searchTerm2 && 'search='+searchTerm2+'&'}page=${paged? paged :page3+1}&perpage=${rowed? rowed :rowsPerPage3}`, {
      credentials: "include",
    });
    let data = await response.json();
    if (data.success) {
      setDriverdel(data.data.data);
      setPage3(data.data.current_page - 1)
      setTotalcount3(data.data.total)
      setLoading(false);
    } else {
      setLoading(false);
      if( data.message == 'unauthenticated'){
        window.location.replace(props.exit);
        return;
      }
      toast.error(data.message);
    }
  };

  const getDriverDeliveriesStats = async (id) => {
    setLoading(true);
    let response = await fetch(props.url + `/get-driver-delivery-requests-stats/${id}`, {
      credentials: "include",
    });
    let res = await response.json();
    if (res) {
      setLoading(false);
    if (res.success) {
      setUserData({
        firstname: res.data.first_name,
        lastname: res.data.last_name,
        email: res.data.email,
        phonenumber: res.data.phone_number,
        vehicle: res.data.vehicle,
        status: res.data.status,
        password: "",
        confirm: "",
        compdel: res.data.completed_count,
        penddel: res.data.pending_count,
        pendingpickup: res.data.pending_pickup,
        onroute: res.data.onroute,
        completedtoday: res.data.completed_today,
      });
      setMonthlydel(res.data.monthlydel);
      setDriver_id(id)
      setdriverdetopen(true);
    } else {
      if( res.message == 'unauthenticated'){
        window.location.replace(props.exit);
        return;
      }
      toast.error(res.message);
    }
  }
  };

  const handledetailsOpen = (id) => {
    setLoading(true);
    getDriverDeliveries(id)
    getDriverDeliveriesStats(id)
  };
  const handleClose = () => {
    setUserData({
      firstname: "",
      lastname: "",
      email: "",
      phonenumber: "",
      password: "",
      confirm: "",
    });
    seterrors([]);
    setselveh([]);
    setOpen(false);
    setAddloading(false);
  };
  const addhandleClickOpen = () => {
    setaddOpen(true);
  };
  handledetailsClose;
  return (
    <Card>
      {loading && (
        <Backdrop className={classes.backdrop} open>
          <CircularProgress color="inherit" />
        </Backdrop>
      )}
      <CardHeader color="danger">
        <h4 className={classes.cardTitleWhite}>Delivery Drivers</h4>
      </CardHeader>
      <CardBody>
            <Button onClick={addhandleClickOpen} color="danger">
              <Add /> Add Driver
            </Button>
            <Table
              searchKeyword={searchHandler}
              term={searchTerm}
              setTerm={setSearchTerm}
              tableHeaderColor="gray"
              tableHead={[
                "",
                "Name",
                "Email",
                "Telephone",
                "Assigned Vehicles",
                "Driver details",
                "Edit",
                "Delete",
              ]}
              tableData={
                drivers.length == 0 ? (
                  <TableRow>
                    <TableCell
                      style={{ textAlign: "center" }}
                      colSpan={7}
                      className={classes.tableCell}
                    >
                      No Driver
                    </TableCell>
                  </TableRow>
                ) : (
                  drivers.map((prop) => {
                    return (
                      <TableRow key={prop.id} className={classes.tableBodyRow}>
                        <TableCell className={classes.tableCell} key={prop.id}>
                        <Avatar
                            alt={prop.first_name}
                            src={props.imgurl + prop.avatar}
                            sx={{ width: 56, height: 56 }}
                          />
                        </TableCell>
                        <TableCell className={classes.tableCell} key={prop.id}>
                          {prop.first_name + " " + prop.last_name}
                        </TableCell>
                        <TableCell className={classes.tableCell} key={prop.id}>
                          {prop.email}
                        </TableCell>
                        <TableCell className={classes.tableCell} key={prop.id}>
                          {prop.phone_number}
                        </TableCell>
                        <TableCell className={classes.tableCell} key={prop.id}>
                          <div className={classes.flexContainer}>
                            {prop.vehicle.map((prop) => (
                              <img
                                className={classes.vehicleimg}
                                variant="square"
                                key={prop.id}
                                alt="X"
                                src={props.imgurl + prop.img}
                              />
                            ))}
                          </div>
                        </TableCell>
                        <TableCell className={classes.tableCell} key={prop.id}>
                          <Details
                            onClick={() => handledetailsOpen(prop.id)}
                            style={{ color: "black", cursor: "pointer" }}
                            key={prop.id}
                          />
                        </TableCell>
                        <TableCell className={classes.tableCell} key={prop.id}>
                          <Edit
                            onClick={() => handleClickOpen(prop.id)}
                            style={{ color: "black", cursor: "pointer" }}
                            key={prop.id}
                          />
                        </TableCell>
                        <TableCell className={classes.tableCell} key={prop.id}>
                          <Delete
                            style={{ cursor: "pointer" }}
                            onClick={() => confirmdeleteDriver(prop.id)}
                            key={prop.id}
                          />
                        </TableCell>
                      </TableRow>
                    );
                  })
                )
              }
            />
            <TablePagination
      component="div"
      count={totalcount1}
      page={page1}
      onPageChange={handleChangePage1}
      rowsPerPage={rowsPerPage1}
      onRowsPerPageChange={handleChangeRowsPerPage1}
    />
         
      </CardBody>
      <Dialog
        fullWidth={true}
        maxWidth={!addloading ? "sm" : "xs"}
        open={addopen}
        TransitionComponent={Transition}
        keepMounted
        onClose={addhandleClose}
        aria-labelledby="alert-dialog-slide-title"
        aria-describedby="alert-dialog-slide-description"
      >
        <DialogContent>
          <DialogContentText id="alert-dialog-slide-description">
            <GridContainer>
              <GridItem xs={12} sm={12} md={12}>
                <>
                  <CardHeader
                    style={{ textAlign: "center" }}
                    color={!addloading ? "danger" : ""}
                  >
                    <h4
                      className={
                        !addloading
                          ? classes.cardTitleWhite
                          : classes.cardTitleBlack
                      }
                    >
                      {!addloading ? "Add driver" : "Adding Driver"}
                    </h4>
                  </CardHeader>
                  <CardBody>
                    {addloading ? (
                      <DialogContentText style={{ textAlign: "center" }}>
                        <CircularProgress color="secondary" />
                      </DialogContentText>
                    ) : (
                      <form onSubmit={handleaddClose}>
                        <GridContainer>
                          <GridItem xs={12} sm={12} md={6}>
                            <TextField
                              onChange={handleChange}
                              id="firstname"
                              value={userData.firstname}
                              label="First Name"
                              style={{ marginTop: 10 }}
                              fullWidth
                              margin="normal"
                              InputProps={{
                                startAdornment: (
                                  <InputAdornment position="start">
                                    <PersonOutlineIcon />
                                  </InputAdornment>
                                ),
                              }}
                              error={errors.firstname}
                              helperText={errors.firstname && errors.firstname}
                            />
                          </GridItem>
                          <GridItem xs={12} sm={12} md={6}>
                            <TextField
                              onChange={handleChange}
                              id="lastname"
                              value={userData.lastname}
                              label="Last Name"
                              style={{ marginTop: 10 }}
                              fullWidth
                              margin="normal"
                              InputProps={{
                                startAdornment: (
                                  <InputAdornment position="start">
                                    <PersonOutlineIcon />
                                  </InputAdornment>
                                ),
                              }}
                              error={errors.lastname}
                              helperText={errors.lastname && errors.lastname}
                            />
                          </GridItem>
                        </GridContainer>

                        <GridContainer>
                          <GridItem xs={12} sm={12} md={12}>
                            <TextField
                              onChange={handleChange}
                              id="email"
                              value={userData.email}
                              label="Email"
                              style={{ marginTop: 10 }}
                              fullWidth
                              type="email"
                              margin="normal"
                              InputProps={{
                                startAdornment: (
                                  <InputAdornment position="start">
                                    <MailOutlineIcon />
                                  </InputAdornment>
                                ),
                              }}
                              error={errors.email}
                              helperText={errors.email && errors.email}
                            />
                          </GridItem>
                        </GridContainer>
                        <GridContainer>
                          <GridItem xs={12} sm={12} md={12}>
                            <TextField
                              onChange={handleChange}
                              id="phonenumber"
                              value={userData.phonenumber}
                              label="Phone Number"
                              style={{ marginTop: 10 }}
                              fullWidth
                              type="number"
                              margin="normal"
                              InputProps={{
                                startAdornment: (
                                  <InputAdornment position="start">
                                    <PhoneIcon />
                                  </InputAdornment>
                                ),
                              }}
                              error={errors.phonenumber}
                              helperText={
                                errors.phonenumber && errors.phonenumber
                              }
                            />
                          </GridItem>
                        </GridContainer>
                        <GridContainer>
                          <GridItem xs={12} sm={12} md={6}>
                            <TextField
                              onChange={handleChange}
                              value={userData.password}
                              label="Password"
                              type="password"
                              style={{ marginTop: 10 }}
                              id="password"
                              fullWidth
                              margin="normal"
                              InputProps={{
                                startAdornment: (
                                  <InputAdornment position="start">
                                    <LockOpenIcon />
                                  </InputAdornment>
                                ),
                              }}
                              error={errors.password}
                              helperText={errors.password && errors.password}
                            />
                          </GridItem>
                          <GridItem xs={12} sm={12} md={6}>
                            <TextField
                              onChange={handleChange}
                              value={userData.confirm}
                              type="password"
                              label="Confirm Password"
                              style={{ marginTop: 10 }}
                              id="confirm"
                              fullWidth
                              margin="normal"
                              InputProps={{
                                startAdornment: (
                                  <InputAdornment position="start">
                                    <LockOpenIcon />
                                  </InputAdornment>
                                ),
                              }}
                              error={errors.confirm}
                              helperText={errors.confirm && errors.confirm}
                            />
                          </GridItem>
                        </GridContainer>
                        <GridContainer>
                          <GridItem xs={12} sm={12} md={12}>
                            <label>Assigned Vehicle (choose one)</label>
                            <br />
                            <Danger>{errors.selectedveh}</Danger>
                            <div className={classes.flexContainer}>
                              {vehicles.map((prop) => (
                                <div
                                  onClick={() =>
                                    handleSelevtedVehicleChange(prop.id)
                                  }
                                  key={prop.id}
                                  className={classes.flexItem}
                                >
                                  <img
                                    width="100%"
                                    height="100%"
                                    src={props.imgurl + prop.img}
                                  />
                                  <div
                                    className={
                                      selveh.includes(prop.id)
                                        ? classes.selected
                                        : classes.overlay
                                    }
                                  >
                                    <CheckCircle className={classes.playIcon} />
                                  </div>
                                </div>
                              ))}
                            </div>
                          </GridItem>
                        </GridContainer>
                      </form>
                    )}
                  </CardBody>
                </>
              </GridItem>
            </GridContainer>
          </DialogContentText>
        </DialogContent>
        {!addloading && (
          <DialogActions>
            <Button
              style={{ display: "block", marginLeft: "0", marginRight: "auto" }}
              onClick={addhandleClose}
              color="danger"
            >
              <Close />
              Cancel
            </Button>
            <Button
              style={{ float: "right" }}
              color="danger"
              onClick={handleaddClose}
            >
              Create <Arrow />
            </Button>
          </DialogActions>
        )}
      </Dialog>
      <Dialog
        fullWidth={true}
        maxWidth={!addloading ? "sm" : "xs"}
        open={open}
        TransitionComponent={Transition}
        keepMounted
        onClose={handleClose}
        aria-labelledby="alert-dialog-slide-title"
        aria-describedby="alert-dialog-slide-description"
      >
        <DialogContent>
          <DialogContentText id="alert-dialog-slide-description">
            <GridContainer>
              <GridItem xs={12} sm={12} md={12}>
                <>
                  <CardHeader
                    style={{ textAlign: "center" }}
                    color={!addloading ? "danger" : ""}
                  >
                    <h4
                      className={
                        !addloading
                          ? classes.cardTitleWhite
                          : classes.cardTitleBlack
                      }
                    >
                      {!addloading ? "Update driver" : "Updating Driver"}
                    </h4>
                  </CardHeader>
                  <CardBody>
                    {addloading ? (
                      <DialogContentText style={{ textAlign: "center" }}>
                        <CircularProgress color="secondary" />
                      </DialogContentText>
                    ) : (
                      <form onSubmit={handleeditClose}>
                        <GridContainer>
                          <GridItem xs={12} sm={12} md={6}>
                            <TextField
                              onChange={handleChange}
                              id="firstname"
                              value={userData.firstname}
                              label="First Name"
                              style={{ marginTop: 10 }}
                              fullWidth
                              margin="normal"
                              InputProps={{
                                startAdornment: (
                                  <InputAdornment position="start">
                                    <PersonOutlineIcon />
                                  </InputAdornment>
                                ),
                              }}
                              error={errors.firstname}
                              helperText={errors.firstname && errors.firstname}
                            />
                          </GridItem>
                          <GridItem xs={12} sm={12} md={6}>
                            <TextField
                              onChange={handleChange}
                              id="lastname"
                              value={userData.lastname}
                              label="Last Name"
                              style={{ marginTop: 10 }}
                              fullWidth
                              margin="normal"
                              InputProps={{
                                startAdornment: (
                                  <InputAdornment position="start">
                                    <PersonOutlineIcon />
                                  </InputAdornment>
                                ),
                              }}
                              error={errors.lastname}
                              helperText={errors.lastname && errors.lastname}
                            />
                          </GridItem>
                        </GridContainer>

                        <GridContainer>
                          <GridItem xs={12} sm={12} md={12}>
                            <TextField
                              onChange={handleChange}
                              id="email"
                              value={userData.email}
                              label="Email"
                              style={{ marginTop: 10 }}
                              fullWidth
                              type="email"
                              margin="normal"
                              InputProps={{
                                startAdornment: (
                                  <InputAdornment position="start">
                                    <MailOutlineIcon />
                                  </InputAdornment>
                                ),
                              }}
                              error={errors.email}
                              helperText={errors.email && errors.email}
                            />
                          </GridItem>
                        </GridContainer>
                        <GridContainer>
                          <GridItem xs={12} sm={12} md={12}>
                            <TextField
                              onChange={handleChange}
                              id="phonenumber"
                              value={userData.phonenumber}
                              label="Phone Number"
                              style={{ marginTop: 10 }}
                              fullWidth
                              type="number"
                              margin="normal"
                              InputProps={{
                                startAdornment: (
                                  <InputAdornment position="start">
                                    <PhoneIcon />
                                  </InputAdornment>
                                ),
                              }}
                              error={errors.phonenumber}
                              helperText={
                                errors.phonenumber && errors.phonenumber
                              }
                            />
                          </GridItem>
                        </GridContainer>
                        <GridContainer>
                          <GridItem xs={12} sm={12} md={6}>
                            <TextField
                              onChange={handleChange}
                              value={userData.password}
                              label="Password"
                              type="password"
                              style={{ marginTop: 10 }}
                              id="password"
                              fullWidth
                              InputLabelProps={{
                                shrink: true,
                              }}
                              placeholder="Enter to change current password"
                              margin="normal"
                              error={errors.password}
                              helperText={errors.password && errors.password}
                            />
                          </GridItem>
                          <GridItem xs={12} sm={12} md={6}>
                            <TextField
                              onChange={handleChange}
                              value={userData.confirm}
                              type="password"
                              label="Confirm Password"
                              style={{ marginTop: 10 }}
                              id="confirm"
                              InputLabelProps={{
                                shrink: true,
                              }}
                              placeholder="Enter to confirm change"
                              fullWidth
                              margin="normal"
                              error={errors.confirm}
                              helperText={errors.confirm && errors.confirm}
                            />
                          </GridItem>
                        </GridContainer>
                        <GridContainer>
                          <GridItem xs={12} sm={12} md={12}>
                            <label>Assigned Vehicle (choose one)</label>
                            <br />
                            <Danger>{errors.selectedveh}</Danger>
                            <div className={classes.flexContainer}>
                              {vehicles.map((prop) => (
                                <div
                                  onClick={() =>
                                    handleSelevtedVehicleChange(prop.id)
                                  }
                                  key={prop.id}
                                  className={classes.flexItem}
                                >
                                  <img
                                    width="100%"
                                    height="100%"
                                    src={props.imgurl + prop.img}
                                  />
                                  <div
                                    className={
                                      selveh.includes(prop.id)
                                        ? classes.selected
                                        : classes.overlay
                                    }
                                  >
                                    <CheckCircle className={classes.playIcon} />
                                  </div>
                                </div>
                              ))}
                            </div>
                          </GridItem>
                        </GridContainer>
                      </form>
                    )}
                  </CardBody>
                </>
              </GridItem>
            </GridContainer>
          </DialogContentText>
        </DialogContent>
        {!addloading && (
          <DialogActions>
            <Button
              style={{ display: "block", marginLeft: "0", marginRight: "auto" }}
              onClick={handleClose}
              color="danger"
            >
              <Close />
              Cancel
            </Button>
            <Button
              style={{ float: "right" }}
              color="danger"
              onClick={handleeditClose}
            >
              Update <Arrow />
            </Button>
          </DialogActions>
        )}
      </Dialog>
      <Dialog
        fullWidth={true}
        maxWidth={"md"}
        open={driverdetopen}
        TransitionComponent={Transition}
        keepMounted
        onClose={handledetailsClose}
        aria-labelledby="alert-dialog-slide-title"
        aria-describedby="alert-dialog-slide-description"
      >
        <DialogContent>
          <DialogContentText id="alert-dialog-slide-description">
            <GridContainer>
              <GridItem xs={12} sm={12} md={12}>
                <>
                  <CardHeader style={{ textAlign: "center" }} color="danger">
                    <h4 className={classes.cardTitleWhite}>Driver Details</h4>
                  </CardHeader>
                  <CardBody>
                    <GridContainer>
                      <GridItem style={{ marginBottom: "30px" }} xs={12} sm={12} md={12}>
                        <CardHeader style={{ marginTop: 10 }} color="danger">
                          <h4 style={{ textAlign: "center" }}>
                            Monthly Delivery Assignment for{" "}
                            {1900 + new Date().getYear()}{" "}
                          </h4>
                          <ChartistGraph
                            className="ct-chart"
                            data={{
                              labels: [
                                "Jan",
                                "Feb",
                                "Mar",
                                "Apr",
                                "May",
                                "Jun",
                                "Jul",
                                "Aug",
                                "Sep",
                                "Oct",
                                "Nov",
                                "Dec",
                              ],
                              series: [monthlydel],
                            }}
                            type="Bar"
                            options={{
                              ...monthlyDeliveriesChart.options,
                              high: Math.max(...monthlydel) + 5,
                            }}
                            responsiveOptions={
                              monthlyDeliveriesChart.responsiveOptions
                            }
                            listener={monthlyDeliveriesChart.animation}
                          />
                        </CardHeader>
                      </GridItem>
                    </GridContainer>
                    <GridContainer>
                      <GridItem
                        className={classes.centertext}
                        xs={12}
                        sm={12}
                        md={4}
                      >
                        <div>
                          <h6 className={classes.detailhead}>
                            <em>Driver</em>
                          </h6>
                          <div className={classes.detailcell}>
                            <PersonOutlineIcon />
                            <span>
                              Name:{" "}
                              {userData.firstname + " " + userData.lastname}
                            </span>
                          </div>
                          <div className={classes.detailcell}>
                            <EmailIcon />
                            <span>Email: {userData.email}</span>
                          </div>
                          <div className={classes.detailcell}>
                            <CallIcon />
                            <span>Telephone: {userData.phonenumber}</span>
                          </div>
                          <hr />
                          <div className={classes.detailcell}>
                            <div className={classes.flexContainer}>
                              {userData.vehicle &&
                                userData.vehicle.map((prop) => (
                                  <img
                                    className={classes.vehicleimg}
                                    variant="square"
                                    key={prop.id}
                                    alt="X"
                                    src={props.imgurl + prop.img}
                                  />
                                ))}
                            </div>
                          </div>
                        </div>
                      </GridItem>
                      <GridItem
                        className={classes.centertext}
                        xs={12}
                        sm={12}
                        md={4}
                      >
                        <div>
                          <h6 className={classes.detailhead}>
                            <em>Deliveries</em>
                          </h6>
                          <div className={classes.detailcell}>
                            <DoneAllIcon />
                            <span>
                              Completed Deliveries: {userData.compdel}
                            </span>
                          </div>
                          <div className={classes.detailcell}>
                            <HourglassEmptyIcon />
                            <span>
                              Incomplete Deliveries: {userData.penddel}
                            </span>
                          </div>
                          <div className={classes.detailcell}>
                            <ListAltIcon />
                            <span>
                              Total Assigned:{" "}
                              {userData.penddel + userData.compdel}
                            </span>
                          </div>
                          <hr />
                          <div
                            style={{ cursor: "pointer", marginTop: "10px" }}
                            onClick={handledrivdelOpen}
                            className={classes.detailcell}
                          >
                            <Details /> View Deliveries<span></span>
                          </div>
                        </div>
                      </GridItem>
                      <GridItem
                        className={classes.centertext}
                        xs={12}
                        sm={12}
                        md={4}
                      >
                        <div>
                          <h6 className={classes.detailhead}>
                            <em>Current Deliveries</em>
                          </h6>
                          <div className={classes.detailcell}>
                            <span>
                              Deliveries Pending Pickup:{" "}
                              {userData.pendingpickup}
                            </span>
                          </div>
                          <div className={classes.detailcell}>
                            <span>On-Route to Deliver: {userData.onroute}</span>
                          </div>
                          <div className={classes.detailcell}>
                            <span>
                              Deliveries Completed Today:{" "}
                              {userData.completedtoday}
                            </span>
                          </div>
                          <hr />
                          <div className={classes.detailcell}>
                            Driver Status:&nbsp;
                            <Chip
                              label={userData.status}
                              color={
                                userData.status == "available"
                                  ? "primary"
                                  : "secondary"
                              }
                            />
                            <span></span>
                          </div>
                        </div>
                      </GridItem>
                      <GridItem xs={12} sm={12} md={12}>
                        <div className={classes.centertext}>
                          <Button
                            onClick={handledetailsClose}
                            variant="outlined"
                            style={{
                              background: "#ababab",
                              color: "white",
                              cursor: "pointer",
                            }}
                            className={classes.submit}
                          >
                            Close
                          </Button>
                        </div>
                      </GridItem>
                    </GridContainer>
                  </CardBody>
                </>
              </GridItem>
            </GridContainer>
          </DialogContentText>
        </DialogContent>
      </Dialog>
      <Dialog
        fullScreen
        open={drivdelopen}
        onClose={handledrivdelClose}
        TransitionComponent={Transition2}
      >
        <AppBar className={classes.appBar}>
          <Toolbar>
            <IconButton
              edge="start"
              color="inherit"
              onClick={handledrivdelClose}
              aria-label="close"
            >
              <CloseIcon />
            </IconButton>
            <Typography variant="h6" className={classes.title}>
              Deliveries Assigned To{" "}
              {userData.firstname + " " + userData.lastname}
            </Typography>
          </Toolbar>
        </AppBar>
        <DialogContent style={{ background: "#EEEEEE" }}>
          <Table
            searchKeyword={searchHandler2}
            term={searchTerm2}
            setTerm={setSearchTerm2}
            tableHeaderColor="gray"
            tableHead={[
              "Order ID",
              "Service",
              "Sender",
              "From",
              "To",
              "Status",
              "Packages",
            ]}
            tableData={
              driverdel.length == 0 ? (
                <TableRow>
                  <TableCell
                    style={{ textAlign: "center" }}
                    colSpan={7}
                    className={classes.tableCell}
                  >
                    No Deliveries
                  </TableCell>
                </TableRow>
              ) : (
                driverdel.map((prop) => {
                  return (
                    <TableRow key={prop.id} className={classes.tableBodyRow}>
                      <TableCell className={classes.tableCell} key={prop.id}>
                      <Link to={{pathname: "/admin/searchdeliveries", property: { orderId: prop.delivery.order_id }}} >
                        {prop.delivery.order_id}
                        </Link>
                      </TableCell>
                      <TableCell className={classes.tableCell} key={prop.id}>
                        {prop.delivery.service.service_name}
                      </TableCell>
                      <TableCell className={classes.tableCell} key={prop.id}>
                      {prop.delivery.creator ? prop.delivery.creator.first_name : prop.delivery.sender_name}{" "}
                       {(prop.delivery.creator && prop.delivery.creator.last_name) && prop.delivery.creator.last_name}
                      </TableCell>
                      <TableCell className={classes.tableCell} key={prop.id}>
                        {prop.from_id &&
                          prop.delivery.from_location.location_name}{" "}
                        {prop.fromAddress && "(" + prop.fromAddress + ")"}
                        {prop.facility_id &&
                          prop.to_id &&
                          prop.facility.facility_name +
                            "(" +
                            prop.facility.zone.zone_name +
                            ")"}
                        {prop.from_facility_id &&
                          prop.from_facility.facility_name +
                            "(" +
                            prop.from_facility.zone.zone_name +
                            ")"}
                      </TableCell>
                      <TableCell className={classes.tableCell} key={prop.id}>
                        {prop.to_id && prop.delivery.to_location.location_name}{" "}
                        {prop.toAddress && "(" + prop.toAddress + ")"}
                        {prop.facility_id &&
                          prop.from_id &&
                          prop.facility.facility_name +
                            "(" +
                            prop.facility.zone.zone_name +
                            ")"}
                        {prop.to_facility_id &&
                          prop.to_facility.facility_name +
                            "(" +
                            prop.to_facility.zone.zone_name +
                            ")"}
                      </TableCell>
                      <TableCell className={classes.tableCell} key={prop.id}>
                        {prop.status == "pendingpickup" && "On-Route to Pickup"}
                        {prop.status == "onroute" && "On-Route to Deliver"}
                        {prop.status == "complete" && "Delivered"}
                      </TableCell>
                      <TableCell className={classes.tableCell} key={prop.id}>
                        {prop.delivery.service.service_name ==
                        "Food Service" ? (
                          <VisibilityOffIcon
                            style={{ cursor: "not-allowed" }}
                          />
                        ) : (
                          <View
                            onClick={(e) =>
                              toggleDraweropen(e, prop.delivery.id)
                            }
                            style={{ cursor: "pointer" }}
                          />
                        )}
                      </TableCell>
                    </TableRow>
                  );
                })
              )
            }
          />
          <TablePagination
      component="div"
      count={totalcount3}
      page={page3}
      onPageChange={handleChangePage3}
      rowsPerPage={rowsPerPage3}
      onRowsPerPageChange={handleChangeRowsPerPage3}
    />
        </DialogContent>
      </Dialog>
      <Drawer anchor={"right"} open={opendrawer} onClose={toggleDrawerclose}>
        <div className={clsx(classes.list)} role="presentation">
          <List style={{ background: "#EEEEEE" }}>
            <ListItem>
              <ListItemIcon>
                <Close
                  style={{ cursor: "pointer" }}
                  onClick={toggleDrawerclose}
                />
              </ListItemIcon>
              <ListItemIcon>
                <FontAwesomeIcon icon={faBoxOpen} />
              </ListItemIcon>
              <ListItemText
                primary={
                  packages.packages ? packages.packages.length + " Packages" : packages.items ? packages.items.length + " Items" : ""
                }
              />
            </ListItem>
          </List>
          <Divider />
          <Divider style={{ marginBottom: "10px" }} />
          {packages.packages &&
            packages.packages.map((prop) => {
              return (
                <>
                  <ListItem>
                    <GridContainer>
                      <GridItem xs={4} sm={4} md={4}>
                        <Avatar
                          className={classes.avatarimg}
                          variant="square"
                          key={prop.id}
                          alt="Package"
                          src={props.imgurl + prop.packages.img}
                        />
                      </GridItem>
                      <GridItem xs={4} sm={4} md={4}>
                        <Typography
                          align="center"
                          variant="subtitle2"
                          gutterBottom
                        >
                          Package Type
                        </Typography>
                        <Typography
                          align="center"
                          variant="caption"
                          display="block"
                          gutterBottom
                        >
                          {prop.packages.package_name}{" "}
                        </Typography>
                      </GridItem>
                      <GridItem xs={4} sm={4} md={4}>
                        <Typography
                          align="center"
                          variant="subtitle2"
                          gutterBottom
                        >
                          Package ID
                        </Typography>
                        <Typography
                          align="center"
                          variant="caption"
                          display="block"
                          gutterBottom
                        >
                          {prop.package_reference_id
                            ? prop.package_reference_id
                            : "N/A"}
                        </Typography>
                      </GridItem>
                    </GridContainer>
                  </ListItem>
                  <Divider style={{ marginBottom: "10px" }} />
                </>
              );
            })}
            {packages.items &&
            packages.items.map((prop) => {
              return (
                <>
                  <ListItem>
                    <GridContainer style={{ width: "100%" }}>
                      <GridItem xs={4} sm={4} md={4}>
                      <img
                          className={classes.vehicleimg}
                          key={prop.id}
                          alt="Item"
                          src={props.imgurl + prop.items.img}
                        />
                      </GridItem>
                      <GridItem xs={4} sm={4} md={4}>
                        <Typography variant="subtitle2" gutterBottom>
                          Package Type
                        </Typography>
                        <Typography
                          variant="caption"
                          display="block"
                          gutterBottom
                        >
                          {prop.items.item_name}{" "}
                        </Typography>
                      </GridItem>
                      <GridItem xs={4} sm={4} md={4}>
                        <Typography variant="subtitle2" gutterBottom>
                          Package ID
                        </Typography>
                        <Typography
                          variant="caption"
                          display="block"
                          gutterBottom
                        >
                          {prop.package_reference_id
                            ? prop.package_reference_id
                            : "N/A"}
                        </Typography>
                      </GridItem>
                    </GridContainer>
                  </ListItem>

                  <Divider style={{ marginBottom: "10px" }} />
                </>
              );
            })}
        </div>
      </Drawer>
      <Dialog
        fullWidth={true}
        maxWidth="sm"
        open={openconfirm}
        TransitionComponent={Transition}
        keepMounted
        classes={{ scrollPaper: classes.scrollPaper }}
        onClose={handleconfirmClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">{"DELETE DRIVER"}</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            Are you sure you want to delete this driver?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button
            style={{ display: "block", marginLeft: "0", marginRight: "auto" }}
            onClick={handleconfirmClose}
            color="danger"
          >
            <Close />
            Cancel
          </Button>
          <Button
            style={{ float: "right" }}
            color="danger"
            onClick={deleteDriver}
          >
            Proceed <Arrow />
          </Button>
        </DialogActions>
      </Dialog>
    </Card>
  );
}

Drivers.propTypes = {
  url: PropTypes.string,
  imgurl: PropTypes.string,
  exit: PropTypes.string,
};
