import React, { useState, useEffect } from "react";
import TextField from "@material-ui/core/TextField";
import { makeStyles } from "@material-ui/core/styles";
import Backdrop from "@material-ui/core/Backdrop";
import Close from "@material-ui/icons/Close";
import MenuItem from "@material-ui/core/MenuItem";
import PhoneIcon from "@material-ui/icons/Phone";
import FormHelperText from '@material-ui/core/FormHelperText';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import LockOpenIcon from "@material-ui/icons/LockOpen";
import MailOutlineIcon from "@material-ui/icons/MailOutline";
import InputLabel from "@material-ui/core/InputLabel";
import InputAdornment from "@material-ui/core/InputAdornment";
import TablePagination from '@material-ui/core/TablePagination';
import PropTypes from "prop-types";
import PersonOutlineIcon from "@material-ui/icons/PersonOutline";
import Arrow from "@material-ui/icons/DoubleArrow";
import { toast } from "react-toastify";
import DialogTitle from "@material-ui/core/DialogTitle";
import "react-toastify/dist/ReactToastify.css";
import Card from "components/Card/Card.js";
import CardHeader from "components/Card/CardHeader.js";
import CardBody from "components/Card/CardBody.js";
import Table from "components/Table/Table.js";
import Edit from "@material-ui/icons/Edit";
import Delete from "@material-ui/icons/DeleteOutline";
import Button from "components/CustomButtons/Button.js";
import Add from "@material-ui/icons/Add";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import CircularProgress from "@material-ui/core/CircularProgress";
import TableRow from "@material-ui/core/TableRow";
import TableCell from "@material-ui/core/TableCell";
import GridItem from "components/Grid/GridItem.js";
import GridContainer from "components/Grid/GridContainer.js";
import Slide from "@material-ui/core/Slide";

const styles = {
  backdrop: {
    zIndex: 5,
    color: "#fff",
  },
  scrollPaper: {
    alignItems: "baseline", // default center
  },
  tableCell: {
    textAlign: "center",
  },
  cardCategoryWhite: {
    "&,& a,& a:hover,& a:focus": {
      color: "rgba(255,255,255,.62)",
      margin: "0",
      fontSize: "14px",
      marginTop: "0",
      marginBottom: "0",
    },
    "& a,& a:hover,& a:focus": {
      color: "#FFFFFF",
    },
  },
  cardTitleWhite: {
    color: "#FFFFFF",
    marginTop: "0px",
    minHeight: "auto",
    fontWeight: "300",
    fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif",
    marginBottom: "3px",
    textDecoration: "none",
    "& small": {
      color: "#777",
      fontSize: "65%",
      fontWeight: "400",
      lineHeight: "1",
    },
  },
  cardTitleBlack: {
    color: "#000000",
    marginTop: "0px",
    minHeight: "auto",
    fontWeight: "300",
    fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif",
    marginBottom: "3px",
    textDecoration: "none",
    "& small": {
      color: "#777",
      fontSize: "65%",
      fontWeight: "400",
      lineHeight: "1",
    },
  },
};

const useStyles = makeStyles(styles);
const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="down" ref={ref} {...props} />;
});

export default function Staff(props) {
  toast.configure();
  const [editid, setEditid] = useState(0);
  const [searchTerm, setSearchTerm] = useState("");
  const [open, setOpen] = React.useState(false);
  const [addopen, setaddOpen] = React.useState(false);
  const [loading, setLoading] = useState(true);
  const [staffs, setStaffs] = useState([]);
  const [addloading, setAddloading] = useState(false);
  const [userData, setUserData] = useState({
    firstname: "",
    lastname: "",
    email: "",
    phonenumber: "",
    role:"",
    password: "",
    confirm: "",
  });
  const [errors, seterrors] = useState([]);
  const [deleteid, setDeleteid] = useState(0);
  const [openconfirm, setOpenconfirm] = useState(false);
  const [page, setPage] = React.useState(0);
  const [totalcount, setTotalcount] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);

  const handleChangePage = (event, newPage) => {
    setLoading(true)
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
    setLoading(true)
  };
  

  const handleconfirmClose = () => {
    setDeleteid(0);
    setOpenconfirm(false);
  };

  const getStaffs = async (paged=0) => {
    if(paged){
      setPage(paged-1)
    }
    let response = await fetch(props.url + `/get-staffs?${searchTerm && 'search='+searchTerm+'&'}page=${paged?paged-1:page+1}&perpage=${rowsPerPage}`, { credentials: "include" });
    let data = await response.json();
    if (data.success) {
      setStaffs(data.data.data);
      setPage(data.data.current_page - 1)
      setTotalcount(data.data.total)
      setLoading(false);
    } else {
      setLoading(false);
      if( data.message == 'unauthenticated'){
        window.location.replace(props.exit);
        return;
      }
      toast.error(data.message);
    }
  };

  useEffect(() => {
    getStaffs();
  }, [page,rowsPerPage]);

  const handleChange = (event) => {
    setUserData({ ...userData, [event.target.id]: event.target.value });
  };
  const validate = () => {
    let temp = [];
    temp.firstname = userData.firstname ? "" : "This field is required";
    temp.lastname = userData.lastname ? "" : "This field is required";
    temp.role = userData.role ? "" : "This field is required";
    if (!userData.email) {
      temp.email = "This field is required";
    } else if (!/\S+@\S+\.\S+/.test(userData.email)) {
      temp.email = "Email address is invalid";
    }
    if (!userData.phonenumber) {
      temp.phonenumber = "This field is required";
    } else if (userData.phonenumber.length < 7) {
      temp.phonenumber = "Phone number should be at least 7 digits";
    }
    temp.password = userData.password ? "" : "This field is required";
    if (!userData.confirm) {
      temp.confirm = "This field is required";
    } else if (userData.confirm !== userData.password) {
      temp.confirm = "Passwords dont match";
    }
    seterrors({ ...temp });
    return Object.values(temp).every((x) => x == "");
  };

  const updatevalidate = () => {
    let temp = [];
    temp.firstname = userData.firstname ? "" : "This field is required";
    temp.lastname = userData.lastname ? "" : "This field is required";
    if (!userData.email) {
      temp.email = "This field is required";
    } else if (!/\S+@\S+\.\S+/.test(userData.email)) {
      temp.email = "Email address is invalid";
    }
    if (!userData.phonenumber) {
      temp.phonenumber = "This field is required";
    } else if (userData.phonenumber.length < 7) {
      temp.phonenumber = "Phone number should be at least 7 digits";
    }

    if (userData.password) {
      if (!userData.confirm) {
        temp.confirm = "This field is required";
      } else {
        if (userData.confirm !== userData.password) {
          temp.confirm = "Passwords dont match";
        }
      }
    }
    seterrors({ ...temp });
    return Object.values(temp).every((x) => x == "");
  };

  const handleaddClose = async (event) => {
    event.preventDefault();
    if (validate()) {
      setAddloading(true);
      let response = await fetch(props.url + "/create-staff", {
        method: "POST",
        headers: {
          "Content-type": "application/json",
        },
        body: JSON.stringify(userData),
        credentials: "include",
      });
      let res = await response.json();
      if (res) {
        if (res.success) {
          setStaffs([...staffs, { ...res.data }]);
          addhandleClose();
          toast.success("Staff added");
        } else {
          setAddloading(false);
          if( res.message == 'unauthenticated'){
            window.location.replace(props.exit);
            return;
          }
          else if (res.message.email[0]) {
            seterrors({ ...errors, email: res.message.email[0] });
          } else {
            addhandleClose();
            toast.error(res.message);
          }
        }
      }
    }
  };

  const handleeditClose = async (event) => {
    event.preventDefault();
    if (updatevalidate()) {
      setAddloading(true);
      let response = await fetch(props.url + `/update-staff/${editid}`, {
        method: "PUT",
        headers: {
          "Content-type": "application/json",
        },
        body: JSON.stringify(userData),
        credentials: "include",
      });
      let res = await response.json();
      if (res) {
        if (res.success) {
          setStaffs(
            staffs.map((prop) => (prop.id === editid ? { ...res.data } : prop))
          );
          handleClose();
          toast.success("Staff Updated");
        } else {
          setAddloading(false);
          if( res.message == 'unauthenticated'){
            window.location.replace(props.exit);
            return;
          }
          else if (res.message.email[0]) {
            seterrors({ ...errors, email: res.message.email[0] });
          } else {
            addhandleClose();
            toast.error(res.message);
          }
        }
      }
    }
  };

  const confirmdeleteStaff = (id) => {
    setDeleteid(id);
    setOpenconfirm(true);
  };

  const deleteStaff = async () => {
    setLoading(true);
    const cid = deleteid;
    let response = await fetch(props.url + "/delete-staff", {
      method: "DELETE",
      headers: {
        "Content-type": "application/json",
      },
      body: JSON.stringify({ id: deleteid }),
      credentials: "include",
    });
    let res = await response.json();
    if (res) {
      setDeleteid(0);
      setOpenconfirm(false);
      setLoading(false);
      if (res.success) {
        setStaffs(staffs.filter((current) => cid !== current.id));
        toast.success("Staff deleted");
      } else {
          if( res.message == 'unauthenticated'){
            window.location.replace(props.exit);
            return;
          }
        toast.error(res.message);
      }
    }
  };

  const searchHandler = async () => {
    getStaffs(1)
  };
  const handleClose = () => {
    setUserData({
      firstname: "",
      lastname: "",
      email: "",
      phonenumber: "",
      role:"",
      password: "",
      confirm: "",
    });
    setEditid(0);
    seterrors([]);
    setOpen(false);
    setAddloading(false);
  };
  const handleClickOpen = async (id) => {
    setLoading(true);
    let response = await fetch(props.url + `/get-staff/${id}`, {
      credentials: "include",
    });
    let res = await response.json();
    if (res) {
      setLoading(false);
      if (res.success) {
        setUserData({
          firstname: res.data.first_name,
          lastname: res.data.last_name,
          email: res.data.email,
          phonenumber: res.data.phone_number,
          password: "",
          confirm: "",
        });
        setEditid(id);
        setOpen(true);
      } else {
        setLoading(false);
        if( res.message == 'unauthenticated'){
          window.location.replace(props.exit);
          return;
        }
        toast.error(res.message);
      }
    }
  };
  const addhandleClose = () => {
    setUserData({
      firstname: "",
      lastname: "",
      email: "",
      phonenumber: "",
      role:"",
      password: "",
      confirm: "",
    });
    seterrors([]);
    setaddOpen(false);
    setAddloading(false);
  };
  const addhandleClickOpen = () => {
    setaddOpen(true);
  };
  const classes = useStyles();

  return (
    <Card>
      {loading && (
        <Backdrop className={classes.backdrop} open>
          <CircularProgress color="inherit" />
        </Backdrop>
      )}
      <CardHeader color="danger">
        <h4 className={classes.cardTitleWhite}>Management Team</h4>
      </CardHeader>
      <CardBody>
        <Button onClick={addhandleClickOpen} color="danger">
          <Add /> Add staff
        </Button>
        <Table
          searchKeyword={searchHandler}
          term={searchTerm}
          setTerm={setSearchTerm}
          tableHeaderColor="gray"
          tableHead={[
            "First Name",
            "Last Name",
            "User Role",
            "Email",
            "Telephone",
            "Edit",
            "Delete",
          ]}
          tableData={
            staffs.length == 0 ? (
              <TableRow>
                <TableCell
                  style={{ textAlign: "center" }}
                  colSpan={7}
                  className={classes.tableCell}
                >
                  No Staff
                </TableCell>
              </TableRow>
            ) : (
              staffs.map((prop) => {
                return (
                  <TableRow key={prop.id} className={classes.tableBodyRow}>
                    <TableCell className={classes.tableCell} key={prop.id}>
                      {prop.first_name}
                    </TableCell>
                    <TableCell className={classes.tableCell} key={prop.id}>
                      {prop.last_name}
                    </TableCell>
                    <TableCell className={classes.tableCell} key={prop.id}>
                      {prop.roles[0] ? prop.roles[0].name == 'admin' ? "Admin" : "Content Manager" : ''}
                    </TableCell>
                    <TableCell className={classes.tableCell} key={prop.id}>
                      {prop.email}
                    </TableCell>
                    <TableCell className={classes.tableCell} key={prop.id}>
                      {prop.phone_number}
                    </TableCell>
                    <TableCell className={classes.tableCell} key={prop.id}>
                      <Edit
                        onClick={() => handleClickOpen(prop.id)}
                        style={{ color: "black", cursor: "pointer" }}
                        key={prop.id}
                      />
                    </TableCell>
                    <TableCell className={classes.tableCell} key={prop.id}>
                      <Delete
                        style={{ cursor: "pointer" }}
                        onClick={() => confirmdeleteStaff(prop.id)}
                        key={prop.id}
                      />
                    </TableCell>
                  </TableRow>
                );
              })
            )
          }
        />
      </CardBody>
      <TablePagination
      component="div"
      count={totalcount}
      page={page}
      onPageChange={handleChangePage}
      rowsPerPage={rowsPerPage}
      onRowsPerPageChange={handleChangeRowsPerPage}
    />
      <Dialog
        fullWidth={true}
        maxWidth={!addloading ? "sm" : "xs"}
        open={addopen}
        TransitionComponent={Transition}
        keepMounted
        onClose={addhandleClose}
        aria-labelledby="alert-dialog-slide-title"
        aria-describedby="alert-dialog-slide-description"
      >
        <DialogContent>
          <DialogContentText id="alert-dialog-slide-description">
            <GridContainer>
              <GridItem xs={12} sm={12} md={12}>
                <>
                  <CardHeader
                    style={{ textAlign: "center" }}
                    color={!addloading ? "danger" : ""}
                  >
                    <h4
                      className={
                        !addloading
                          ? classes.cardTitleWhite
                          : classes.cardTitleBlack
                      }
                    >
                      {!addloading ? "Add Staff" : "Adding Staff"}
                    </h4>
                  </CardHeader>
                  <CardBody>
                    {addloading ? (
                      <DialogContentText style={{ textAlign: "center" }}>
                        <CircularProgress color="secondary" />
                      </DialogContentText>
                    ) : (
                      <form onSubmit={handleaddClose}>
                        <GridContainer>
                          <GridItem xs={12} sm={12} md={6}>
                            <TextField
                              onChange={handleChange}
                              id="firstname"
                              value={userData.firstname}
                              label="First Name"
                              style={{ marginTop: 20 }}
                              fullWidth
                              margin="normal"
                              error={errors.firstname}
                              InputProps={{
                                startAdornment: (
                                  <InputAdornment position="start">
                                    <PersonOutlineIcon />
                                  </InputAdornment>
                                ),
                              }}
                              helperText={errors.firstname && errors.firstname}
                            />
                          </GridItem>
                          <GridItem xs={12} sm={12} md={6}>
                            <TextField
                              onChange={handleChange}
                              id="lastname"
                              value={userData.lastname}
                              label="Last Name"
                              style={{ marginTop: 20 }}
                              fullWidth
                              margin="normal"
                              error={errors.lastname}
                              InputProps={{
                                startAdornment: (
                                  <InputAdornment position="start">
                                    <PersonOutlineIcon />
                                  </InputAdornment>
                                ),
                              }}
                              helperText={errors.lastname && errors.lastname}
                            />
                          </GridItem>
                        </GridContainer>

                        <GridContainer>
                          <GridItem xs={12} sm={12} md={12}>
                            <TextField
                              onChange={handleChange}
                              id="email"
                              value={userData.email}
                              label="Email"
                              style={{ marginTop: 20 }}
                              fullWidth
                              type="email"
                              margin="normal"
                              InputProps={{
                                startAdornment: (
                                  <InputAdornment position="start">
                                    <MailOutlineIcon />
                                  </InputAdornment>
                                ),
                              }}
                              error={errors.email}
                              helperText={errors.email && errors.email}
                            />
                          </GridItem>
                        </GridContainer>
                        <GridContainer>
                          <GridItem xs={12} sm={12} md={12}>
                            <TextField
                              onChange={handleChange}
                              id="phonenumber"
                              value={userData.phonenumber}
                              label="Phone Number"
                              style={{ marginTop: 20 }}
                              fullWidth
                              type="number"
                              margin="normal"
                              InputProps={{
                                startAdornment: (
                                  <InputAdornment position="start">
                                    <PhoneIcon />
                                  </InputAdornment>
                                ),
                              }}
                              error={errors.phonenumber}
                              helperText={
                                errors.phonenumber && errors.phonenumber
                              }
                            />
                          </GridItem>
                        </GridContainer>
                        <GridContainer>
                          <GridItem xs={12} sm={12} md={12}>
                          <FormControl style={{ minWidth: "100%" }} className={classes.formControl} error={errors.role ? true : false}>
        <InputLabel id="demo-simple-select-error-label">User Role</InputLabel>
        <Select
          id="role"
          value={userData.role}
          onChange={(e) =>
            setUserData({
              ...userData,
              role: e.target.value,
            })
          }
        >
          <MenuItem value="admin">Admin</MenuItem>
          <MenuItem value="content_manager">Content Manager</MenuItem>
        </Select>
        <FormHelperText>{errors.role}</FormHelperText>
      </FormControl>
                          </GridItem>
                        </GridContainer>
                        <GridContainer>
                          <GridItem xs={12} sm={12} md={6}>
                            <TextField
                              onChange={handleChange}
                              value={userData.password}
                              label="Password"
                              type="password"
                              style={{ marginTop: 20 }}
                              id="password"
                              fullWidth
                              margin="normal"
                              InputProps={{
                                startAdornment: (
                                  <InputAdornment position="start">
                                    <LockOpenIcon />
                                  </InputAdornment>
                                ),
                              }}
                              error={errors.password}
                              helperText={errors.password && errors.password}
                            />
                          </GridItem>
                          <GridItem xs={12} sm={12} md={6}>
                            <TextField
                              onChange={handleChange}
                              value={userData.confirm}
                              type="password"
                              label="Confirm Password"
                              style={{ marginTop: 20 }}
                              id="confirm"
                              fullWidth
                              margin="normal"
                              InputProps={{
                                startAdornment: (
                                  <InputAdornment position="start">
                                    <LockOpenIcon />
                                  </InputAdornment>
                                ),
                              }}
                              error={errors.confirm}
                              helperText={errors.confirm && errors.confirm}
                            />
                          </GridItem>
                        </GridContainer>
                      </form>
                    )}
                  </CardBody>
                </>
              </GridItem>
            </GridContainer>
          </DialogContentText>
        </DialogContent>
        {!addloading && (
          <DialogActions>
            <Button
              style={{ display: "block", marginLeft: "0", marginRight: "auto" }}
              onClick={addhandleClose}
              color="danger"
            >
              <Close />
              Cancel
            </Button>
            <Button
              style={{ float: "right" }}
              color="danger"
              onClick={handleaddClose}
            >
              Create <Arrow />
            </Button>
          </DialogActions>
        )}
      </Dialog>

      <Dialog
        fullWidth={true}
        maxWidth={!addloading ? "sm" : "xs"}
        open={open}
        TransitionComponent={Transition}
        keepMounted
        onClose={handleClose}
        aria-labelledby="alert-dialog-slide-title"
        aria-describedby="alert-dialog-slide-description"
      >
        <DialogContent>
          <DialogContentText id="alert-dialog-slide-description">
            <GridContainer>
              <GridItem xs={12} sm={12} md={12}>
                <>
                  <CardHeader
                    style={{ textAlign: "center" }}
                    color={!addloading ? "danger" : ""}
                  >
                    <h4
                      className={
                        !addloading
                          ? classes.cardTitleWhite
                          : classes.cardTitleBlack
                      }
                    >
                      {!addloading ? "Update Staff" : "Updating Staff"}
                    </h4>
                  </CardHeader>
                  <CardBody>
                    {addloading ? (
                      <DialogContentText style={{ textAlign: "center" }}>
                        <CircularProgress color="secondary" />
                      </DialogContentText>
                    ) : (
                      <form onSubmit={handleeditClose}>
                        <GridContainer>
                          <GridItem xs={12} sm={12} md={6}>
                            <TextField
                              onChange={handleChange}
                              InputLabelProps={{
                                shrink: true,
                              }}
                              id="firstname"
                              value={userData.firstname}
                              label="First Name"
                              style={{ marginTop: 20 }}
                              fullWidth
                              margin="normal"
                              InputProps={{
                                startAdornment: (
                                  <InputAdornment position="start">
                                    <PersonOutlineIcon />
                                  </InputAdornment>
                                ),
                              }}
                              error={errors.firstname}
                              helperText={errors.firstname && errors.firstname}
                            />
                          </GridItem>
                          <GridItem xs={12} sm={12} md={6}>
                            <TextField
                              onChange={handleChange}
                              InputLabelProps={{
                                shrink: true,
                              }}
                              id="lastname"
                              value={userData.lastname}
                              label="Last Name"
                              style={{ marginTop: 20 }}
                              fullWidth
                              margin="normal"
                              InputProps={{
                                startAdornment: (
                                  <InputAdornment position="start">
                                    <PersonOutlineIcon />
                                  </InputAdornment>
                                ),
                              }}
                              error={errors.lastname}
                              helperText={errors.lastname && errors.lastname}
                            />
                          </GridItem>
                        </GridContainer>

                        <GridContainer>
                          <GridItem xs={12} sm={12} md={12}>
                            <TextField
                              onChange={handleChange}
                              InputLabelProps={{
                                shrink: true,
                              }}
                              id="email"
                              value={userData.email}
                              label="Email"
                              style={{ marginTop: 20 }}
                              fullWidth
                              type="email"
                              margin="normal"
                              InputProps={{
                                startAdornment: (
                                  <InputAdornment position="start">
                                    <MailOutlineIcon />
                                  </InputAdornment>
                                ),
                              }}
                              error={errors.email}
                              helperText={errors.email && errors.email}
                            />
                          </GridItem>
                        </GridContainer>
                        <GridContainer>
                          <GridItem xs={12} sm={12} md={12}>
                            <TextField
                              onChange={handleChange}
                              InputLabelProps={{
                                shrink: true,
                              }}
                              id="phonenumber"
                              value={userData.phonenumber}
                              label="Phone Number"
                              style={{ marginTop: 20 }}
                              fullWidth
                              type="number"
                              margin="normal"
                              InputProps={{
                                startAdornment: (
                                  <InputAdornment position="start">
                                    <PhoneIcon />
                                  </InputAdornment>
                                ),
                              }}
                              error={errors.phonenumber}
                              helperText={
                                errors.phonenumber && errors.phonenumber
                              }
                            />
                          </GridItem>
                        </GridContainer>
                        <GridContainer>
                          <GridItem xs={12} sm={12} md={6}>
                            <TextField
                              onChange={handleChange}
                              value={userData.password}
                              InputLabelProps={{
                                shrink: true,
                              }}
                              label="Password"
                              type="password"
                              placeholder="Enter to change current password"
                              style={{ marginTop: 20 }}
                              id="password"
                              fullWidth
                              margin="normal"
                              error={errors.password}
                              helperText={errors.password && errors.password}
                            />
                          </GridItem>
                          <GridItem xs={12} sm={12} md={6}>
                            <TextField
                              onChange={handleChange}
                              value={userData.confirm}
                              InputLabelProps={{
                                shrink: true,
                              }}
                              placeholder="Enter to confirm change"
                              type="password"
                              label="Confirm Password"
                              style={{ marginTop: 20 }}
                              id="confirm"
                              fullWidth
                              margin="normal"
                              error={errors.confirm}
                              helperText={errors.confirm && errors.confirm}
                            />
                          </GridItem>
                        </GridContainer>
                      </form>
                    )}
                  </CardBody>
                </>
              </GridItem>
            </GridContainer>
          </DialogContentText>
        </DialogContent>
        {!addloading && (
          <DialogActions>
            <Button
              style={{ display: "block", marginLeft: "0", marginRight: "auto" }}
              onClick={handleClose}
              color="danger"
            >
              <Close />
              Cancel
            </Button>
            <Button color="danger" onClick={handleeditClose}>
              Update <Arrow />
            </Button>
          </DialogActions>
        )}
      </Dialog>
      <Dialog
        fullWidth={true}
        maxWidth="sm"
        open={openconfirm}
        TransitionComponent={Transition}
        keepMounted
        classes={{ scrollPaper: classes.scrollPaper }}
        onClose={handleconfirmClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">{"DELETE STAFF"}</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            Are you sure you want to delete this staff?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button
            style={{ display: "block", marginLeft: "0", marginRight: "auto" }}
            onClick={handleconfirmClose}
            color="danger"
          >
            <Close />
            Cancel
          </Button>
          <Button
            style={{ float: "right" }}
            color="danger"
            onClick={deleteStaff}
          >
            Proceed <Arrow />
          </Button>
        </DialogActions>
      </Dialog>
    </Card>
  );
}

Staff.propTypes = {
  url: PropTypes.string,
  imgurl: PropTypes.string,
  exit: PropTypes.string,
};
