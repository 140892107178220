import React, { useEffect, useState } from "react";
import SpellcheckIcon from '@material-ui/icons/Spellcheck';
import DialogContent from "@material-ui/core/DialogContent";
import { makeStyles } from "@material-ui/core/styles";
import Chip from "@material-ui/core/Chip";
import TablePagination from '@material-ui/core/TablePagination';
import Typography from "@material-ui/core/Typography";
import Box from "@material-ui/core/Box";
import ClearIcon from '@material-ui/icons/Clear';
import FormatListNumberedIcon from '@material-ui/icons/FormatListNumbered';
import PropTypes from "prop-types";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import Danger from "components/Typography/Danger.js";
import DoneAllIcon from "@material-ui/icons/DoneAll";
import SettingsIcon from '@material-ui/icons/Settings';
import InputAdornment from "@material-ui/core/InputAdornment";
import DialogTitle from "@material-ui/core/DialogTitle";
import Close from "@material-ui/icons/Close";
import Arrow from "@material-ui/icons/DoubleArrow";
import HourglassEmptyIcon from "@material-ui/icons/HourglassEmpty";
import CircularProgress from "@material-ui/core/CircularProgress";
import TableRow from "@material-ui/core/TableRow";
import TableCell from "@material-ui/core/TableCell";
import TextField from "@material-ui/core/TextField";
import Table from "components/Table/Table.js";
import Card from "components/Card/Card.js";
import CardHeader from "components/Card/CardHeader.js";
import Backdrop from "@material-ui/core/Backdrop";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import CardBody from "components/Card/CardBody.js";
import Edit from "@material-ui/icons/Edit";
import Delete from "@material-ui/icons/DeleteOutline";
import Button from "components/CustomButtons/Button.js";
import Add from "@material-ui/icons/Add";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContentText from "@material-ui/core/DialogContentText";
import GridItem from "components/Grid/GridItem.js";
import GridContainer from "components/Grid/GridContainer.js";
import AppBar from "@material-ui/core/AppBar";
import Slide from "@material-ui/core/Slide";

const styles = {
  appBar: {
    position: "relative",
    background: "#ec4c48",
  },
  scrollPaper: {
    alignItems: "baseline", // default center
  },

  detailcell: {
    display: "flex",
    alignItems: "center",
    flexWrap: "wrap",
    marginBottom: "15px",
  }, 
  avatarimg: {
    display: "block",
    height: 50,
    width: 50,
    marginLeft: "auto",
    marginRight: "auto",
  },
  centertext: {
    textAlign: "center",
  },
  flexContainer: {
    display: "flex",
    flexFlow: "row wrap",
    justifyContent: "space-around",
    padding: 0,
    margin: 0,
    listStyle: "none",
  },
  flexItem: {
    position: "relative",
    width: "150px",
    height: "150px",
    marginTop: "5px",
    lineHeight: "150px",
    textAlign: "center",
  },
  vehicleimg: {
    display: "block",
    height: 50,
    width: 50,
    marginLeft: "auto",
    marginRight: "auto",
  },
  vehimg: {
    width: "100%",
    height: "100%",
  },

  overlay: {
    display: "none",
  },
  playIcon: {
    position: "absolute",
    fontSize: "50px",
    color: "green",
    cursor: "pointer",
    left: "0",
    right: "0",
    marginLeft: "auto",
    marginRight: "auto",
    top: "0",
    bottom: "0",
    marginTop: "auto",
    marginBottom: "auto",
    width: "150px",
  },
  selected: {
    position: "absolute",
    opacity: "1",
    color: "green",
    top: "0",
    bottom: "0",
    left: "0",
    right: "0",
  },
  list: {
    width: "100%",
    position: "relative",
  },
  detailhead: {
    marginTop: "10",
    marginBottom: "0",
  },

  backdrop: {
    zIndex: 9999,
    color: "#fff",
  },
  tableCell: {
    textAlign: "center",
  },
  cardCategoryWhite: {
    "&,& a,& a:hover,& a:focus": {
      color: "rgba(255,255,255,.62)",
      margin: "0",
      fontSize: "14px",
      marginTop: "0",
      marginBottom: "0",
    },
    "& a,& a:hover,& a:focus": {
      color: "#FFFFFF",
    },
  },
  cardTitleWhite: {
    color: "#FFFFFF",
    marginTop: "0px",
    minHeight: "auto",
    fontWeight: "300",
    fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif",
    marginBottom: "3px",
    textDecoration: "none",
    "& small": {
      color: "#777",
      fontSize: "65%",
      fontWeight: "400",
      lineHeight: "1",
    },
  },
  cardTitleBlack: {
    color: "#000000",
    marginTop: "0px",
    minHeight: "auto",
    fontWeight: "300",
    fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif",
    marginBottom: "3px",
    textDecoration: "none",
    "& small": {
      color: "#777",
      fontSize: "65%",
      fontWeight: "400",
      lineHeight: "1",
    },
  },
};

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`scrollable-force-tabpanel-${index}`}
      aria-labelledby={`scrollable-force-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box p={3}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};

function a11yProps(index) {
  return {
    id: `scrollable-force-tab-${index}`,
    "aria-controls": `scrollable-force-tabpanel-${index}`,
  };
}

const useStyles = makeStyles(styles);
const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="down" ref={ref} {...props} />;
});

export default function Loyalty(props) {
  toast.configure();
  const classes = useStyles();
  const [open, setOpen] = useState(false);
  const [addopen, setaddOpen] = React.useState(false);
  const [editid, setEditid] = useState(0);
  const [searchTerm, setSearchTerm] = useState("");
  const [searchTerm2, setSearchTerm2] = useState("");
  const [searchTerm3, setSearchTerm3] = useState("");
  const [loading, setLoading] = useState(true);
  const [rewards, setRewards] = useState([]);
  const [pendreq, setPendreq] = useState([]);
  const [processedreq, setProcessedreq] = useState([]);
  const [addloading, setAddloading] = useState(false);
  const [value, setValue] = useState(0);
  const [rewardData, setRewardData] = useState({
    name: "",
    minimum_points: "",
    img: "",
  });
  const [preview, setPreview] = useState(null);
  const [errors, seterrors] = useState([]);
  const [deleteid, setDeleteid] = useState(0);
  const [processreward, setProcessreward] = useState(0);
  const [processrewardid, setProcessrewardid] = useState(0);
  const [openconfirmreward, setOpenconfirmreward] = useState(false);
  const [openconfirm, setOpenconfirm] = useState(false);
  const [page1, setPage1] = React.useState(0);
  const [totalcount1, setTotalcount1] = React.useState(0);
  const [rowsPerPage1, setRowsPerPage1] = React.useState(10);
  const [page2, setPage2] = React.useState(0);
  const [totalcount2, setTotalcount2] = React.useState(0);
  const [rowsPerPage2, setRowsPerPage2] = React.useState(10);
  const [page3, setPage3] = React.useState(0);
  const [totalcount3, setTotalcount3] = React.useState(0);
  const [rowsPerPage3, setRowsPerPage3] = React.useState(10);

  const handleChangePage1 = (event, newPage) => {
    setLoading(true)
    setPage1(newPage);
  };

  const handleChangeRowsPerPage1 = (event) => {
    setLoading(true)
    setRowsPerPage1(parseInt(event.target.value, 10));
    setPage1(0);
  };

  const handleChangePage2 = (event, newPage) => {
    setLoading(true)
    setPage2(newPage);
  };

  const handleChangeRowsPerPage2 = (event) => {
    setLoading(true)
    setRowsPerPage2(parseInt(event.target.value, 10));
    setPage2(0);
  };

  const handleChangePage3 = (event, newPage) => {
    setLoading(true)
    setPage3(newPage);
  };

  const handleChangeRowsPerPage3 = (event) => {
    setLoading(true)
    setRowsPerPage3(parseInt(event.target.value, 10));
    setPage3(0);
  };

  const handletabChange = (event, newValue) => {
    setValue(newValue);
  };
  

  const handleconfirmClose = () => {
    setDeleteid(0);
    setOpenconfirm(false);
  };

  const handleconfirmRewardClose = () => {
    setProcessrewardid(0)
    setProcessreward(0);
    setOpenconfirmreward(false);
  };


  const getRewards = async (paged=0) => {
    if(paged){
      setPage1(paged-1)
    }
    let response = await fetch(props.url + `/get-rewards?${searchTerm && 'search='+searchTerm+'&'}page=${paged?paged-1:page1+1}&perpage=${rowsPerPage1}`, {
      credentials: "include",
    });
    let data = await response.json();
    if (data.success) {
      setRewards(data.data.data);
      setPage1(data.data.current_page - 1)
      setTotalcount1(data.data.total)
      setLoading(false);
    } else {
      if( data.message == 'unauthenticated'){
        window.location.replace(props.exit);
        return;
      }
      setLoading(false);
      toast.error(data.message);
    }
  };

  const getPendingRequests = async (paged=0) => {
    if(paged){
      setPage2(paged-1)
    }
    let response = await fetch(props.url + `/get-pending-reward-request?${searchTerm2 && 'search='+searchTerm2+'&'}page=${paged?paged-1:page2+1}&perpage=${rowsPerPage2}`, {
      credentials: "include",
    });
    let data = await response.json();
    if (data.success) {
      setPendreq(data.data.data);
      setPage2(data.data.current_page - 1)
      setTotalcount2(data.data.total)
      setLoading(false);
    } else {
      setLoading(false);
      if( data.message == 'unauthenticated'){
        window.location.replace(props.exit);
        return;
      }
      toast.error(data.message);
    }
  };

  const getProcessedRequests = async (paged=0) => {
    if(paged){
      setPage3(paged-1)
    }
    let response = await fetch(props.url + `/get-processed-reward-request?${searchTerm3 && 'search='+searchTerm3+'&'}page=${paged?paged-1:page3+1}&perpage=${rowsPerPage3}`, {
      credentials: "include",
    });
    let data = await response.json();
    if (data.success) {
      setProcessedreq(data.data.data);
      setPage3(data.data.current_page - 1)
      setTotalcount3(data.data.total)
      setLoading(false);
    } else {
      setLoading(false);
      if( data.message == 'unauthenticated'){
        window.location.replace(props.exit);
        return;
      }
      toast.error(data.message);
    }
  };

  useEffect(() => {
    getRewards();
    getPendingRequests();
    getProcessedRequests();
  }, [page1,rowsPerPage1,page2,rowsPerPage2,page3,rowsPerPage3]);

  const handleChange = (event) => {
    setRewardData({ ...rewardData, [event.target.id]: event.target.value });
  };

  const onRewardImageChange = (e) => {
    let files = e.target.files || e.dataTransfer.files;
    if (!files.length) {
      setRewardData({ ...rewardData, img: "none" });
      return;
    }
    if (!files[0].name.match(/\.(jpg|jpeg|png|gif)$/)) {
      setRewardData({ ...rewardData, img: "invalid" });
      return;
    }
    let reader = new FileReader();
    reader.onload = (e) => {
      setRewardData({ ...rewardData, img: e.target.result });
    };
    reader.readAsDataURL(files[0]);
    const objectUrl = URL.createObjectURL(files[0]);
    setPreview(objectUrl);
  };

  const validate = () => {
    let temp = [];
    temp.name = rewardData.name ? "" : "This field is required";
    temp.img = rewardData.img ? "" : "This field is required";
    temp.minimum_points = rewardData.minimum_points ? "" : "This field is required";
    if (rewardData.img == "invalid") {
      temp.img = "Please select a valid image (JPG|JPEG|PNG|GIF)";
    }
    if (rewardData.img == "none") {
      temp.img = "This field is required";
    }
    seterrors({ ...temp });
    return Object.values(temp).every((x) => x == "");
  };

  const updatevalidate = () => {
    let temp = [];
    temp.name = rewardData.name ? "" : "This field is required";
    temp.minimum_points = rewardData.minimum_points ? "" : "This field is required";
    if (rewardData.img == "invalid") {
      temp.img = "Please select a valid image (JPG|JPEG|PNG|GIF)";
    }
    if (rewardData.img == "none") {
      temp.img = "This field is required";
    }
    seterrors({ ...temp });
    return Object.values(temp).every((x) => x == "");
  };


  const addhandleClose = () => {
    setRewardData({
      name: "",
      minimum_points: "",
      img: "",
    });
    setPreview(null);
    seterrors([]);
    setaddOpen(false);
    setAddloading(false);
  };

  const searchHandler = async () => {
    getRewards(1)
  };

  const searchHandler2 = async () => {
    getPendingRequests(1)
  };

  const searchHandler3 = async () => {
    getProcessedRequests(1)
  };


  const handleaddClose = async (event) => {
    event.preventDefault();
    if (validate()) {
      setAddloading(true);
      let response = await fetch(props.url + "/create-reward", {
        method: "POST",
        headers: {
          "Content-type": "application/json",
        },
        body: JSON.stringify({ ...rewardData }),
        credentials: "include",
      });
      let res = await response.json();
      if (res) {
        if (res.success) {
          setRewards([...rewards, { ...res.data }]);
          addhandleClose();
          toast.success("Reward added");
        } else {
          if( res.message == 'unauthenticated'){
            window.location.replace(props.exit);
            return;
          }
            addhandleClose();
            toast.error(res.message);
        }
      }
    }
  };

  const handleeditClose = async (event) => {
    event.preventDefault();
    if (updatevalidate()) {
      setAddloading(true);
      let response = await fetch(props.url + `/update-reward/${editid}`, {
        method: "PUT",
        headers: {
          "Content-type": "application/json",
        },
        body: JSON.stringify({ ...rewardData }),
        credentials: "include",
      });
      let res = await response.json();
      if (res) {
        if (res.success) {
          setRewards(
            rewards.map((prop) => (prop.id === editid ? { ...res.data } : prop))
          );
          handleClose();
          toast.success("Reward Updated");
        } else {
          if( res.message == 'unauthenticated'){
            window.location.replace(props.exit);
            return;
          }
            addhandleClose();
            toast.error(res.message);
        }
      }
    }
  };

  const handleClickOpen = async (id) => {
    setLoading(true);
    let response = await fetch(props.url + `/get-reward/${id}`, {
      credentials: "include",
    });
    let res = await response.json();
    if (res) {
      setLoading(false);
      if (res.success) {
        setRewardData({
          name: res.data.reward_name,
          minimum_points: res.data.minimum_points,
          img: "valid",
        });
        setPreview(props.imgurl + res.data.img);
        setEditid(id);
        setOpen(true);
      } else {
        if( res.message == 'unauthenticated'){
          window.location.replace(props.exit);
          return;
        }
        toast.error(res.message);
      }
    }
  };

  const confirmdeleteReward = (id) => {
    setDeleteid(id);
    setOpenconfirm(true);
  };

  const confirmProcessReward = (id,rewardtype) => {
    setProcessrewardid(id)
    setProcessreward(rewardtype);
    setOpenconfirmreward(true);
  };


  const deleteReward = async () => {
    setLoading(true);
    const cid = deleteid;
    let response = await fetch(props.url + "/delete-reward", {
      method: "DELETE",
      headers: {
        "Content-type": "application/json",
      },
      body: JSON.stringify({ id: deleteid }),
      credentials: "include",
    });
    let res = await response.json();
    if (res) {
      setDeleteid(0);
      setOpenconfirm(false);
      setLoading(false);
      if (res.success) {
        setRewards(rewards.filter((current) => cid !== current.id));
        toast.success("Reward deleted");
      } else {
        if( res.message == 'unauthenticated'){
          window.location.replace(props.exit);
          return;
        }
        toast.error(res.message);
      }
    }
  };

  const processReward = async () => {
    setLoading(true);
    const cid = processrewardid;
    let response = await fetch(props.url + "/process-reward", {
      method: "PUT",
      headers: {
        "Content-type": "application/json",
      },
      body: JSON.stringify({ rewardtype : processreward , rewardid : processrewardid }),
      credentials: "include",
    });
    let res = await response.json();
    if (res) {
      handleconfirmRewardClose()
      setLoading(false);
      if (res.success) {
        setPendreq(pendreq.filter((current) => cid !== current.id));
        setProcessedreq([...processedreq, { ...res.data }]);
        toast.success("Reward processed");
      } else {
        if( res.message == 'unauthenticated'){
          window.location.replace(props.exit);
          return;
        }
        toast.error(res.message);
      }
    }
  };




  const handleClose = () => {
    setRewardData({
      name: "",
      minimum_points: "",
      img: "",
    });
    setPreview(null);
    seterrors([]);
    setOpen(false);
    setAddloading(false);
  };
  const addhandleClickOpen = () => {
    setaddOpen(true);
  };
  return (
    <Card>
      {loading && (
        <Backdrop className={classes.backdrop} open>
          <CircularProgress color="inherit" />
        </Backdrop>
      )}
      <CardHeader color="danger">
        <h4 className={classes.cardTitleWhite}>Redemptions &amp; Rewards</h4>
      </CardHeader>
      <CardBody>
        <div className={classes.root}>
          <AppBar position="static" color="default">
            <Tabs
              value={value}
              onChange={handletabChange}
              indicatorColor="secondary"
              textColor="secondary"
              variant="fullWidth"
              scrollButtons="on"
              aria-label="icon label tabs example"
            >
              <Tab
                label="Configurations"
                icon={<SettingsIcon />}
                {...a11yProps(0)}
              />
              <Tab
                label="Requests Pending Approval"
                icon={<HourglassEmptyIcon />}
                {...a11yProps(1)}
              />
               <Tab
                label="Processed Requests"
                icon={<DoneAllIcon />}
                {...a11yProps(2)}
              />
            </Tabs>
          </AppBar>
          <TabPanel value={value} index={0}>
            <Button onClick={addhandleClickOpen} color="danger">
              <Add /> Add Reward
            </Button>
            <Table
              searchKeyword={searchHandler}
              term={searchTerm}
              setTerm={setSearchTerm}
              tableHeaderColor="gray"
              tableHead={[
                "Reward",
                "Img",
                "Minimum Point",
                "Edit",
                "Delete",
              ]}
              tableData={
                rewards.length == 0 ? (
                  <TableRow>
                    <TableCell
                      style={{ textAlign: "center" }}
                      colSpan={8}
                      className={classes.tableCell}
                    >
                      No Rewards
                    </TableCell>
                  </TableRow>
                ) : (
                  rewards.map((prop) => {
                    return (
                      <TableRow key={prop.id} className={classes.tableBodyRow}>
                        <TableCell className={classes.tableCell} key={prop.id}>
                          {prop.reward_name}
                        </TableCell>
                        <TableCell
                                className={classes.tableCell}
                                key={prop.id}
                              >
                                <img
                                  className={classes.avatarimg}
                                  variant="square"
                                  key={prop.id}
                                  alt="Service"
                                  src={props.imgurl + prop.img}
                                />
                              </TableCell>
                        <TableCell className={classes.tableCell} key={prop.id}>
                          {prop.minimum_points}
                        </TableCell>
                        <TableCell className={classes.tableCell} key={prop.id}>
                          <Edit
                            onClick={() => handleClickOpen(prop.id)}
                            style={{ color: "black", cursor: "pointer" }}
                            key={prop.id}
                          />
                        </TableCell>
                        <TableCell className={classes.tableCell} key={prop.id}>
                          <Delete
                            style={{ cursor: "pointer" }}
                            onClick={() => confirmdeleteReward(prop.id)}
                            key={prop.id}
                          />
                        </TableCell>
                      </TableRow>
                    );
                  })
                )
              }
            />
            <TablePagination
      component="div"
      count={totalcount1}
      page={page1}
      onPageChange={handleChangePage1}
      rowsPerPage={rowsPerPage1}
      onRowsPerPageChange={handleChangeRowsPerPage1}
    />
          </TabPanel>
          <TabPanel value={value} index={1}>
            <Table
              searchKeyword={searchHandler2}
              term={searchTerm2}
              setTerm={setSearchTerm2}
              tableHeaderColor="gray"
              tableHead={[
                "Client's Name",
                "Client's Email",
                "Client's Telephone",
                "Client's Current Points",
                "Reward Type",
                "Approve",
                "Reject",
              ]}
              tableData={
                pendreq.length == 0 ? (
                  <TableRow>
                    <TableCell
                      style={{ textAlign: "center" }}
                      colSpan={6}
                      className={classes.tableCell}
                    >
                      No Requests Pending Approval
                    </TableCell>
                  </TableRow>
                ) : (
                  pendreq.map((prop) => {
                    return (
                      <TableRow key={prop.id} className={classes.tableBodyRow}>
                        <TableCell className={classes.tableCell} key={prop.id}>
                          {prop.client.first_name } {  prop.client.last_name ? prop.client.last_name : ''}
                        </TableCell>
                        <TableCell className={classes.tableCell} key={prop.id}>
                          {prop.client.email}
                        </TableCell>
                        <TableCell className={classes.tableCell} key={prop.id}>
                          {prop.client.phone_number}
                        </TableCell>
                        <TableCell className={classes.tableCell} key={prop.id}>
                          {prop.client.points ? prop.client.points.points : ''}
                        </TableCell>
                        <TableCell className={classes.tableCell} key={prop.id}>
                          {prop.reward.reward_name}
                        </TableCell>
                        <TableCell className={classes.tableCell} key={prop.id}>
                          <Button
                          onClick={() => confirmProcessReward(prop.id,1)}
                            variant="contained"
                            style={{ padding: "10px" }}
                            color="success"
                            size="small"
                          >
                            <DoneAllIcon />
                          </Button>
                        </TableCell>
                        <TableCell className={classes.tableCell} key={prop.id}>
                          <Button
                          onClick={() => confirmProcessReward(prop.id,2)}
                            variant="contained"
                            style={{ padding: "10px" }}
                            color="danger"
                            size="small"
                          >
                            <ClearIcon />
                          </Button>
                        </TableCell>
                      </TableRow>
                    );
                  })
                )
              }
            />
            <TablePagination
      component="div"
      count={totalcount2}
      page={page2}
      onPageChange={handleChangePage2}
      rowsPerPage={rowsPerPage2}
      onRowsPerPageChange={handleChangeRowsPerPage2}
    />
          </TabPanel>

          <TabPanel value={value} index={2}>
            <Table
              searchKeyword={searchHandler3}
              term={searchTerm3}
              setTerm={setSearchTerm3}
              tableHeaderColor="gray"
              tableHead={[
                "Client's Name",
                "Client's Email",
                "Client's Telephone",
                "Client's Current Points",
                "Reward Type",
                "Status",
                "Processed By",
              ]}
              tableData={
                processedreq.length == 0 ? (
                  <TableRow>
                    <TableCell
                      style={{ textAlign: "center" }}
                      colSpan={7}
                      className={classes.tableCell}
                    >
                      No Processed Requests
                    </TableCell>
                  </TableRow>
                ) : (
                  processedreq.map((prop) => {
                    return (
                      <TableRow key={prop.id} className={classes.tableBodyRow}>
                        <TableCell className={classes.tableCell} key={prop.id}>
                          {prop.client.first_name } {  prop.client.last_name ? prop.client.last_name : ''}
                        </TableCell>
                        <TableCell className={classes.tableCell} key={prop.id}>
                          {prop.client.email}
                        </TableCell>
                        <TableCell className={classes.tableCell} key={prop.id}>
                          {prop.client.phone_number}
                        </TableCell>
                        <TableCell className={classes.tableCell} key={prop.id}>
                          {prop.client.points ? prop.client.points.points : ''}
                        </TableCell>
                        <TableCell className={classes.tableCell} key={prop.id}>
                          {prop.reward.reward_name}
                        </TableCell>
                        <TableCell className={classes.tableCell} key={prop.id}>
                        <Chip
                            label={prop.status}
                            color={
                              prop.status == "approved"
                                ? "primary"
                                : "secondary"
                            }
                          />
                        </TableCell>
                        <TableCell className={classes.tableCell} key={prop.id}>
                          {prop.processor_admin.first_name } {  prop.processor_admin.last_name ? prop.processor_admin.last_name : ''}
                        </TableCell>
                      </TableRow>
                    );
                  })
                )
              }
            />
            <TablePagination
      component="div"
      count={totalcount3}
      page={page3}
      onPageChange={handleChangePage3}
      rowsPerPage={rowsPerPage3} 
      onRowsPerPageChange={handleChangeRowsPerPage3}
    />
          </TabPanel>
        </div>
      </CardBody>
      <Dialog
        fullWidth={true}
        maxWidth={!addloading ? "sm" : "xs"}
        open={addopen}
        TransitionComponent={Transition}
        keepMounted
        onClose={addhandleClose}
        aria-labelledby="alert-dialog-slide-title"
        aria-describedby="alert-dialog-slide-description"
      >
        <DialogContent>
          <DialogContentText id="alert-dialog-slide-description">
            <GridContainer>
              <GridItem xs={12} sm={12} md={12}>
                <>
                  <CardHeader
                    style={{ textAlign: "center" }}
                    color={!addloading ? "danger" : ""}
                  >
                    <h4
                      className={
                        !addloading
                          ? classes.cardTitleWhite
                          : classes.cardTitleBlack
                      }
                    >
                      {!addloading ? "Add Reward" : "Adding Reward"}
                    </h4>
                  </CardHeader>
                  <CardBody>
                    {addloading ? (
                      <DialogContentText style={{ textAlign: "center" }}>
                        <CircularProgress color="secondary" />
                      </DialogContentText>
                    ) : (
                      <form onSubmit={handleaddClose}>
                        <GridContainer>
                          <GridItem xs={12} sm={12} md={12}>
                            <TextField
                              onChange={handleChange}
                              id="name"
                              value={rewardData.name}
                              label="Reward Name"
                              style={{ marginTop: 10 }}
                              fullWidth
                              margin="normal"
                              InputProps={{
                                startAdornment: (
                                  <InputAdornment position="start">
                                    <SpellcheckIcon />
                                  </InputAdornment>
                                ),
                              }}
                              error={errors.name}
                              helperText={errors.name && errors.name}
                            />
                          </GridItem>
                          <GridItem xs={12} sm={12} md={12}>
                            <TextField
                              onChange={handleChange}
                              id="minimum_points"
                              value={rewardData.minimum_points}
                              label="Minimum Points"
                              style={{ marginTop: 10 }}
                              fullWidth
                              margin="normal"
                              type="number"
                              InputProps={{
                                startAdornment: (
                                  <InputAdornment position="start">
                                    <FormatListNumberedIcon />
                                  </InputAdornment>
                                ),
                              }}
                              error={errors.minimum_points}
                              helperText={errors.minimum_points && errors.minimum_points}
                            />
                          </GridItem>
                          <GridItem xs={12} sm={12} md={12}>
                            <label>Image</label>
                            <br />
                            <input
                              required
                              onChange={onRewardImageChange}
                              id="vehaddimg"
                              type="file"
                            />
                            <Danger>{errors.img}</Danger>
                          </GridItem>
                        </GridContainer>
                        <GridContainer>
                        <GridItem xs={12} sm={12} md={2} />
                        <GridItem xs={12} sm={12} md={8}>
                          {preview != null && (
                            <img width="100%" src={preview} />
                          )}
                        </GridItem>
                        <GridItem xs={12} sm={12} md={2} />
                      </GridContainer>
                      </form>
                    )}
                  </CardBody>
                </>
              </GridItem>
            </GridContainer>
          </DialogContentText>
        </DialogContent>
        {!addloading && (
          <DialogActions>
            <Button
              style={{ display: "block", marginLeft: "0", marginRight: "auto" }}
              onClick={addhandleClose}
              color="danger"
            >
              <Close />
              Cancel
            </Button>
            <Button
              style={{ float: "right" }}
              color="danger"
              onClick={handleaddClose}
            >
              Create <Arrow />
            </Button>
          </DialogActions>
        )}
      </Dialog>
      <Dialog
        fullWidth={true}
        maxWidth={!addloading ? "sm" : "xs"}
        open={open}
        TransitionComponent={Transition}
        keepMounted
        onClose={handleClose}
        aria-labelledby="alert-dialog-slide-title"
        aria-describedby="alert-dialog-slide-description"
      >
        <DialogContent>
          <DialogContentText id="alert-dialog-slide-description">
            <GridContainer>
              <GridItem xs={12} sm={12} md={12}>
                <>
                  <CardHeader
                    style={{ textAlign: "center" }}
                    color={!addloading ? "danger" : ""}
                  >
                    <h4
                      className={
                        !addloading
                          ? classes.cardTitleWhite
                          : classes.cardTitleBlack
                      }
                    >
                      {!addloading ? "Update Reward" : "Updating Reward"}
                    </h4>
                  </CardHeader>
                  <CardBody>
                    {addloading ? (
                      <DialogContentText style={{ textAlign: "center" }}>
                        <CircularProgress color="secondary" />
                      </DialogContentText>
                    ) : (
                      <form onSubmit={handleeditClose}>
                        <GridContainer>
                          <GridItem xs={12} sm={12} md={12}>
                            <TextField
                              onChange={handleChange}
                              id="name"
                              value={rewardData.name}
                              label="Reward Name"
                              style={{ marginTop: 10 }}
                              fullWidth
                              margin="normal"
                              InputProps={{
                                startAdornment: (
                                  <InputAdornment position="start">
                                    <SpellcheckIcon />
                                  </InputAdornment>
                                ),
                              }}
                              error={errors.name}
                              helperText={errors.name && errors.name}
                            />
                          </GridItem>
                          <GridItem xs={12} sm={12} md={12}>
                            <TextField
                              onChange={handleChange}
                              id="minimum_points"
                              value={rewardData.minimum_points}
                              label="Minimum Points"
                              style={{ marginTop: 10 }}
                              fullWidth
                              margin="normal"
                              type="number"
                              InputProps={{
                                startAdornment: (
                                  <InputAdornment position="start">
                                    <FormatListNumberedIcon />
                                  </InputAdornment>
                                ),
                              }}
                              error={errors.minimum_points}
                              helperText={errors.minimum_points && errors.minimum_points}
                            />
                          </GridItem>
                          <GridItem xs={12} sm={12} md={12}>
                            <label>Image</label>
                            <br />
                            <input
                              onChange={onRewardImageChange}
                              id="veheditimg"
                              type="file"
                            />
                            <Danger>{errors.img}</Danger>
                          </GridItem>
                        </GridContainer>
                        <GridContainer>
                        <GridItem xs={12} sm={12} md={2} />
                        <GridItem xs={12} sm={12} md={8}>
                          {preview != null && (
                            <img width="100%" src={preview} />
                          )}
                        </GridItem>
                        <GridItem xs={12} sm={12} md={2} />
                      </GridContainer>
                      </form>
                    )}
                  </CardBody>
                </>
              </GridItem>
            </GridContainer>
          </DialogContentText>
        </DialogContent>
        {!addloading && (
          <DialogActions>
            <Button
              style={{ display: "block", marginLeft: "0", marginRight: "auto" }}
              onClick={handleClose}
              color="danger"
            >
              <Close />
              Cancel
            </Button>
            <Button
              style={{ float: "right" }}
              color="danger"
              onClick={handleeditClose}
            >
              Update <Arrow />
            </Button>
          </DialogActions>
        )}
      </Dialog>
  
      <Dialog
        fullWidth={true}
        maxWidth="sm"
        open={openconfirm}
        TransitionComponent={Transition}
        keepMounted
        classes={{ scrollPaper: classes.scrollPaper }}
        onClose={handleconfirmClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">{"DELETE REWARD"}</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            Are you sure you want to delete this reward?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button
            style={{ display: "block", marginLeft: "0", marginRight: "auto" }}
            onClick={handleconfirmClose}
            color="danger"
          >
            <Close />
            Cancel
          </Button>
          <Button
            style={{ float: "right" }}
            color="danger"
            onClick={deleteReward}
          >
            Proceed <Arrow />
          </Button>
        </DialogActions>
      </Dialog>

      <Dialog
        fullWidth={true}
        maxWidth="sm"
        open={openconfirmreward}
        TransitionComponent={Transition}
        keepMounted
        classes={{ scrollPaper: classes.scrollPaper }}
        onClose={handleconfirmRewardClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">{processreward == 1 ? "APPROVE REWARD" : "DENY REWARD"}</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
          {processreward == 1 ? "Are you sure you want to approve this reward? " : "Are you sure you want to deny this reward? "}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button
            style={{ display: "block", marginLeft: "0", marginRight: "auto" }}
            onClick={handleconfirmRewardClose}
            color="danger"
          >
            <Close />
            Cancel
          </Button>
          <Button
            style={{ float: "right" }}
            color="danger"
            onClick={processReward}
          >
            Proceed <Arrow />
          </Button>
        </DialogActions>
      </Dialog>

    </Card>
  );
}
Loyalty.propTypes = {
  url: PropTypes.string,
  imgurl: PropTypes.string,
  exit: PropTypes.any,
};
