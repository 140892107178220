import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import Arrow from "@material-ui/icons/DoubleArrow";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import clsx from "clsx";
import Chip from "@material-ui/core/Chip";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import moment from "moment";
import CheckCircle from "@material-ui/icons/CheckCircleOutline";
import Badge from "@material-ui/core/Badge";
import List from "@material-ui/core/List";
import DialogActions from "@material-ui/core/DialogActions";
import TablePagination from '@material-ui/core/TablePagination';
import Close from "@material-ui/icons/Close";
import Arrowed from "@material-ui/icons/DoubleArrow";
import ListItem from "@material-ui/core/ListItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Link } from "react-router-dom";
import {
  faBoxOpen,
  faDolly,
  faPeopleCarry,
  // faRoute,
  faShippingFast,
} from "@fortawesome/free-solid-svg-icons";
import Select from "react-select";
import Drawer from "@material-ui/core/Drawer";
import CustomButton from "components/CustomButtons/Button.js";
import Typography from "@material-ui/core/Typography";
import Box from "@material-ui/core/Box";
import AppBar from "@material-ui/core/AppBar";
import TimelapseIcon from "@material-ui/icons/Timelapse";
import { makeStyles } from "@material-ui/core/styles";
import CircularProgress from "@material-ui/core/CircularProgress";
import GridItem from "components/Grid/GridItem.js";
import Divider from "@material-ui/core/Divider";
import GridContainer from "components/Grid/GridContainer.js";
import Card from "components/Card/Card.js";
import LocationOn from "@material-ui/icons/LocationOn";
import EmailIcon from "@material-ui/icons/Email";
import Backdrop from "@material-ui/core/Backdrop";
import TableRow from "@material-ui/core/TableRow";
import TableCell from "@material-ui/core/TableCell";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import CardHeader from "components/Card/CardHeader.js";
import CardBody from "components/Card/CardBody.js";
import Table from "components/Table/Table.js";
import View from "@material-ui/icons/Visibility";
import CallIcon from "@material-ui/icons/Call";
import PersonOutlineIcon from "@material-ui/icons/PersonOutline";
import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import Slide from "@material-ui/core/Slide";

const styles = {
  list: {
    width: "100%",
    position: "relative",
  },
  select: {
    width: "100%",
    maxWidth: 600,
    zIndex: 5,
  },
  vehicleimg: {
    display: "block",
    height: 50,
    width: 50,
    marginLeft: "auto",
    marginRight: "auto",
  },
  flexContainer: {
    display: "flex",
    flexFlow: "row wrap",
    justifyContent: "space-around",
    padding: 0,
    margin: 0,
    listStyle: "none",
  },
  flexItem: {
    position: "relative",
    padding: "5px",
    width: "150px",
    height: "150px",
    marginTop: "5px",
    lineHeight: "150px",
    textAlign: "center",
  },
  vehimg: {
    width: "100%",
    height: "100%",
  },

  overlay: {
    display: "none",
  },
  playIcon: {
    position: "absolute",
    fontSize: "50px",
    color: "green",
    cursor: "pointer",
    left: "0",
    right: "0",
    marginLeft: "auto",
    marginRight: "auto",
    top: "0",
    bottom: "0",
    marginTop: "auto",
    marginBottom: "auto",
    width: "150px",
  },
  selected: {
    position: "absolute",
    opacity: "1",
    color: "green",
    top: "0",
    bottom: "0",
    left: "0",
    right: "0",
  },
  scrollPaper: {
    alignItems: "baseline", // default center
  },
  backdrop: {
    zIndex: 5,
    color: "#fff",
  },
  media: {
    height: 140,
  },
  detailcell: {
    display: "flex",
    alignItems: "center",
    flexWrap: "wrap",
    marginBottom: "15px",
  },
  detailcell2: {
    textAlign: "center",
    marginBottom: "15px",
  },
  tableCell: {
    textAlign: "center",
  },
  cardCategoryWhite: {
    color: "rgba(255,255,255,.62)",
    margin: "0",
    fontSize: "14px",
    marginTop: "0",
    marginBottom: "0",
  },
  cardTitleWhite: {
    color: "#FFFFFF",
    marginTop: "0px",
    minHeight: "auto",
    fontWeight: "300",
    fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif",
    marginBottom: "3px",
    textDecoration: "none",
  },
  avatarimg: {
    display: "block",
    marginLeft: "auto",
    marginRight: "auto",
  },
  dividetab: {},
  pos: {
    textAlign: "center",
    fontWeight: "bold",
    fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif",
  },
  container: {
    display: "flex",
    flexWrap: "wrap",
  },
  formControl: {
    minWidth: "100%",
  },
};

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`scrollable-force-tabpanel-${index}`}
      aria-labelledby={`scrollable-force-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box p={3}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};

function a11yProps(index) {
  return {
    id: `scrollable-force-tab-${index}`,
    "aria-controls": `scrollable-force-tabpanel-${index}`,
  };
}

const useStyles = makeStyles(styles);
const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="down" ref={ref} {...props} />;
});

export default function Deliveries(props) {
  const [options, setOptions] = useState([
    {
      label: "Drivers heading to Location",
      options: [],
    },
    {
      label: "Available Drivers",
      options: [],
    },
    {
      label: "Other Drivers",
      options: [],
    },
  ]);
  toast.configure();
  const dividermatch = useMediaQuery("(max-width:750px)");
  const [update, setUpdate] = useState(0);
  const [sortingid, setSortingid] = useState(0);
  const [driverid, setDriverid] = useState(0);
  const [deliveryid, setDeliveryid] = useState(0);
  const [delivery, setDelivery] = useState({});
  const [searchserviceTerm1, setSearchserviceTerm1] = useState("all");
  const [searchserviceTerm2, setSearchserviceTerm2] = useState("all");
  const [searchserviceTerm3, setSearchserviceTerm3] = useState("all");
  const [searchserviceTerm4, setSearchserviceTerm4] = useState("all");
  const [open, setOpen] = React.useState(false);
  const [routeDrawer, setRouteDrawer] = React.useState(false);
  const [opendrawer, setOpendrawer] = React.useState(false);
  const [openfinaldrawer, setOpenfinaldrawer] = React.useState(false);
  const [openSelect, setopenSelect] = React.useState(false);
  const [openvehicleSelect, setopenvehicleSelect] = useState(false);
  const [category, setCategory] = React.useState("");
  const [orderdata, setOrderData] = useState({
    order_id: "",
    sender_name: "",
    sender_email: "",
    sender_telephone: "",
    sender_location: "",
    recipient_name: "",
    recipient_email: "",
    recipient_telephone: "",
    recipient_location: "",
    pack_type: "",
    boundary: "",
    driver: "",
    vehicle: "",
    cbm: 0,
    weight: 0,
    price: 0,
  });
  const [loading, setLoading] = useState(true);
  const [addloading, setAddloading] = useState(false);
  const [deliveryroutes, setDeliveryroutes] = useState([]);
  const [facilities, setFacilities] = useState([]);
  const [services, setServices] = useState([]);
  const [pendingpickup, setPendingpickup] = useState([]);
  const [unassigned, setUnassigned] = useState([]);
  const [onroutetofacility, setOnroutetofacility] = useState([]);
  const [outfordelivery, setOutfordelivery] = useState([]);
  const [searchTerm1, setSearchTerm1] = useState("");
  const [searchTerm2, setSearchTerm2] = useState("");
  const [searchTerm3, setSearchTerm3] = useState("");
  const [searchTerm4, setSearchTerm4] = useState("");
  const [selvehid, setSelvehid] = useState(0);
  const [seldeliveryid, setSeldeliveryid] = useState(0);
  const [vehicles, setVehicles] = useState([]);
  const [value, setValue] = useState(0);
  const [modtype, setModtype] = useState("");
  const [deleteid, setDeleteid] = useState(0);
  const [openconfirm, setOpenconfirm] = useState(false);
  const [page1, setPage1] = React.useState(0);
  const [totalcount1, setTotalcount1] = React.useState(0);
  const [rowsPerPage1, setRowsPerPage1] = React.useState(10);
  const [page2, setPage2] = React.useState(0);
  const [totalcount2, setTotalcount2] = React.useState(0);
  const [rowsPerPage2, setRowsPerPage2] = React.useState(10);
  const [page3, setPage3] = React.useState(0);
  const [totalcount3, setTotalcount3] = React.useState(0);
  const [rowsPerPage3, setRowsPerPage3] = React.useState(10);
  const [page4, setPage4] = React.useState(0);
  const [totalcount4, setTotalcount4] = React.useState(0);
  const [rowsPerPage4, setRowsPerPage4] = React.useState(10);

  const handleChangePage1 = (event, newPage) => {
    setPage1(newPage);
    setLoading(true)
  };

  const handleChangeRowsPerPage1 = (event) => {
    setRowsPerPage1(parseInt(event.target.value, 10));
    setPage1(0);
    setLoading(true)
  };

  const handleChangePage2 = (event, newPage) => {
    setPage2(newPage);
    setLoading(true)
  };

  const handleChangeRowsPerPage2 = (event) => {
    setRowsPerPage2(parseInt(event.target.value, 10));
    setPage2(0);
    setLoading(true)
  };

  const handleChangePage3 = (event, newPage) => {
    setPage3(newPage);
    setLoading(true)
  };

  const handleChangeRowsPerPage3 = (event) => {
    setRowsPerPage3(parseInt(event.target.value, 10));
    setPage3(0);
    setLoading(true)
  };

  const handleChangePage4 = (event, newPage) => {
    setPage4(newPage);
    setLoading(true)
  };

  const handleChangeRowsPerPage4 = (event) => {
    setRowsPerPage4(parseInt(event.target.value, 10));
    setPage4(0);
    setLoading(true)
  };

  const handleconfirmClose = () => {
    setDeleteid(0);
    setModtype("");
    setOpenconfirm(false);
  };

  const processDeletion = () => {
    if (modtype == "unassigned") {
      deleteUnassignedDelivery();
    } else if (modtype == "assigned") {
      deleteAssignedDelivery();
    }
  };

  const handletabChange = (event, newValue) => {
    setValue(newValue);
  };

  const getLocalData = async () => {
    let response3 = await fetch(props.url + "/get-vehicles", {
      credentials: "include",
    });
    let res3 = await response3.json();
    let response4 = await fetch(props.url + "/get-services", {
      credentials: "include",
    });
    let res4 = await response4.json();

    if (res3) {
      if (res3.success) {
        setVehicles(res3.data);
      } else {
        if( res3.message == 'unauthenticated'){
          window.location.replace(props.exit);
          return;
        }
        toast.error(res3.message);
      }
    }
    if (res4) {
      if (res4.success) {
        const def_services = [];
        res4.data.forEach((serv) => def_services.push(serv.service_name));
        setServices(def_services);
      } else {
        if( res4.message == 'unauthenticated'){
          window.location.replace(props.exit);
          return;
        }
        toast.error(res4.message);
      }
      setLoading(false);
    }
  };


  const getUnassignedDeliveries = async (termed="",paged=0) => {
    if(paged){
      setPage1(paged-1)
    }
    let response1 = await fetch(props.url + `/get-unassigned-international-deliveries?${searchTerm1 && 'search='+searchTerm1+'&'}${termed ? 'category='+termed+'&' : searchserviceTerm1 ? 'category='+searchserviceTerm1+'&' : ''}page=${paged?paged-1:page1+1}&perpage=${rowsPerPage1}`, {
      credentials: "include",
    });
    let res1 = await response1.json();
    if (res1.success) {
      setUnassigned(res1.data.data);
      setPage1(res1.data.current_page - 1)
      setTotalcount1(res1.data.total)
    }
    else {
      if( res1.message == 'unauthenticated'){
        window.location.replace(props.exit);
        return;
      }
    }
  };

  const getPendingDeliveries = async (termed="",paged=0) => {
    if(paged){
      setPage2(paged-1)
    }
    let response2 = await fetch(props.url + `/get-pending-international-deliveries?${searchTerm2 && 'search='+searchTerm2+'&'}${termed ? 'category='+termed+'&' : searchserviceTerm2 ? 'category='+searchserviceTerm2+'&' : ''}page=${paged?paged-1:page2+1}&perpage=${rowsPerPage2}`, {
      credentials: "include",
    });
    let res2 = await response2.json();
    if (res2.success) {
      setPendingpickup(res2.data.data);
      setPage2(res2.data.current_page - 1)
      setTotalcount2(res2.data.total)
    }
    else {
      if( res2.message == 'unauthenticated'){
        window.location.replace(props.exit);
        return;
      }
    }
  };

  const getOnrouteDeliveries = async (termed="",paged=0) => {
    if(paged){
      setPage3(paged-1)
    }
    let response3 = await fetch(props.url + `/get-onroutetofacility-international-deliveries?${searchTerm3 && 'search='+searchTerm3+'&'}${termed ? 'category='+termed+'&' : searchserviceTerm3 ? 'category='+searchserviceTerm3+'&' : ''}page=${paged?paged-1:page3+1}&perpage=${rowsPerPage3}`, {
      credentials: "include",
    });
    let res3 = await response3.json();
    if (res3.success) {
      setOnroutetofacility(res3.data.data);
      setPage3(res3.data.current_page - 1)
      setTotalcount3(res3.data.total)
    }
    else {
      if( res3.message == 'unauthenticated'){
        window.location.replace(props.exit);
        return;
      }
    }
  };

  const getOutfordeliveryDeliveries = async (termed="",paged=0) => {
    if(paged){
      setPage4(paged-1)
    }
    let response4 = await fetch(props.url + `/get-outfordelivery-international-deliveries?${searchTerm4 && 'search='+searchTerm4+'&'}${termed ? 'category='+termed+'&' : searchserviceTerm4 ? 'category='+searchserviceTerm4+'&' : ''}page=${paged?paged-1:page4+1}&perpage=${rowsPerPage4}`, {
      credentials: "include",
    });
    let res4 = await response4.json();
    if (res4.success) {
      setOutfordelivery(res4.data.data);
      setPage4(res4.data.current_page - 1)
      setTotalcount4(res4.data.total)
    }
    else {
      if( res4.message == 'unauthenticated'){
        window.location.replace(props.exit);
        return;
      }
    }
  };

  useEffect(() => {
    getUnassignedDeliveries()
    getPendingDeliveries()
    getOnrouteDeliveries()
    getOutfordeliveryDeliveries()
    getLocalData();
  }, [page1,rowsPerPage1,page2,rowsPerPage2,page3,rowsPerPage3,page4,rowsPerPage4]);

  const toggleDraweropen = async (event, $id) => {
    if (
      event.type === "keydown" &&
      (event.key === "Tab" || event.key === "Shift")
    ) {
      return;
    }
    setLoading(true);
    let response = await fetch(props.url + `/get-delivery-details/${$id}`, {
      credentials: "include",
    });
    let res = await response.json();
    if (res) {
      setLoading(false);
      if (res.success) {
        setDelivery(res.data);
        setOpendrawer(true);
      } else {
        if( res.message == 'unauthenticated'){
          window.location.replace(props.exit);
          return;
        }
        toast.error(res.message);
      }
    }
  };

  const searchserviceHandler1 = (termed) => {
    setSearchserviceTerm1(termed);
    getUnassignedDeliveries(termed,1)
  };

  const searchserviceHandler2 = (termed) => {
    setSearchserviceTerm2(termed);
    getPendingDeliveries(termed,1)
  };

  const searchserviceHandler3 = (termed) => {
    setSearchserviceTerm3(termed);
    getOnrouteDeliveries(termed,1)
  };

  const searchserviceHandler4 = (termed) => {
    setSearchserviceTerm4(termed);
    getOutfordeliveryDeliveries(termed,1)
  };


  const closeRouteDrawer = (event) => {
    if (
      event.type === "keydown" &&
      (event.key === "Tab" || event.key === "Shift")
    ) {
      return;
    }
    setDeliveryroutes([]);
    setRouteDrawer(false);
  };

  const toggleFinalDraweropen = async (event, $id) => {
    if (
      event.type === "keydown" &&
      (event.key === "Tab" || event.key === "Shift")
    ) {
      return;
    }
    setLoading(true);
    let response = await fetch(props.url + `/get-final-delivery-details/${$id}`, {
      credentials: "include",
    });
    let res = await response.json();
    if (res) {
      setLoading(false);
      if (res.success) {
        setDelivery(res.data);
        setOpenfinaldrawer(true);
      } else {
        if( res.message == 'unauthenticated'){
          window.location.replace(props.exit);
          return;
        }
        toast.error(res.message);
      }
    }
  };

  const toggleDrawerclose = (event) => {
    if (
      event.type === "keydown" &&
      (event.key === "Tab" || event.key === "Shift")
    ) {
      return;
    }
    setDelivery({});
    setOpendrawer(false);
  };

  const toggleFinalDrawerclose = (event) => {
    if (
      event.type === "keydown" &&
      (event.key === "Tab" || event.key === "Shift")
    ) {
      return;
    }
    setDelivery({});
    setOpenfinaldrawer(false);
  };

  const confirmdeleteUnassignedDelivery = (id) => {
    setDeleteid(id);
    setModtype("unassigned");
    setOpenconfirm(true);
  };

  const deleteUnassignedDelivery = async () => {
    setLoading(true);
    const cid = deleteid;
    let response = await fetch(props.url + "/delete-delivery", {
      method: "DELETE",
      headers: {
        "Content-type": "application/json",
      },
      body: JSON.stringify({ id: deleteid }),
      credentials: "include",
    });
    let res = await response.json();
    if (res) {
      setDeleteid(0);
      setModtype("");
      setOpenconfirm(false);
      setLoading(false);
      if (res.success) {
        setUnassigned(unassigned.filter((current) => cid !== current.id));
        toast.success("Delivery Request deleted");
      } else {
        if( res.message == 'unauthenticated'){
          window.location.replace(props.exit);
          return;
        }
        toast.error(res.message);
      }
    }
  };

  const confirmdeleteAssignedDelivery = (id) => {
    setDeleteid(id);
    setModtype("assigned");
    setOpenconfirm(true);
  };

  const deleteAssignedDelivery = async () => {
    const cid = deleteid;
    setLoading(true);
    let response = await fetch(props.url + "/delete-delivery", {
      method: "DELETE",
      headers: {
        "Content-type": "application/json",
      },
      body: JSON.stringify({ id: deleteid }),
      credentials: "include",
    });
    let res = await response.json();
    if (res) {
      setDeleteid(0);
      setModtype("");
      setOpenconfirm(false);
      setLoading(false);
      if (res.success) {
        setPendingpickup(pendingpickup.filter((current) => cid !== current.id));
        toast.success("Delivery Request deleted");
      } else {
        if( res.message == 'unauthenticated'){
          window.location.replace(props.exit);
          return;
        }
        toast.error(res.message);
      }
    }
  };

  const processdelivery = () => {
    if (update) {
      processpendingdelivery();
    } else {
      processnewdelivery();
    }
  };

  const processnewdelivery = async () => {
    setAddloading(true);
    let response = await fetch(props.url + "/process-unassigned-international-delivery", {
      method: "POST",
      headers: {
        "Content-type": "application/json",
      },
      body: JSON.stringify({
        deliveryid: deliveryid,
        driverid: driverid,
        sortingid: sortingid,
      }),
      credentials: "include",
    });
    let res = await response.json();
    if (res) {
      if (res.success) {
        setUnassigned(
          unassigned.filter((current) => deliveryid !== current.id)
        );
        setPendingpickup([...pendingpickup, res.data]);
        handleSelectClose();
        toast.success("Delivery Request Assigned");
      } else {
        if( res.message == 'unauthenticated'){
          window.location.replace(props.exit);
          return;
        }
        toast.error(res.message);
        handleSelectClose();
      }
    }
  };

  const processpendingdelivery = async () => {
    setAddloading(true);
    let response = await fetch(props.url + "/process-pending-international-delivery", {
      method: "POST",
      headers: {
        "Content-type": "application/json",
      },
      body: JSON.stringify({
        deliveryid: deliveryid,
        driverid: driverid,
        sortingid: sortingid,
      }),
      credentials: "include",
    });
    let res = await response.json();
    if (res) {
      if (res.success) {
        setPendingpickup(
          pendingpickup.map((prop) =>
            prop.id === deliveryid ? { ...res.data } : prop
          )
        );
        handleSelectClose();
        toast.success("Delivery Request Reassigned");
      } else {
        if( res.message == 'unauthenticated'){
          window.location.replace(props.exit);
          return;
        }
        toast.error(res.message);
        handleSelectClose();
      }
    }
  };

  const searchHandler1 = () => {
    getUnassignedDeliveries(searchserviceTerm1,1)
  };

  const searchHandler2 = () => {
    getPendingDeliveries(searchserviceTerm2,1)
  };

  const searchHandler3 = () => {
    getOnrouteDeliveries(searchserviceTerm3,1)
  };

  const searchHandler4 = () => {
    getOutfordeliveryDeliveries(searchserviceTerm4,1)
  };
  const handleSelectOpen = async (id, did, cat) => {
    setLoading(true)
    if (cat == "update") {
      setUpdate(true);
    }
    setDeliveryid(did);
    let response3 = await fetch(props.url + `/get-delivery-handler/${id}/${did}`, {
      credentials: "include",
    });
    let res3 = await response3.json();
    if (res3) {
      setLoading(false)
      if (res3.success) {
        if(res3.data.length == 2){
          setOptions([
            {
              label: "Agents assigned to Delivery Location",
              options: res3.data[0],
            },
          ]);
          let opts = [];
          res3.data[1].forEach((fac) =>
          opts.push({
            label: fac.facility_name + " (" + fac.zone.zone_name + ")",
            value: fac.id,
          })
        );
        setSortingid(opts[0])
        setFacilities(opts);
        }
        else if(res3.data.length == 4){
        setOptions([
          {
            label: "Drivers heading to Location",
            options: res3.data[0],
          },
          {
            label: "Available Drivers",
            options: res3.data[1],
          },
          {
            label: "Other Drivers",
            options: res3.data[2],
          },
        ]);

        const options = [];
        res3.data[3].forEach((fac) =>
          options.push({
            label: fac.facility_name + " (" + fac.zone.zone_name + ")",
            value: fac.id,
          })
        );
        setFacilities(options);
      }
        setopenSelect(true);
      } else {
        if( res3.message == 'unauthenticated'){
          window.location.replace(props.exit);
          return;
        }
        toast.error(res3.message);
      }
    }
  };


  const handleSelectClose = () => {
    setOptions([
      {
        label: "Drivers heading to Location",
        options: [],
      },
      {
        label: "Available Drivers",
        options: [],
      },
      {
        label: "Other Drivers",
        options: [],
      },
    ]);
    setSortingid(0);
    setDriverid(0);
    setUpdate(false);
    setAddloading(false);
    setopenSelect(false);
  };


  const handleVehicleSelectUpdateClose = async (event) => {
    event.preventDefault();
    setAddloading(true);
    let response = await fetch(props.url + `/update-delivery-vehicle`, {
      method: "PUT",
      headers: {
        "Content-type": "application/json",
      },
      body: JSON.stringify({ deliveryid: seldeliveryid, vehicleid: selvehid }),
      credentials: "include",
    });
    let res = await response.json();
    if (res) {
      if (res.success) {
        if (category === "unassigned") {
          setUnassigned(
            unassigned.map((prop) =>
              prop.id === seldeliveryid ? { ...res.data } : prop
            )
          );
        } else if (category === "pending") {
          setPendingpickup(
            pendingpickup.map((prop) =>
              prop.id === seldeliveryid ? { ...res.data } : prop
            )
          );
        }
        handleVehicleSelectClose();
        toast.success("Vehicle Changed");
      } else {
        if( res.message == 'unauthenticated'){
          window.location.replace(props.exit);
          return;
        }
        handleVehicleSelectClose();
        toast.error(res.message);
      }
    }
  };

  const handleVehicleSelectClose = () => {
    setCategory("");
    setAddloading(false);
    setSelvehid(0);
    setSeldeliveryid(0);
    setopenvehicleSelect(false);
  };

  const handleClose = () => {
    setOrderData({
      order_id: "",
      sender_name: "",
      sender_email: "",
      sender_telephone: "",
      sender_location: "",
      recipient_name: "",
      recipient_email: "",
      recipient_telephone: "",
      recipient_location: "",
      pack_type: "",
      boundary: "",
      driver: "",
      vehicle: "",
      cbm: 0,
      weight: 0,
      price: 0,
    });
    setOpen(false);
  };

  const classes = useStyles();
  return (
    <GridContainer>
      {loading && (
        <Backdrop className={classes.backdrop} open>
          <CircularProgress color="inherit" />
        </Backdrop>
      )}
      <GridItem xs={12} sm={12} md={12}>
        <Card>
          {/* <Select className={classes.select}
                options={options}/> */}
          <CardHeader color="danger">
            <h4 className={classes.cardTitleWhite}>International Delivery Requests</h4>
          </CardHeader>
          <CardBody>
            <div className={classes.root}>
              <AppBar position="static" color="default">
                <Tabs
                  value={value}
                  onChange={handletabChange}
                  indicatorColor="secondary"
                  textColor="secondary"
                  variant={dividermatch ? "scrollable" : "fullWidth"}
                  scrollButtons="on"
                  aria-label="icon label tabs example"
                >
                  <Tab
                    className={dividermatch && classes.dividetab}
                    label="Unassigned Request"
                    icon={<TimelapseIcon />}
                    {...a11yProps(0)}
                  />
                  <Tab
                    className={dividermatch && classes.dividetab}
                    label="Pending Pick-Up From Client"
                    icon={<FontAwesomeIcon icon={faDolly} />}
                    {...a11yProps(1)}
                  />
                  <Tab
                    className={dividermatch && classes.dividetab}
                    label="On-Route To Primary Sorting Facility"
                    icon={<FontAwesomeIcon icon={faShippingFast} />}
                    {...a11yProps(2)}
                  />
                  <Tab
                    label="On-Route To Final Destination"
                    icon={<FontAwesomeIcon icon={faShippingFast} />}
                    {...a11yProps(3)}
                  />
                </Tabs>
              </AppBar>
              <TabPanel value={value} index={0}>
                <Table
                  searchKeyword={searchHandler1}
                  term={searchTerm1}
                  setTerm={setSearchTerm1}
                  searchserviceKeyword={searchserviceHandler1}
                  serviceterm={searchserviceTerm1}
                  allservices={services}
                  tableHeaderColor="gray"
                  tableHead={[
                    "Order ID",
                    "Sender",
                    "Reciepient",
                    "From",
                    "To",
                    "Price",
                    "Assign Handler",
                    "Details",
                    "Cancel",
                  ]}
                  tableData={
                    unassigned.length == 0 ? (
                      <TableRow>
                        <TableCell
                          style={{ textAlign: "center" }}
                          colSpan={9}
                          className={classes.tableCell}
                        >
                          No Unassigned Requests
                        </TableCell>
                      </TableRow>
                    ) : (
                      unassigned.map((prop) => {
                        return (
                          <TableRow
                            key={prop.id}
                            className={classes.tableBodyRow}
                          >
                            <TableCell
                              className={classes.tableCell}
                              key={prop.id}
                            >
                              <Link to={{pathname: "/admin/searchdeliveries", property: { orderId: prop.order_id }}} >
                              {prop.order_id}
                              </Link>
                            </TableCell>
                            <TableCell
                              className={classes.tableCell}
                              key={prop.id}
                            >
                              {prop.creator ? prop.creator.first_name : prop.sender_name}{" "}
                            {(prop.creator && prop.creator.last_name) && prop.creator.last_name}
                            </TableCell>
                            <TableCell
                              className={classes.tableCell}
                              key={prop.id}
                            >
                              {prop.reciepient ? prop.reciepient.first_name : prop.creator ? prop.creator.first_name : prop.sender_name}{" "}
                             {prop.reciepient ? prop.reciepient.last_name : prop.creator ? prop.creator.last_name ? prop.creator.last_name : ""  : ""}
                      
                            </TableCell>
                            <TableCell
                              className={classes.tableCell}
                              key={prop.id}
                            >
                              {prop.from_location.location_name}
                            </TableCell>
                            <TableCell
                              className={classes.tableCell}
                              key={prop.id}
                            >
                              {prop.to_location.location_name}
                            </TableCell>
                            <TableCell
                              className={classes.tableCell}
                              key={prop.id}
                            >
                              GMD {prop.price}
                            </TableCell>
                            <TableCell
                              className={classes.tableCell}
                              key={prop.id}
                            >
                              <FontAwesomeIcon
                                style={{ cursor: "pointer", fontSize: "25px" }}
                                onClick={() =>
                                  handleSelectOpen(
                                    prop.from_location.id,
                                    prop.id,
                                    "add"
                                  )
                                }
                                icon={faPeopleCarry}
                              />
                            </TableCell>
                            <TableCell
                              className={classes.tableCell}
                              key={prop.id}
                            >
                              <View
                                key={prop.id}
                                onClick={(e) => toggleDraweropen(e, prop.id)}
                                style={{ cursor: "pointer" }}
                              />
                            </TableCell>
                            <TableCell
                              className={classes.tableCell}
                              key={prop.id}
                            >
                              <Close
                                color="secondary"
                                onClick={() =>
                                  confirmdeleteUnassignedDelivery(prop.id)
                                }
                                style={{ cursor: "pointer" }}
                              />
                            </TableCell>
                          </TableRow>
                        );
                      })
                    )
                  }
                  intl={true}
                />
               <TablePagination
      component="div"
      count={totalcount1}
      page={page1}
      onPageChange={handleChangePage1}
      rowsPerPage={rowsPerPage1}
      onRowsPerPageChange={handleChangeRowsPerPage1}
    />
              </TabPanel>
              <TabPanel value={value} index={1}>
                <Table
                  searchKeyword={searchHandler2}
                  term={searchTerm2}
                  setTerm={setSearchTerm2}
                  searchserviceKeyword={searchserviceHandler2}
                  serviceterm={searchserviceTerm2}
                  allservices={services}
                  tableHeaderColor="gray"
                  tableHead={[
                    "Order ID",
                    "Sender",
                    "From",
                    "Next Destination",
                    "Final Destination",
                    "Price",
                    "Handler",
                    "Re-Assign Handler",
                    "Details",
                    "Cancel",
                  ]}
                  tableData={
                    pendingpickup.length == 0 ? (
                      <TableRow>
                        <TableCell
                          style={{ textAlign: "center" }}
                          colSpan={10}
                          className={classes.tableCell}
                        >
                          No Deliveries Pending Pick-Up
                        </TableCell>
                      </TableRow>
                    ) : (
                      pendingpickup.map((prop) => {
                        return (
                          <TableRow
                            key={prop.id}
                            className={classes.tableBodyRow}
                          >
                            <TableCell
                              className={classes.tableCell}
                              key={prop.id}
                            >
                              <Link to={{pathname: "/admin/searchdeliveries", property: { orderId: prop.order_id }}} >
                              {prop.order_id}
                              </Link>
                            </TableCell>
                            <TableCell
                              className={classes.tableCell}
                              key={prop.id}
                            >
                              {prop.creator ? prop.creator.first_name : prop.sender_name}{" "}
                            {(prop.creator && prop.creator.last_name) && prop.creator.last_name}
                            </TableCell>
                            <TableCell
                              className={classes.tableCell}
                              key={prop.id}
                            >
                              {prop.from_location.location_name}
                            </TableCell>
                            <TableCell
                              className={classes.tableCell}
                              key={prop.id}
                            >
                              {prop.clientfacility
                                ? prop.clientfacility.facility.facility_name
                                : prop.to_location.location_name}
                            </TableCell>
                            <TableCell
                              className={classes.tableCell}
                              key={prop.id}
                            >
                              {prop.to_location.location_name}
                            </TableCell>
                            <TableCell
                              className={classes.tableCell}
                              key={prop.id}
                            >
                              GMD {prop.price}
                            </TableCell>
                            <TableCell
                              className={classes.tableCell}
                              key={prop.id}
                            >
                              {prop.clientfacility
                                ? prop.clientfacility.driver.first_name +
                                  " " +
                                  prop.clientfacility.driver.last_name
                                : prop.clientrecipient.driver.first_name +
                                  " " +
                                  prop.clientrecipient.driver.last_name}
                            </TableCell>
                            <TableCell
                              className={classes.tableCell}
                              key={prop.id}
                            >
                              <FontAwesomeIcon
                                style={{ cursor: "pointer", fontSize: "25px" }}
                                onClick={() =>
                                  handleSelectOpen(
                                    prop.from_location.id,
                                    prop.id,
                                    "update"
                                  )
                                }
                                icon={faPeopleCarry}
                              />
                            </TableCell>
                            <TableCell
                              className={classes.tableCell}
                              key={prop.id}
                            >
                              <View
                                key={prop.id}
                                style={{ cursor: "pointer" }}
                                onClick={(e) => toggleDraweropen(e, prop.id)}
                              />
                            </TableCell>
                            <TableCell
                              className={classes.tableCell}
                              key={prop.id}
                            >
                              <Close
                                Close
                                color="secondary"
                                onClick={() =>
                                  confirmdeleteAssignedDelivery(prop.id)
                                }
                                style={{ cursor: "pointer" }}
                              />
                            </TableCell>
                          </TableRow>
                        );
                      })
                    )
                  }
                  intl={true}
                />
                <TablePagination
      component="div"
      count={totalcount2}
      page={page2}
      onPageChange={handleChangePage2}
      rowsPerPage={rowsPerPage2}
      onRowsPerPageChange={handleChangeRowsPerPage2}
    />
              </TabPanel>

              <TabPanel value={value} index={2}>
                <Table
                  searchKeyword={searchHandler3}
                  term={searchTerm3}
                  setTerm={setSearchTerm3}
                  searchserviceKeyword={searchserviceHandler3}
                  serviceterm={searchserviceTerm3}
                  allservices={services}
                  tableHeaderColor="gray"
                  tableHead={[
                    "Order ID",
                    "Sender",
                    "From",
                    "To",
                    "Final Destination",
                    "Handler",
                    "Price",
                    "Details",
                  ]}
                  tableData={
                    onroutetofacility.length == 0 ? (
                      <TableRow>
                        <TableCell
                          style={{ textAlign: "center" }}
                          colSpan={9}
                          className={classes.tableCell}
                        >
                          No On-Route Deliveries
                        </TableCell>
                      </TableRow>
                    ) : (
                      onroutetofacility.map((prop) => {
                        return (
                          <TableRow
                            key={prop.id}
                            className={classes.tableBodyRow}
                          >
                            <TableCell
                              className={classes.tableCell}
                              key={prop.id}
                            >
                              <Link to={{pathname: "/admin/searchdeliveries", property: { orderId: prop.delivery.order_id }}} >
                              {prop.delivery.order_id}
                              </Link>
                            </TableCell>
                            <TableCell
                              className={classes.tableCell}
                              key={prop.id}
                            >
                              {prop.delivery.creator ? prop.delivery.creator.first_name : prop.delivery.sender_name}{" "}
                       {(prop.delivery.creator && prop.delivery.creator.last_name) && prop.delivery.creator.last_name}
                            </TableCell>
                            <TableCell
                              className={classes.tableCell}
                              key={prop.id}
                            >
                              {prop.delivery.from_location.location_name}
                            </TableCell>
                            <TableCell
                              className={classes.tableCell}
                              key={prop.id}
                            >
                              {prop.facility.facility_name}
                            </TableCell>
                            <TableCell
                              className={classes.tableCell}
                              key={prop.id}
                            >
                              {prop.delivery.to_location.location_name}
                            </TableCell>
                            <TableCell
                              className={classes.tableCell}
                              key={prop.id}
                            >
                              {prop.driver.first_name +
                                " " +
                                prop.driver.last_name}
                            </TableCell>
                            <TableCell
                              className={classes.tableCell}
                              key={prop.id}
                            >
                              GMD {prop.delivery.price}
                            </TableCell>
                            <TableCell
                              className={classes.tableCell}
                              key={prop.id}
                            >
                              <View
                                style={{ cursor: "pointer" }}
                                onClick={(e) =>
                                  toggleDraweropen(e, prop.delivery.id)
                                }
                              />
                            </TableCell>
                          </TableRow>
                        );
                      })
                    )
                  }
                  intl={true}
                />
                <TablePagination
      component="div"
      count={totalcount3}
      page={page3}
      onPageChange={handleChangePage3}
      rowsPerPage={rowsPerPage3}
      onRowsPerPageChange={handleChangeRowsPerPage3}
    />
              </TabPanel>
              <TabPanel value={value} index={3}>
                <Table
                  searchKeyword={searchHandler4}
                  term={searchTerm4}
                  setTerm={setSearchTerm4}
                  searchserviceKeyword={searchserviceHandler4}
                  serviceterm={searchserviceTerm4}
                  allservices={services}
                  tableHeaderColor="gray"
                  tableHead={[
                    "Order ID",
                    "Sender",
                    "Reciepient",
                    "From",
                    "To",
                    "Price",
                    "Handler",
                    "Details",
                  ]}
                  tableData={
                    outfordelivery.length == 0 ? (
                      <TableRow>
                        <TableCell
                          style={{ textAlign: "center" }}
                          colSpan={8}
                          className={classes.tableCell}
                        >
                          No Deliveries On-Route to Recipient
                        </TableCell>
                      </TableRow>
                    ) : (
                      outfordelivery.map((prop) => {
                        return (
                          <TableRow
                            key={prop.id}
                            className={classes.tableBodyRow}
                          >
                            <TableCell
                              className={classes.tableCell}
                              key={prop.id}
                            >
                              <Link to={{pathname: "/admin/searchdeliveries", property: { orderId: prop.order_id }}} >
                              {prop.order_id}
                              </Link>
                            </TableCell>
                            <TableCell
                              className={classes.tableCell}
                              key={prop.id}
                            >
                              {prop.creator ? prop.creator.first_name : prop.sender_name}{" "}
                            {(prop.creator && prop.creator.last_name) && prop.creator.last_name}
                            </TableCell>
                            <TableCell
                              className={classes.tableCell}
                              key={prop.id}
                            >
                              {prop.reciepient ? prop.reciepient.first_name : prop.creator ? prop.creator.first_name : prop.sender_name}{" "}
                             {prop.reciepient ? prop.reciepient.last_name : prop.creator ? prop.creator.last_name ? prop.creator.last_name : ""  : ""}
                            </TableCell>
                            <TableCell
                              className={classes.tableCell}
                              key={prop.id}
                            >
                              {prop.facilityrecipient
                                ? prop.facilityrecipient.facility.facility_name
                                : prop.from_location.location_name}
                            </TableCell>
                            <TableCell
                              className={classes.tableCell}
                              key={prop.id}
                            >
                              {prop.to_location.location_name}
                            </TableCell>
                            <TableCell
                              className={classes.tableCell}
                              key={prop.id}
                            >
                              GMD {prop.price}
                            </TableCell>
                            <TableCell
                              className={classes.tableCell}
                              key={prop.id}
                            >
                              {prop.facilityrecipient
                                ? prop.facilityrecipient.driver.first_name +
                                  " " +
                                  prop.facilityrecipient.driver.last_name
                                : prop.clientrecipient.driver.first_name +
                                  " " +
                                  prop.clientrecipient.driver.last_name}
                            </TableCell>
                            {/* <TableCell
                              className={classes.tableCell}
                              key={prop.id}
                            >
                              <FontAwesomeIcon
                                style={{ cursor: "pointer" }}
                                onClick={(e) => openRouteDrawer(e, prop.id)}
                                icon={faRoute}
                              />
                            </TableCell> */}
                            <TableCell
                              className={classes.tableCell}
                              key={prop.id}
                            >
                              <View
                                style={{ cursor: "pointer" }}
                                onClick={(e) =>
                                  toggleFinalDraweropen(e, prop.id)
                                }
                              />
                            </TableCell>
                          </TableRow>
                        );
                      })
                    )
                  }
                  intl={true}
                />
                <TablePagination
      component="div"
      count={totalcount4}
      page={page4}
      onPageChange={handleChangePage4}
      rowsPerPage={rowsPerPage4}
      onRowsPerPageChange={handleChangeRowsPerPage4}
    />
              </TabPanel>
            </div>
          </CardBody>
        </Card>
      </GridItem>
      <Dialog
        fullWidth={true}
        maxWidth={"xl"}
        open={open}
        TransitionComponent={Transition}
        keepMounted
        onClose={handleClose}
        aria-labelledby="alert-dialog-slide-title"
        aria-describedby="alert-dialog-slide-description"
      >
        <DialogContent>
          <DialogContentText id="alert-dialog-slide-description">
            <GridContainer>
              <GridItem xs={12} sm={12} md={12}>
                <>
                  <CardHeader color="danger">
                    <h4 className={classes.cardTitleWhite}>Courier Service</h4>
                    <p className={classes.cardCategoryWhite}>
                      Order. No: {orderdata.order_id}
                    </p>
                  </CardHeader>
                  <CardBody>
                    <GridContainer>
                      <GridItem xs={12} sm={12} md={6}>
                        <div>
                          <h6>
                            <em>Sender</em>
                          </h6>
                          <div className={classes.detailcell}>
                            <PersonOutlineIcon />
                            <span>Name: {orderdata.sender_name}</span>
                          </div>
                          <div className={classes.detailcell}>
                            <EmailIcon />
                            <span>Email: {orderdata.sender_email}</span>
                          </div>
                          <div className={classes.detailcell}>
                            <CallIcon />
                            <span>Telephone: {orderdata.sender_telephone}</span>
                          </div>
                          <div className={classes.detailcell}>
                            <LocationOn />
                            <span>Location: {orderdata.sender_location}</span>
                          </div>
                        </div>
                      </GridItem>
                      <Divider orientation="vertical" flexItem />
                      <GridItem xs={12} sm={12} md={5}>
                        <div>
                          <h6>
                            <em>Reciepient</em>
                          </h6>
                          <div className={classes.detailcell}>
                            <PersonOutlineIcon />
                            <span>Name: {orderdata.recipient_name}</span>
                          </div>
                          <div className={classes.detailcell}>
                            <EmailIcon />
                            <span>Email: {orderdata.recipient_email}</span>
                          </div>
                          <div className={classes.detailcell}>
                            <CallIcon />
                            <span>
                              Telephone: {orderdata.recipient_telephone}
                            </span>
                          </div>
                          <div className={classes.detailcell}>
                            <LocationOn />
                            <span>
                              Location: {orderdata.recipient_location}
                            </span>
                          </div>
                        </div>
                      </GridItem>
                    </GridContainer>

                    <hr />
                    <GridContainer>
                      <GridItem xs={12} sm={12} md={12}>
                        <div>
                          <h6 style={{ textAlign: "center" }}>
                            <em>Package Info</em>
                          </h6>
                          <div className={classes.detailcell2}>
                            <span>Package Type: {orderdata.pack_type}</span>
                          </div>
                          <div className={classes.detailcell2}>
                            <span>Boundary: {orderdata.boundary}</span>
                          </div>
                          <div className={classes.detailcell2}>
                            <span>
                              Driver: {orderdata.driver} ({orderdata.vehicle})
                            </span>
                          </div>
                          <div className={classes.detailcell2}>
                            <span>
                              CMB: {orderdata.cbm} | Weight:{orderdata.weight}
                            </span>
                          </div>
                          <div className={classes.detailcell2}>
                            <span>Price: GMD {orderdata.price}</span>
                          </div>
                        </div>
                      </GridItem>
                    </GridContainer>
                  </CardBody>
                </>
              </GridItem>
            </GridContainer>
          </DialogContentText>
        </DialogContent>
      </Dialog>

      <Dialog
        fullWidth={true}
        maxWidth={!addloading ? "sm" : "xs"}
        disableEscapeKeyDown
        open={openvehicleSelect}
        onClose={handleVehicleSelectClose}
      >
        <DialogTitle style={{ textAlign: "center" }}>
          {!addloading ? "Set Delivery Vehicle" : "Setting Delivery Vehicle"}
        </DialogTitle>
        {addloading ? (
          <DialogContentText style={{ textAlign: "center" }}>
            <CircularProgress color="secondary" />
          </DialogContentText>
        ) : (
          <DialogContent>
            <GridContainer>
              <GridItem xs={12} sm={12} md={12}>
                <label>Assigned Vehicle (choose one)</label>
                <br />
                <div className={classes.flexContainer}>
                  {vehicles.map((prop) => (
                    <div key={prop.id} className={classes.flexItem}>
                      <img
                        width="100%"
                        height="100%"
                        onClick={() => setSelvehid(prop.id)}
                        src={props.imgurl + prop.img}
                      />
                      <div
                        className={
                          selvehid == prop.id
                            ? classes.selected
                            : classes.overlay
                        }
                      >
                        <CheckCircle className={classes.playIcon} />
                      </div>
                    </div>
                  ))}
                </div>
              </GridItem>
            </GridContainer>
          </DialogContent>
        )}
        {!addloading && (
          <DialogActions>
            <CustomButton
              style={{ display: "block", marginLeft: "0", marginRight: "auto" }}
              onClick={handleVehicleSelectClose}
              color="danger"
            >
              <Close />
              Cancel
            </CustomButton>
            <CustomButton
              style={{ float: "right" }}
              color="danger"
              onClick={handleVehicleSelectUpdateClose}
            >
              Proceed <Arrowed />
            </CustomButton>
          </DialogActions>
        )}
      </Dialog>

      <Dialog
        fullWidth={true}
        maxWidth={addloading ? "xs" : "sm"}
        disableEscapeKeyDown
        open={openSelect}
        onClose={handleSelectClose}
      >
        {addloading ? (
          <>
            <DialogTitle style={{ textAlign: "center" }}>
              Assigning Delivery Request
            </DialogTitle>
            <DialogContentText style={{ textAlign: "center" }}>
              <CircularProgress color="secondary" />
            </DialogContentText>{" "}
          </>
        ) : (
          <>
            <DialogContent>
                  <GridContainer>
                    <GridItem xs={12} sm={12} md={12}>
                      <DialogTitle style={{ textAlign: "center" }}>
                        Select Next Destination
                      </DialogTitle>
                    </GridItem>
                    <GridItem xs={12} sm={12} md={12}>
                      <Select
                        value={sortingid}
                        onChange={(val) => setSortingid(val)}
                        menuPortalTarget={document.body}
                        placeholder="Select Sorting Facility..."
                        styles={{
                          menuPortal: (base) => ({ ...base, zIndex: 9999 }),
                        }}
                        options={facilities}
                      />
                    </GridItem>
                  </GridContainer>
                  <DialogTitle style={{ textAlign: "center" }}>
                        Select Delivery Handler
                      </DialogTitle>
                  <GridContainer>
                    
                    <GridItem xs={12} sm={12} md={12}>
                      <Select
                        menuPortalTarget={document.body}
                        value={driverid}
                        onChange={(val) => setDriverid(val)}
                        placeholder="Select Driver..."
                        styles={{
                          menuPortal: (base) => ({ ...base, zIndex: 9999 }),
                        }}
                        options={options}
                      />
                    </GridItem>
                  </GridContainer>
                  <GridContainer>
                    <CustomButton
                      style={{
                        display: "block",
                        marginLeft: "0",
                        marginRight: "auto",
                        marginTop:"15px"
                      }}
                      onClick={handleSelectClose}
                      color="danger"
                    >
                      <Close />Close
                    </CustomButton>
                    <CustomButton
                      onClick={() => processdelivery()}
                      style={{ float: "right", marginTop:"15px" }}
                      color="danger"
                      disabled={sortingid == 0 || driverid == 0}
                    >
                      Proceed <Arrow />
                    </CustomButton>
                  </GridContainer>
               
            </DialogContent>{" "}
          </>
        )}
      </Dialog>
      <Drawer anchor={"right"} open={opendrawer} onClose={toggleDrawerclose}>
        <div className={clsx(classes.list)} role="presentation">
          <List style={{ background: "#EEEEEE" }}>
            <ListItem>
              <ListItemIcon>
                <Close
                  style={{ cursor: "pointer" }}
                  onClick={toggleDrawerclose}
                />
              </ListItemIcon>
              <ListItemText primary="Delivery Details" />
            </ListItem>
          </List>
          <Divider style={{ marginBottom: "10px" }} />
          <ListItem>
            <GridContainer style={{ width: "100%" }}>
              <GridItem xs={12} sm={12} md={12}>
                <Typography
                  style={{ textDecoration: "underline" }}
                  align="center"
                  variant="subtitle2"
                  gutterBottom
                >
                  {delivery.service && delivery.service.service_name}
                </Typography>
                <Typography
                  style={{ textDecoration: "underline" }}
                  align="center"
                  variant="subtitle2"
                  gutterBottom
                >
                  {delivery.boundary && delivery.boundary.boundary_name}{" "}
                  Delivery
                </Typography>
              </GridItem>
              <GridItem
                style={{ borderRight: "1px solid #EEEEEE" }}
                xs={6}
                sm={6}
                md={6}
              >
                <Typography
                  style={{ textDecoration: "underline" }}
                  align="center"
                  variant="subtitle2"
                  gutterBottom
                >
                  INITIAL PICKUP
                </Typography>
                <Typography align="center" variant="subtitle2" gutterBottom>
                  Location:{" "}
                  <span
                    style={{ textTransform: "capitalize", fontSize: "15px" }}
                  >
                    {delivery.from_location &&
                      delivery.from_location.location_name}
                  </span>
                </Typography>
                <Typography align="center" variant="subtitle2" gutterBottom>
                  Address:{" "}
                  <span
                    style={{ textTransform: "capitalize", fontSize: "15px" }}
                  >
                    {delivery.fromAddress}
                  </span>
                </Typography>
              </GridItem>
              <GridItem xs={6} sm={6} md={6}>
                <Typography
                  style={{ textDecoration: "underline" }}
                  align="center"
                  variant="subtitle2"
                  gutterBottom
                >
                  FINAL DESTINATION
                </Typography>
                <Typography align="center" variant="subtitle2" gutterBottom>
                  Location:{" "}
                  <span
                    style={{ textTransform: "capitalize", fontSize: "15px" }}
                  >
                    {delivery.to_location && delivery.to_location.location_name}
                  </span>
                </Typography>
                <Typography align="center" variant="subtitle2" gutterBottom>
                  Address:{" "}
                  <span
                    style={{ textTransform: "capitalize", fontSize: "15px" }}
                  >
                    {delivery.toAddress}
                  </span>
                </Typography>
              </GridItem>
            </GridContainer>
          </ListItem>
          <Divider style={{ marginBottom: "10px" }} />
          <ListItem>
            <GridContainer style={{ width: "100%" }}>
              <GridItem
                style={{ borderRight: "1px solid #EEEEEE" }}
                xs={6}
                sm={6}
                md={6}
              >
                <Typography
                  style={{ textDecoration: "underline" }}
                  align="center"
                  variant="subtitle2"
                  gutterBottom
                >
                  SENDER
                </Typography>
                <Typography align="center" variant="subtitle2" gutterBottom>
                  Name:{" "}
                  <span
                    style={{ textTransform: "capitalize", fontSize: "15px" }}
                  >
                    {delivery.creator ? delivery.creator.first_name : delivery.sender_name}{" "}
                    {(delivery.creator && delivery.creator.last_name) && delivery.creator.last_name}
                  </span>
                </Typography>
                <Typography align="center" variant="subtitle2" gutterBottom>
                  Telephone:{" "}
                  <span
                    style={{ textTransform: "capitalize", fontSize: "15px" }}
                  >
                    {delivery.creator ? delivery.creator.phone_number : delivery.sender_phone}
                  </span>
                </Typography>
              </GridItem>
              {!delivery.clientfacility && (
                <GridItem xs={6} sm={6} md={6}>
                  <Typography
                    style={{ textDecoration: "underline" }}
                    align="center"
                    variant="subtitle2"
                    gutterBottom
                  >
                    RECIEPIENT
                  </Typography>
                  <Typography align="center" variant="subtitle2" gutterBottom>
                    Name:{" "}
                    <span
                      style={{ textTransform: "capitalize", fontSize: "15px" }}
                    >
                       {delivery.reciepient ? delivery.reciepient.first_name : delivery.creator ? delivery.creator.first_name : delivery.sender_name}{" "}
                             {delivery.reciepient ? delivery.reciepient.last_name : delivery.creator ? delivery.creator.last_name ? delivery.creator.last_name : ""  : ""}
                      
                    </span>
                  </Typography>
                  <Typography align="center" variant="subtitle2" gutterBottom>
                    Telephone:{" "}
                    <span
                      style={{ textTransform: "capitalize", fontSize: "15px" }}
                    >
                    {delivery.reciepient ? delivery.reciepient.phone : delivery.creator ? delivery.creator.phone_number ? delivery.creator.phone_number : "": delivery.sender_phone }

                    </span>
                  </Typography>
                </GridItem>
              )}
              {delivery.clientfacility && (
                <GridItem xs={6} sm={6} md={6}>
                  <Typography
                    style={{ textDecoration: "underline" }}
                    align="center"
                    variant="subtitle2"
                    gutterBottom
                  >
                    NEXT DESTINATION
                  </Typography>
                  <Typography align="center" variant="subtitle2" gutterBottom>
                    Zone:{" "}
                    <span
                      style={{ textTransform: "capitalize", fontSize: "15px" }}
                    >
                      {delivery.clientfacility.facility.zone.zone_name}
                    </span>
                  </Typography>
                  <Typography align="center" variant="subtitle2" gutterBottom>
                    Facility:{" "}
                    <span
                      style={{ textTransform: "capitalize", fontSize: "15px" }}
                    >
                      {delivery.clientfacility.facility.facility_name}
                    </span>
                  </Typography>
                </GridItem>
              )}
            </GridContainer>
          </ListItem>
          <Divider />
          <ListItem>
            <GridContainer style={{ width: "100%" }}>
              {delivery.clientfacility &&
                delivery.clientfacility.status == "onroute" && (
                  <GridItem xs={12} sm={12} md={12}>
                    <Typography align="center" variant="subtitle2" gutterBottom>
                      Picked up{" "}
                      {moment(delivery.clientfacility.updated_at).fromNow()} by{" "}
                      <span
                        style={{
                          textTransform: "capitalize",
                          fontSize: "15px",
                        }}
                      >
                        {delivery.clientfacility &&
                          delivery.clientfacility.driver.first_name +
                            " " +
                            delivery.clientfacility.driver.last_name}
                      </span>
                    </Typography>
                  </GridItem>
                )}
              {delivery.clientfacility &&
                delivery.clientfacility.status == "pendingpickup" && (
                  <GridItem xs={12} sm={12} md={12}>
                    <Typography align="center" variant="subtitle2" gutterBottom>
                      Assigned {moment(delivery.updated_at).fromNow()} to{" "}
                      <span
                        style={{
                          textTransform: "capitalize",
                          fontSize: "15px",
                        }}
                      >
                        {delivery.clientfacility &&
                          delivery.clientfacility.driver.first_name +
                            " " +
                            delivery.clientfacility.driver.last_name}
                      </span>{" "}
                    </Typography>
                  </GridItem>
                )}

              {delivery.status == "unassigned" && (
                <GridItem xs={12} sm={12} md={12}>
                  <Typography align="center" variant="subtitle2" gutterBottom>
                    Delivery Request made{" "}
                    {moment(delivery.created_at).fromNow()}{" "}
                  </Typography>
                </GridItem>
              )}

            {(delivery.status == "unassigned" || delivery.status == "pendingpickup" )&& (
                <GridItem xs={12} sm={12} md={12}>
                  <Box style={{display:'flex',alignItems:"center",justifyContent:"center"}} >
                   <Chip 
                            label={delivery.paid == 0 ? "Cash Payment on Pickup" : 'Already Paid'}
                            color={
                              delivery.paid == 1 
                                ? "primary"
                                : "secondary"
                            }
                          />
                          </Box>
                </GridItem>
              )}

              {delivery.clientrecipient && (
                <GridItem xs={12} sm={12} md={12}>
                  <Typography align="center" variant="subtitle2" gutterBottom>
                    Assigned {moment(delivery.updated_at).fromNow()} to{" "}
                    <span
                      style={{ textTransform: "capitalize", fontSize: "15px" }}
                    >
                      {delivery.clientrecipient &&
                        delivery.clientrecipient.driver.first_name +
                          " " +
                          delivery.clientrecipient.driver.last_name}
                    </span>
                  </Typography>
                </GridItem>
              )}
            </GridContainer>
          </ListItem>
          {delivery.items && delivery.items.length > 0 && (
            <>
              {" "}
              <List style={{ background: "#EEEEEE" }}>
                <ListItem>
                  <ListItemIcon>
                    <FontAwesomeIcon icon={faBoxOpen} />
                  </ListItemIcon>
                  <ListItemText
                    primary={
                      delivery.items &&
                      delivery.items.length + (delivery.items.length != 1 ? " Items" : " Item")
                    }
                  />
                </ListItem>
              </List>
              <Divider />
            </>
          )}
          <Divider style={{ marginBottom: "10px" }} />
          
            {delivery.items &&
            delivery.items.map((prop) => {
              return (
                <>
                  <ListItem>
                    <GridContainer style={{ width: "100%" }}>
                      <GridItem xs={4} sm={4} md={4}>
                      <img
                          className={classes.vehicleimg}
                          key={prop.id}
                          alt="Item"
                          src={props.imgurl + prop.items.img}
                        />
                      </GridItem>
                      <GridItem xs={4} sm={4} md={4}>
                        <Typography variant="subtitle2" gutterBottom>
                          Package Type
                        </Typography>
                        <Typography
                          variant="caption"
                          display="block"
                          gutterBottom
                        >
                          {prop.items.item_name}{" "}
                        </Typography>
                      </GridItem>
                      <GridItem xs={4} sm={4} md={4}>
                        <Typography variant="subtitle2" gutterBottom>
                          Package ID
                        </Typography>
                        <Typography
                          variant="caption"
                          display="block"
                          gutterBottom
                        >
                          {prop.package_reference_id
                            ? prop.package_reference_id
                            : "N/A"}
                        </Typography>
                      </GridItem>
                    </GridContainer>
                  </ListItem>

                  <Divider style={{ marginBottom: "10px" }} />
                </>
              );
            })}
        </div>
      </Drawer>

      <Drawer
        anchor={"right"}
        open={openfinaldrawer}
        onClose={toggleFinalDrawerclose}
      >
        <div className={clsx(classes.list)} role="presentation">
          <List style={{ background: "#EEEEEE" }}>
            <ListItem>
              <ListItemIcon>
                <Close
                  style={{ cursor: "pointer" }}
                  onClick={toggleFinalDrawerclose}
                />
              </ListItemIcon>
              <ListItemText primary="Delivery Details" />
            </ListItem>
          </List>
          <Divider style={{ marginBottom: "10px" }} />
          <ListItem>
            <GridContainer style={{ width: "100%" }}>
              <GridItem xs={12} sm={12} md={12}>
                <Typography
                  style={{ textDecoration: "underline" }}
                  align="center"
                  variant="subtitle2"
                  gutterBottom
                >
                  {delivery.service && delivery.service.service_name}
                </Typography>
                <Typography
                  style={{ textDecoration: "underline" }}
                  align="center"
                  variant="subtitle2"
                  gutterBottom
                >
                  {delivery.boundary && delivery.boundary.boundary_name}{" "}
                  Delivery
                </Typography>
              </GridItem>
              <GridItem
                style={{ borderRight: "1px solid #EEEEEE" }}
                xs={6}
                sm={6}
                md={6}
              >
                <Typography
                  style={{ textDecoration: "underline" }}
                  align="center"
                  variant="subtitle2"
                  gutterBottom
                >
                  INITIAL PICKUP
                </Typography>
                <Typography align="center" variant="subtitle2" gutterBottom>
                  Location:{" "}
                  <span
                    style={{ textTransform: "capitalize", fontSize: "15px" }}
                  >
                    {delivery.from_location &&
                      delivery.from_location.location_name}
                  </span>
                </Typography>
                <Typography align="center" variant="subtitle2" gutterBottom>
                  Address:{" "}
                  <span
                    style={{ textTransform: "capitalize", fontSize: "15px" }}
                  >
                    {delivery.fromAddress}
                  </span>
                </Typography>
              </GridItem>
              <GridItem xs={6} sm={6} md={6}>
                <Typography
                  style={{ textDecoration: "underline" }}
                  align="center"
                  variant="subtitle2"
                  gutterBottom
                >
                  FINAL DESTINATION
                </Typography>
                <Typography align="center" variant="subtitle2" gutterBottom>
                  Location:{" "}
                  <span
                    style={{ textTransform: "capitalize", fontSize: "15px" }}
                  >
                    {delivery.to_location && delivery.to_location.location_name}
                  </span>
                </Typography>
                <Typography align="center" variant="subtitle2" gutterBottom>
                  Address:{" "}
                  <span
                    style={{ textTransform: "capitalize", fontSize: "15px" }}
                  >
                    {delivery.toAddress}
                  </span>
                </Typography>
              </GridItem>
            </GridContainer>
          </ListItem>
          <Divider style={{ marginBottom: "10px" }} />
          <ListItem>
            <GridContainer style={{ width: "100%" }}>
              <GridItem
                style={{ borderRight: "1px solid #EEEEEE" }}
                xs={6}
                sm={6}
                md={6}
              >
                <Typography
                  style={{ textDecoration: "underline" }}
                  align="center"
                  variant="subtitle2"
                  gutterBottom
                >
                  SENDER
                </Typography>
                <Typography align="center" variant="subtitle2" gutterBottom>
                  Name:{" "}
                  <span
                    style={{ textTransform: "capitalize", fontSize: "15px" }}
                  >
                    {delivery.creator ? delivery.creator.first_name : delivery.sender_name}{" "}
                            {(delivery.creator && delivery.creator.last_name) && delivery.creator.last_name}
                  </span>
                </Typography>
                <Typography align="center" variant="subtitle2" gutterBottom>
                  Telephone:{" "}
                  <span
                    style={{ textTransform: "capitalize", fontSize: "15px" }}
                  >
                    {delivery.creator ? delivery.creator.phone_number : delivery.sender_phone}
                  </span>
                </Typography>
              </GridItem>
              <GridItem xs={6} sm={6} md={6}>
                <Typography
                  style={{ textDecoration: "underline" }}
                  align="center"
                  variant="subtitle2"
                  gutterBottom
                >
                  RECIEPIENT
                </Typography>
                <Typography align="center" variant="subtitle2" gutterBottom>
                  Name:{" "}
                  <span
                    style={{ textTransform: "capitalize", fontSize: "15px" }}
                  >
                     {delivery.reciepient ? delivery.reciepient.first_name : delivery.creator ? delivery.creator.first_name : delivery.sender_name}{" "}
                             {delivery.reciepient ? delivery.reciepient.last_name : delivery.creator ? delivery.creator.last_name ? delivery.creator.last_name : ""  : ""}
                      
                  </span>
                </Typography>
                <Typography align="center" variant="subtitle2" gutterBottom>
                  Telephone:{" "}
                  <span
                    style={{ textTransform: "capitalize", fontSize: "15px" }}
                  >
                    {delivery.reciepient ? delivery.reciepient.phone : delivery.creator ? delivery.creator.phone_number ? delivery.creator.phone_number : "": delivery.sender_phone}

                  </span>
                </Typography>
              </GridItem>
            </GridContainer>
          </ListItem>
          <Divider />
          <ListItem>
            <GridContainer style={{ width: "100%" }}>
              {delivery.status == "outfordelivery" && (
                <GridItem xs={12} sm={12} md={12}>
                  <Typography align="center" variant="subtitle2" gutterBottom>
                    Picked up {moment(delivery.updated_at).fromNow()} by{" "}
                    <span
                      style={{ textTransform: "capitalize", fontSize: "15px" }}
                    >
                      {delivery.clientrecipient &&
                        delivery.clientrecipient.driver.first_name +
                          " " +
                          delivery.clientrecipient.driver.last_name}
                    </span>
                  </Typography>
                </GridItem>
              )}
            </GridContainer>
          </ListItem>

          <ListItem>
            <GridContainer style={{ width: "100%" }}></GridContainer>
          </ListItem>
          {delivery.items && delivery.items.length > 0 && (
            <>
              {" "}
              <List style={{ background: "#EEEEEE" }}>
                <ListItem>
                  <ListItemIcon>
                    <FontAwesomeIcon icon={faBoxOpen} />
                  </ListItemIcon>
                  <ListItemText
                    primary={
                      delivery.items &&
                      delivery.items.length + " Items"
                    }
                  />
                </ListItem>
              </List>
              <Divider />
            </>
          )}
          <Divider style={{ marginBottom: "10px" }} />
          
            {delivery.items &&
            delivery.items.map((prop) => {
              return (
                <>
                  <ListItem>
                    <GridContainer style={{ width: "100%" }}>
                      <GridItem xs={4} sm={4} md={4}>
                      <img
                          className={classes.vehicleimg}
                          key={prop.id}
                          alt="Item"
                          src={props.imgurl + prop.items.img}
                        />
                      </GridItem>
                      <GridItem xs={4} sm={4} md={4}>
                        <Typography variant="subtitle2" gutterBottom>
                          Package Type
                        </Typography>
                        <Typography
                          variant="caption"
                          display="block"
                          gutterBottom
                        >
                          {prop.items.item_name}{" "}
                        </Typography>
                      </GridItem>
                      <GridItem xs={4} sm={4} md={4}>
                        <Typography variant="subtitle2" gutterBottom>
                          Package ID
                        </Typography>
                        <Typography
                          variant="caption"
                          display="block"
                          gutterBottom
                        >
                          {prop.package_reference_id
                            ? prop.package_reference_id
                            : "N/A"}
                        </Typography>
                      </GridItem>
                    </GridContainer>
                  </ListItem>

                  <Divider style={{ marginBottom: "10px" }} />
                </>
              );
            })}
        </div>
      </Drawer>

      <Drawer anchor={"top"} open={routeDrawer} onClose={closeRouteDrawer}>
        <div className={clsx(classes.list)} role="presentation">
          <List style={{ background: "#EA4642" }}>
            <ListItem style={{ color: "white" }}>
              <ListItemIcon>
                <Close
                  style={{ cursor: "pointer", color: "white" }}
                  onClick={closeRouteDrawer}
                />
              </ListItemIcon>
              <ListItemText primary="Delivery Routes" />
            </ListItem>
          </List>
          <Divider style={{ marginBottom: "10px" }} />
          <ListItem>
            <GridContainer style={{ width: "100%" }}>
              <GridItem xs={12} sm={12} md={12}>
                <Typography align="center" variant="subtitle2" gutterBottom>
                  {deliveryroutes[0] &&
                    deliveryroutes[0].delivery.from_location.location_name +
                      " (" +
                      deliveryroutes[0].delivery.fromAddress +
                      ")"}
                </Typography>
              </GridItem>
              {deliveryroutes.map((prop) => {
                return (
                  <React.Fragment key={prop}>
                    <GridItem xs={12} sm={12} md={12}>
                      <Divider style={{ marginBottom: "10px" }} />
                    </GridItem>
                    <GridItem xs={12} sm={12} md={12}>
                      <div
                        style={{
                          display: "block",
                          marginLeft: "auto",
                          marginRight: "auto",
                          width: "100%",
                        }}
                      >
                        <GridItem
                          style={{ padding: "0" }}
                          xs={12}
                          sm={12}
                          md={12}
                        >
                          <Divider
                            style={{ margin: "0 auto", height: "60px" }}
                            orientation="vertical"
                          />
                        </GridItem>
                        <GridItem
                          style={{ textAlign: "center" }}
                          xs={12}
                          sm={12}
                          md={12}
                        >
                          <Badge
                            color={
                              prop.status == "complete"
                                ? "primary"
                                : "secondary"
                            }
                            variant="dot"
                          >
                            <Typography>
                              Status: {prop.status == "onroute" && "On Route"}
                              {prop.status == "pendingpickup" &&
                                "To be Picked Up"}
                              {prop.status == "complete" && "Delivered"}
                            </Typography>
                          </Badge>
                          <Chip
                            style={{
                              display: "block",
                              marginLeft: "auto",
                              marginRight: "auto",
                              overflow: "auto",
                            }}
                            variant="outlined"
                            label={
                              "Driver: " +
                              prop.driver.first_name +
                              " " +
                              prop.driver.last_name +
                              " (" +
                              (prop.driver.vehicle[0]
                                ? prop.driver.vehicle[0].vehicle_name
                                : "No Vehicle") +
                              ")"
                            }
                          />
                        </GridItem>
                        <GridItem xs={12} sm={12} md={12}>
                          <Divider
                            style={{ margin: "0 auto", height: "60px" }}
                            orientation="vertical"
                          />
                          <ExpandMoreIcon
                            style={{
                              color: "#b8b8b8",
                              display: "block",
                              marginLeft: "auto",
                              marginRight: "auto",
                            }}
                          />
                        </GridItem>
                      </div>
                    </GridItem>
                    <GridItem xs={12} sm={12} md={12}>
                      <Divider style={{ marginBottom: "10px" }} />
                      <Typography
                        align="center"
                        variant="subtitle2"
                        gutterBottom
                      >
                        {prop.to_id && prop.delivery.to_location.location_name}{" "}
                        {prop.toAddress && "(" + prop.toAddress + ")"}
                        {prop.facility_id &&
                          prop.from_id &&
                          prop.facility.facility_name +
                            "(" +
                            prop.facility.zone.zone_name +
                            ")"}
                        {prop.to_facility_id &&
                          prop.to_facility.facility_name +
                            "(" +
                            prop.to_facility.zone.zone_name +
                            ")"}
                      </Typography>
                    </GridItem>
                  </React.Fragment>
                );
              })}
            </GridContainer>
          </ListItem>
        </div>
      </Drawer>

      <Dialog
        fullWidth={true}
        maxWidth="sm"
        open={openconfirm}
        TransitionComponent={Transition}
        keepMounted
        classes={{ scrollPaper: classes.scrollPaper }}
        onClose={handleconfirmClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          {"DELETE DELIVERY REQUEST"}
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            Are you sure you want to cancel this delivery request?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <CustomButton
            style={{ display: "block", marginLeft: "0", marginRight: "auto" }}
            onClick={handleconfirmClose}
            color="danger"
          >
            <Close />
            Cancel
          </CustomButton>
          <CustomButton
            style={{ float: "right" }}
            color="danger"
            onClick={processDeletion}
          >
            Proceed <Arrowed />
          </CustomButton>
        </DialogActions>
      </Dialog>
    </GridContainer>
  );
}

Deliveries.propTypes = {
  url: PropTypes.string,
  imgurl: PropTypes.string,
  exit: PropTypes.string,
};
