import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { makeStyles } from "@material-ui/core/styles";
import Backdrop from "@material-ui/core/Backdrop";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import TextField from "@material-ui/core/TextField";
import Card from "components/Card/Card.js";
import { useHistory } from "react-router-dom";
import Table from "components/Table/Table.js";
import CardHeader from "components/Card/CardHeader.js";
import CardBody from "components/Card/CardBody.js";
import Button from "components/CustomButtons/Button.js";
import ArrowBackIcon from "@material-ui/icons/ArrowBack";
import CircularProgress from "@material-ui/core/CircularProgress";
import TableRow from "@material-ui/core/TableRow";
import TableCell from "@material-ui/core/TableCell";

const styles = {
  backdrop: {
    zIndex: 5,
    color: "#fff",
  },
  typo: {
    paddingLeft: "25%",
    marginBottom: "40px",
    position: "relative",
  },
  note: {
    fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
    bottom: "10px",
    color: "#c0c1c2",
    display: "block",
    fontWeight: "400",
    fontSize: "13px",
    lineHeight: "13px",
    left: "0",
    marginLeft: "20px",
    position: "absolute",
    width: "260px",
  },
  cardCategoryWhite: {
    color: "rgba(255,255,255,.62)",
    margin: "0",
    fontSize: "14px",
    marginTop: "0",
    marginBottom: "0",
  },
  cardTitleWhite: {
    color: "#FFFFFF",
    marginTop: "0px",
    minHeight: "auto",
    fontWeight: "300",
    fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif",
    marginBottom: "3px",
    textDecoration: "none",
  },
};

const useStyles = makeStyles(styles);

export default function ZonePricing({ match, url, exit }) {
  toast.configure();
  const {
    params: { zid },
  } = match;
  let history = useHistory();
  const [rates, setRates] = useState([]);
  const [zones, setZones] = useState({});
  const [vehicles, setVehicles] = useState([]);
  const [vehids, setVehids] = useState([]);
  const [update, setUpdate] = useState(false);
  const [zonedata, setZonedata] = useState([
    {
      name: "",
    },
  ]);
  const [loading, setLoading] = useState(true);
  const classes = useStyles();
  const getRelations = async () => {
    let response = await fetch(url + `/get-rates/${zid}`, {
      credentials: "include",
    });
    let data = await response.json();
    if (data.success) {
      setRates(data.data[0]);
      setVehicles(data.data[1]);
      setZones(data.data[2]);
      setVehids(data.data[3]);
      setLoading(false);
    } else {
      if( data.message == 'unauthenticated'){
        window.location.replace(exit);
        return;
      }
      toast.error(data.message);
    }
  };
  const getZonedata = async () => {
    let response = await fetch(url + `/get-zone-data/${zid}`, {
      credentials: "include",
    });
    let data = await response.json();
    if (data.success) {
      setZonedata({ name: data.data[0] });
    } else {
      if( data.message == 'unauthenticated'){
        window.location.replace(exit);
        return;
      }
      toast.error(data.message);
    }
  };
  const UpdateRates = async (e) => {
    setLoading(true);
    e.preventDefault();
    let response = await fetch(url + `/update-zone-rates/${zid}`, {
      method: "PUT",
      headers: {
        "Content-type": "application/json",
      },
      body: JSON.stringify({ rates: rates, vehids: vehids }),
      credentials: "include",
    });
    let data = await response.json();
    if (data.success) {
      setRates(data.data[0]);
      setVehicles(data.data[1]);
      setZones(data.data[2]);
      setVehids(data.data[3]);
      setUpdate(false);
      setLoading(false);
      toast.success("Rate updated");
    } else {
      if( data.message == 'unauthenticated'){
        window.location.replace(exit);
        return;
      }
      toast.error(data.message);
    }
  };
  useEffect(() => {
    getRelations();
    getZonedata();
  }, []);
  const handlepricingchange = (event, zoneid, vehid) => {
    const target = event.target;
    console.log(zoneid, vehid, target.value);
    setRates(
      rates.map((rate, index) =>
        index === zoneid
          ? rate.map((val, key) =>
              key === vehid ? parseInt(target.value) : val
            )
          : rate
      )
    );
    console.log(rates);
    setUpdate(true);
  };
  return (
    <Card>
      {loading && (
        <Backdrop className={classes.backdrop} open>
          <CircularProgress color="inherit" />
        </Backdrop>
      )}
      <CardHeader color="danger">
        <h4 className={classes.cardTitleWhite}>{zonedata.name} rates </h4>
      </CardHeader>
      <CardBody>
        <Button
          onClick={history.goBack}
          style={{ float: "left" }}
          color="danger"
        >
          <ArrowBackIcon />
          Back
        </Button>
        <Table
          tableHeaderColor="black"
          tableHead={[`${zonedata.name} to`].concat(vehicles)}
          tableData={rates.map((prop, ind) => (
            <TableRow key={prop[0]} className={classes.tableBodyRow}>
              <TableCell className={classes.tableCell} key={prop[0]}>
                {zones[prop[0]]}
              </TableCell>
              {prop.map((val, key) =>
                key === 0 ? null : (
                  <TableCell className={classes.tableCell} key={key}>
                    <TextField
                      style={{ width: "100%" }}
                      label="GMD"
                      value={val}
                      type="number"
                      onChange={(e) => handlepricingchange(e, ind, key)}
                      id="standard-start-adornment"
                    />
                  </TableCell>
                )
              )}
            </TableRow>
          ))}
          search={false}
        />
        {update && (
          <Button
            onClick={UpdateRates}
            style={{ float: "right" }}
            color="danger"
          >
            Save Changes
          </Button>
        )}
      </CardBody>
    </Card>
  );
}
ZonePricing.propTypes = {
  url: PropTypes.string,
  exit: PropTypes.string,
  match: PropTypes.shape({
    params: PropTypes.shape({
      zid: PropTypes.number.isRequired,
      veh: PropTypes.number.isRequired,
    }),
  }),
};
