import React, { useRef,useEffect,useState } from "react";
import Backdrop from "@material-ui/core/Backdrop";
import CircularProgress from "@material-ui/core/CircularProgress";
import { makeStyles } from "@material-ui/core/styles";
import { toast } from "react-toastify";
import Box from "@material-ui/core/Box";
import ArrowForward from "@material-ui/icons/ArrowForward";
import "react-toastify/dist/ReactToastify.css";
import Badge from "@material-ui/core/Badge";
import PropTypes from "prop-types";
import Chip from "@material-ui/core/Chip";
import Divider from '@material-ui/core/Divider';
import moment from 'moment';
import Search from "@material-ui/icons/Search";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import TextField from '@material-ui/core/TextField';
import Image from "react-image-enlarger";
import Button from "components/CustomButtons/Button.js";
import GridItem from "components/Grid/GridItem.js";
import GridContainer from "components/Grid/GridContainer.js";
import Card from "components/Card/Card.js";
import Typography from "@material-ui/core/Typography";
import CardHeader from "components/Card/CardHeader.js";
import CardBody from "components/Card/CardBody.js";
import CardFooter from "components/Card/CardFooter.js";

import styles from "assets/jss/material-dashboard-react/views/dashboardStyle.js";

const useStyles = makeStyles(styles);

export default function Search_Deliveries(props) {
  toast.configure();
  const classes = useStyles();
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(false);
  const [orderid, setOrderid] = useState(props.location.property ? props.location.property.orderId : '');
  const [delivery, setDelivery] = useState({});
  const [found, setFound] = useState(false);
  const [zoomed, setZoomed] = React.useState(false);
  let form=useRef();

  const searchOrder = async (e) => {
    e.preventDefault();
    if(orderid==""){
      setError(true)
    }
    else{
      setError(false)
    setLoading(true);
      let response = await fetch(props.url + `/get-order-details/${orderid}`, {
        credentials: "include",
      });
      let res = await response.json();
      if (res) {
        if (res.success) {
          setDelivery(res.data)
          console.log(delivery)
          setFound(true);
        } else {
          if( res.message == 'unauthenticated'){
            window.location.replace(props.exit);
            return;
          }
          toast.error(res.message);
          setFound(false)
        }
        setLoading(false);
    }
  }
  };

  useEffect(() => {
    if(props.location.property){
      form.current.requestSubmit();
    }
  }, []);
  
  return (
    <div>
      <GridContainer >
        {loading && (
          <Backdrop style={{ zIndex: 5, color: "#fff" }} open>
            <CircularProgress color="inherit" />
          </Backdrop>
        )}
        </GridContainer>
      <GridContainer style={{display:'div',justifyContent:'space-around'}}>
        <GridItem xs={12} sm={12} md={6} lg={6}>
          <Card>
            <form ref={form} onSubmit={searchOrder}>
             <CardBody>
              <h4 style={{textAlign:"center"}} className={classes.cardTitle}>Search for Delivery Request</h4>
              <p style={{textAlign:"center"}}  className={classes.cardCategory}>
                <span className={classes.cardCategory}>
      <TextField InputLabelProps={{
                 classes: {
                   root: classes.label
                 }
                }}
                 error={error} helperText={error && 'Please enter an order id'} value={orderid} onChange={(e)=>setOrderid(e.target.value)} style={{width:'100%'}} id="outlined-basic" label="Enter Order ID" variant="outlined" />
                </span>{" "}
              </p>
            </CardBody>
            <CardFooter chart>
              <div style={{ display: "block",marginLeft: "auto",marginRight: "auto",}} >
              <Button
              color="danger"
              type="submit"
            >
              Search  <Search />
            </Button>
              </div>
            </CardFooter>
            </form>
          </Card>
        </GridItem>
        </GridContainer>
      {found == true && 
      <GridContainer>
        <GridItem xs={12} sm={12} md={12}>
          <Card>
            <CardHeader color="danger">
              <h4 className={classes.cardTitleWhite}>
                Delivery Details
              </h4>
            </CardHeader>
            <CardBody>
            <GridContainer>
        <GridItem style={{ borderRight: "1px solid #EEEEEE" }} xs={12} sm={12} md={4} lg={4}>
        <Typography style={{ textDecoration: "underline",textAlign:'center' }} align="center" variant="subtitle2" gutterBottom>
        Cost of Delivery: GMD {delivery && delivery.price}
         </Typography>
          <GridContainer>
        <GridItem xs={12} sm={12} md={6} lg={6}>
        <Typography style={{ textDecoration: "underline" }} align="center" variant="subtitle2" gutterBottom>
                Boundary
        </Typography>
        <img
          className={classes.detailimg}
          variant="square"
          alt="Boundary"
          src={delivery && props.imgurl + delivery.boundary.img}
        />
         <Typography align="center" variant="body2" gutterBottom>
         {delivery && delivery.boundary.boundary_name}
          </Typography>
        </GridItem>
        <GridItem xs={12} sm={12} md={6} lg={6}>
        <Typography style={{ textDecoration: "underline" }} align="center" variant="subtitle2" gutterBottom>
                Service
        </Typography>
        <img
          className={classes.detailimg}
          variant="square"
          alt="Service"
          src={delivery && props.imgurl + delivery.service.img}
        />
         <Typography align="center" variant="body2" gutterBottom>
         {delivery && delivery.service.service_name}
          </Typography>
        </GridItem>
      </GridContainer>
      <Divider />
      <GridContainer>
        <GridItem xs={12} sm={12} md={12} lg={4}>
        <Typography style={{ textDecoration: "underline" }} align="center" variant="subtitle2" gutterBottom>
                From
        </Typography>
        <Typography align="center" variant="subtitle2" gutterBottom>
        {delivery && delivery.from_location.location_name}
        </Typography>
        <Typography align="center" variant="body2" gutterBottom>
        {delivery && delivery.fromAddress}
        </Typography>
        </GridItem>
        <GridItem style={{textAlign:'center',margin:'auto'}} xs={12} sm={12} md={0} lg={4}>
        <ArrowForward/>
        </GridItem>
        <GridItem xs={12} sm={12} md={12} lg={4}>
        <Typography style={{ textDecoration: "underline" }} align="center" variant="subtitle2" gutterBottom>
                To
        </Typography>
        <Typography align="center" variant="subtitle2" gutterBottom>
        {delivery && delivery.to_location.location_name}
        </Typography> 
        <Typography align="center" variant="body2" gutterBottom>
        {delivery && delivery.toAddress}
        </Typography>  
        </GridItem>
      </GridContainer>
      <Divider />
      <GridContainer>
      <GridItem style={{ borderRight: "1px solid #EEEEEE",marginTop:'10px' }} xs={12} sm={12} md={12} lg={12}>
          <div alignItems="center"  w="100%" justifyContent="space-around">
            <div  mt="20px" mb="20px" maxH="100%">
            <Box style={{display:'flex',alignItems:"center",justifyContent:"center"}} >
            <Chip 
                            label={delivery.paid == 0 ? "Cash Payment on Pickup" : 'Already Paid'}
                            color={
                              delivery.paid == 1 
                                ? "primary"
                                : "secondary"
                            }
                          />
                          </Box>
          {delivery.payment && 
            <><Typography style={{ textDecoration: "underline" }} align="center" variant="subtitle2" gutterBottom>
            Proof of Payment
            </Typography>
            <div direction={{ sm: "column", md: "row" }} mt="30px"  justifyContent="space-around" w="100%" align="center" >
                    <div  mr="10px" direction="column" justifyContent="space-around" alignItems="center"   >
                    <Image
      style={{ width: "200px", height: "auto" }}
      zoomed={zoomed}
      src={props.imgurl+delivery.payment.img}
      alt="Proof of Payment"
      onClick={() => setZoomed(true)}
      onRequestClose={() => setZoomed(false)}
    />
                        </div>
                  </div>
            </>
            }
            </div>
          </div>
        </GridItem>
        </GridContainer>
        </GridItem>
        <GridItem style={{ borderRight: "1px solid #EEEEEE" }} xs={12} sm={12} md={4} lg={4}>
          <div alignItems="center"  w="100%" justifyContent="space-around">
            <div  mb="20px" maxH="100%">
            {delivery.grouped_packages && delivery.grouped_packages.length!=0 && 
            <><Typography style={{ textDecoration: "underline" }} align="center" variant="subtitle2" gutterBottom>
            Delivery Packages
            </Typography>
          <div mt="30px" style={{ display: "flex", justifyContent: "space-around" }} >
                    {delivery.grouped_packages && delivery.grouped_packages.map((pack,ind) =><div key={ind} style={{ display: "flex",flexDirection:"column", justifyContent: "space-around",alignItems:"Center" }} >
                        <img src={props.imgurl+pack[1]} className={classes.detailimg}/>
                        <Typography align="center" variant="body2" gutterBottom>
                          {pack[0]} {pack[2]}{pack[0]!=1&&'s'}
                        </Typography>
                      </div>
                      )
                    }
                  </div>
                  </>
            }
            {delivery.grouped_items && delivery.grouped_items.length!=0 && 
            <><Typography style={{ textDecoration: "underline" }} align="center" variant="subtitle2" gutterBottom>
              Delivery Items
            </Typography>
          <div mt="30px" style={{ display: "flex", justifyContent: "space-around" }} >
                    {delivery.grouped_items && delivery.grouped_items.map((pack,ind) =><div key={ind} mr="10px" direction="column" justifyContent="space-around" alignItems="center" >
                        <img src={props.imgurl+pack[1]} className={classes.detailimg}/>
                        <Typography align="center" variant="body2" gutterBottom>
                          Item: {pack[2]}
                        </Typography>
                        <Typography align="center" variant="body2" gutterBottom>
                          Quantity: {pack[0]}
                        </Typography>
                      </div>
                      )
                    }
                  </div>
                  </>
            }

          {(delivery.service && delivery.service.service_name == 'Food Service') && 
            <><Typography style={{ textDecoration: "underline" }} align="center" variant="subtitle2" gutterBottom>
            Mode of Delivery
            </Typography>
            <div direction={{ sm: "column", md: "row" }} mt="30px"  justifyContent="space-around" w="100%" align="center" >
                    <div  mr="10px" direction="column" justifyContent="space-around" alignItems="center"   >
                        <img src={props.imgurl+delivery.vehicle.img} className={classes.detailimg}/>
                        <Typography align="center" variant="body2" gutterBottom>
                          {delivery.vehicle.vehicle_name}
                        </Typography>
                      </div>
                  </div>
            </>
            }
            </div>
          </div>
      <Divider />
      <GridContainer>
        <GridItem xs={12} sm={12} md={12} lg={4}>
        <Typography style={{ textDecoration: "underline" }} align="center" variant="subtitle2" gutterBottom>
            Sender
            </Typography>
          <div direction={{ sm: "column", md: "row" }} mt="30px"  justifyContent="space-around" w="100%" align="center" >
                    <div  mr="10px" direction="column" justifyContent="space-around" alignItems="center"   >
                        <img src={props.imgurl + "avatarplaceholder.png"} className={classes.detailimg}/>
                        <Typography align="center" variant="body2" gutterBottom>
                        Name: 
                        {delivery.creator ? delivery.creator.first_name : delivery.sender_name}{" "}
                       {(delivery.creator && delivery.creator.last_name) && delivery.creator.last_name}
                        </Typography>
                        <Typography align="center" variant="body2" gutterBottom>
                        Telephone: {delivery.creator ? delivery.creator.phone_number : delivery.sender_phone}
                        </Typography>
                      </div>
                  </div>
        </GridItem>
        <GridItem style={{textAlign:'center',margin:'auto'}} xs={12} sm={12} md={12} lg={4}>
        <ArrowForward/>
        </GridItem>
        <GridItem xs={12} sm={12} md={12} lg={4}>
        <Typography style={{ textDecoration: "underline" }} align="center" variant="subtitle2" gutterBottom>
            Reciepient
            </Typography>
          <div direction={{ sm: "column", md: "row" }} mt="30px"  justifyContent="space-around" w="100%" align="center" >
                    <div  mr="10px" direction="column" justifyContent="space-around" alignItems="center"   >
                        <img src={props.imgurl + "avatarplaceholder.png"} className={classes.detailimg}/>
                        <Typography align="center" variant="body2" gutterBottom>
                        Name: {delivery.reciepient ? delivery.reciepient.first_name : delivery.creator ? delivery.creator.first_name : delivery.sender_name}{" "}
                             {delivery.reciepient ? delivery.reciepient.last_name : delivery.creator ? delivery.creator.last_name ? delivery.creator.last_name : ""  : ""}
                      
                        </Typography>
                        <Typography align="center" variant="body2" gutterBottom>
                        Telephone: {delivery.reciepient ? delivery.reciepient.phone : delivery.creator ? delivery.creator.phone_number ? delivery.creator.phone_number : "": delivery.sender_phone }
                        </Typography>
                      </div>
                  </div>  
        </GridItem>
      </GridContainer>
        </GridItem>
        
        <GridItem xs={12} sm={12} md={4} lg={4}>
      <GridContainer>
        <GridItem lg={12}>
          <GridContainer style={{ width: "100%" }}>
          <GridItem xs={12} sm={12} md={12}>
                <Typography align="center" variant="body2" gutterBottom>
                   Overall Status: 
                   <Chip
                        color={delivery.status === "delivered" ? "primary" : "secondary"}
                        label=
                        {delivery.status == 'unassigned' ? 'Unassigned':
                        delivery.status == 'atfacility' ? 'At ' + (delivery.unassigned_facility.facility.facility_name):
                        delivery.status == 'pendingpickup' ? 'Pending Pickup':
                        delivery.status == 'pendingpickupatfacility' ? 'Pending Pickup from ' + (delivery.pendingfacilityfacility ? delivery.pendingfacilityfacility.from_facility.facility_name : delivery.pendingfacilityrecipient.facility.facility_name):
                        delivery.status == 'outfordelivery' ? 'Out For Delivery':
                        delivery.status == 'delivered' ? 'Delivered':
                        delivery.status == 'tofirstfacility' ? 'On-Route to ' + (delivery.onrouteclientfacility.facility.facility_name):
                        delivery.status == 'tofacility' ? 'On-Route to ' + (delivery.onroutefacilityfacility.to_facility.facility_name):
                        "Undefined Status"}
                      />
                </Typography>
              </GridItem>
              
          {(delivery.routes && delivery.routes[0]) &&
              <GridItem xs={12} sm={12} md={12}>
                <Typography style={{ textDecoration: "underline" }} align="center" variant="subtitle2" gutterBottom>
            Routes
            </Typography>
                <Typography style={{ background: "#EEEEEE",padding:'5px' }} align="center" variant="subtitle2" gutterBottom>
                    {delivery.routes[0].delivery.from_location.location_name +
                      " (" +
                      delivery.routes[0].delivery.fromAddress +
                      ")"}
                      <Typography align="center" variant="body2" gutterBottom>
                      {delivery.status == "pendingpickup" ?
          "To be picked up" : "Picked up here on " + moment(delivery.routes[0].picked_up_at).format('MMMM DD, YYYY HH:mm')}
            </Typography>
                </Typography>
              </GridItem>
                }
              {(delivery.routes&&delivery.routes.length!=0) ? delivery.routes.map((prop,ind) => {
                return (
                  <React.Fragment key={prop}>
                    <GridItem xs={12} sm={12} md={12}>
                      <div
                        style={{
                          display: "block",
                          marginLeft: "auto",
                          marginRight: "auto",
                          width: "100%",
                        }}
                      >
                        <GridItem
                          style={{ padding: "0" }}
                          xs={12}
                          sm={12}
                          md={12}
                        >
                          <Divider
                            style={{ margin: "0 auto", height: "20px" }}
                            orientation="vertical"
                          />
                        </GridItem>
                        <GridItem
                          style={{ textAlign: "center" }}
                          xs={12}
                          sm={12}
                          md={12}
                        >
                          <Badge
                            color={
                              prop.status == "complete"
                                ? "primary"
                                : "secondary"
                            }
                            variant="dot"
                          >
                            <Typography>
                              Status: {prop.status == "onroute" && "On Route"}
                              {prop.status == "pendingpickup" &&
                                "To be Picked Up"}
                              {prop.status == "complete" && "Delivered"}
                            </Typography>
                          </Badge>
                          <Chip
                            style={{
                              display: "block",
                              marginLeft: "auto",
                              marginRight: "auto",
                              overflow: "auto",
                            }}
                            variant="outlined"
                            label={prop.driver ?
                              "Driver: " +
                              prop.driver.first_name +
                              " " +
                              prop.driver.last_name :
                              "Collected at facility by recipient"
                            }
                          />
                        </GridItem>
                        <GridItem xs={12} sm={12} md={12}>
                          <Divider
                            style={{ margin: "0 auto", height: "20px" }}
                            orientation="vertical"
                          />
                          <ExpandMoreIcon
                            style={{
                              color: "#b8b8b8",
                              display: "block",
                              marginLeft: "auto",
                              marginRight: "auto",
                            }}
                          />
                        </GridItem>
                      </div>
                    </GridItem>
                    <GridItem xs={12} sm={12} md={12}>
                      <Typography
                      style={{background:"#EEEEEE",padding:'5px'}}
                        align="center"
                        variant="subtitle2"
                        gutterBottom
                      >
                        <Typography  align="center"
                        variant="body2"
                        gutterBottom>
                        {prop.status == "complete" && "Arrived here on " + 
          moment(prop.updated_at).format('MMMM DD, YYYY HH:mm')}
          {prop.status == "onroute" && "On Route to deliver"}
          </Typography>
                        {prop.to_id && prop.delivery.to_location.location_name}{" "}
                        {prop.toAddress && "(" + prop.toAddress + ")"}
                        {prop.facility_id &&
                          prop.from_id &&
                          prop.facility.facility_name +
                            "(" +
                            prop.facility.zone.zone_name +
                            ")"}
                        {prop.to_facility_id &&
                          prop.to_facility.facility_name +
                            "(" +
                            prop.to_facility.zone.zone_name +
                            ")"}
                            {(delivery.routes[ind+1]&&delivery.routes[ind+1].status!='pendingpickup') && <Typography align="center"
                        variant="body2"
                        gutterBottom>
                        Picked up here on {moment(delivery.routes[ind+1].picked_up_at).format('MMMM DD, YYYY HH:mm')}
          </Typography>}
                      </Typography>
                    </GridItem>
                  </React.Fragment>
                );
              }):
              <Typography align="center" variant="subtitle2" gutterBottom>
                Delivery request yet to be processed
                </Typography>}
            </GridContainer>
          
        </GridItem>
        
      </GridContainer>
        </GridItem>
      </GridContainer>
     
              </CardBody>
          </Card>
        </GridItem>
      </GridContainer>}
      </div>
  );
}

Search_Deliveries.propTypes = {
  url: PropTypes.string,
  imgurl: PropTypes.string,
  exit: PropTypes.string,
  location: PropTypes.shape({
    property: PropTypes.shape({
      orderId : PropTypes.string
    }),
  }),
};
