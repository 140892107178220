import React, { useState, useEffect } from "react";
import CircularProgress from "@material-ui/core/CircularProgress";
import TablePagination from '@material-ui/core/TablePagination';
import TableRow from "@material-ui/core/TableRow";
import TableCell from "@material-ui/core/TableCell";
import Backdrop from "@material-ui/core/Backdrop";
import { toast } from "react-toastify";
import Moment from "react-moment";
import "react-toastify/dist/ReactToastify.css";
import { makeStyles } from "@material-ui/core/styles";
import Card from "components/Card/Card.js";
import PropTypes from "prop-types";
import Table from "components/Table/Table.js";
import CardHeader from "components/Card/CardHeader.js";
import CardBody from "components/Card/CardBody.js";

const styles = {
  backdrop: {
    zIndex: 5,
    color: "#fff",
  },
  tableCell: {
    textAlign: "center",
  },
  typo: {
    paddingLeft: "25%",
    marginBottom: "40px",
    position: "relative",
  },
  note: {
    fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
    bottom: "10px",
    color: "#c0c1c2",
    display: "block",
    fontWeight: "400",
    fontSize: "13px",
    lineHeight: "13px",
    left: "0",
    marginLeft: "20px",
    position: "absolute",
    width: "260px",
  },
  cardCategoryWhite: {
    color: "rgba(255,255,255,.62)",
    margin: "0",
    fontSize: "14px",
    marginTop: "0",
    marginBottom: "0",
  },
  cardTitleWhite: {
    color: "#FFFFFF",
    marginTop: "0px",
    minHeight: "auto",
    fontWeight: "300",
    fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif",
    marginBottom: "3px",
    textDecoration: "none",
  },
};

const useStyles = makeStyles(styles);

export default function Logs(props) {
  toast.configure();
  const classes = useStyles();
  const [logs, setLogs] = useState([]);
  const [searchTerm, setSearchTerm] = useState("");
  const [selectedDate, handleDateChange] = useState(null);
  const [loading, setLoading] = useState(true);
  const [page, setPage] = React.useState(0);
  const [totalcount, setTotalcount] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(25);

  const handleChangePage = (event, newPage) => {
    setLoading(true)
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
    setLoading(true)
  };

  const getLogs = async (stat="") => {
    let response = await fetch(props.url + `/get-logs?${searchTerm && 'search='+searchTerm+'&'}${stat && 'date=true&month='+(stat.getMonth()+1)+'&year='+stat.getUTCFullYear()+'&day='+stat.getDate()+'&'}page=${page+1}&perpage=${rowsPerPage}`, {
      credentials: "include",
    });
    let res = await response.json();
    if (res.success) {
      setLogs(res.data.data);
      setPage(res.data.current_page - 1)
      setTotalcount(res.data.total)
    }
    else {
      if( res.message == 'unauthenticated'){
        window.location.replace(props.exit);
        return;
      }
    }
    setLoading(false);
  };

  useEffect(() => {
    getLogs();
  }, [page,rowsPerPage]);


  const searchHandler = () => {
    getLogs(selectedDate)
  };

  const searchdateHandler = (e) => {
    console.log(e)
    handleDateChange(e)
    getLogs(e)
  };
  return (
    <Card>
      {loading && (
        <Backdrop className={classes.backdrop} open>
          <CircularProgress color="inherit" />
        </Backdrop>
      )}
      <CardHeader color="danger">
        <h4 className={classes.cardTitleWhite}>
          System Logs
        </h4>
      </CardHeader>
      <CardBody>
        <Table
          searchKeyword={searchHandler}
          term={searchTerm}
          setTerm={setSearchTerm}
          selectedDate={selectedDate}
          searchdateKeyword={searchdateHandler}
          tableHeaderColor="gray"
          tableHead={[
            "Log ID",
            "User Name",
            "User Email",
            "Activity",
            "Description",
            "Date",
          ]}
          tableData={
            logs.length == 0 ? (
              <TableRow>
                <TableCell
                  style={{ textAlign: "center" }}
                  colSpan={6}
                  className={classes.tableCell}
                >
                  No Logs
                </TableCell>
              </TableRow>
            ) : (
              logs.map((prop) => {
                return (
                  <TableRow style={{
                  borderLeft:
                  prop.category == 'add' ? '10px solid #02960c' : 
                  prop.category == 'delete' ? '10px solid #fa2200' :
                  prop.category == 'update' ? '10px solid #f2a11f' :
                  '10px solid gray'}} 
                  key={prop.id} className={classes.tableBodyRow}>
                    <TableCell className={classes.tableCell} key={prop.id}>
                      {prop.id}
                    </TableCell>
                    <TableCell className={classes.tableCell} key={prop.id}>
                      {prop.user_name}
                    </TableCell>
                    <TableCell className={classes.tableCell} key={prop.id}>
                      {prop.user_email}
                    </TableCell>
                    <TableCell className={classes.tableCell} key={prop.id}>
                      {prop.subject}
                    </TableCell>
                    <TableCell className={classes.tableCell} key={prop.id}>
                      {prop.desc}
                    </TableCell>
                    <TableCell className={classes.tableCell} key={prop.id}>
                      <Moment format="DD MMMM YYYY (HH:mm)">{prop.created_at}</Moment>
                    </TableCell>
                  </TableRow>
                );
              })
            )
          }
          logs={true}
          search={false}
        />
      </CardBody>
      <TablePagination
      component="div"
      count={totalcount}
      page={page}
      onPageChange={handleChangePage}
      rowsPerPage={rowsPerPage}
      onRowsPerPageChange={handleChangeRowsPerPage}
    />
      </Card>
  );
}
Logs.propTypes = {
  url: PropTypes.string,
  exit: PropTypes.string,
};
