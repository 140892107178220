import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import ArrowBackIcon from "@material-ui/icons/ArrowBack";
import Grid from "@material-ui/core/Grid";
import { Icon } from "@progress/kendo-react-common";
import { makeStyles } from "@material-ui/core/styles";
import logo from "assets/img/fatt-logo.jpeg";
import CircularProgress from "@material-ui/core/CircularProgress";
import GridItem from "components/Grid/GridItem.js";
import "@progress/kendo-theme-material/dist/all.css";
import { Button } from '@progress/kendo-react-buttons';
import { PDFExport, savePDF } from '@progress/kendo-react-pdf';
import { useRef } from 'react';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faFile } from "@fortawesome/free-solid-svg-icons";
import GridContainer from "components/Grid/GridContainer.js";
import Card from "components/Card/Card.js";
import CustomButton from "components/CustomButtons/Button.js";
import Moment from "react-moment";
import "../../src/assets/css/Invoice.css";
import Backdrop from "@material-ui/core/Backdrop";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import CardHeader from "components/Card/CardHeader.js";
import CardBody from "components/Card/CardBody.js";

const styles = {
  select: {
    width: "100%",
    maxWidth: 600,
    zIndex: 5,
  },

  flexContainer: {
    display: "flex",
    flexFlow: "row wrap",
    justifyContent: "space-around",
    padding: 0,
    margin: 0,
    listStyle: "none",
  },
  flexItem: {
    position: "relative",
    padding: "5px",
    width: "150px",
    height: "150px",
    marginTop: "5px",
    lineHeight: "150px",
    textAlign: "center",
  },
  vehimg: {
    width: "100%",
    height: "100%",
  },
  vehicleimg: {
    display: "block",
    height: 50,
    width: 50,
    marginLeft: "auto",
    marginRight: "auto",
  },
  overlay: {
    display: "none",
  },
  playIcon: {
    position: "absolute",
    fontSize: "50px",
    color: "green",
    cursor: "pointer",
    left: "0",
    right: "0",
    marginLeft: "auto",
    marginRight: "auto",
    top: "0",
    bottom: "0",
    marginTop: "auto",
    marginBottom: "auto",
    width: "150px",
  },
  avatarimg: {
    display: "block",
    marginLeft: "auto",
    marginRight: "auto",
  },
  selected: {
    position: "absolute",
    opacity: "1",
    color: "green",
    top: "0",
    bottom: "0",
    left: "0",
    right: "0",
  },
  pos: {
    textAlign: "center",
    fontWeight: "bold",
    fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif",
  },
  list: {
    width: "100%",
    position: "relative",
  },
  backdrop: {
    zIndex: 5,
    color: "#fff",
  },
  detailcell: {
    display: "flex",
    alignItems: "center",
    flexWrap: "wrap",
    marginBottom: "15px",
  },
  media: {
    height: 140,
  },
  detailcell2: {
    textAlign: "center",
    marginBottom: "15px",
  },
  tableCell: {
    textAlign: "center",
  },
  cardCategoryWhite: {
    color: "rgba(255,255,255,.62)",
    margin: "0",
    fontSize: "14px",
    marginTop: "0",
    marginBottom: "0",
  },
  cardTitleWhite: {
    color: "#FFFFFF",
    marginTop: "0px",
    minHeight: "auto",
    fontWeight: "300",
    fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif",
    marginBottom: "3px",
    textDecoration: "none",
  },
  container: {
    display: "flex",
    flexWrap: "wrap",
  },
  dividetab: {
    fontSize: "9px",
  },
  formControl: {
    minWidth: "100%",
  },
};

const useStyles = makeStyles(styles);

export default function InvoiceDetails({ match, history, url, exit }) {
  toast.configure();
  const {
    params: { invid, compid },
  } = match;
  const [loading, setLoading] = useState(true);
  const [invoiceData, setInvoiceData] = useState({
    company: "",
    no: 0,
    period: null,
    cost: 0,
    paid: 0,
    balance: 0,
    num_del: 0,
    deliveries: [],
  });
  const pdfExportComponent = useRef(null);
  const contentArea = useRef(null);
  
  const handleExportWithComponent = () => {
   savePDF(contentArea.current, { paperSize: "A4" });
  }

  const getInvoicedata = async () => {
    let response = await fetch(
      url + `/get-company-invoice-data/${invid}/${compid}`,
      { credentials: "include" }
    );
    let data = await response.json();
    if (data.success) {
      setInvoiceData({
        company: data.data.company,
        no: data.data.id,
        period: data.data.created_at,
        cost: data.data.total_amount,
        paid: data.data.amount_paid,
        balance: data.data.total_amount - data.data.amount_paid,
        num_del: data.data.num_del,
        deliveries: data.data.deliveries,
      });
    } else {
      if( data.message == 'unauthenticated'){
        window.location.replace(exit);
        return;
      }
      toast.error(data.message);
    }
    setLoading(false);
  };

  useEffect(() => {
    getInvoicedata();
  }, []);

  const classes = useStyles();
  return (
    <GridContainer>
      {loading && (
        <Backdrop className={classes.backdrop} open>
          <CircularProgress color="inherit" />
        </Backdrop>
      )}
      <GridItem xs={12} sm={12} md={12}>
        <Card>
          <CardHeader color="danger">
            <h4 className={classes.cardTitleWhite}>
              Invoice for {invoiceData.company[0]}
            </h4>
          </CardHeader>
          <CardBody>
            <Grid container>
              <GridItem
                style={{ display: "flex", justifyContent: "space-between" }}
                xs={12}
                sm={12}
                md={12}
              >
                <CustomButton
                  onClick={() => history.goBack()}
                  style={{ float: "left", marginBottom: "10px" }}
                  color="danger"
                >
                  <ArrowBackIcon />
                  Back
                </CustomButton>
                <Button style={{float:'right'}} onClick={handleExportWithComponent} className="btn btn-sm btn-white m-b-10 p-l-5"><FontAwesomeIcon icon={faFile} /> Export as PDF</Button>      
        
                {/* <CustomButton  color="grey">
                  <FontAwesomeIcon icon={faFile} /> Export as PDF
                </CustomButton> */}
              </GridItem>
            </Grid>
            <PDFExport ref={pdfExportComponent} paperSize="A4">
        <div ref={contentArea}>
            <div className={classes.root}>
              <div className="invoice">
              <div style={{display:'flex',flexDirection:'column',justifyContent:'space-between',alignItems:'center'}}>
      <img src={logo} alt="logo" style={{width:'150px',height:'50px'}} me="10px"/>
         <div className="invoice-company print-font text-inverse f-w-600">
            Invoice No: {invoiceData.no}
         </div>
         </div>
                <div className="invoice-header">
                  <div className="invoice-from">
                    <address className="m-t-5 m-b-5">
                      <strong
                        style={{ textDecoration: "underline" }}
                        className="text-inverse print-font"
                      >
                        <Moment format="MMMM YYYY">{invoiceData.period}</Moment>
                      </strong>
                      <br />
                      <strong className="text-inverse print-font">
                        {invoiceData.company[0]}
                      </strong>
                      <br />
                      <strong className="text-inverse print-font">
                        Email: {invoiceData.company[1]}
                      </strong>
                      <br />
                      <strong className="text-inverse print-font">
                        Tel: {invoiceData.company[2]}
                      </strong>
                      <br />
                    </address>
                  </div>
                  <div className="invoice-date print-font">
                    <small>Statistics</small>
                    <div className="date text-inverse m-t-5 print-font">
                      Deliveries: {invoiceData.num_del}
                    </div>
                    <div className="date text-inverse m-t-5 print-font">
                      Total cost: GMD {invoiceData.cost}
                    </div>
                    <div className="date text-inverse m-t-5 print-font">
                      Amound Paid: GMD {invoiceData.paid}
                    </div>
                    <div className="date text-inverse m-t-5 print-font">
                      Balance: GMD {invoiceData.balance}
                    </div>
                  </div>
                </div>
                <div className="invoice-content">
                  <div className="table-responsive">
                    <table
                      style={{ width: "100%", borderCollapse:'collapse' }}
                      className="table table-invoice"
                    >
                      <thead>
                        <tr style={{ borderBottom: "3px solid black" }}>
                          <th className="print-font" style={{ textAlign: "center" }} width="10%">
                            ORDER ID
                          </th>
                          <th className="print-font"  style={{ textAlign: "center" }} width="10%">
                            SERVICE
                          </th>
                          <th className="print-font"  style={{ textAlign: "center" }} width="10%">
                            BOUNDARY
                          </th>
                          <th className="print-font" style={{ textAlign: "center" }} width="10%">
                            PICKUP ADDR.
                          </th>
                          <th className="print-font"  style={{ textAlign: "center" }} width="10%">
                            DROPOFF ADDR.
                          </th>
                          <th className="print-font"  style={{ textAlign: "center" }} width="10%">
                            PRICE
                          </th>
                        </tr>
                      </thead>
                      <tbody>
                        {invoiceData.deliveries.length == 0 ? (
                          <tr>
                            <td colSpan={6} style={{ textAlign: "center" }}>
                              No Delivery Requests Made
                            </td>
                          </tr>
                        ) : (
                          invoiceData.deliveries.map((row) => (
                            <tr style={{ borderBottom: "1px solid #F0F3F4" }} key={row.id}>
                              <td className="print-font"  style={{ textAlign: "center" }}>
                                {row.order_id}
                              </td>
                              <td className="print-font"  style={{ textAlign: "center" }}>
                                {row.service.service_name}
                              </td>
                              <td className="print-font" style={{ textAlign: "center" }}>
                                {row.boundary.boundary_name}
                              </td>
                              <td className="print-font" style={{ textAlign: "center" }}>
                                <span className="text-inverse">
                                  {row.from_location.location_name}
                                </span>
                                <br />
                                <small>{row.fromAddress}</small>
                              </td>
                              <td className="print-font" style={{ textAlign: "center" }}>
                                <span className="text-inverse">
                                  {row.to_location.location_name}
                                </span>
                                <br />
                                <small>{row.toAddress}</small>
                              </td>
                              <td className="print-font"  style={{ textAlign: "center" }}>
                                GMD {row.price}
                              </td>
                            </tr>
                          ))
                        )}
                      </tbody>
                    </table>
                  </div>
                  <div className="invoice-price">
               <div className="invoice-price-left">
               </div>
               <div className="invoice-price-right print-font">
                  <small className="print-font">TOTAL</small> <span className="print-font f-w-600">GMD {invoiceData.cost}</span>
               </div>
            </div>
                </div>
                <div className="invoice-note print-font">
            * All payments are made at Fatt Fatt HQ(Traffic Lights)<br/>
            * If you have any questions concerning this invoice, contact  send us a message
         </div>
         <div className="invoice-footer">
            <div style={{textAlign:'center'}} className="print-font text-center">
               THANK YOU FOR YOUR BUSINESS
            </div>
            <div style={{textAlign:'center'}} className="text-center">
            <span className="m-r-10"><Icon name="globe" />fattfatt.cayor.tech</span>&nbsp;&nbsp;
               <span className="m-r-10"><span className="k-icon k-i-tumblr"></span> 220-3994855</span>&nbsp;&nbsp;
               <span className="m-r-10"><Icon name="email" /> fattfatt@gmail.com</span>
            </div>
         </div>
              </div>
            </div>
            </div>
      </PDFExport>
          </CardBody>
        </Card>
      </GridItem>
    </GridContainer>
  );
}
InvoiceDetails.propTypes = {
  url: PropTypes.string,
  exit: PropTypes.string,
  match: PropTypes.shape({
    params: PropTypes.shape({
      invid: PropTypes.number.isRequired,
      compid: PropTypes.number.isRequired,
    }),
  }),
  history: PropTypes.any,
};



