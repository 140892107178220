import React, { useState, useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";
import PhoneIcon from "@material-ui/icons/Phone";
import MailOutlineIcon from "@material-ui/icons/MailOutline";
import Backdrop from "@material-ui/core/Backdrop";
import Close from "@material-ui/icons/Close";
import PropTypes from "prop-types";
import TablePagination from '@material-ui/core/TablePagination';
import TextField from "@material-ui/core/TextField";
import InputAdornment from "@material-ui/core/InputAdornment";
import PersonOutlineIcon from "@material-ui/icons/PersonOutline";
import { toast } from "react-toastify";
import Rating from '@material-ui/lab/Rating';
import "react-toastify/dist/ReactToastify.css";
import BorderColorIcon from '@material-ui/icons/BorderColor';
import Card from "components/Card/Card.js";
import CardHeader from "components/Card/CardHeader.js";
import CardBody from "components/Card/CardBody.js";
import Table from "components/Table/Table.js";
import Button from "components/CustomButtons/Button.js";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import CircularProgress from "@material-ui/core/CircularProgress";
import TableRow from "@material-ui/core/TableRow";
import TableCell from "@material-ui/core/TableCell";
import GridItem from "components/Grid/GridItem.js";
import GridContainer from "components/Grid/GridContainer.js";
import Slide from "@material-ui/core/Slide";
import Details from "@material-ui/icons/Visibility";

const styles = {
  backdrop: {
    zIndex: 5,
    color: "#fff",
  },
  scrollPaper: {
    alignItems: "baseline", // default center
  },
  tableCell: {
    textAlign: "center",
  },
  cardCategoryWhite: {
    "&,& a,& a:hover,& a:focus": {
      color: "rgba(255,255,255,.62)",
      margin: "0",
      fontSize: "14px",
      marginTop: "0",
      marginBottom: "0",
    },
    "& a,& a:hover,& a:focus": {
      color: "#FFFFFF",
    },
  },
  cardTitleWhite: {
    color: "#FFFFFF",
    marginTop: "0px",
    minHeight: "auto",
    fontWeight: "300",
    fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif",
    marginBottom: "3px",
    textDecoration: "none",
    "& small": {
      color: "#777",
      fontSize: "65%",
      fontWeight: "400",
      lineHeight: "1",
    },
  },
  cardTitleBlack: {
    color: "#000000",
    marginTop: "0px",
    minHeight: "auto",
    fontWeight: "300",
    fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif",
    marginBottom: "3px",
    textDecoration: "none",
    "& small": {
      color: "#777",
      fontSize: "65%",
      fontWeight: "400",
      lineHeight: "1",
    },
  },
};

const useStyles = makeStyles(styles);
const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="down" ref={ref} {...props} />;
});

export default function Ratings(props) {
  toast.configure();
  const [searchTerm, setSearchTerm] = useState("");
  const [addopen, setaddOpen] = React.useState(false);
  const [loading, setLoading] = useState(true);
  const [ratings, setRatings] = useState([]);
  const [rating, setRating] = useState({});
  const [avgrating, setAvgrating] = useState([]);
  const [page, setPage] = React.useState(0);
  const [totalcount, setTotalcount] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);

  const handleChangePage = (event, newPage) => {
    setLoading(true)
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
    setLoading(true)
  };
  

  const getRatings = async (paged=0) => {
    if(paged){
      setPage(paged-1)
    }
    let response = await fetch(props.url + `/get-feedbacks?${searchTerm && 'search='+searchTerm+'&'}page=${paged?paged-1:page+1}&perpage=${rowsPerPage}`, { credentials: "include" });
    let data = await response.json();
    if (data.success) {
      setRatings(data.data.data);
      setPage(data.data.current_page - 1)
      setTotalcount(data.data.total)
      getAverageRatings();
      setLoading(false);
    } else {
      setLoading(false);
      if( data.message == 'unauthenticated'){
        window.location.replace(props.exit);
        return;
      }
      toast.error(data.message);
    }
  };

  const getAverageRatings = async (paged=0) => {
    if(paged){
      setPage(paged-1)
    }
    let response = await fetch(props.url + `/get-average-feedback?${searchTerm && 'search='+searchTerm}`, { credentials: "include" });
    let data = await response.json();
    if (data.success) {
      setAvgrating(data.data);
    } else {
      setLoading(false);
      if( data.message == 'unauthenticated'){
        window.location.replace(props.exit);
        return;
      }
      toast.error(data.message);
    }
  };

  

  useEffect(() => {
    getRatings();
  }, [page,rowsPerPage]);




  const searchHandler = async () => {
    getRatings(1)
  };

  const handleClose = () => {
    setRating({})
    setaddOpen(false);
  };
  const handleClickOpen = async (id) => {
    setLoading(true);
    let response = await fetch(props.url + `/get-feedback/${id}`, {
      credentials: "include",
    });
    let res = await response.json();
    if (res.success) {
      setRating(res.data);
      setLoading(false);
      setaddOpen(true);
    } else {
      setLoading(false);
      if( res.message == 'unauthenticated'){
        window.location.replace(props.exit);
        return;
      }
      toast.error(res.message);
    }
  };
  const classes = useStyles();

  return (
    <Card>
      {loading && (
        <Backdrop className={classes.backdrop} open>
          <CircularProgress color="inherit" />
        </Backdrop>
      )}
      <CardHeader color="danger">
        <h4 className={classes.cardTitleWhite}>Ratings</h4>
      </CardHeader>
      <CardBody>
        <Table
          searchKeyword={searchHandler}
          term={searchTerm}
          setTerm={setSearchTerm}
          tableHeaderColor="gray"
          tableHead={[
            "Full Name",
            "Email",
            "Telephone",
            "Rating",
            "View Details"
          ]}
          tableData={
            ratings.length == 0 ? (
              <TableRow>
                <TableCell
                  style={{ textAlign: "center" }}
                  colSpan={5}
                  className={classes.tableCell}
                >
                  No ratings
                </TableCell>
              </TableRow>
            ) : (
              ratings.map((prop) => {
                return (
                  <TableRow key={prop.id} className={classes.tableBodyRow}>
                    <TableCell className={classes.tableCell} key={prop.id}>
                      {prop.full_name}
                    </TableCell>
                    <TableCell className={classes.tableCell} key={prop.id}>
                      {prop.email}
                    </TableCell>
                    <TableCell className={classes.tableCell} key={prop.id}>
                      {prop.telephone}
                    </TableCell>
                    <TableCell className={classes.tableCell} key={prop.id}>
                    <Rating name="read-only" value={prop.rating} readOnly />
                    </TableCell>
                    <TableCell className={classes.tableCell} key={prop.id}>
                      <Details
                        onClick={() => handleClickOpen(prop.id)}
                        style={{ color: "black", cursor: "pointer" }}
                        key={prop.id}
                      />
                    </TableCell>
                  </TableRow>
                );
              })
            )
          }
          showrating={true}
          avgrating={avgrating}
        />
      </CardBody>
      <TablePagination
      component="div"
      count={totalcount}
      page={page}
      onPageChange={handleChangePage}
      rowsPerPage={rowsPerPage}
      onRowsPerPageChange={handleChangeRowsPerPage}
    />
      <Dialog
        fullWidth={true}
        maxWidth={ "sm"}
        open={addopen}
        TransitionComponent={Transition}
        keepMounted
        onClose={handleClose}
        aria-labelledby="alert-dialog-slide-title"
        aria-describedby="alert-dialog-slide-description"
      >
        <DialogContent>
          <DialogContentText id="alert-dialog-slide-description">
            <GridContainer>
              <GridItem xs={12} sm={12} md={12}>
                <>
                  <CardHeader
                    style={{ textAlign: "center" }}
                    
                  >
                    <h4
                      className={
                          classes.cardTitleBlack
                      }
                    >
                     Rating Details
                    </h4>
                  </CardHeader>
                  <CardBody>
                  <GridContainer>
                          <GridItem xs={12} sm={12} md={6}>
                            <TextField
                              InputLabelProps={{
                                shrink: true,
                              }}
                              value={rating.full_name}
                              label="Full Name"
                              style={{ marginTop: 20 }}
                              fullWidth
                              margin="normal"
                              InputProps={{
                                startAdornment: (
                                  <InputAdornment position="start">
                                    <PersonOutlineIcon />
                                  </InputAdornment>
                                ),
                              }}
                            />
                          </GridItem>
                          <GridItem xs={12} sm={12} md={6}>
                            <TextField
                              InputLabelProps={{
                                shrink: true,
                              }}
                              value={rating.telephone}
                              label="Telephone"
                              style={{ marginTop: 20 }}
                              fullWidth
                              margin="normal"
                              InputProps={{
                                startAdornment: (
                                  <InputAdornment position="start">
                                    <PhoneIcon />
                                  </InputAdornment>
                                ),
                              }}
                            />
                          </GridItem>
                        </GridContainer>

                        <GridContainer>
                          <GridItem xs={12} sm={12} md={12}>
                            <TextField
                              InputLabelProps={{
                                shrink: true,
                              }}
                              value={rating.email}
                              label="Email"
                              style={{ marginTop: 20 }}
                              fullWidth
                              type="email"
                              margin="normal"
                              InputProps={{
                                startAdornment: (
                                  <InputAdornment position="start">
                                    <MailOutlineIcon />
                                  </InputAdornment>
                                ),
                              }}
                            />
                          </GridItem>
                        </GridContainer>
                        <GridContainer>
                          <GridItem xs={12} sm={12} md={12}>
                            <TextField
                              InputLabelProps={{
                                shrink: true,
                              }}
                              value={rating.message} label="Message"
                              style={{ marginTop: 20 }}
                              fullWidth
                              multiline
                              margin="normal"
                              InputProps={{
                                startAdornment: (
                                  <InputAdornment position="start">
                                    <BorderColorIcon />
                                  </InputAdornment>
                                ),
                              }}
                            />
                          </GridItem>
                        </GridContainer>
                        <GridContainer>
                        <GridItem xs={12} sm={12} md={4}>
                        <Rating name="read-only" value={parseInt(rating.rating)} size="large" readOnly /> 
                        </GridItem>
                        </GridContainer>
                  </CardBody>
                </>
              </GridItem>
            </GridContainer>
          </DialogContentText>
        </DialogContent>
          <DialogActions>
            <Button
              style={{ display: "block", marginLeft: "auto", marginRight: "auto" }}
              onClick={handleClose}
              color="danger"
            >
              <Close />
              Cancel
            </Button>
          </DialogActions>
      </Dialog>

    </Card>
  );
}
Ratings.propTypes = {
  url: PropTypes.string,
  exit: PropTypes.string,
};
