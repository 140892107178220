import React, { useEffect, useState } from "react";
import ChartistGraph from "react-chartist";
import View from "@material-ui/icons/Visibility";
import Backdrop from "@material-ui/core/Backdrop";
import CircularProgress from "@material-ui/core/CircularProgress";
import { makeStyles } from "@material-ui/core/styles";
import TableRow from "@material-ui/core/TableRow";
import TableCell from "@material-ui/core/TableCell";
import VisibilityOffIcon from "@material-ui/icons/VisibilityOff";
import { toast } from "react-toastify";
import clsx from "clsx";
import Close from "@material-ui/icons/Close";
import ListItem from "@material-ui/core/ListItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import { Link } from "react-router-dom";
import PropTypes from "prop-types";
import ListItemText from "@material-ui/core/ListItemText";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faBoxOpen } from "@fortawesome/free-solid-svg-icons";
import Typography from "@material-ui/core/Typography";
import Drawer from "@material-ui/core/Drawer";
import Avatar from "@material-ui/core/Avatar";
import List from "@material-ui/core/List";
import Divider from "@material-ui/core/Divider";
import "react-toastify/dist/ReactToastify.css";
import Icon from "@material-ui/core/Icon";
import SupervisorAccountIcon from "@material-ui/icons/SupervisorAccount";
import LocationOn from "@material-ui/icons/LocationOn";
import Update from "@material-ui/icons/Update";
import AccessTime from "@material-ui/icons/AccessTime";
import GridItem from "components/Grid/GridItem.js";
import GridContainer from "components/Grid/GridContainer.js";
import Table from "components/Table/Table.js";
import Card from "components/Card/Card.js";
import CardHeader from "components/Card/CardHeader.js";
import CardIcon from "components/Card/CardIcon.js";
import CardBody from "components/Card/CardBody.js";
import CardFooter from "components/Card/CardFooter.js";

import {
  userTypesChart,
  monthlyDeliveriesChart,
  monthlySubscriptionsChart,
} from "variables/charts.js";

import styles from "assets/jss/material-dashboard-react/views/dashboardStyle.js";

const useStyles = makeStyles(styles);

export default function Dashboard(props) {
  toast.configure();
  const classes = useStyles();
  const [loading, setLoading] = useState(true);
  const [opendrawer, setOpendrawer] = useState(false);
  const [dashData, setDashData] = useState({});
  const [users, setUsers] = useState([]);
  const [deliveries, setDeliveries] = useState([]);
  const [compDeliveries, setcompDeliveries] = useState([]);
  const [packages, setPackages] = useState([]);

  const getDashboard = async () => {
    let response = await fetch(props.url + "/get-dashboard-data", {
      credentials: "include",
    });
    let data = await response.json();
    if (data.success) {
      setDashData(data.data[0]);
      setUsers(data.data[1]);
      setDeliveries(data.data[2]);
      setcompDeliveries(data.data[3]);
      setLoading(false);
    } else {
      setLoading(false);
      if( data.message == 'unauthenticated'){
        window.location.replace(props.exit);
        return;
      }
      toast.error(data.message);
    }
  };

  useEffect(() => {
    getDashboard();
  }, []);

  const toggleDraweropen = async (event, $id) => {
    if (
      event.type === "keydown" &&
      (event.key === "Tab" || event.key === "Shift")
    ) {
      return;
    }
    setLoading(true);
    let response = await fetch(props.url + `/get-delivery-packages/${$id}`, {
      credentials: "include",
    });
    let res = await response.json();
    if (res) {
      setLoading(false);
      if (res.success) {
        setPackages(res.data);
        console.log(packages);
        setOpendrawer(true);
      } else {
        if( res.message == 'unauthenticated'){
          window.location.replace(props.exit);
          return;
        }
        toast.error(res.message);
      }
    }
  };

  const toggleDrawerclose = (event) => {
    if (
      event.type === "keydown" &&
      (event.key === "Tab" || event.key === "Shift")
    ) {
      return;
    }
    setPackages([]);
    setOpendrawer(false);
  };
  return (
    <div>
      <GridContainer>
        {loading && (
          <Backdrop style={{ zIndex: 5, color: "#fff" }} open>
            <CircularProgress color="inherit" />
          </Backdrop>
        )}
        <GridItem xs={12} sm={6} md={6} lg={3}>
          <Card>
            <CardHeader color="warning" stats icon>
              <CardIcon color="warning">
                <Icon>person</Icon>
              </CardIcon>
              <p className={classes.cardCategory}>Users</p>
              <h3 className={classes.cardTitle}>
                <small>
                  {dashData.ind} Individual{dashData.ind != 1 && "s"}
                </small>
              </h3>
              <h3 className={classes.cardTitle}>
                <small>
                  {dashData.corp} Corprate Client{dashData.corp != 1 && "s"}
                </small>
              </h3>
            </CardHeader>
            <CardFooter stats>
              <div className={classes.stats}>
                <Icon>person</Icon>
                {dashData.ind + dashData.corp} Clients
              </div>
            </CardFooter>
          </Card>
        </GridItem>
        <GridItem xs={12} sm={6} md={6} lg={3}>
          <Card>
            <CardHeader color="danger" stats icon>
              <CardIcon color="danger">
                <LocationOn />
              </CardIcon>
              <p className={classes.cardCategory}>Zones</p>
              <h3 className={classes.cardTitle}>
                <small>{dashData.local} Local</small>
              </h3>
              <h3 className={classes.cardTitle}>
                <small>{dashData.intl} International</small>
              </h3>
            </CardHeader>
            <CardFooter stats>
              <div className={classes.stats}>
                <LocationOn />
                {dashData.local + dashData.intl} Zones
              </div>
            </CardFooter>
          </Card>
        </GridItem>
        <GridItem xs={12} sm={6} md={6} lg={3}>
          <Card>
            <CardHeader color="danger" stats icon>
              <CardIcon color="danger">
                <Icon>info_outline</Icon>
              </CardIcon>
              <p className={classes.cardCategory}>Unassigned request</p>
              <h3 className={classes.cardTitle}>
                <small>
                  {dashData.pend_req} Order{dashData.pend_req != 1 && "s"}
                </small>
              </h3>
              <h3 className={classes.cardTitle}>
                <small>
                  {dashData.pend_corp} Unapproved Client
                  {dashData.pend_corp != 1 && "s"}
                </small>
              </h3>
            </CardHeader>
            <CardFooter stats>
              <div className={classes.stats}>
                <Update />
                Just Updated
              </div>
            </CardFooter>
          </Card>
        </GridItem>
        <GridItem xs={12} sm={6} md={6} lg={3}>
          <Card>
            <CardHeader color="warning" stats icon>
              <CardIcon color="warning">
                <SupervisorAccountIcon />
              </CardIcon>
              <p className={classes.cardCategory}>Resources</p>
              <h3 className={classes.cardTitle}>
                <small>
                  {dashData.drivers} Driver{dashData.drivers != 1 && "s"}
                </small>
              </h3>
              <h3 className={classes.cardTitle}>
                <small>
                  {dashData.facilities} Sorting{" "}
                  {dashData.facilities != 1 ? "Facilities" : "Facility"}
                </small>
              </h3>
            </CardHeader>
            <CardFooter stats>
              <div className={classes.stats}>
                <Update />
                Just Updated
              </div>
            </CardFooter>
          </Card>
        </GridItem>
      </GridContainer>
      <GridContainer>
        <GridItem xs={12} sm={12} md={6} lg={4}>
          <Card chart>
            <CardHeader color="danger">
              <ChartistGraph
                className="ct-chart"
                data={{
                  labels: ["Individuals", "Corporations"],
                  series: [[dashData.ind, dashData.corp]],
                }}
                type="Line"
                options={{
                  ...userTypesChart.options,
                  high:
                    dashData.corp > dashData.ind
                      ? dashData.corp + 5
                      : dashData.ind + 5,
                }}
                listener={userTypesChart.animation}
              />
            </CardHeader>
            <CardBody>
              <h4 className={classes.cardTitle}>Clients</h4>
              <p className={classes.cardCategory}>
                <span className={classes.cardCategory}>
                  Individuals v. Corporations
                </span>{" "}
              </p>
            </CardBody>
            <CardFooter chart>
              <div className={classes.stats}>
                <AccessTime /> all time
              </div>
            </CardFooter>
          </Card>
        </GridItem>
        <GridItem xs={12} sm={12} md={6} lg={4}>
          <Card chart>
            <CardHeader color="warning">
              <ChartistGraph
                className="ct-chart"
                data={{
                  labels: [
                    "Jan",
                    "Feb",
                    "Mar",
                    "Apr",
                    "May",
                    "Jun",
                    "Jul",
                    "Aug",
                    "Sep",
                    "Oct",
                    "Nov",
                    "Dec",
                  ],
                  series: [deliveries],
                }}
                type="Bar"
                options={{
                  ...monthlyDeliveriesChart.options,
                  high: Math.max(...deliveries) + 5,
                }}
                responsiveOptions={monthlyDeliveriesChart.responsiveOptions}
                listener={monthlyDeliveriesChart.animation}
              />
            </CardHeader>
            <CardBody>
              <h4 className={classes.cardTitle}>Monthly Deliveries</h4>
              <p className={classes.cardCategory}>{1900 + new Date().getYear()} Performance</p>
            </CardBody>
            <CardFooter chart>
              <div className={classes.stats}>
                <AccessTime /> {1900 + new Date().getYear()}
              </div>
            </CardFooter>
          </Card>
        </GridItem>
        <GridItem xs={12} sm={12} md={12} lg={4}>
          <Card chart>
            <CardHeader color="danger">
              <ChartistGraph
                className="ct-chart"
                data={{
                  labels: [
                    "Jan",
                    "Feb",
                    "Mar",
                    "Apr",
                    "May",
                    "Jun",
                    "Jul",
                    "Aug",
                    "Sep",
                    "Oct",
                    "Nov",
                    "Dec",
                  ],
                  series: [users],
                }}
                type="Line"
                options={{
                  ...monthlySubscriptionsChart.options,
                  high: Math.max(...users) + 5,
                }}
                listener={monthlySubscriptionsChart.animation}
                responsiveOptions={monthlySubscriptionsChart.responsiveOptions}
              />
            </CardHeader>
            <CardBody>
              <h4 className={classes.cardTitle}>Client Registrations</h4>
              <p className={classes.cardCategory}>
              {1900 + new Date().getYear()} - Number of Clients Registered Monthly
              </p>
            </CardBody>
            <CardFooter chart>
              <div className={classes.stats}>
                <AccessTime /> {1900 + new Date().getYear()}
              </div>
            </CardFooter>
          </Card>
        </GridItem>
      </GridContainer>
      <GridContainer>
        <GridItem xs={12} sm={12} md={12}>
          <Card>
            <CardHeader color="success">
              <h4 className={classes.cardTitleWhite}>
                Recently Completed Orders
              </h4>
              <p className={classes.cardCategoryWhite}>
                Most Recently Completed Deliveries
              </p>
            </CardHeader>
            <CardBody>
              <Table
                tableHeaderColor="warning"
                tableHead={[
                  "Order ID",
                  "Service",
                  "Boundary",
                  "Sender",
                  "Reciepient",
                  "From",
                  "To",
                  "Price",
                  "Packages",
                ]}
                tableData={
                  compDeliveries.length == 0 ? (
                    <TableRow>
                      <TableCell
                        style={{ textAlign: "center" }}
                        colSpan={9}
                        className={classes.tableCell}
                      >
                        No Completed Deliveries
                      </TableCell>
                    </TableRow>
                  ) : (
                    compDeliveries.map((prop) => {
                      return (
                        <TableRow
                          key={prop.id}
                          className={classes.tableBodyRow}
                        >
                          <TableCell
                            className={classes.tableCell}
                            key={prop.id}
                          >
                            <Link to={{pathname: "/admin/searchdeliveries", property: { orderId: prop.order_id }}} >
                            {prop.order_id}
                            </Link>
                          </TableCell>
                          <TableCell
                            className={classes.tableCell}
                            key={prop.id}
                          >
                            {prop.service.service_name}
                          </TableCell>
                          <TableCell
                            className={classes.tableCell}
                            key={prop.id}
                          >
                            {prop.boundary.boundary_name}
                          </TableCell>
                          <TableCell
                            className={classes.tableCell}
                            key={prop.id}
                          >
                            {prop.creator ? prop.creator.first_name : prop.sender_name}{" "}
                            {(prop.creator && prop.creator.last_name) && prop.creator.last_name}
                          </TableCell>
                          <TableCell
                            className={classes.tableCell}
                            key={prop.id}
                          >
                             {prop.reciepient ? prop.reciepient.first_name : prop.creator ? prop.creator.first_name : prop.sender_name}{" "}
                             {prop.reciepient ? prop.reciepient.last_name : prop.creator ? prop.creator.last_name ? prop.creator.last_name : ""  : ""}
                            
                          </TableCell>
                          <TableCell
                            className={classes.tableCell}
                            key={prop.id}
                          >
                            {prop.from_location.location_name}{" "}
                            {prop.fromAddress && "(" + prop.fromAddress + ")"}
                          </TableCell>
                          <TableCell
                            className={classes.tableCell}
                            key={prop.id}
                          >
                            {prop.to_location.location_name}{" "}
                            {prop.toAddress && "(" + prop.toAddress + ")"}
                          </TableCell>
                          <TableCell
                            className={classes.tableCell}
                            key={prop.id}
                          >
                            GMD {prop.price}
                          </TableCell>
                          <TableCell
                            className={classes.tableCell}
                            key={prop.id}
                          >
                            {prop.service.service_name == "Food Service" ? (
                              <VisibilityOffIcon
                                style={{ cursor: "not-allowed" }}
                              />
                            ) : (
                              <View
                                key={prop.id}
                                style={{ cursor: "pointer" }}
                                onClick={(e) => toggleDraweropen(e, prop.id)}
                              />
                            )}
                          </TableCell>
                        </TableRow>
                      );
                    })
                  )
                }
                search={false}
              />
            </CardBody>
          </Card>
        </GridItem>
      </GridContainer>
      <Drawer anchor={"right"} open={opendrawer} onClose={toggleDrawerclose}>
        <div className={clsx(classes.list)} role="presentation">
          <List style={{ background: "#EEEEEE" }}>
            <ListItem>
              <ListItemIcon>
                <Close
                  style={{ cursor: "pointer" }}
                  onClick={toggleDrawerclose}
                />
              </ListItemIcon>
              <ListItemIcon>
                <FontAwesomeIcon icon={faBoxOpen} />
              </ListItemIcon>
              <ListItemText
                primary={
                  packages.packages ? packages.packages.length + " Packages" : packages.items ? packages.items.length + " Items" : ""
                }
              />
            </ListItem>
          </List>
          <Divider />
          <Divider style={{ marginBottom: "10px" }} />
          {packages.packages &&
            packages.packages.map((prop) => {
              return (
                <>
                  <ListItem>
                    <GridContainer style={{ width: "100%" }}>
                      <GridItem xs={4} sm={4} md={4}>
                        <Avatar
                          className={classes.avatarimg}
                          variant="square"
                          key={prop.id}
                          alt="Package"
                          src={props.imgurl + prop.packages.img}
                        />
                      </GridItem>
                      <GridItem xs={4} sm={4} md={4}>
                        <Typography
                          align="center"
                          variant="subtitle2"
                          gutterBottom
                        >
                          Package Type
                        </Typography>
                        <Typography
                          align="center"
                          variant="caption"
                          display="block"
                          gutterBottom
                        >
                          {prop.packages.package_name}{" "}
                        </Typography>
                      </GridItem>
                      <GridItem xs={4} sm={4} md={4}>
                        <Typography
                          align="center"
                          variant="subtitle2"
                          gutterBottom
                        >
                          Package ID
                        </Typography>
                        <Typography
                          align="center"
                          variant="caption"
                          display="block"
                          gutterBottom
                        >
                          {prop.package_reference_id
                            ? prop.package_reference_id
                            : "N/A"}
                        </Typography>
                      </GridItem>
                    </GridContainer>
                  </ListItem>
                  <Divider style={{ marginBottom: "10px" }} />
                </>
              );
            })}
            {packages.items &&
            packages.items.map((prop) => {
              return (
                <>
                  <ListItem>
                    <GridContainer style={{ width: "100%" }}>
                      <GridItem xs={4} sm={4} md={4}>
                      <img
                          className={classes.vehicleimg}
                          key={prop.id}
                          alt="Item"
                          src={props.imgurl + prop.items.img}
                        />
                      </GridItem>
                      <GridItem xs={4} sm={4} md={4}>
                        <Typography variant="subtitle2" gutterBottom>
                          Package Type
                        </Typography>
                        <Typography
                          variant="caption"
                          display="block"
                          gutterBottom
                        >
                          {prop.items.item_name}{" "}
                        </Typography>
                      </GridItem>
                      <GridItem xs={4} sm={4} md={4}>
                        <Typography variant="subtitle2" gutterBottom>
                          Package ID
                        </Typography>
                        <Typography
                          variant="caption"
                          display="block"
                          gutterBottom
                        >
                          {prop.package_reference_id
                            ? prop.package_reference_id
                            : "N/A"}
                        </Typography>
                      </GridItem>
                    </GridContainer>
                  </ListItem>

                  <Divider style={{ marginBottom: "10px" }} />
                </>
              );
            })}
        </div>
      </Drawer>
    </div>
  );
}

Dashboard.propTypes = {
  url: PropTypes.string,
  imgurl: PropTypes.string,
  exit: PropTypes.any,
};
