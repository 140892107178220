import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import CardActionArea from "@material-ui/core/CardActionArea";
import CardContent from "@material-ui/core/CardContent";
import Select from "react-select";
import TablePagination from '@material-ui/core/TablePagination';
import CheckCircle from "@material-ui/icons/CheckCircleOutline";
import Image from "react-image-enlarger";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import CardMedia from "@material-ui/core/CardMedia";
import Badge from "@material-ui/core/Badge";
import Chip from "@material-ui/core/Chip";
import Tabs from "@material-ui/core/Tabs";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import Tab from "@material-ui/core/Tab";
import moment from "moment";
import DialogActions from "@material-ui/core/DialogActions";
import Arrowed from "@material-ui/icons/DoubleArrow";
import Drawer from "@material-ui/core/Drawer";
import Close from "@material-ui/icons/Close";
import Typography from "@material-ui/core/Typography";
import Box from "@material-ui/core/Box";
import { Link } from "react-router-dom";
import ArrowBackIcon from "@material-ui/icons/ArrowBack";
import AppBar from "@material-ui/core/AppBar";
import TimelapseIcon from "@material-ui/icons/Timelapse";
import { makeStyles } from "@material-ui/core/styles";
import CircularProgress from "@material-ui/core/CircularProgress";
import GridItem from "components/Grid/GridItem.js";
import Divider from "@material-ui/core/Divider";
import GridContainer from "components/Grid/GridContainer.js";
import Card from "components/Card/Card.js";
import LocationOn from "@material-ui/icons/LocationOn";
import EmailIcon from "@material-ui/icons/Email";
import Backdrop from "@material-ui/core/Backdrop";
import TableRow from "@material-ui/core/TableRow";
import TableCell from "@material-ui/core/TableCell";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import CardHeader from "components/Card/CardHeader.js";
import CardBody from "components/Card/CardBody.js";
import Table from "components/Table/Table.js";
import View from "@material-ui/icons/Visibility";
import Button from "@material-ui/core/Button";
import CallIcon from "@material-ui/icons/Call";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faBoxOpen,
  faDolly,
  // faRoute,
  faPeopleCarry,
  faShippingFast,
} from "@fortawesome/free-solid-svg-icons";
import clsx from "clsx";
import Avatar from "@material-ui/core/Avatar";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText";
import PersonOutlineIcon from "@material-ui/icons/PersonOutline";
import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import Slide from "@material-ui/core/Slide";

const styles = {
  select: {
    width: "100%",
    maxWidth: 600,
    zIndex: 5,
  },

  flexContainer: {
    display: "flex",
    flexFlow: "row wrap",
    justifyContent: "space-around",
    padding: 0,
    margin: 0,
    listStyle: "none",
  },
  flexItem: {
    position: "relative",
    padding: "5px",
    width: "150px",
    height: "150px",
    marginTop: "5px",
    lineHeight: "150px",
    textAlign: "center",
  },
  vehimg: {
    width: "100%",
    height: "100%",
  },
  vehicleimg: {
    display: "block",
    height: 50,
    width: 50,
    marginLeft: "auto",
    marginRight: "auto",
  },
  overlay: {
    display: "none",
  },
  playIcon: {
    position: "absolute",
    fontSize: "50px",
    color: "green",
    cursor: "pointer",
    left: "0",
    right: "0",
    marginLeft: "auto",
    marginRight: "auto",
    top: "0",
    bottom: "0",
    marginTop: "auto",
    marginBottom: "auto",
    width: "150px",
  },
  avatarimg: {
    display: "block",
    marginLeft: "auto",
    marginRight: "auto",
  },
  selected: {
    position: "absolute",
    opacity: "1",
    color: "green",
    top: "0",
    bottom: "0",
    left: "0",
    right: "0",
  },
  pos: {
    textAlign: "center",
    fontWeight: "bold",
    fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif",
  },
  list: {
    width: "100%",
    position: "relative",
  },
  backdrop: {
    zIndex: 5,
    color: "#fff",
  },
  detailcell: {
    display: "flex",
    alignItems: "center",
    flexWrap: "wrap",
    marginBottom: "15px",
  },
  media: {
    height: 140,
  },
  detailcell2: {
    textAlign: "center",
    marginBottom: "15px",
  },
  tableCell: {
    textAlign: "center",
  },
  cardCategoryWhite: {
    color: "rgba(255,255,255,.62)",
    margin: "0",
    fontSize: "14px",
    marginTop: "0",
    marginBottom: "0",
  },
  cardTitleWhite: {
    color: "#FFFFFF",
    marginTop: "0px",
    minHeight: "auto",
    fontWeight: "300",
    fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif",
    marginBottom: "3px",
    textDecoration: "none",
  },
  container: {
    display: "flex",
    flexWrap: "wrap",
  },
  dividetab: {
    fontSize: "9px",
  },
  formControl: {
    minWidth: "100%",
  },
};

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`scrollable-force-tabpanel-${index}`}
      aria-labelledby={`scrollable-force-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box p={3}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};

function a11yProps(index) {
  return {
    id: `scrollable-force-tab-${index}`,
    "aria-controls": `scrollable-force-tabpanel-${index}`,
  };
}

const useStyles = makeStyles(styles);
const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="down" ref={ref} {...props} />;
});

export default function SortingDeliveries({ match, url, imgurl, exit }) {
  toast.configure();
  const dividermatch = useMediaQuery("(max-width:600px)");
  const {
    params: { sid },
  } = match;
  const [open, setOpen] = React.useState(false);
  const [zoomed, setZoomed] = React.useState(false);
  const [openSelect, setopenSelect] = React.useState(false);
  const [openIntlSelect, setopenIntlSelect] = React.useState(false);
  const [orderdata, setOrderData] = useState({
    order_id: "",
    sender_name: "",
    sender_email: "",
    sender_telephone: "",
    sender_location: "",
    recipient_name: "",
    recipient_email: "",
    recipient_telephone: "",
    recipient_location: "",
    pack_type: "",
    boundary: "",
    driver: "",
    vehicle: "",
    cbm: 0,
    weight: 0,
    price: 0,
  });
  const [options, setOptions] = useState([
    {
      label: "Drivers heading to Location",
      options: [],
    },
    {
      label: "Available Drivers",
      options: [],
    },
    {
      label: "Other Drivers",
      options: [],
    },
  ]);
  const [sortingdata, setSortingdata] = useState("");
  const [services, setServices] = useState([]);
  const [searchserviceTerm1, setSearchserviceTerm1] = useState("all");
  const [searchserviceTerm2, setSearchserviceTerm2] = useState("all");
  const [searchserviceTerm3, setSearchserviceTerm3] = useState("all");
  const [searchcategoryTerm1, setSearchcategoryTerm1] = useState("all");
  const [searchcategoryTerm2, setSearchcategoryTerm2] = useState("all");
  const [searchcategoryTerm3, setSearchcategoryTerm3] = useState("all");
  const [addloading, setAddloading] = useState(false);
  const [opendrawer, setOpendrawer] = React.useState(false);
  const [facilities, setFacilities] = useState([]);
  const [delivery, setDelivery] = useState({});
  const [step, setStep] = useState(0);
  const [intlstep, setIntlstep] = useState(0);
  const [routeDrawer, setRouteDrawer] = React.useState(false);
  const [deliveryroutes, setDeliveryroutes] = useState([]);
  const [openvehicleSelect, setopenvehicleSelect] = useState(false);
  const [category, setCategory] = useState("");
  const [selvehid, setSelvehid] = useState(0);
  const [seldeliveryid, setSeldeliveryid] = useState(0);
  const [selvehimg, setSelvehimg] = useState("");
  const [update, setUpdate] = useState(0);
  const [sortingid, setSortingid] = useState(0);
  const [intldestid, setIntldestid] = useState(0);
  const [driverid, setDriverid] = useState(0);
  const [handlerid, setHandlerid] = useState(0);
  const [deliveryid, setDeliveryid] = useState(0);
  const [loading, setLoading] = useState(true);
  const [pendingreq, setPendingreq] = useState([]);
  const [unassigned, setUnassigned] = useState([]);
  const [onroute, setOnroute] = useState([]);
  const [pendingreq2, setPendingreq2] = useState([]);
  const [unassigned2, setUnassigned2] = useState([]);
  const [onroute2, setOnroute2] = useState([]);
  const [vehicles, setVehicles] = useState([]);
  const [searchTerm1, setSearchTerm1] = useState("");
  const [searchTerm2, setSearchTerm2] = useState("");
  const [searchTerm3, setSearchTerm3] = useState("");
  const [searchTerm4, setSearchTerm4] = useState("");
  const [searchTerm5, setSearchTerm5] = useState("");
  const [searchTerm6, setSearchTerm6] = useState("");
  const [value1, setValue1] = React.useState(0);
  const [value2, setValue2] = React.useState(0);
  const [page1, setPage1] = React.useState(0);
  const [totalcount1, setTotalcount1] = React.useState(0);
  const [rowsPerPage1, setRowsPerPage1] = React.useState(10);
  const [page2, setPage2] = React.useState(0);
  const [totalcount2, setTotalcount2] = React.useState(0);
  const [rowsPerPage2, setRowsPerPage2] = React.useState(10);
  const [page3, setPage3] = React.useState(0);
  const [totalcount3, setTotalcount3] = React.useState(0);
  const [rowsPerPage3, setRowsPerPage3] = React.useState(10);
  const [page4, setPage4] = React.useState(0);
  const [totalcount4, setTotalcount4] = React.useState(0);
  const [rowsPerPage4, setRowsPerPage4] = React.useState(10);
  const [page5, setPage5] = React.useState(0);
  const [totalcount5, setTotalcount5] = React.useState(0);
  const [rowsPerPage5, setRowsPerPage5] = React.useState(10);
  const [page6, setPage6] = React.useState(0);
  const [totalcount6, setTotalcount6] = React.useState(0);
  const [rowsPerPage6, setRowsPerPage6] = React.useState(10);

  const handletabChange1 = (event, newValue) => {
    setValue1(newValue);
  };

  const handletabChange2 = (event, newValue) => {
    setValue2(newValue);
  };

  const handleChangePage1 = (event, newPage) => {
    setPage1(newPage);
    setLoading(true)
  };

  const handleChangeRowsPerPage1 = (event) => {
    setRowsPerPage1(parseInt(event.target.value, 10));
    setPage1(0);
    setLoading(true)
  };

  const handleChangePage2 = (event, newPage) => {
    setPage2(newPage);
    setLoading(true)
  };

  const handleChangeRowsPerPage2 = (event) => {
    setRowsPerPage2(parseInt(event.target.value, 10));
    setPage2(0);
    setLoading(true)
  };

  const handleChangePage3 = (event, newPage) => {
    setPage3(newPage);
    setLoading(true)
  };

  const handleChangeRowsPerPage3 = (event) => {
    setRowsPerPage3(parseInt(event.target.value, 10));
    setPage3(0);
    setLoading(true)
  };

  const handleChangePage4 = (event, newPage) => {
    setPage4(newPage);
    setLoading(true)
  };

  const handleChangeRowsPerPage4 = (event) => {
    setRowsPerPage4(parseInt(event.target.value, 10));
    setPage4(0);
    setLoading(true)
  };

  const handleChangePage5 = (event, newPage) => {
    setPage5(newPage);
    setLoading(true)
  };

  const handleChangeRowsPerPage5 = (event) => {
    setRowsPerPage5(parseInt(event.target.value, 10));
    setPage5(0);
    setLoading(true)
  };

  const handleChangePage6 = (event, newPage) => {
    setPage6(newPage);
    setLoading(true)
  };

  const handleChangeRowsPerPage6 = (event) => {
    setRowsPerPage6(parseInt(event.target.value, 10));
    setPage6(0);
    setLoading(true)
  };



  const getUnassignedLocalDeliveries = async (termed="",paged=0) => {
    if(paged){
      setPage1(paged-1)
    }
    let response1 = await fetch(url + `/get-unassigned-sorting-local-deliveries/${sid}?${searchTerm1 && 'search='+searchTerm1+'&'}${termed ? 'category='+termed+'&' : searchserviceTerm1 ? 'category='+searchserviceTerm1+'&' : ''}page=${paged?paged-1:page1+1}&perpage=${rowsPerPage1}`, {
      credentials: "include",
    });
    let res1 = await response1.json();
    if (res1.success) {
      setUnassigned(res1.data.data);
      setPage1(res1.data.current_page - 1)
      setTotalcount1(res1.data.total)
    }
    else {
      if( res1.message == 'unauthenticated'){
        window.location.replace(exit);
        return;
      }
    }
  };

  const getPendingLocalDeliveries = async (termed="",paged=0) => {
    if(paged){
      setPage2(paged-1)
    }
    let response2 = await fetch(url + `/get-pending-sorting-local-deliveries/${sid}?${searchTerm2 && 'search='+searchTerm2+'&'}${termed ? 'category='+termed+'&' : searchserviceTerm2 ? 'category='+searchserviceTerm2+'&' : ''}page=${paged?paged-1:page2+1}&perpage=${rowsPerPage2}`, {
      credentials: "include",
    });
    let res2 = await response2.json();
    if (res2.success) {
      setPendingreq(res2.data.data);
      setPage2(res2.data.current_page - 1)
      setTotalcount2(res2.data.total)
    }
    else {
      if( res2.message == 'unauthenticated'){
        window.location.replace(exit);
        return;
      }
    }
  };

  const getOnrouteLocalDeliveries = async (termed="",paged=0) => {
    if(paged){
      setPage3(paged-1)
    }
    let response3 = await fetch(url + `/get-onroute-sorting-local-deliveries/${sid}?${searchTerm3 && 'search='+searchTerm3+'&'}${termed ? 'category='+termed+'&' : searchserviceTerm3 ? 'category='+searchserviceTerm3+'&' : ''}page=${paged?paged-1:page3+1}&perpage=${rowsPerPage3}`, {
      credentials: "include",
    });
    let res3 = await response3.json();
    if (res3.success) {
      setOnroute(res3.data.data);
      setPage3(res3.data.current_page - 1)
      setTotalcount3(res3.data.total)
    }
    else {
      if( res3.message == 'unauthenticated'){
        window.location.replace(exit);
        return;
      }
    }
  };

  const getUnassignedIntlDeliveries = async (termed="",paged=0) => {
    if(paged){
      setPage4(paged-1)
    }
    let response1 = await fetch(url + `/get-unassigned-sorting-international-deliveries/${sid}?${searchTerm4 && 'search='+searchTerm4+'&'}${termed ? 'category='+termed+'&' : searchcategoryTerm1 ? 'category='+searchcategoryTerm1+'&' : ''}page=${paged?paged-1:page4+1}&perpage=${rowsPerPage4}`, {
      credentials: "include",
    });
    let res1 = await response1.json();
    if (res1.success) {
      setUnassigned2(res1.data.data);
      setPage4(res1.data.current_page - 1)
      setTotalcount4(res1.data.total)
    }
    else {
      if( res1.message == 'unauthenticated'){
        window.location.replace(exit);
        return;
      }
    }
  };

  const getPendingIntlDeliveries = async (termed="",paged=0) => {
    if(paged){
      setPage5(paged-1)
    }
    let response2 = await fetch(url + `/get-pending-sorting-international-deliveries/${sid}?${searchTerm5 && 'search='+searchTerm5+'&'}${termed ? 'category='+termed+'&' : searchcategoryTerm2 ? 'category='+searchcategoryTerm2+'&' : ''}page=${paged?paged-1:page5+1}&perpage=${rowsPerPage5}`, {
      credentials: "include",
    });
    let res2 = await response2.json();
    if (res2.success) {
      setPendingreq2(res2.data.data);
      setPage5(res2.data.current_page - 1)
      setTotalcount5(res2.data.total)
    }
    else {
      if( res2.message == 'unauthenticated'){
        window.location.replace(exit);
        return;
      }
    }
  };

  const getOnrouteIntlDeliveries = async (termed="",paged=0) => {
    if(paged){
      setPage6(paged-1)
    }
    let response3 = await fetch(url + `/get-onroute-sorting-international-deliveries/${sid}?${searchTerm6 && 'search='+searchTerm6+'&'}${termed ? 'category='+termed+'&' : searchcategoryTerm3 ? 'category='+searchcategoryTerm3+'&' : ''}page=${paged?paged-1:page6+1}&perpage=${rowsPerPage6}`, {
      credentials: "include",
    });
    let res3 = await response3.json();
    if (res3.success) {
      setOnroute2(res3.data.data);
      setPage6(res3.data.current_page - 1)
      setTotalcount6(res3.data.total)
    }
    else {
      if( res3.message == 'unauthenticated'){
        window.location.replace(exit);
        return;
      }
    }
  };


  const getSortingdata = async () => {
    let response = await fetch(url + `/get-sorting-facility/${sid}`, {
      credentials: "include",
    });
    let data = await response.json();
    if (data.success) {
      setSortingdata(data.data);
    } else {
      if( data.message == 'unauthenticated'){
        window.location.replace(exit);
        return;
      }
      toast.error(data.message);
    }
  };

  const getSortingfacilities = async () => {
    let response = await fetch(
      url + `/get-sorting-facilities-exclude-current/${sid}`,
      { credentials: "include" }
    );
    let data = await response.json();
    if (data.success) {
      const options = [];
      data.data.forEach((fac) =>
        options.push({
          label: fac.facility_name + " (" + fac.zone.zone_name + ")",
          value: fac.id,
        })
      );
      setFacilities(options);
    } else {
      if( data.message == 'unauthenticated'){
        window.location.replace(exit);
        return;
      }
      toast.error(data.message);
    }
  };

  const getServices = async () => {
    let response = await fetch(url + `/get-services`, {
      credentials: "include",
    });
    let data = await response.json();
    if (data.success) {
      const def_services = [];
      data.data.forEach((serv) => def_services.push(serv.service_name));
      setServices(def_services);
    } else {
      if( data.message == 'unauthenticated'){
        window.location.replace(exit);
        return;
      }
      toast.error(data.message);
    }
  };

  const getVehicles = async () => {
    let response = await fetch(url + `/get-vehicles`, {
      credentials: "include",
    });
    let data = await response.json();
    if (data) {
      setLoading(false);
      if (data.success) {
        setVehicles(data.data);
      } else {
        if( data.message == 'unauthenticated'){
          window.location.replace(exit);
          return;
        }
        toast.error(data.message);
      }
    }
  };

  useEffect(() => {
    getSortingdata();
    getSortingfacilities();
    getServices();
    getVehicles();
    getUnassignedLocalDeliveries()
    getPendingLocalDeliveries()
    getOnrouteLocalDeliveries()
    getUnassignedIntlDeliveries()
    getPendingIntlDeliveries()
    getOnrouteIntlDeliveries()
  }, [page1,rowsPerPage1,page2,rowsPerPage2,page3,rowsPerPage3,page4,rowsPerPage4,page5,rowsPerPage5,page6,rowsPerPage6]);

  const toggleDraweropen = async (event, $id) => {
    if (
      event.type === "keydown" &&
      (event.key === "Tab" || event.key === "Shift")
    ) {
      return;
    }
    setLoading(true);
    let response = await fetch(url + `/get-sorting-delivery-details/${$id}`, {
      credentials: "include",
    });
    let res = await response.json();
    if (res) {
      setLoading(false);
      if (res.success) {
        setDelivery(res.data);
        setOpendrawer(true);
      } else {
        if( res.message == 'unauthenticated'){
          window.location.replace(exit);
          return;
        }
        toast.error(res.message);
      }
    }
  };

  const toggleDrawerclose = (event) => {
    if (
      event.type === "keydown" &&
      (event.key === "Tab" || event.key === "Shift")
    ) {
      return;
    }
    setDelivery({});
    setOpendrawer(false);
  };

  const processdelivery = (category) => {
    if (update) {
      processpendingdelivery(category);
    } else {
      processnewdelivery(category);
    }
  };

  const processnewdelivery = async (category) => {
    setAddloading(true);
    let response = await fetch(url + "/process-unassigned-sorting-delivery", {
      method: "POST",
      headers: {
        "Content-type": "application/json",
      },
      body: JSON.stringify({
        deliveryid: deliveryid,
        driverid: driverid,
        sortingid: sortingid,
        fromsortingid: sid,
        category: category,
      }),
      credentials: "include",
    });
    let res = await response.json();
    if (res) {
      if (res.success) {
        setUnassigned(
          unassigned.filter((current) => deliveryid !== current.delivery.id)
        );
        setPendingreq([...pendingreq, res.data]);
        handleSelectClose();
        toast.success("Delivery Assigned to Driver");
      } else {
        if( res.message == 'unauthenticated'){
          window.location.replace(exit);
          return;
        }
        toast.error(res.message);
        handleSelectClose();
      }
    }
  };

  const processpendingdelivery = async (category) => {
    setAddloading(true);
    let response = await fetch(url + "/process-pending-sorting-delivery", {
      method: "POST",
      headers: {
        "Content-type": "application/json",
      },
      body: JSON.stringify({
        deliveryid: deliveryid,
        driverid: driverid,
        sortingid: sortingid,
        fromsortingid: sid,
        category: category,
      }),
      credentials: "include",
    });
    let res = await response.json();
    if (res) {
      if (res.success) {
        setPendingreq(
          pendingreq.map((prop) =>
            prop.delivery.id === deliveryid ? { ...res.data } : prop
          )
        );
        handleSelectClose();
        toast.success("Delivery Assigned to Driver");
      } else {
        if( res.message == 'unauthenticated'){
          window.location.replace(exit);
          return;
        }
        toast.error(res.message);
        handleSelectClose();
      }
    }
  };

  const searchHandler1 =  () => {
    getUnassignedLocalDeliveries(searchserviceTerm1,1);
  };

  const searchHandler2 = async () => {
    getPendingLocalDeliveries(searchserviceTerm2,1)
  };

  const searchHandler3 = async () => {
    getOnrouteLocalDeliveries(searchserviceTerm3,1)
  };

  const searchHandler4 =  () => {
    getUnassignedIntlDeliveries(searchcategoryTerm1,1);
  };

  const searchHandler5 = async () => {
    getPendingIntlDeliveries(searchcategoryTerm2,1)
  };

  const searchHandler6 = async () => {
    getOnrouteIntlDeliveries(searchcategoryTerm3,1)
  };

  const searchserviceHandler1 =  (termed) => {
    setSearchserviceTerm1(termed);
    getUnassignedLocalDeliveries(termed,1);
  };

  const searchserviceHandler2 = (termed) => {
    setSearchserviceTerm2(termed);
    getPendingLocalDeliveries(termed,1)
  };

  const searchserviceHandler3 = (termed) => {
    setSearchserviceTerm3(termed);
    getOnrouteLocalDeliveries(termed,1)
  };

  const searchcategoryHandler1 =  (termed) => {
    setSearchcategoryTerm1(termed);
    getUnassignedIntlDeliveries(termed,1);
  };

  const searchcategoryHandler2 = (termed) => {
    setSearchcategoryTerm2(termed);
    getPendingIntlDeliveries(termed,1)
  };

  const searchcategoryHandler3 = (termed) => {
    setSearchcategoryTerm3(termed);
    getOnrouteIntlDeliveries(termed,1)
  };
  

  const closeRouteDrawer = (event) => {
    if (
      event.type === "keydown" &&
      (event.key === "Tab" || event.key === "Shift")
    ) {
      return;
    }
    setDeliveryroutes([]);
    setRouteDrawer(false);
  };

  const handleVehicleSelectOpen = async (did, id, category) => {
    setCategory(category);
    setLoading(true);
    setSelvehid(id);
    setSeldeliveryid(did);
    let response = await fetch(url + `/get-delivery-vehicle/${did}`, {
      credentials: "include",
    });
    let res = await response.json();
    if (res) {
      setLoading(false);
      if (res.success) {
        setSelvehimg(res.data && res.data.img);
        setopenvehicleSelect(true);
      } else {
        if( res.message == 'unauthenticated'){
          window.location.replace(exit);
          return;
        }
        toast.error(res.message);
      }
    }
  };

  const handleVehicleSelectUpdateClose = async (event) => {
    event.preventDefault();
    setAddloading(true);
    let response = await fetch(url + `/update-delivery-vehicle`, {
      method: "PUT",
      headers: {
        "Content-type": "application/json",
      },
      body: JSON.stringify({
        deliveryid: seldeliveryid,
        vehicleid: selvehid,
        sorting_type: category,
        sorting_id: sid,
      }),
      credentials: "include",
    });
    let res = await response.json();
    if (res) {
      if (res.success) {
        if (category === "unassigned") {
          setUnassigned(
            unassigned.map((prop) =>
              prop.delivery.id === seldeliveryid ? { ...res.data } : prop
            )
          );
        } else if (category === "pending") {
          setPendingreq(
            pendingreq.map((prop) =>
              prop.delivery.id === seldeliveryid ? { ...res.data } : prop
            )
          );
        }
        handleVehicleSelectClose();
        toast.success("Vehicle Changed");
      } else {
        if( res.message == 'unauthenticated'){
          window.location.replace(exit);
          return;
        }
        handleVehicleSelectClose();
        toast.error(res.message);
      }
    }
  };

  const handleVehicleSelectClose = () => {
    setCategory("");
    setAddloading(false);
    setSelvehimg("");
    setSelvehid(0);
    setSeldeliveryid(0);
    setopenvehicleSelect(false);
  };

  const handleSelectOpen = async (id, did, cat) => {
    if (cat == "update") {
      setUpdate(true);
    }
    setDeliveryid(did);
    let response3 = await fetch(url + `/get-delivery-driver/${id}/${did}`, {
      credentials: "include",
    });
    let res3 = await response3.json();
    if (res3) {
      if (res3.success) {
        setOptions([
          {
            label: "Drivers heading to Location",
            options: res3.data[0],
          },
          {
            label: "Available Drivers",
            options: res3.data[1],
          },
          {
            label: "Other Drivers",
            options: res3.data[2],
          },
        ]);
        setopenSelect(true);
      } else {
        if( res3.message == 'unauthenticated'){
          window.location.replace(exit);
          return;
        }
        toast.error(res3.message);
      }
    }
  };

  const handleIntlSelectOpen = async (id, did, cat) => {
    setLoading(true)
    if (cat == "update") {
      setUpdate(true);
    }
    setDeliveryid(did);
    let response3 = await fetch(url + `/get-sorting-delivery-handler/${id}/${did}/${sid}`, {
      credentials: "include",
    });
    let res3 = await response3.json();
    if (res3) {
      setLoading(false)
      if (res3.success) {
        if(res3.data.length == 2){
          setOptions([
            {
              label: "Agents assigned to Delivery Location",
              options: res3.data[0],
            },
          ]);
          let opts = [];
          res3.data[1].forEach((fac) =>
          opts.push({
            label: fac.facility_name + " (" + fac.zone.zone_name + ")",
            value: fac.id,
          })
        );
        setSortingid(opts[0])
        setFacilities(opts);
        }
        else if(res3.data.length == 4){
        setOptions([
          {
            label: "Drivers heading to Location",
            options: res3.data[0],
          },
          {
            label: "Available Drivers",
            options: res3.data[1],
          },
          {
            label: "Other Drivers",
            options: res3.data[2],
          },
        ]);

        const options = [];
        res3.data[3].forEach((fac) =>
          options.push({
            label: fac.facility_name + " (" + fac.zone.zone_name + ")",
            value: fac.id,
          })
        );
        setFacilities(options);
      }
        setopenIntlSelect(true);
      } else {
        if( res3.message == 'unauthenticated'){
          window.location.replace(exit);
          return;
        }
        toast.error(res3.message);
      }
    }
  };

  const handleIntlSelectClose = () => {
    setOptions([
      {
        label: "Drivers heading to Location",
        options: [],
      },
      {
        label: "Available Drivers",
        options: [],
      },
      {
        label: "Other Drivers",
        options: [],
      },
    ]);
    setSortingid(0);
    setDriverid(0);
    setUpdate(false);
    setAddloading(false);
    setopenIntlSelect(false);
  };

  const backtofirst = () => {
    setSortingid(0);
    setDriverid(0);
    setStep(0);
  };

  const backtosecond = () => {
    setDriverid(0);
    setStep(1);
  };

  const backtointlfirst = () => {
    setIntldestid(0);
    setHandlerid(0);
    setIntlstep(0);
  };

  const backtointlsecond = () => {
    setHandlerid(0);
    setIntlstep(1);
  };

  const handleSelectClose = () => {
    setOptions([
      {
        label: "Drivers heading to Location",
        options: [],
      },
      {
        label: "Available Drivers",
        options: [],
      },
      {
        label: "Other Drivers",
        options: [],
      },
    ]);
    setSortingid(0);
    setDriverid(0);
    setStep(0);
    setUpdate(false);
    setAddloading(false);
    setopenSelect(false);
  };

  const handleClose = () => {
    setOrderData({
      order_id: "",
      sender_name: "",
      sender_email: "",
      sender_telephone: "",
      sender_location: "",
      recipient_name: "",
      recipient_email: "",
      recipient_telephone: "",
      recipient_location: "",
      pack_type: "",
      boundary: "",
      driver: "",
      vehicle: "",
      cbm: 0,
      weight: 0,
      price: 0,
    });
    setOpen(false);
  };

  const classes = useStyles();
  return (
    <GridContainer>
      {loading && (
        <Backdrop className={classes.backdrop} open>
          <CircularProgress color="inherit" />
        </Backdrop>
      )}
      <GridItem xs={12} sm={12} md={12}>
      <Link to="/admin/sorting-facilities">
                  <Button
                    style={{ float: "left", marginBottom: "10px" }}
                    color="danger"
                  >
                    <ArrowBackIcon />
                    Back
                  </Button>{" "}
                </Link>
      </GridItem>
      <GridItem xs={12} sm={12} md={12}>
        <Card>
          <CardHeader color="danger">
            <h4 className={classes.cardTitleWhite}>
              Local Deliveries {sortingdata && "at " +sortingdata.facility_name}
            </h4>
          </CardHeader>
          <CardBody>
            <div className={classes.root}>
              <AppBar position="static" color="default">
                <Tabs
                  value={value1}
                  onChange={handletabChange1}
                  variant="fullWidth"
                  scrollButtons="on"
                  indicatorColor="secondary"
                  textColor="secondary"
                  aria-label="scrollable force tabs example"
                >
                  <Tab
                    className={dividermatch && classes.dividetab}
                    label="Unassigned Request"
                    icon={<TimelapseIcon />}
                    {...a11yProps(0)}
                  />
                  <Tab
                    className={dividermatch && classes.dividetab}
                    label="Pending Pick-Up From Sorting Facility"
                    icon={<FontAwesomeIcon icon={faDolly} />}
                    {...a11yProps(1)}
                  />
                  <Tab
                    className={dividermatch && classes.dividetab}
                    label="On-Route To Next Sorting Facility"
                    icon={<FontAwesomeIcon icon={faShippingFast} />}
                    {...a11yProps(2)}
                  />
                </Tabs>
              </AppBar>
              <TabPanel value={value1} index={0}>
                <Table
                  searchKeyword={searchHandler1}
                  term={searchTerm1}
                  setTerm={setSearchTerm1}
                  searchserviceKeyword={searchserviceHandler1}
                  serviceterm={searchserviceTerm1}
                  allservices={services}
                  tableHeaderColor="gray"
                  tableHead={[
                    "Order ID",
                    "Service",
                    "Sender",
                    "Recipient",
                    "Initial Pickup Location",
                    "Final Destination",
                    "Vehicle",
                    "Price",
                    "Assign to Driver",
                    "Details",
                  ]}
                  tableData={
                    unassigned.length == 0 ? (
                      <TableRow>
                        <TableCell
                          style={{ textAlign: "center" }}
                          colSpan={10}
                          className={classes.tableCell}
                        >
                          No Unassigned Requests
                        </TableCell>
                      </TableRow>
                    ) : (
                      unassigned.map((prop) => {
                        return (
                          <TableRow
                            key={prop.id}
                            className={classes.tableBodyRow}
                          >
                            <TableCell
                              className={classes.tableCell}
                              key={prop.id}
                            >
                              <Link to={{pathname: "/admin/searchdeliveries", property: { orderId: prop.delivery.order_id }}} >
                              {prop.delivery.order_id}
                              </Link>
                            </TableCell>
                            <TableCell
                              className={classes.tableCell}
                              key={prop.id}
                            >
                              {prop.delivery.service.service_name}
                            </TableCell>
                            <TableCell
                              className={classes.tableCell}
                              key={prop.id}
                            >
                              {prop.delivery.creator ? prop.delivery.creator.first_name : prop.delivery.sender_name}{" "}
                       {(prop.delivery.creator && prop.delivery.creator.last_name) && prop.delivery.creator.last_name}
                            </TableCell>
                            <TableCell
                              className={classes.tableCell}
                              key={prop.id}
                            >
                              {prop.delivery.reciepient ? prop.delivery.reciepient.first_name : prop.delivery.creator ? prop.delivery.creator.first_name : prop.delivery.sender_name}{" "}
                             {prop.delivery.reciepient ? prop.delivery.reciepient.last_name : prop.delivery.creator ? prop.delivery.creator.last_name ? prop.delivery.creator.last_name : ""  : ""}
                              
                            </TableCell>
                            <TableCell
                              className={classes.tableCell}
                              key={prop.id}
                            >
                              {prop.delivery.from_location.location_name}
                            </TableCell>
                            <TableCell
                              className={classes.tableCell}
                              key={prop.id}
                            >
                              {prop.delivery.to_location.location_name}
                            </TableCell>
                            <TableCell
                              className={classes.tableCell}
                              key={prop.id}
                            >
                              <img
                                className={classes.vehicleimg}
                                style={{ cursor: "pointer" }}
                                onClick={() =>
                                  handleVehicleSelectOpen(
                                    prop.delivery.id,
                                    prop.delivery.vehicle.id,
                                    "unassigned"
                                  )
                                }
                                key={prop.id}
                                alt="X"
                                src={imgurl + prop.delivery.vehicle.img}
                              />
                            </TableCell>
                            <TableCell
                              className={classes.tableCell}
                              key={prop.id}
                            >
                              GMD {prop.delivery.price}
                            </TableCell>
                            <TableCell
                              className={classes.tableCell}
                              key={prop.id}
                            >
                              <FontAwesomeIcon
                                style={{ cursor: "pointer", fontSize: "25px" }}
                                onClick={() =>
                                  handleSelectOpen(
                                    prop.delivery.from_location.id,
                                    prop.delivery.id,
                                    "add"
                                  )
                                }
                                icon={faPeopleCarry}
                              />
                            </TableCell>
                            {/* <TableCell
                              className={classes.tableCell}
                              key={prop.id}
                            >
                              <FontAwesomeIcon
                                style={{ cursor: "pointer" }}
                                onClick={(e) =>
                                  openRouteDrawer(e, prop.delivery.id)
                                }
                                icon={faRoute}
                              />
                            </TableCell> */}
                            <TableCell
                              className={classes.tableCell}
                              key={prop.id}
                            >
                              <View
                                key={prop.id}
                                style={{ cursor: "pointer" }}
                                onClick={(e) =>
                                  toggleDraweropen(e, prop.delivery.id)
                                }
                              />
                            </TableCell>
                          </TableRow>
                        );
                      })
                    )
                  }
                  service={true}
                />
                <TablePagination
      component="div"
      count={totalcount1}
      page={page1}
      onPageChange={handleChangePage1}
      rowsPerPage={rowsPerPage1}
      onRowsPerPageChange={handleChangeRowsPerPage1}
    />
              </TabPanel>
              <TabPanel value={value1} index={1}>
                <Table
                  searchKeyword={searchHandler2}
                  term={searchTerm2}
                  setTerm={setSearchTerm2}
                  searchserviceKeyword={searchserviceHandler2}
                  serviceterm={searchserviceTerm2}
                  allservices={services}
                  tableHeaderColor="gray"
                  tableHead={[
                    "Order ID",
                    "Service",
                    "Sender",
                    "Initial Pickup Location",
                    "Next Destination",
                    "Final Destination",
                    "Vehicle",
                    "Price",
                    "Assigned Driver",
                    "Re-Assign Driver",
                    "Details",
                  ]}
                  tableData={
                    pendingreq.length == 0 ? (
                      <TableRow>
                        <TableCell
                          style={{ textAlign: "center" }}
                          colSpan={11}
                          className={classes.tableCell}
                        >
                          No Pending Requests
                        </TableCell>
                      </TableRow>
                    ) : (
                      pendingreq.map((prop) => {
                        return (
                          <TableRow
                            key={prop.id}
                            className={classes.tableBodyRow}
                          >
                            <TableCell
                              className={classes.tableCell}
                              key={prop.id}
                            >
                              <Link to={{pathname: "/admin/searchdeliveries", property: { orderId: prop.delivery.order_id }}} >
                              {prop.delivery.order_id}
                              </Link>
                            </TableCell>
                            <TableCell
                              className={classes.tableCell}
                              key={prop.id}
                            >
                              {prop.delivery.service.service_name}
                            </TableCell>
                            <TableCell
                              className={classes.tableCell}
                              key={prop.id}
                            >
                              {prop.delivery.creator ? prop.delivery.creator.first_name : prop.delivery.sender_name}{" "}
                       {(prop.delivery.creator && prop.delivery.creator.last_name) && prop.delivery.creator.last_name}
                            </TableCell>
                            <TableCell
                              className={classes.tableCell}
                              key={prop.id}
                            >
                              {prop.delivery.from_location.location_name}
                            </TableCell>
                            <TableCell
                              className={classes.tableCell}
                              key={prop.id}
                            >
                              {prop.to_facility_id
                                ? prop.to_facility.facility_name
                                : prop.delivery.to_location.location_name}
                            </TableCell>
                            <TableCell
                              className={classes.tableCell}
                              key={prop.id}
                            >
                              {prop.delivery.to_location.location_name}
                            </TableCell>
                            <TableCell
                              className={classes.tableCell}
                              key={prop.id}
                            >
                              <img
                                className={classes.vehicleimg}
                                style={{ cursor: "pointer" }}
                                onClick={() =>
                                  handleVehicleSelectOpen(
                                    prop.delivery.id,
                                    prop.delivery.vehicle.id,
                                    "pending"
                                  )
                                }
                                key={prop.id}
                                alt="X"
                                src={imgurl + prop.delivery.vehicle.img}
                              />
                            </TableCell>
                            <TableCell
                              className={classes.tableCell}
                              key={prop.id}
                            >
                              GMD {prop.delivery.price}
                            </TableCell>
                            <TableCell
                              className={classes.tableCell}
                              key={prop.id}
                            >
                              {prop.driver.first_name +
                                " " +
                                prop.driver.last_name}
                            </TableCell>
                            <TableCell
                              className={classes.tableCell}
                              key={prop.id}
                            >
                              <FontAwesomeIcon
                                style={{ cursor: "pointer", fontSize: "25px" }}
                                onClick={() =>
                                  handleSelectOpen(
                                    prop.delivery.from_location.id,
                                    prop.delivery.id,
                                    "update"
                                  )
                                }
                                icon={faPeopleCarry}
                              />
                            </TableCell>
                            <TableCell
                              className={classes.tableCell}
                              key={prop.id}
                            >
                              <View
                                key={prop.id}
                                style={{ cursor: "pointer" }}
                                onClick={(e) =>
                                  toggleDraweropen(e, prop.delivery.id)
                                }
                              />
                            </TableCell>
                          </TableRow>
                        );
                      })
                    )
                  }
                  service={true}
                />
                <TablePagination
      component="div"
      count={totalcount2}
      page={page2}
      onPageChange={handleChangePage2}
      rowsPerPage={rowsPerPage2}
      onRowsPerPageChange={handleChangeRowsPerPage2}
    />
              </TabPanel>

              <TabPanel value={value1} index={2}>
                <Table
                  searchKeyword={searchHandler3}
                  term={searchTerm3}
                  setTerm={setSearchTerm3}
                  searchserviceKeyword={searchserviceHandler3}
                  serviceterm={searchserviceTerm3}
                  allservices={services}
                  tableHeaderColor="gray"
                  tableHead={[
                    "Order ID",
                    "Service",
                    "Sender",
                    "Initial Pickup Location",
                    "Next Sorting Facility",
                    "Final Destination",
                    "Price",
                    "Assigned Driver",
                    "Details",
                  ]}
                  tableData={
                    onroute.length == 0 ? (
                      <TableRow>
                        <TableCell
                          style={{ textAlign: "center" }}
                          colSpan={9}
                          className={classes.tableCell}
                        >
                          No Deliveries On-Route
                        </TableCell>
                      </TableRow>
                    ) : (
                      onroute.map((prop) => {
                        return (
                          <TableRow
                            key={prop.id}
                            className={classes.tableBodyRow}
                          >
                            <TableCell
                              className={classes.tableCell}
                              key={prop.id}
                            >
                              <Link to={{pathname: "/admin/searchdeliveries", property: { orderId: prop.delivery.order_id }}} >
                              {prop.delivery.order_id}
                              </Link>
                            </TableCell>
                            <TableCell
                              className={classes.tableCell}
                              key={prop.id}
                            >
                              {prop.delivery.service.service_name}
                            </TableCell>
                            <TableCell
                              className={classes.tableCell}
                              key={prop.id}
                            >
                              {prop.delivery.creator ? prop.delivery.creator.first_name : prop.delivery.sender_name}{" "}
                       {(prop.delivery.creator && prop.delivery.creator.last_name) && prop.delivery.creator.last_name}
                            </TableCell>
                            <TableCell
                              className={classes.tableCell}
                              key={prop.id}
                            >
                              {prop.delivery.from_location.location_name}
                            </TableCell>
                            <TableCell
                              className={classes.tableCell}
                              key={prop.id}
                            >
                              {prop.to_facility.facility_name}
                            </TableCell>
                            <TableCell
                              className={classes.tableCell}
                              key={prop.id}
                            >
                              {prop.delivery.to_location.location_name}
                            </TableCell>
                            <TableCell
                              className={classes.tableCell}
                              key={prop.id}
                            >
                              GMD {prop.delivery.price}
                            </TableCell>
                            <TableCell
                              className={classes.tableCell}
                              key={prop.id}
                            >
                              {prop.driver.first_name +
                                " " +
                                prop.driver.last_name}
                            </TableCell>
                            <TableCell
                              className={classes.tableCell}
                              key={prop.id}
                            >
                              <View
                                key={prop.id}
                                style={{ cursor: "pointer" }}
                                onClick={(e) =>
                                  toggleDraweropen(e, prop.delivery.id)
                                }
                              />
                            </TableCell>
                          </TableRow>
                        );
                      })
                    )
                  }
                  service={true}
                />
                <TablePagination
      component="div"
      count={totalcount3}
      page={page3}
      onPageChange={handleChangePage3}
      rowsPerPage={rowsPerPage3}
      onRowsPerPageChange={handleChangeRowsPerPage3}
    />
              </TabPanel>
            </div>
          </CardBody>
        </Card>
      </GridItem>

      <GridItem xs={12} sm={12} md={12}>
        <Card>
          <CardHeader color="danger">
            <h4 className={classes.cardTitleWhite}>
              International Deliveries {sortingdata && "at " +sortingdata.facility_name}
            </h4>
          </CardHeader>
          <CardBody>
            <div className={classes.root}>
              <AppBar position="static" color="default">
                <Tabs
                  value={value2}
                  onChange={handletabChange2}
                  variant="fullWidth"
                  scrollButtons="on"
                  indicatorColor="secondary"
                  textColor="secondary"
                  aria-label="scrollable force tabs example"
                >
                  <Tab
                    className={dividermatch && classes.dividetab}
                    label="Unassigned Request"
                    icon={<TimelapseIcon />}
                    {...a11yProps(0)}
                  />
                  <Tab
                    className={dividermatch && classes.dividetab}
                    label="Pending Pick-Up From Sorting Facility"
                    icon={<FontAwesomeIcon icon={faDolly} />}
                    {...a11yProps(1)}
                  />
                  <Tab
                    className={dividermatch && classes.dividetab}
                    label="On-Route To Next Sorting Facility"
                    icon={<FontAwesomeIcon icon={faShippingFast} />}
                    {...a11yProps(2)}
                  />
                </Tabs>
              </AppBar>
              <TabPanel value={value2} index={0}>
                <Table
                  searchKeyword={searchHandler4}
                  term={searchTerm4}
                  setTerm={setSearchTerm4}
                  searchserviceKeyword={searchcategoryHandler1}
                  serviceterm={searchcategoryTerm1}
                  allservices={services}
                  tableHeaderColor="gray"
                  tableHead={[
                    "Order ID",
                    "Sender",
                    "Recipient",
                    "Initial Pickup Location",
                    "Final Destination",
                    "Price",
                    "Assign to Handler",
                    "Details",
                  ]}
                  tableData={
                    unassigned2.length == 0 ? (
                      <TableRow>
                        <TableCell
                          style={{ textAlign: "center" }}
                          colSpan={8}
                          className={classes.tableCell}
                        >
                          No Unassigned Requests
                        </TableCell>
                      </TableRow>
                    ) : (
                      unassigned2.map((prop) => {
                        return (
                          <TableRow
                            key={prop.id}
                            className={classes.tableBodyRow}
                          >
                            <TableCell
                              className={classes.tableCell}
                              key={prop.id}
                            >
                              <Link to={{pathname: "/admin/searchdeliveries", property: { orderId: prop.delivery.order_id }}} >
                              {prop.delivery.order_id}
                              </Link>
                            </TableCell>
                            <TableCell
                              className={classes.tableCell}
                              key={prop.id}
                            >
                              {prop.delivery.creator ? prop.delivery.creator.first_name : prop.delivery.sender_name}{" "}
                       {(prop.delivery.creator && prop.delivery.creator.last_name) && prop.delivery.creator.last_name}
                            </TableCell>
                            <TableCell
                              className={classes.tableCell}
                              key={prop.id}
                            >
                              {prop.delivery.reciepient ? prop.delivery.reciepient.first_name : prop.delivery.creator ? prop.delivery.creator.first_name : prop.delivery.sender_name}{" "}
                             {prop.delivery.reciepient ? prop.delivery.reciepient.last_name : prop.delivery.creator ? prop.delivery.creator.last_name ? prop.delivery.creator.last_name : ""  : ""}
                              
                            </TableCell>
                            <TableCell
                              className={classes.tableCell}
                              key={prop.id}
                            >
                              {prop.delivery.from_location.location_name}
                            </TableCell>
                            <TableCell
                              className={classes.tableCell}
                              key={prop.id}
                            >
                              {prop.delivery.to_location.location_name}
                            </TableCell>
                            <TableCell
                              className={classes.tableCell}
                              key={prop.id}
                            >
                              GMD {prop.delivery.price}
                            </TableCell>
                            <TableCell
                              className={classes.tableCell}
                              key={prop.id}
                            >
                              <FontAwesomeIcon
                                style={{ cursor: "pointer", fontSize: "25px" }}
                                onClick={() =>
                                  handleIntlSelectOpen(
                                    prop.delivery.from_location.id,
                                    prop.delivery.id,
                                    "add"
                                  )
                                }
                                icon={faPeopleCarry}
                              />
                            </TableCell>
                            <TableCell
                              className={classes.tableCell}
                              key={prop.id}
                            >
                              <View
                                key={prop.id}
                                style={{ cursor: "pointer" }}
                                onClick={(e) =>
                                  toggleDraweropen(e, prop.delivery.id)
                                }
                              />
                            </TableCell>
                          </TableRow>
                        );
                      })
                    )
                  }
                  intl={true}
                />
                <TablePagination
      component="div"
      count={totalcount4}
      page={page4}
      onPageChange={handleChangePage4}
      rowsPerPage={rowsPerPage4}
      onRowsPerPageChange={handleChangeRowsPerPage4}
    />
              </TabPanel>
              <TabPanel value={value2} index={1}>
                <Table
                  searchKeyword={searchHandler5}
                  term={searchTerm5}
                  setTerm={setSearchTerm5}
                  searchserviceKeyword={searchcategoryHandler2}
                  serviceterm={searchcategoryTerm2}
                  allservices={services}
                  tableHeaderColor="gray"
                  tableHead={[
                    "Order ID",
                    "Sender",
                    "Initial Pickup Location",
                    "Next Destination",
                    "Final Destination",
                    "Price",
                    "Assigned Handler",
                    "Re-Assign Handler",
                    "Details",
                  ]}
                  tableData={
                    pendingreq2.length == 0 ? (
                      <TableRow>
                        <TableCell
                          style={{ textAlign: "center" }}
                          colSpan={9}
                          className={classes.tableCell}
                        >
                          No Pending Requests
                        </TableCell>
                      </TableRow>
                    ) : (
                      pendingreq2.map((prop) => {
                        return (
                          <TableRow
                            key={prop.id}
                            className={classes.tableBodyRow}
                          >
                            <TableCell
                              className={classes.tableCell}
                              key={prop.id}
                            >
                              <Link to={{pathname: "/admin/searchdeliveries", property: { orderId: prop.delivery.order_id }}} >
                              {prop.delivery.order_id}
                              </Link>
                            </TableCell>
                            <TableCell
                              className={classes.tableCell}
                              key={prop.id}
                            >
                              {prop.delivery.creator ? prop.delivery.creator.first_name : prop.delivery.sender_name}{" "}
                       {(prop.delivery.creator && prop.delivery.creator.last_name) && prop.delivery.creator.last_name}
                            </TableCell>
                            <TableCell
                              className={classes.tableCell}
                              key={prop.id}
                            >
                              {prop.delivery.from_location.location_name}
                            </TableCell>
                            <TableCell
                              className={classes.tableCell}
                              key={prop.id}
                            >
                              {prop.to_facility_id
                                ? prop.to_facility.facility_name
                                : prop.delivery.to_location.location_name}
                            </TableCell>
                            <TableCell
                              className={classes.tableCell}
                              key={prop.id}
                            >
                              {prop.delivery.to_location.location_name}
                            </TableCell>
                            <TableCell
                              className={classes.tableCell}
                              key={prop.id}
                            >
                              GMD {prop.delivery.price}
                            </TableCell>
                            <TableCell
                              className={classes.tableCell}
                              key={prop.id}
                            >
                              {prop.driver.first_name +
                                " " +
                                prop.driver.last_name}
                            </TableCell>
                            <TableCell
                              className={classes.tableCell}
                              key={prop.id}
                            >
                              <FontAwesomeIcon
                                style={{ cursor: "pointer", fontSize: "25px" }}
                                onClick={() =>
                                  handleIntlSelectOpen(
                                    prop.delivery.from_location.id,
                                    prop.delivery.id,
                                    "update"
                                  )
                                }
                                icon={faPeopleCarry}
                              />
                            </TableCell>
                            <TableCell
                              className={classes.tableCell}
                              key={prop.id}
                            >
                              <View
                                key={prop.id}
                                style={{ cursor: "pointer" }}
                                onClick={(e) =>
                                  toggleDraweropen(e, prop.delivery.id)
                                }
                              />
                            </TableCell>
                          </TableRow>
                        );
                      })
                    )
                  }
                  intl={true}
                />
                <TablePagination
      component="div"
      count={totalcount5}
      page={page5}
      onPageChange={handleChangePage5}
      rowsPerPage={rowsPerPage5}
      onRowsPerPageChange={handleChangeRowsPerPage5}
    />
              </TabPanel>

              <TabPanel value={value2} index={2}>
                <Table
                  searchKeyword={searchHandler6}
                  term={searchTerm6}
                  setTerm={setSearchTerm6}
                  searchserviceKeyword={searchcategoryHandler3}
                  serviceterm={searchcategoryTerm3}
                  allservices={services}
                  tableHeaderColor="gray"
                  tableHead={[
                    "Order ID",
                    "Sender",
                    "Initial Pickup Location",
                    "Next Sorting Facility",
                    "Final Destination",
                    "Price",
                    "Assigned Driver",
                    "Details",
                  ]}
                  tableData={
                    onroute2.length == 0 ? (
                      <TableRow>
                        <TableCell
                          style={{ textAlign: "center" }}
                          colSpan={8}
                          className={classes.tableCell}
                        >
                          No Deliveries On-Route
                        </TableCell>
                      </TableRow>
                    ) : (
                      onroute2.map((prop) => {
                        return (
                          <TableRow
                            key={prop.id}
                            className={classes.tableBodyRow}
                          >
                            <TableCell
                              className={classes.tableCell}
                              key={prop.id}
                            >
                              <Link to={{pathname: "/admin/searchdeliveries", property: { orderId: prop.delivery.order_id }}} >
                              {prop.delivery.order_id}
                              </Link>
                            </TableCell>
                            <TableCell
                              className={classes.tableCell}
                              key={prop.id}
                            >
                              {prop.delivery.creator ? prop.delivery.creator.first_name : prop.delivery.sender_name}{" "}
                       {(prop.delivery.creator && prop.delivery.creator.last_name) && prop.delivery.creator.last_name}
                            </TableCell>
                            <TableCell
                              className={classes.tableCell}
                              key={prop.id}
                            >
                              {prop.delivery.from_location.location_name}
                            </TableCell>
                            <TableCell
                              className={classes.tableCell}
                              key={prop.id}
                            >
                              {prop.to_facility.facility_name}
                            </TableCell>
                            <TableCell
                              className={classes.tableCell}
                              key={prop.id}
                            >
                              {prop.delivery.to_location.location_name}
                            </TableCell>
                            <TableCell
                              className={classes.tableCell}
                              key={prop.id}
                            >
                              GMD {prop.delivery.price}
                            </TableCell>
                            <TableCell
                              className={classes.tableCell}
                              key={prop.id}
                            >
                              {prop.driver.first_name +
                                " " +
                                prop.driver.last_name}
                            </TableCell>
                            <TableCell
                              className={classes.tableCell}
                              key={prop.id}
                            >
                              <View
                                key={prop.id}
                                style={{ cursor: "pointer" }}
                                onClick={(e) =>
                                  toggleDraweropen(e, prop.delivery.id)
                                }
                              />
                            </TableCell>
                          </TableRow>
                        );
                      })
                    )
                  }
                  service={true}
                />
                <TablePagination
      component="div"
      count={totalcount6}
      page={page6}
      onPageChange={handleChangePage6}
      rowsPerPage={rowsPerPage6}
      onRowsPerPageChange={handleChangeRowsPerPage6}
    />
              </TabPanel>
            </div>
          </CardBody>
        </Card>
      </GridItem>
      <Dialog
        fullWidth={true}
        maxWidth={"xl"}
        open={open}
        TransitionComponent={Transition}
        keepMounted
        onClose={handleClose}
        aria-labelledby="alert-dialog-slide-title"
        aria-describedby="alert-dialog-slide-description"
      >
        <DialogContent>
          <DialogContentText id="alert-dialog-slide-description">
            <GridContainer>
              <GridItem xs={12} sm={12} md={12}>
                <>
                  <CardHeader color="danger">
                    <h4 className={classes.cardTitleWhite}>Courier Service</h4>
                    <p className={classes.cardCategoryWhite}>
                      Order. No: {orderdata.order_id}
                    </p>
                  </CardHeader>
                  <CardBody>
                    <GridContainer>
                      <GridItem xs={12} sm={12} md={6}>
                        <div>
                          <h6>
                            <em>Sender</em>
                          </h6>
                          <div className={classes.detailcell}>
                            <PersonOutlineIcon />
                            <span>Name: {orderdata.sender_name}</span>
                          </div>
                          <div className={classes.detailcell}>
                            <EmailIcon />
                            <span>Email: {orderdata.sender_email}</span>
                          </div>
                          <div className={classes.detailcell}>
                            <CallIcon />
                            <span>Telephone: {orderdata.sender_telephone}</span>
                          </div>
                          <div className={classes.detailcell}>
                            <LocationOn />
                            <span>Location: {orderdata.sender_location}</span>
                          </div>
                        </div>
                      </GridItem>
                      <Divider orientation="vertical" flexItem />
                      <GridItem xs={12} sm={12} md={5}>
                        <div>
                          <h6>
                            <em>Reciepient</em>
                          </h6>
                          <div className={classes.detailcell}>
                            <PersonOutlineIcon />
                            <span>Name: {orderdata.recipient_name}</span>
                          </div>
                          <div className={classes.detailcell}>
                            <EmailIcon />
                            <span>Email: {orderdata.recipient_email}</span>
                          </div>
                          <div className={classes.detailcell}>
                            <CallIcon />
                            <span>
                              Telephone: {orderdata.recipient_telephone}
                            </span>
                          </div>
                          <div className={classes.detailcell}>
                            <LocationOn />
                            <span>
                              Location: {orderdata.recipient_location}
                            </span>
                          </div>
                        </div>
                      </GridItem>
                    </GridContainer>

                    <hr />
                    <GridContainer>
                      <GridItem xs={12} sm={12} md={12}>
                        <div>
                          <h6 style={{ textAlign: "center" }}>
                            <em>Package Info</em>
                          </h6>
                          <div className={classes.detailcell2}>
                            <span>Package Type: {orderdata.pack_type}</span>
                          </div>
                          <div className={classes.detailcell2}>
                            <span>Boundary: {orderdata.boundary}</span>
                          </div>
                          <div className={classes.detailcell2}>
                            <span>
                              Driver: {orderdata.driver} ({orderdata.vehicle})
                            </span>
                          </div>
                          <div className={classes.detailcell2}>
                            <span>
                              CMB: {orderdata.cbm} | Weight:{orderdata.weight}
                            </span>
                          </div>
                          <div className={classes.detailcell2}>
                            <span>Price: GMD {orderdata.price}</span>
                          </div>
                        </div>
                      </GridItem>
                    </GridContainer>
                  </CardBody>
                </>
              </GridItem>
            </GridContainer>
          </DialogContentText>
        </DialogContent>
      </Dialog>
      <Dialog
        fullWidth={true}
        maxWidth={addloading ? "xs" : "sm"}
        disableEscapeKeyDown
        open={openSelect}
        onClose={handleSelectClose}
      >
        {addloading ? (
          <>
            <DialogTitle style={{ textAlign: "center" }}>
              Assigning Driver to Delivery
            </DialogTitle>
            <DialogContentText style={{ textAlign: "center" }}>
              <CircularProgress color="secondary" />
            </DialogContentText>{" "}
          </>
        ) : (
          <>
            <DialogContent>
              {step == 0 && (
                <GridContainer>
                  <GridItem xs={12} sm={12} md={12}>
                    <DialogTitle style={{ textAlign: "center" }}>
                      Select Next Destination
                    </DialogTitle>
                  </GridItem>
                  <GridItem xs={12} sm={6} md={6}>
                    <Card
                      style={{ boxShadow: "0px 10px 2px 0px rgba(0,0,0,0.19)" }}
                      onClick={() => setStep(1)}
                      className={classes.root}
                    >
                      <CardActionArea>
                        <Typography
                          className={classes.pos}
                          color="textSecondary"
                        >
                          Send to Sorting Facility
                        </Typography>
                        <CardMedia
                          className={classes.media}
                          image={imgurl + "tofacility.gif"}
                        />
                        <CardContent></CardContent>
                      </CardActionArea>
                    </Card>
                  </GridItem>
                  <GridItem xs={12} sm={6} md={6}>
                    <Card
                      style={{ boxShadow: "0px 10px 2px 0px rgba(0,0,0,0.19)" }}
                      onClick={() => setStep(2)}
                      className={classes.root}
                    >
                      <CardActionArea>
                        <Typography
                          className={classes.pos}
                          color="textSecondary"
                        >
                          Send Directly to Recipient
                        </Typography>
                        <CardMedia
                          className={classes.media}
                          image={imgurl + "tocustomer.gif"}
                        />
                        <CardContent></CardContent>
                      </CardActionArea>
                    </Card>
                  </GridItem>
                </GridContainer>
              )}
              {step == 1 && (
                <>
                  <GridContainer>
                    <GridItem xs={12} sm={12} md={12}>
                      <DialogTitle style={{ textAlign: "center" }}>
                        Select Sorting Facility
                      </DialogTitle>
                    </GridItem>
                    <GridItem xs={12} sm={12} md={12}>
                      <Select
                        value={sortingid}
                        onChange={(val) => setSortingid(val)}
                        menuPortalTarget={document.body}
                        placeholder="Select Sorting Facility..."
                        styles={{
                          menuPortal: (base) => ({ ...base, zIndex: 9999 }),
                        }}
                        options={facilities}
                      />
                    </GridItem>
                  </GridContainer>
                  <GridContainer>
                    <Button
                      style={{
                        display: "block",
                        marginLeft: "0",
                        marginRight: "auto",
                      }}
                      onClick={backtofirst}
                      color="primary"
                    >
                      Previous
                    </Button>
                    <Button
                      disabled={sortingid == 0}
                      onClick={() => setStep(3)}
                      style={{ float: "right" }}
                      color="primary"
                    >
                      Proceed
                    </Button>
                  </GridContainer>
                </>
              )}
              {step == 2 && (
                <>
                  <GridContainer>
                    <GridItem xs={12} sm={12} md={12}>
                      <DialogTitle style={{ textAlign: "center" }}>
                        Select Driver
                      </DialogTitle>
                    </GridItem>
                    <GridItem xs={12} sm={12} md={12}>
                      <Select
                        menuPortalTarget={document.body}
                        value={driverid}
                        onChange={(val) => setDriverid(val)}
                        placeholder="Select Driver..."
                        styles={{
                          menuPortal: (base) => ({ ...base, zIndex: 9999 }),
                        }}
                        options={options}
                      />
                    </GridItem>
                  </GridContainer>
                  <GridContainer>
                    <Button
                      style={{
                        display: "block",
                        marginLeft: "0",
                        marginRight: "auto",
                      }}
                      onClick={backtofirst}
                      color="primary"
                    >
                      Previous
                    </Button>
                    <Button
                      onClick={() => processdelivery("client")}
                      style={{ float: "right" }}
                      disabled={driverid == 0}
                      color="primary"
                    >
                      Proceed
                    </Button>
                  </GridContainer>
                </>
              )}

              {step == 3 && (
                <>
                  <GridContainer>
                    <GridItem xs={12} sm={12} md={12}>
                      <DialogTitle style={{ textAlign: "center" }}>
                        Select Driver
                      </DialogTitle>
                    </GridItem>
                    <GridItem xs={12} sm={12} md={12}>
                      <Select
                        value={driverid}
                        onChange={(val) => setDriverid(val)}
                        menuPortalTarget={document.body}
                        placeholder="Select Driver..."
                        styles={{
                          menuPortal: (base) => ({ ...base, zIndex: 9999 }),
                        }}
                        options={options}
                      />
                    </GridItem>
                  </GridContainer>
                  <GridContainer>
                    <Button
                      style={{
                        display: "block",
                        marginLeft: "0",
                        marginRight: "auto",
                      }}
                      onClick={backtosecond}
                      color="primary"
                    >
                      Previous
                    </Button>
                    <Button
                      disabled={driverid == 0}
                      onClick={() => processdelivery("facility")}
                      style={{ float: "right" }}
                      color="primary"
                    >
                      Proceed
                    </Button>
                  </GridContainer>
                </>
              )}
            </DialogContent>{" "}
          </>
        )}
      </Dialog>
      <Drawer anchor={"right"} open={opendrawer} onClose={toggleDrawerclose}>
        <div className={clsx(classes.list)} role="presentation">
          <List style={{ background: "#EEEEEE" }}>
            <ListItem>
              <ListItemIcon>
                <Close
                  style={{ cursor: "pointer" }}
                  onClick={toggleDrawerclose}
                />
              </ListItemIcon>
              <ListItemText primary="Delivery Details" />
            </ListItem>
          </List>
          <Divider style={{ marginBottom: "10px" }} />
          <ListItem>
            <GridContainer style={{ width: "100%" }}>
              <GridItem xs={12} sm={12} md={12}>
                <Typography
                  style={{ textDecoration: "underline" }}
                  align="center"
                  variant="subtitle2"
                  gutterBottom
                >
                  {delivery.service && delivery.service.service_name}
                </Typography>
                <Typography
                  style={{ textDecoration: "underline" }}
                  align="center"
                  variant="subtitle2"
                  gutterBottom
                >
                  {delivery.boundary && delivery.boundary.boundary_name}{" "}
                  Delivery
                </Typography>
              </GridItem>
              <GridItem
                style={{ borderRight: "1px solid #EEEEEE" }}
                xs={6}
                sm={6}
                md={6}
              >
                <Typography
                  align="center"
                  style={{ textDecoration: "underline" }}
                  variant="subtitle2"
                  gutterBottom
                >
                  INITIAL PICKUP
                </Typography>
                <Typography align="center" variant="subtitle2" gutterBottom>
                  Location:{" "}
                  <span
                    style={{ textTransform: "capitalize", fontSize: "15px" }}
                  >
                    {delivery.from_location &&
                      delivery.from_location.location_name}
                  </span>
                </Typography>
                <Typography align="center" variant="subtitle2" gutterBottom>
                  Address:{" "}
                  <span
                    style={{ textTransform: "capitalize", fontSize: "15px" }}
                  >
                    {delivery.fromAddress}
                  </span>
                </Typography>
              </GridItem>
              <GridItem xs={6} sm={6} md={6}>
                <Typography
                  align="center"
                  style={{ textDecoration: "underline" }}
                  variant="subtitle2"
                  gutterBottom
                >
                  FINAL DESTINATION
                </Typography>
                <Typography align="center" variant="subtitle2" gutterBottom>
                  Location:{" "}
                  <span
                    style={{ textTransform: "capitalize", fontSize: "15px" }}
                  >
                    {delivery.to_location && delivery.to_location.location_name}
                  </span>
                </Typography>
                <Typography align="center" variant="subtitle2" gutterBottom>
                  Address:{" "}
                  <span
                    style={{ textTransform: "capitalize", fontSize: "15px" }}
                  >
                    {delivery.toAddress}
                  </span>
                </Typography>
              </GridItem>
            </GridContainer>
          </ListItem>
          <Divider style={{ marginBottom: "10px" }} />
          <ListItem>
            <GridContainer style={{ width: "100%" }}>
              <GridItem
                style={{ borderRight: "1px solid #EEEEEE" }}
                xs={6}
                sm={6}
                md={6}
              >
                <Typography
                  style={{ textDecoration: "underline" }}
                  align="center"
                  variant="subtitle2"
                  gutterBottom
                >
                  SENDER
                </Typography>
                <Typography align="center" variant="subtitle2" gutterBottom>
                  Name:{" "}
                  <span
                    style={{ textTransform: "capitalize", fontSize: "15px" }}
                  >
                    {delivery.creator ? delivery.creator.first_name : delivery.sender_name}{" "}
                            {(delivery.creator && delivery.creator.last_name) && delivery.creator.last_name}
                  </span>
                </Typography>
                <Typography align="center" variant="subtitle2" gutterBottom>
                  Telephone:{" "}
                  <span
                    style={{ textTransform: "capitalize", fontSize: "15px" }}
                  >
                    {delivery.creator ? delivery.creator.phone_number : delivery.sender_phone}
                  </span>
                </Typography>
              </GridItem>
              {delivery.unassigned_facility && (
                <GridItem xs={6} sm={6} md={6}>
                  <Typography
                    style={{ textDecoration: "underline" }}
                    align="center"
                    variant="subtitle2"
                    gutterBottom
                  >
                    RECIEPIENT
                  </Typography>
                  <Typography align="center" variant="subtitle2" gutterBottom>
                    Name:{" "}
                    <span
                      style={{ textTransform: "capitalize", fontSize: "15px" }}
                    >
                     {delivery.reciepient ? delivery.reciepient.first_name : delivery.creator ? delivery.creator.first_name : delivery.sender_name}{" "}
                             {delivery.reciepient ? delivery.reciepient.last_name : delivery.creator ? delivery.creator.last_name ? delivery.creator.last_name : ""  : ""}
                              
                    </span>
                  </Typography>
                  <Typography align="center" variant="subtitle2" gutterBottom>
                    Telephone:{" "}
                    <span
                      style={{ textTransform: "capitalize", fontSize: "15px" }}
                    >
                     {delivery.reciepient ? delivery.reciepient.phone : delivery.creator ? delivery.creator.phone_number ? delivery.creator.phone_number : "": delivery.sender_phone }

                    </span>
                  </Typography>
                </GridItem>
              )}
              {delivery.pendingfacilityfacility && (
                <GridItem xs={6} sm={6} md={6}>
                  <Typography
                    style={{ textDecoration: "underline" }}
                    align="center"
                    variant="subtitle2"
                    gutterBottom
                  >
                    NEXT DESTINATION
                  </Typography>
                  <Typography align="center" variant="subtitle2" gutterBottom>
                    Zone:{" "}
                    <span
                      style={{ textTransform: "capitalize", fontSize: "15px" }}
                    >
                      {
                        delivery.pendingfacilityfacility.to_facility.zone
                          .zone_name
                      }
                    </span>
                  </Typography>
                  <Typography align="center" variant="subtitle2" gutterBottom>
                    Facility:{" "}
                    <span
                      style={{ textTransform: "capitalize", fontSize: "15px" }}
                    >
                      {
                        delivery.pendingfacilityfacility.to_facility
                          .facility_name
                      }
                    </span>
                  </Typography>
                </GridItem>
              )}

              {delivery.pendingfacilityrecipient && (
                <GridItem xs={6} sm={6} md={6}>
                  <Typography
                    style={{ textDecoration: "underline" }}
                    align="center"
                    variant="subtitle2"
                    gutterBottom
                  >
                    NEXT DESTINATION
                  </Typography>
                  <Typography align="center" variant="subtitle2" gutterBottom>
                    Location:{" "}
                    <span
                      style={{ textTransform: "capitalize", fontSize: "15px" }}
                    >
                      {delivery.to_location.location_name}
                    </span>
                  </Typography>
                  <Typography align="center" variant="subtitle2" gutterBottom>
                    Address:{" "}
                    <span
                      style={{ textTransform: "capitalize", fontSize: "15px" }}
                    >
                      {delivery.toAddress}
                    </span>
                  </Typography>
                </GridItem>
              )}
              {delivery.onroutefacilityfacility && (
                <GridItem xs={6} sm={6} md={6}>
                  <Typography
                    style={{ textDecoration: "underline" }}
                    align="center"
                    variant="subtitle2"
                    gutterBottom
                  >
                    NEXT DESTINATION
                  </Typography>
                  <Typography align="center" variant="subtitle2" gutterBottom>
                    Zone:{" "}
                    <span
                      style={{ textTransform: "capitalize", fontSize: "15px" }}
                    >
                      {
                        delivery.onroutefacilityfacility.to_facility.zone
                          .zone_name
                      }
                    </span>
                  </Typography>
                  <Typography align="center" variant="subtitle2" gutterBottom>
                    Facility:{" "}
                    <span
                      style={{ textTransform: "capitalize", fontSize: "15px" }}
                    >
                      {
                        delivery.onroutefacilityfacility.to_facility
                          .facility_name
                      }
                    </span>
                  </Typography>
                </GridItem>
              )}
            </GridContainer>
          </ListItem>
          <Divider />
          <ListItem>
            <GridContainer style={{ width: "100%" }}>
              {delivery.unassigned_facility &&
                delivery.unassigned_facility.status == "unassigned" && (
                  <GridItem xs={12} sm={12} md={12}>
                    <Typography align="center" variant="subtitle2" gutterBottom>
                      Arrived{" "}
                      {moment(
                        delivery.unassigned_facility.updated_at
                      ).fromNow()}{" "}
                    </Typography>
                  </GridItem>
                )}
              {delivery.pendingfacilityrecipient && (
                <GridItem xs={12} sm={12} md={12}>
                  <Typography align="center" variant="subtitle2" gutterBottom>
                    Assigned{" "}
                    {moment(
                      delivery.pendingfacilityrecipient.updated_at
                    ).fromNow()}{" "}
                    to{" "}
                    <span
                      style={{ textTransform: "capitalize", fontSize: "15px" }}
                    >
                      {delivery.pendingfacilityrecipient.driver.first_name +
                        " " +
                        delivery.pendingfacilityrecipient.driver.last_name}
                    </span>
                  </Typography>
                </GridItem>
              )}
              {delivery.pendingfacilityfacility && (
                <GridItem xs={12} sm={12} md={12}>
                  <Typography align="center" variant="subtitle2" gutterBottom>
                    Assigned{" "}
                    {moment(
                      delivery.pendingfacilityfacility.updated_at
                    ).fromNow()}{" "}
                    to{" "}
                    <span
                      style={{ textTransform: "capitalize", fontSize: "15px" }}
                    >
                      {delivery.pendingfacilityfacility.driver.first_name +
                        " " +
                        delivery.pendingfacilityfacility.driver.last_name}
                    </span>
                  </Typography>
                </GridItem>
              )}
              {delivery.onroutefacilityfacility && (
                <GridItem xs={12} sm={12} md={12}>
                  <Typography align="center" variant="subtitle2" gutterBottom>
                    Picked up{" "}
                    {moment(
                      delivery.onroutefacilityfacility.updated_at
                    ).fromNow()}{" "}
                    by{" "}
                    <span
                      style={{ textTransform: "capitalize", fontSize: "15px" }}
                    >
                      {delivery.onroutefacilityfacility.driver.first_name +
                        " " +
                        delivery.onroutefacilityfacility.driver.last_name}
                    </span>
                  </Typography>
                </GridItem>
              )}

{delivery.payment &&
<GridItem xs={12} sm={12} md={12}>
                  <Box style={{display:'flex',alignItems:"center",justifyContent:"center"}} >
                  <Image
      style={{ width: "200px", height: "auto" }}
      zoomed={zoomed}
      src={imgurl+delivery.payment.img}
      alt="Proof of Payment"
      onClick={() => setZoomed(true)}
      onRequestClose={() => setZoomed(false)}
    />
                  </Box>
                  <Typography align="center" variant="subtitle2" gutterBottom>
                   Proof of Payment
                  </Typography>
                </GridItem>
                }

            </GridContainer>
          </ListItem>
          {delivery.packages && delivery.packages.length > 0 && (
            <>
              <List style={{ background: "#EEEEEE" }}>
                <ListItem>
                  <ListItemIcon>
                    <FontAwesomeIcon icon={faBoxOpen} />
                  </ListItemIcon>
                  <ListItemText
                    primary={
                      delivery.packages &&
                      delivery.packages.length + " Packages"
                    }
                  />
                </ListItem>
              </List>
              <Divider />
            </>
          )}
          {delivery.items && delivery.items.length > 0 && (
            <>
              <List style={{ background: "#EEEEEE" }}>
                <ListItem>
                  <ListItemIcon>
                    <FontAwesomeIcon icon={faBoxOpen} />
                  </ListItemIcon>
                  <ListItemText
                    primary={
                      delivery.items &&
                      delivery.items.length + " Items"
                    }
                  />
                </ListItem>
              </List>
              <Divider />
            </>
          )}
          <Divider style={{ marginBottom: "10px" }} />
          {delivery.packages &&
            delivery.packages.map((prop) => {
              return (
                <>
                  <ListItem>
                    <GridContainer style={{ width: "100%" }}>
                      <GridItem xs={4} sm={4} md={4}>
                        <Avatar
                          className={classes.avatarimg}
                          variant="square"
                          key={prop.id}
                          alt="Package"
                          src={imgurl + prop.packages.img}
                        />
                      </GridItem>
                      <GridItem xs={4} sm={4} md={4}>
                        <Typography
                          align="center"
                          variant="subtitle2"
                          gutterBottom
                        >
                          Package Type
                        </Typography>
                        <Typography
                          align="center"
                          variant="caption"
                          display="block"
                          gutterBottom
                        >
                          {prop.packages.package_name}{" "}
                        </Typography>
                      </GridItem>
                      <GridItem xs={4} sm={4} md={4}>
                        <Typography
                          align="center"
                          variant="subtitle2"
                          gutterBottom
                        >
                          Package ID
                        </Typography>
                        <Typography
                          align="center"
                          variant="caption"
                          display="block"
                          gutterBottom
                        >
                          {prop.package_reference_id
                            ? prop.package_reference_id
                            : "N/A"}
                        </Typography>
                      </GridItem>
                    </GridContainer>
                  </ListItem>
                  <Divider style={{ marginBottom: "10px" }} />
                </>
              );
            })}
            {delivery.items &&
            delivery.items.map((prop) => {
              return (
                <>
                  <ListItem>
                    <GridContainer style={{ width: "100%" }}>
                      <GridItem xs={4} sm={4} md={4}>
                      <img
                          className={classes.vehicleimg}
                          key={prop.id}
                          alt="Item"
                          src={imgurl + prop.items.img}
                        />
                      </GridItem>
                      <GridItem xs={4} sm={4} md={4}>
                        <Typography variant="subtitle2" gutterBottom>
                          Package Type
                        </Typography>
                        <Typography
                          variant="caption"
                          display="block"
                          gutterBottom
                        >
                          {prop.items.item_name}{" "}
                        </Typography>
                      </GridItem>
                      <GridItem xs={4} sm={4} md={4}>
                        <Typography variant="subtitle2" gutterBottom>
                          Package ID
                        </Typography>
                        <Typography
                          variant="caption"
                          display="block"
                          gutterBottom
                        >
                          {prop.package_reference_id
                            ? prop.package_reference_id
                            : "N/A"}
                        </Typography>
                      </GridItem>
                    </GridContainer>
                  </ListItem>

                  <Divider style={{ marginBottom: "10px" }} />
                </>
              );
            })}
        </div>
      </Drawer>

      <Dialog
        fullWidth={true}
        maxWidth={!addloading ? "sm" : "xs"}
        disableEscapeKeyDown
        open={openvehicleSelect}
        onClose={handleVehicleSelectClose}
      >
        <DialogTitle style={{ textAlign: "center" }}>
          {!addloading ? "Set Delivery Vehicle" : "Setting Delivery Vehicle"}
        </DialogTitle>
        {addloading ? (
          <DialogContentText style={{ textAlign: "center" }}>
            <CircularProgress color="secondary" />
          </DialogContentText>
        ) : (
          <DialogContent>
            <GridContainer>
              <GridItem
                className={classes.flexContainer}
                xs={12}
                sm={12}
                md={12}
              >
                <label>
                  Suggested Vehicle based on packages CBM and Weight
                </label>
                <br />
                <img
                  className={classes.flexItem}
                  alt="No Vehicle Eligible to Deliver among List of Vehicles"
                  src={imgurl + selvehimg}
                />
                <br />
                <label>Assigned Vehicle (choose one)</label>
                <br />
                <div className={classes.flexContainer}>
                  {vehicles.map((prop) => (
                    <div key={prop.id} className={classes.flexItem}>
                      <img
                        width="100%"
                        height="100%"
                        onClick={() => setSelvehid(prop.id)}
                        src={imgurl + prop.img}
                      />
                      <div
                        className={
                          selvehid == prop.id
                            ? classes.selected
                            : classes.overlay
                        }
                      >
                        <CheckCircle className={classes.playIcon} />
                      </div>
                    </div>
                  ))}
                </div>
              </GridItem>
            </GridContainer>
          </DialogContent>
        )}
        {!addloading && (
          <DialogActions>
            <Button
              style={{ display: "block", marginLeft: "0", marginRight: "auto" }}
              onClick={handleVehicleSelectClose}
              color="danger"
            >
              <Close />
              Cancel
            </Button>
            <Button
              style={{ float: "right" }}
              color="danger"
              onClick={handleVehicleSelectUpdateClose}
            >
              Proceed <Arrowed />
            </Button>
          </DialogActions>
        )}
      </Dialog>

      <Dialog
        fullWidth={true}
        maxWidth={addloading ? "xs" : "sm"}
        disableEscapeKeyDown
        open={openIntlSelect}
        onClose={handleIntlSelectClose}
      >
        {addloading ? (
          <>
            <DialogTitle style={{ textAlign: "center" }}>
              Assigning Delivery Request
            </DialogTitle>
            <DialogContentText style={{ textAlign: "center" }}>
              <CircularProgress color="secondary" />
            </DialogContentText>{" "}
          </>
        ) : (
          <>
            <DialogContent>
              {intlstep == 0 && (
                <GridContainer>
                  <GridItem xs={12} sm={12} md={12}>
                    <DialogTitle style={{ textAlign: "center" }}>
                      Select Next Destination
                    </DialogTitle>
                  </GridItem>
                  <GridItem xs={12} sm={6} md={6}>
                    <Card
                      style={{ boxShadow: "0px 10px 2px 0px rgba(0,0,0,0.19)" }}
                      onClick={() => setIntlstep(1)}
                      className={classes.root}
                    >
                      <CardActionArea>
                        <Typography
                          className={classes.pos}
                          color="textSecondary"
                        >
                          Send to Sorting Facility
                        </Typography>
                        <CardMedia
                          className={classes.media}
                          image={imgurl + "tofacility.gif"}
                        />
                        <CardContent></CardContent>
                      </CardActionArea>
                    </Card>
                  </GridItem>
                  <GridItem xs={12} sm={6} md={6}>
                    <Card
                      style={{ boxShadow: "0px 10px 2px 0px rgba(0,0,0,0.19)" }}
                      onClick={() => setIntlstep(2)}
                      className={classes.root}
                    >
                      <CardActionArea>
                        <Typography
                          className={classes.pos}
                          color="textSecondary"
                        >
                          Send Directly to Recipient
                        </Typography>
                        <CardMedia
                          className={classes.media}
                          image={imgurl + "tocustomer.gif"}
                        />
                        <CardContent></CardContent>
                      </CardActionArea>
                    </Card>
                  </GridItem>
                </GridContainer>
              )}
              {intlstep == 1 && (
                <>
                  <GridContainer>
                    <GridItem xs={12} sm={12} md={12}>
                      <DialogTitle style={{ textAlign: "center" }}>
                        Select Next Destination
                      </DialogTitle>
                    </GridItem>
                    <GridItem xs={12} sm={12} md={12}>
                    <Select
                        value={sortingid}
                        onChange={(val) => setIntldestid(val)}
                        menuPortalTarget={document.body}
                        placeholder="Select Next Destination..."
                        styles={{
                          menuPortal: (base) => ({ ...base, zIndex: 9999 }),
                        }}
                        options={facilities}
                      />
                    </GridItem>
                  </GridContainer>
                  <GridContainer>
                    <Button
                      style={{
                        display: "block",
                        marginLeft: "0",
                        marginRight: "auto",
                      }}
                      onClick={backtointlfirst}
                      color="primary"
                    >
                      Previous
                    </Button>
                    <Button
                      disabled={intldestid == 0}
                      onClick={() => setIntlstep(3)}
                      style={{ float: "right" }}
                      color="primary"
                    >
                      Proceed
                    </Button>
                  </GridContainer>
                </>
              )}
              {intlstep == 2 && (
                <>
                  <GridContainer>
                    <GridItem xs={12} sm={12} md={12}>
                      <DialogTitle style={{ textAlign: "center" }}>
                        Select Handler
                      </DialogTitle>
                    </GridItem>
                    <GridItem xs={12} sm={12} md={12}>
                      <Select
                        menuPortalTarget={document.body}
                        value={handlerid}
                        onChange={(val) => setHandlerid(val)}
                        placeholder="Select Handler..."
                        styles={{
                          menuPortal: (base) => ({ ...base, zIndex: 9999 }),
                        }}
                        options={options}
                      />
                    </GridItem>
                  </GridContainer>
                  <GridContainer>
                    <Button
                      style={{
                        display: "block",
                        marginLeft: "0",
                        marginRight: "auto",
                      }}
                      onClick={backtointlfirst}
                      color="primary"
                    >
                      Previous
                    </Button>
                    <Button
                      onClick={() => processdelivery("client")}
                      style={{ float: "right" }}
                      disabled={handlerid == 0}
                      color="primary"
                    >
                      Proceed
                    </Button>
                  </GridContainer>
                </>
              )}

              {intlstep == 3 && (
                <>
                  <GridContainer>
                    <GridItem xs={12} sm={12} md={12}>
                      <DialogTitle style={{ textAlign: "center" }}>
                        Select Handler
                      </DialogTitle>
                    </GridItem>
                    <GridItem xs={12} sm={12} md={12}>
                      <Select
                        value={handlerid}
                        onChange={(val) => setHandlerid(val)}
                        menuPortalTarget={document.body}
                        placeholder="Select Handler..."
                        styles={{
                          menuPortal: (base) => ({ ...base, zIndex: 9999 }),
                        }}
                        options={options}
                      />
                    </GridItem>
                  </GridContainer>
                  <GridContainer>
                    <Button
                      style={{
                        display: "block",
                        marginLeft: "0",
                        marginRight: "auto",
                      }}
                      onClick={backtointlsecond}
                      color="primary"
                    >
                      Previous
                    </Button>
                    <Button
                      disabled={handlerid == 0}
                      onClick={() => processdelivery("facility")}
                      style={{ float: "right" }}
                      color="primary"
                    >
                      Proceed
                    </Button>
                  </GridContainer>
                </>
              )}

                  {/* <GridContainer>
                    <Button
                      style={{
                        display: "block",
                        marginLeft: "0",
                        marginRight: "auto",
                        marginTop:"15px"
                      }}
                      onClick={handleIntlSelectClose}
                      color="primary"
                    >
                      Close
                    </Button>
                    <Button
                      onClick={() => processdelivery()}
                      style={{ float: "right", marginTop:"15px" }}
                      color="primary"
                      disabled={sortingid == 0 || driverid == 0}
                    >
                      Proceed
                    </Button>
                  </GridContainer> */}
               
            </DialogContent>{" "}
          </>
        )}
      </Dialog>

      <Drawer anchor={"top"} open={routeDrawer} onClose={closeRouteDrawer}>
        <div className={clsx(classes.list)} role="presentation">
          <List style={{ background: "#EA4642" }}>
            <ListItem style={{ color: "white" }}>
              <ListItemIcon>
                <Close
                  style={{ cursor: "pointer", color: "white" }}
                  onClick={closeRouteDrawer}
                />
              </ListItemIcon>
              <ListItemText primary="Delivery Routes" />
            </ListItem>
          </List>
          <Divider style={{ marginBottom: "10px" }} />
          <ListItem>
            <GridContainer style={{ width: "100%" }}>
              <GridItem xs={12} sm={12} md={12}>
                <Typography align="center" variant="subtitle2" gutterBottom>
                  {deliveryroutes[0] &&
                    deliveryroutes[0].delivery.from_location.location_name +
                      " (" +
                      deliveryroutes[0].delivery.fromAddress +
                      ")"}
                </Typography>
              </GridItem>
              {deliveryroutes.map((prop) => {
                return (
                  <React.Fragment key={prop}>
                    <GridItem xs={12} sm={12} md={12}>
                      <Divider style={{ marginBottom: "10px" }} />
                    </GridItem>
                    <GridItem xs={12} sm={12} md={12}>
                      <div
                        style={{
                          display: "block",
                          marginLeft: "auto",
                          marginRight: "auto",
                          width: "100%",
                        }}
                      >
                        <GridItem
                          style={{ padding: "0" }}
                          xs={12}
                          sm={12}
                          md={12}
                        >
                          <Divider
                            style={{ margin: "0 auto", height: "60px" }}
                            orientation="vertical"
                          />
                        </GridItem>
                        <GridItem
                          style={{ textAlign: "center" }}
                          xs={12}
                          sm={12}
                          md={12}
                        >
                          <Badge
                            color={
                              prop.status == "complete"
                                ? "primary"
                                : "secondary"
                            }
                            variant="dot"
                          >
                            <Typography>
                              Status: {prop.status == "onroute" && "On Route"}
                              {prop.status == "pendingpickup" &&
                                "To be Picked Up"}
                              {prop.status == "complete" && "Delivered"}
                            </Typography>
                          </Badge>
                          <Chip
                            style={{
                              display: "block",
                              marginLeft: "auto",
                              marginRight: "auto",
                              overflow: "auto",
                            }}
                            variant="outlined"
                            label={
                              "Driver: " +
                              prop.driver.first_name +
                              " " +
                              prop.driver.last_name
                            }
                          />
                        </GridItem>
                        <GridItem xs={12} sm={12} md={12}>
                          <Divider
                            style={{ margin: "0 auto", height: "60px" }}
                            orientation="vertical"
                          />
                          <ExpandMoreIcon
                            style={{
                              color: "#b8b8b8",
                              display: "block",
                              marginLeft: "auto",
                              marginRight: "auto",
                            }}
                          />
                        </GridItem>
                      </div>
                    </GridItem>
                    <GridItem xs={12} sm={12} md={12}>
                      <Divider style={{ marginBottom: "10px" }} />
                      <Typography
                        align="center"
                        variant="subtitle2"
                        gutterBottom
                      >
                        {prop.to_id && prop.delivery.to_location.location_name}{" "}
                        {prop.toAddress && "(" + prop.toAddress + ")"}
                        {prop.facility_id &&
                          prop.from_id &&
                          prop.facility.facility_name +
                            "(" +
                            prop.facility.zone.zone_name +
                            ")"}
                        {prop.to_facility_id &&
                          prop.to_facility.facility_name +
                            "(" +
                            prop.to_facility.zone.zone_name +
                            ")"}
                      </Typography>
                    </GridItem>
                  </React.Fragment>
                );
              })}
            </GridContainer>
          </ListItem>
        </div>
      </Drawer>
    </GridContainer>
  );
}
SortingDeliveries.propTypes = {
  url: PropTypes.string,
  imgurl: PropTypes.string,
  exit: PropTypes.string,
  match: PropTypes.shape({
    params: PropTypes.shape({
      sid: PropTypes.number.isRequired,
    }),
  }),
};

