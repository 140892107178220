/* eslint-disable */
import React, {useState} from "react";
import PropTypes from "prop-types";
// @material-ui/core components
import TablePagination from '@material-ui/core/TablePagination';
import { makeStyles } from "@material-ui/core/styles";
import InputLabel from '@material-ui/core/InputLabel';
import FormControl from '@material-ui/core/FormControl';
import Typography from "@material-ui/core/Typography";
import Rating from '@material-ui/lab/Rating';
import Select from '@material-ui/core/Select';
import Table from "@material-ui/core/Table";
import TodayIcon from '@material-ui/icons/Today';
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Fab from '@material-ui/core/Fab';
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import Grid from "@material-ui/core/Grid";
import IconButton from "@material-ui/core/IconButton";
import InputAdornment from "@material-ui/core/InputAdornment";
import SearchIcon from "@material-ui/icons/Search";
import DateFnsUtils from '@date-io/date-fns';
import { DatePicker, MuiPickersUtilsProvider } from "@material-ui/pickers";
// import Search from "@material-ui/icons/Search";
//core components
import TextField from '@material-ui/core/TextField';
import GridItem from "components/Grid/GridItem.js";
// core components
import styles from "assets/jss/material-dashboard-react/components/tableStyle.js";

const useStyles = makeStyles(styles);

export default function CustomTable(props) {
  const classes = useStyles();
  const { changePage,avgrating, showrating,tableHead, tableData, invoice, logs, sortinglabel, intl,category, service,serviceterm, selectedDate, searchserviceKeyword, allservices, search, term, setTerm, status, location_type, delstatus, displaydelstatus, searchdelstatusKeyword, searchstatusKeyword, searchdateKeyword, searchcategoryKeyword, searchKeyword } = props;

  return (
    <>
      <Grid container>

        <GridItem xs={12} sm={12} md={4}>
        {logs ?<MuiPickersUtilsProvider utils={DateFnsUtils}>
        <DatePicker
        clearable
        autoOk
        disableFuture
        label="Select Date"
        format="dd/MM/yyyy"
        value={selectedDate}
        onChange={searchdateKeyword}
        InputProps={{
          endAdornment: <InputAdornment position="end"><TodayIcon /></InputAdornment>,
        }}
      />
      </MuiPickersUtilsProvider> : null}
          {invoice ? <FormControl style={{ width: "50%" }} className={classes.formControl}>
            <InputLabel htmlFor="location-native-simple">Status</InputLabel>
            <Select value={status} onChange={(e) => searchstatusKeyword(e.target.value)} native>
              <option value="all">All</option>
              <option value="paid">Paid</option>
              <option value="incomplete">Incomplete</option>
            </Select>
          </FormControl> : null}
          {category ? <FormControl style={{ width: "50%" }} className={classes.formControl}>
            <InputLabel htmlFor="location-native-simple">Category</InputLabel>
            <Select value={location_type} onChange={(e) => searchcategoryKeyword(e.target.value)} native>
              <option value="all">All</option>
              <option value="area">Area</option>
              <option value="restaurant">Restaurant</option>
              <option value="corporation">Corporation</option>
            </Select>
          </FormControl> : null}
          {displaydelstatus ? <FormControl style={{ width: "50%" }} className={classes.formControl}>
            <InputLabel htmlFor="location-native-simple">Delivery Status</InputLabel>
            <Select value={delstatus} onChange={(e) => searchdelstatusKeyword(e.target.value)} native>
              <option value="all">All</option>
              <option value="pendingpickup">Pending Pickup</option>
              <option value="onroute">On-Route</option>
              <option value="complete">Delivered</option>
            </Select>
          </FormControl> : null}
          {service ? <FormControl style={{ width: "100%" }} className={classes.formControl}>
            <InputLabel htmlFor="location-native-simple">Service Type</InputLabel>
            <Select value={serviceterm} onChange={(e) => searchserviceKeyword(e.target.value)} native>
              <option value="all">All</option>
              {allservices.map(service => 
              <option value={service}>{service}</option>
              )}
            </Select>
          </FormControl> : null}

          {intl ? <FormControl style={{ width: "100%" }} className={classes.formControl}>
            <InputLabel htmlFor="location-native-simple">Category</InputLabel>
            <Select value={serviceterm} onChange={(e) => searchserviceKeyword(e.target.value)} native>
              <option value="all">All</option>
              <option value="inbound">Incoming to The Gambia</option>
              <option value="outbound">Outgoing from The Gambia</option>
            </Select>
            </FormControl> : null}

            {showrating ? 
            <>
            <Typography variant="h6" className={classes.title}>
              Average Rating
            </Typography>
              <Rating precision={0.05} name="read-only" value={avgrating} size="large" readOnly /> 
              </>
           : null}
          
        </GridItem>
        <GridItem xs={12} sm={12} md={4}>
        {invoice ?<MuiPickersUtilsProvider utils={DateFnsUtils}>
        <DatePicker
        clearable
        disableFuture
        openTo="year"
        label="Select Period"
        views={["year", "month"]}
        value={selectedDate}
        onChange={searchdateKeyword}
        InputProps={{
          endAdornment: <InputAdornment position="end"><TodayIcon /></InputAdornment>,
        }}
      />
      </MuiPickersUtilsProvider> : null}
        </GridItem>
        <GridItem xs={12} sm={12} md={4}>
          {search ? <Grid container>
<GridItem lg={10}>
  <TextField
            value={term}
            onChange={(e) => setTerm(e.target.value)}
            label="Search"
            id="search"
            margin="dense"
            type="text"
            fullWidth
          />
          </GridItem>
          <GridItem lg={2}>
           <Fab onClick={searchKeyword} className={classes.searchbutton} size="medium" aria-label="search">
        <SearchIcon />
      </Fab></GridItem>
      </Grid> : null}
      {(invoice||logs) ? <Grid container>
<GridItem lg={10}>
  <TextField
            value={term}
            onChange={(e) => setTerm(e.target.value)}
            label={invoice ? "Search Invoice No." : "Search logs..."}
            id="search"
            margin="dense"
            type="text"
            fullWidth
          />
          </GridItem>
          <GridItem lg={2}>
           <Fab onClick={searchKeyword} className={classes.searchbutton} size="medium" aria-label="search">
        <SearchIcon />
      </Fab></GridItem>
      </Grid> : null}

        </GridItem>
      </Grid>
      {
        logs &&<>
        <Grid container>
        <GridItem xs={12} sm={12} md={4}>
        </GridItem>
        <GridItem style={{display:'flex',justifyContent:'center'}} xs={12} sm={12} md={4}>
        <p style={{fontWeight:'bold',textDecoration:'underline'}}>Categories</p>
        </GridItem>
        <GridItem xs={12} sm={12} md={4}>
        </GridItem>
        </Grid>
      <Grid container>

        <GridItem xs={12} sm={12} md={4}>
        </GridItem>
        <GridItem style={{display:'flex',justifyContent:'space-between'}} xs={12} sm={12} md={4}>
        <p style={{borderLeft:'15px solid #02960c',fontWeight:'bold',paddingLeft:'5px'}}>Creation</p>
         <p style={{borderLeft:'15px solid #fa2200',fontWeight:'bold',paddingLeft:'5px'}}>Deletion</p> 
         <p style={{borderLeft:'15px solid #f2a11f',fontWeight:'bold',paddingLeft:'5px'}}>Update</p> 
         <p style={{borderLeft:'15px solid grey',fontWeight:'bold',paddingLeft:'5px'}}>Other</p>
        </GridItem>
        <GridItem xs={12} sm={12} md={4}>
        </GridItem>
      </Grid>
      
      </>
      }
      {
        sortinglabel &&<>
      <Grid container>
        <GridItem style={{display:'flex',justifyContent:'space-between'}} xs={12} sm={12} md={4}>
        <p style={{borderLeft:'5px solid #02960c',fontWeight:'bold',paddingLeft:'5px'}}>Mark for Primary Sorting Facility</p>
        </GridItem>
        <GridItem xs={12} sm={12} md={4}>
        </GridItem>
        <GridItem xs={12} sm={12} md={4}>
        </GridItem>
      </Grid>
      
      </>
      }
      <div className={classes.tableResponsive}>
        <Table className={classes.table}>
          {tableHead !== undefined ? (
            <TableHead className={classes["black" + "TableHeader"]}>
              <TableRow className={classes.tableHeadRow}>
                {tableHead.map((prop, key) => {
                  return (
                    <TableCell
                      className={classes.tableCell + " " + classes.tableHeadCell}
                      key={key}
                    >
                      {prop}
                    </TableCell>
                  );
                })}
              </TableRow>
            </TableHead>
          ) : null}
          <TableBody>
            {tableData}
          </TableBody>
        </Table>
        
      </div>
    </>
  );
}

CustomTable.defaultProps = {
  tableHeaderColor: "gray",
  invoice: false,
  sortinglabel: false,
  intl: false,
  logs: false,
  category: false,
  search: true,
  term: '',
  searchKeyword: null,
  showrating: false,
  avgrating:1
};

CustomTable.propTypes = {
  tableHeaderColor: PropTypes.oneOf([
    "warning",
    "primary",
    "danger",
    "success",
    "info",
    "rose",
    "gray",
  ]),
  tableHead: PropTypes.arrayOf(PropTypes.string),
  tableData: PropTypes.array,
  invoice: PropTypes.bool,
  intl: PropTypes.bool,
  logs: PropTypes.bool,
  showrating: PropTypes.bool,
  avgrating: PropTypes.number,
  category: PropTypes.bool,
  sortinglabel: PropTypes.bool,
  search: PropTypes.bool,
  term: PropTypes.string,
  searchKeyword: PropTypes.func,
  status: PropTypes.string,
  selectedDate: PropTypes.any,
  location_type: PropTypes.string,
  searchstatusKeyword: PropTypes.func,
  searchdateKeyword: PropTypes.func,
  searchcategoryKeyword: PropTypes.func
};
