/* eslint-disable */
import React from "react";
import PropTypes from "prop-types";
import CircularProgress from "@material-ui/core/CircularProgress";
import Backdrop from "@material-ui/core/Backdrop";

const Logout = (props) => {
  React.useEffect(() => {
    (async () => {

      let response = await fetch(props.url + "/logout", {
        credentials: "include",
        method: "POST",
        headers: {
          "Content-type": "application/json",
        },
      });
      let data = await response.json();
      window.location.replace(props.exit);
    })();
  }, []);
  return (
    <Backdrop style={{ zIndex: 5, color: "#fff" }} open>
      <CircularProgress color="inherit" />
    </Backdrop>
  );
};

Logout.propTypes = {
  url: PropTypes.string,
  exit: PropTypes.string,
};

export default Logout;
