/* eslint-disable */
import React, { useState, useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";
import Backdrop from "@material-ui/core/Backdrop";
import { toast } from "react-toastify";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSpinner } from "@fortawesome/free-solid-svg-icons";
import "react-toastify/dist/ReactToastify.css";
import GridItem from "components/Grid/GridItem.js";
import TextField from "@material-ui/core/TextField";
import CircularProgress from "@material-ui/core/CircularProgress";
import GridContainer from "components/Grid/GridContainer.js";
import Button from "components/CustomButtons/Button.js";
import Card from "components/Card/Card.js";
import CardHeader from "components/Card/CardHeader.js";
import CardBody from "components/Card/CardBody.js";
import CardFooter from "components/Card/CardFooter.js";

const styles = {
  backdrop: {
    zIndex: 5,
    color: "#fff",
  },
  cardCategoryWhite: {
    color: "rgba(255,255,255,.62)",
    margin: "0",
    fontSize: "14px",
    marginTop: "0",
    marginBottom: "0",
  },
  cardTitleWhite: {
    color: "#FFFFFF",
    marginTop: "0px",
    minHeight: "auto",
    fontWeight: "300",
    fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif",
    marginBottom: "3px",
    textDecoration: "none",
  },
};

const useStyles = makeStyles(styles);

export default function UserProfile(props) {
  toast.configure();
  const [userData, setUserData] = useState({
    firstname: "",
    lastname: "",
    email: "",
    phonenumber: "",
    password: "",
    confirm: "",
  });
  const [loading, setLoading] = useState(true);
  const [addloading, setAddloading] = useState(false);
  const [errors, seterrors] = useState([]);

  const getAdmin = async () => {
    let response = await fetch(props.url + "/get-profile", {
      credentials: "include",
    });
    let data = await response.json();
    if (data.success) {
      setUserData({
        firstname: data.data.first_name,
        lastname: data.data.last_name,
        email: data.data.email,
        phonenumber: data.data.phone_number,
        password: "",
        confirm: "",
      });
      setLoading(false);
    }
    else {
      if( data.message == 'unauthenticated'){
        window.location.replace(exit);
        return;
      }
    }
  };
  useEffect(() => {
    getAdmin();
  }, []);

  const handleChange = (event) => {
    setUserData({ ...userData, [event.target.id]: event.target.value });
  };

  const updatevalidate = () => {
    let temp = [];
    temp.firstname = userData.firstname ? "" : "This field is required";
    temp.lastname = userData.lastname ? "" : "This field is required";
    if (!userData.email) {
      temp.email = "This field is required";
    } else if (!/\S+@\S+\.\S+/.test(userData.email)) {
      temp.email = "Email address is invalid";
    }
    if (!userData.phonenumber) {
      temp.phonenumber = "This field is required";
    } else if (userData.phonenumber.length < 7) {
      temp.phonenumber = "Phone number should be at least 7 digits";
    }
    temp.password = userData.password ? "" : "This field is required";
    if (!userData.confirm) {
      temp.confirm = "This field is required";
    } else if (userData.confirm !== userData.password) {
      temp.confirm = "Passwords dont match";
    }
    seterrors({ ...temp });
    return Object.values(temp).every((x) => x == "");
  };

  const updateProfile = async (event) => {
    event.preventDefault();
    if (updatevalidate()) {
      setAddloading(true);
      let response = await fetch(props.url + `/update-profile`, {
        method: "PUT",
        headers: {
          "Content-type": "application/json",
        },
        body: JSON.stringify(userData),
        credentials: "include",
      });
      let res = await response.json();
      if (res) {
        setAddloading(false);
        if (res.success) {
          setUserData({
            firstname: res.data.first_name,
            lastname: res.data.last_name,
            email: res.data.email,
            phonenumber: res.data.phone_number,
            password: "",
            confirm: "",
          });
          props.updateusername(res.data.first_name + " " + res.data.last_name);
          toast.success("Account Updated");
        } else {
          if( res.message == 'unauthenticated'){
            window.location.replace(props.exit);
            return;
          }
          else if (res.message.email[0]) {
            seterrors({ ...errors, email: res.message.email[0] });
          } else {
            toast.error(res.message);
          }
        }
      }
    }
  };

  const classes = useStyles();
  return (
    <div>
      {loading && (
        <Backdrop className={classes.backdrop} open>
          <CircularProgress color="inherit" />
        </Backdrop>
      )}
      <GridContainer>
        <GridItem xs={12} sm={12} md={12}>
          <Card>
            <CardHeader color="danger">
              <h4 className={classes.cardTitleWhite}>My Profile</h4>
              <p className={classes.cardCategoryWhite}>Update your profile</p>
            </CardHeader>
            <CardBody>
              <form onSubmit={updateProfile}>
                <GridContainer>
                  <GridItem xs={12} sm={12} md={6}>
                    <TextField
                      onChange={handleChange}
                      InputLabelProps={{
                        shrink: true,
                      }}
                      id="firstname"
                      value={userData.firstname}
                      label="First Name"
                      style={{ marginTop: 25 }}
                      fullWidth
                      margin="normal"
                      error={errors.firstname}
                      helperText={errors.firstname && errors.firstname}
                    />
                  </GridItem>
                  <GridItem xs={12} sm={12} md={6}>
                    <TextField
                      onChange={handleChange}
                      InputLabelProps={{
                        shrink: true,
                      }}
                      id="lastname"
                      value={userData.lastname}
                      label="Last Name"
                      style={{ marginTop: 25 }}
                      fullWidth
                      margin="normal"
                      error={errors.lastname}
                      helperText={errors.lastname && errors.lastname}
                    />
                  </GridItem>
                </GridContainer>

                <GridContainer>
                  <GridItem xs={12} sm={12} md={12}>
                    <TextField
                      onChange={handleChange}
                      InputLabelProps={{
                        shrink: true,
                      }}
                      id="email"
                      value={userData.email}
                      label="Email"
                      style={{ marginTop: 25 }}
                      fullWidth
                      type="email"
                      margin="normal"
                      error={errors.email}
                      helperText={errors.email && errors.email}
                    />
                  </GridItem>
                </GridContainer>
                <GridContainer>
                  <GridItem xs={12} sm={12} md={12}>
                    <TextField
                      onChange={handleChange}
                      InputLabelProps={{
                        shrink: true,
                      }}
                      id="phonenumber"
                      value={userData.phonenumber}
                      label="Phone Number"
                      style={{ marginTop: 25 }}
                      fullWidth
                      type="number"
                      margin="normal"
                      error={errors.phonenumber}
                      helperText={errors.phonenumber && errors.phonenumber}
                    />
                  </GridItem>
                </GridContainer>
                <GridContainer>
                  <GridItem xs={12} sm={12} md={6}>
                    <TextField
                      onChange={handleChange}
                      value={userData.password}
                      InputLabelProps={{
                        shrink: true,
                      }}
                      label="Password"
                      type="password"
                      placeholder="Enter password to confirm"
                      style={{ marginTop: 25 }}
                      id="password"
                      fullWidth
                      margin="normal"
                      error={errors.password}
                      helperText={errors.password && errors.password}
                    />
                  </GridItem>
                  <GridItem xs={12} sm={12} md={6}>
                    <TextField
                      onChange={handleChange}
                      value={userData.confirm}
                      InputLabelProps={{
                        shrink: true,
                      }}
                      placeholder="Re-enter Password"
                      type="password"
                      label="Confirm Password"
                      style={{ marginTop: 25 }}
                      id="confirm"
                      fullWidth
                      margin="normal"
                      error={errors.confirm}
                      helperText={errors.confirm && errors.confirm}
                    />
                  </GridItem>
                </GridContainer>
              </form>
            </CardBody>
            <CardFooter>
              <Button onClick={updateProfile} color="danger">
                {addloading ? (
                  <FontAwesomeIcon
                    style={{ fontSize: "23px" }}
                    icon={faSpinner}
                    pulse
                  />
                ) : (
                  "Update Profile"
                )}
              </Button>
            </CardFooter>
          </Card>
        </GridItem>
      </GridContainer>
    </div>
  );
}
