import React, { useState, useEffect } from "react";
import Backdrop from "@material-ui/core/Backdrop";
import { toast } from "react-toastify";
import Close from "@material-ui/icons/Close";
import LocationOnIcon from "@material-ui/icons/LocationOn";
import Arrow from "@material-ui/icons/DoubleArrow";
import PropTypes from "prop-types";
import FlightIcon from "@material-ui/icons/Flight";
import Typography from "@material-ui/core/Typography";
import Box from "@material-ui/core/Box";
import TablePagination from '@material-ui/core/TablePagination';
import "react-toastify/dist/ReactToastify.css";
import { makeStyles } from "@material-ui/core/styles";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import AppBar from "@material-ui/core/AppBar";
import { Link } from "react-router-dom";
import Card from "components/Card/Card.js";
import CircularProgress from "@material-ui/core/CircularProgress";
import Table from "components/Table/Table.js";
import CardHeader from "components/Card/CardHeader.js";
import CardBody from "components/Card/CardBody.js";
import Add from "@material-ui/icons/Add";
import SyncAltIcon from "@material-ui/icons/SyncAlt";
import Edit from "@material-ui/icons/Edit";
import Details from "@material-ui/icons/Visibility";
import Delete from "@material-ui/icons/DeleteOutline";
import Button from "components/CustomButtons/Button.js";
import TextField from "@material-ui/core/TextField";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import TableRow from "@material-ui/core/TableRow";
import TableCell from "@material-ui/core/TableCell";
import Slide from "@material-ui/core/Slide";

const styles = {
  typo: {
    paddingLeft: "25%",
    marginBottom: "40px",
    position: "relative",
  },
  scrollPaper: {
    alignItems: "baseline", // default center
  },
  tableCell: {
    textAlign: "center",
  },
  note: {
    fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
    bottom: "10px",
    color: "#c0c1c2",
    display: "block",
    fontWeight: "400",
    fontSize: "13px",
    lineHeight: "13px",
    left: "0",
    marginLeft: "20px",
    position: "absolute",
    width: "260px",
  },
  cardCategoryWhite: {
    color: "rgba(255,255,255,.62)",
    margin: "0",
    fontSize: "14px",
    marginTop: "0",
    marginBottom: "0",
  },
  cardTitleWhite: {
    color: "#FFFFFF",
    marginTop: "0px",
    minHeight: "auto",
    fontWeight: "300",
    fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif",
    marginBottom: "3px",
    textDecoration: "none",
  },
  backdrop: {
    zIndex: 5,
    color: "#fff",
  },
};

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`scrollable-force-tabpanel-${index}`}
      aria-labelledby={`scrollable-force-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box p={3}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};

function a11yProps(index) {
  return {
    id: `scrollable-force-tab-${index}`,
    "aria-controls": `scrollable-force-tabpanel-${index}`,
  };
}

const useStyles = makeStyles(styles);
const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="down" ref={ref} {...props} />;
});

export default function Zones(props) {
  toast.configure();
  const classes = useStyles();
  const [open, setOpen] = useState(false);
  const [editopen, setEditopen] = useState(false);
  const [editid, setEditid] = useState(false);
  const [loading, setLoading] = useState(true);
  const [addloading, setAddloading] = useState(false);
  const [localzones, setLocalzones] = useState([]);
  const [intlzones, setIntlzones] = useState([]);
  const [errors, seterrors] = useState([]);
  const [zone, setZone] = useState({
    name: "",
  });
  const [value, setValue] = useState(0);
  const [searchTerm1, setSearchTerm1] = useState("");
  const [searchTerm2, setSearchTerm2] = useState("");
  const [deleteid, setDeleteid] = useState(0);
  const [zonetype, setZonetype] = useState("");
  const [openconfirm, setOpenconfirm] = useState(false);
  const [page1, setPage1] = React.useState(0);
  const [totalcount1, setTotalcount1] = React.useState(0);
  const [rowsPerPage1, setRowsPerPage1] = React.useState(10);
  const [page2, setPage2] = React.useState(0);
  const [totalcount2, setTotalcount2] = React.useState(0);
  const [rowsPerPage2, setRowsPerPage2] = React.useState(10);

  const handleChangePage1 = (event, newPage) => {
    setLoading(true)
    setPage1(newPage);
  };

  const handleChangeRowsPerPage1 = (event) => {
    setRowsPerPage1(parseInt(event.target.value, 10));
    setPage1(0);
    setLoading(true)
  };

  const handleChangePage2 = (event, newPage) => {
    setLoading(true)
    setPage2(newPage);
  };

  const handleChangeRowsPerPage2 = (event) => {
    setRowsPerPage2(parseInt(event.target.value, 10));
    setPage2(0);
    setLoading(true)
  };

  const handletabChange = (event, newValue) => {
    setValue(newValue);
  };

  const handleconfirmClose = () => {
    setDeleteid(0);
    setZonetype("");
    setOpenconfirm(false);
  };

  const validate = () => {
    let temp = [];
    temp.zone = zone.name ? "" : "This field is required";
    seterrors({ ...temp });
    return Object.values(temp).every((x) => x == "");
  };

  const getLocalZones = async (paged=0) => {
    if(paged){
      setPage1(paged-1)
    }
    let response = await fetch(props.url + `/get-local-zones?${searchTerm1 && 'search='+searchTerm1+'&'}page=${paged?paged-1:page1+1}&perpage=${rowsPerPage1}`, { credentials: "include" });
    let res = await response.json();

    if (res) {
      if (res.success) {
        setLocalzones(res.data.data);
        setPage1(res.data.current_page - 1)
      setTotalcount1(res.data.total)
      } else {
        if( res.message == 'unauthenticated'){
          window.location.replace(props.exit);
          return;
        }
        toast.error(res.message);
      }
    }
    setLoading(false);
  };

  const getInternationalZones = async (paged=0) => {
    if(paged){
      setPage2(paged-1)
    }
    let response = await fetch(props.url + `/get-international-zones?${searchTerm2 && 'search='+searchTerm2+'&'}page=${paged?paged-1:page2+1}&perpage=${rowsPerPage2}`, { credentials: "include" });
    let res = await response.json();

    if (res) {
      if (res.success) {
        setIntlzones(res.data.data);
        setPage2(res.data.current_page - 1)
      setTotalcount2(res.data.total)
      } else {
        if( res.message == 'unauthenticated'){
          window.location.replace(props.exit);
          return;
        }
        toast.error(res.message);
      }
    }
    setLoading(false);
  };
  useEffect(() => {
    getLocalZones();
    getInternationalZones();
  }, [page1,rowsPerPage1,page2,rowsPerPage2]);

  const handleChange = (event) => {
    setZone({ ...zone, [event.target.id]: event.target.value });
  };

  const confirmdeleteZone = (id, zonetype) => {
    setDeleteid(id);
    setZonetype(zonetype);
    setOpenconfirm(true);
  };

  const deleteZone = async () => {
    setLoading(true);
    const cid = deleteid;
    let response = await fetch(props.url + "/delete-zone", {
      method: "DELETE",
      headers: {
        "Content-type": "application/json",
      },
      body: JSON.stringify({ id: deleteid }),
      credentials: "include",
    });
    let res = await response.json();
    if (res) {
      setDeleteid(0);
      setOpenconfirm(false);
      setLoading(false);
      if (res.success) {
        if (zonetype == "local") {
          setLocalzones(localzones.filter((current) => cid !== current.id));
        } else {
          setIntlzones(intlzones.filter((current) => cid !== current.id));
        }
        toast.success("Zone deleted");
      } else {
        if( res.message == 'unauthenticated'){
          window.location.replace(props.exit);
          return;
        }
        toast.error(res.message);
      }
    }
  };
  const handleClickOpen = async (id, zonetype) => {
    let response = await fetch(props.url + `/get-zone/${id}`, {
      credentials: "include",
    });
    let res = await response.json();
    if (res) {
      if (res.success) {
        setZone({
          name: res.data.zone_name,
        });
        setEditid(res.data.id);
        setEditopen(true);
        setZonetype(zonetype);
      } else {
        if( res.message == 'unauthenticated'){
          window.location.replace(props.exit);
          return;
        }
        toast.error(res.message);
      }
    }
  };

  const handleOpen = (zonetype) => {
    setZonetype(zonetype);
    setOpen(true);
  };
  const handleClose = () => {
    setZone({
      name: "",
    });
    setZonetype("");
    seterrors([]);
    setOpen(false);
    setAddloading(false);
  };
  const edithandleClose = () => {
    setZone({
      name: "",
    });
    setZonetype("");
    setAddloading(false);
    setEditopen(false);
    setAddloading(false);
  };
  const edithandleupdateClose = async (event) => {
    event.preventDefault();
    if (validate()) {
      setAddloading(true);
      let response = await fetch(props.url + `/update-zone/${editid}`, {
        method: "PUT",
        headers: {
          "Content-type": "application/json",
        },
        body: JSON.stringify(zone),
        credentials: "include",
      });
      let res = await response.json();
      if (res) {
        if (res.success) {
          if (zonetype == "local") {
            setLocalzones(
              localzones.map((prop) =>
                prop.id === editid ? { ...res.data } : prop
              )
            );
          } else {
            setIntlzones(
              intlzones.map((prop) =>
                prop.id === editid ? { ...res.data } : prop
              )
            );
          }
          edithandleClose();
          toast.success("Zone Updated");
        } else {
          if( res.message == 'unauthenticated'){
            window.location.replace(props.exit);
            return;
          }
          toast.error(res.message);
        }
      }
    }
  };
  const searchHandler1 = () => {
    getLocalZones(1)
  };

  const searchHandler2 = async () => {
    setPage2(0);
    getInternationalZones()
  };
  const handleaddClose = async (event) => {
    event.preventDefault();
    if (validate()) {
      setAddloading(true);
      let response = await fetch(props.url + "/create-zone", {
        method: "POST",
        headers: {
          "Content-type": "application/json",
        },
        body: JSON.stringify(
          zonetype == "local"
            ? { ...zone, boundary: "Local" }
            : { ...zone, boundary: "International" }
        ),
        credentials: "include",
      });
      let res = await response.json();
      if (res) {
        if (res.success) {
          if (zonetype == "local") {
            setLocalzones([...localzones, { ...res.data, locations_count: 0 }]);
          } else {
            setIntlzones([...intlzones, { ...res.data, locations_count: 0 }]);
          }
          toast.success("Zone added");
        } else {
          if( res.message == 'unauthenticated'){
            window.location.replace(props.exit);
            return;
          }
          toast.error(res.message);
        }
      }
      handleClose();
    }
  };
  return (
    <Card>
      {loading && (
        <Backdrop className={classes.backdrop} open>
          <CircularProgress color="inherit" />
        </Backdrop>
      )}
      <CardHeader color="danger">
        <h4 className={classes.cardTitleWhite}>Zones</h4>
      </CardHeader>
      <CardBody>
        <div className={classes.root}>
          <AppBar position="static" color="default">
            <Tabs
              value={value}
              onChange={handletabChange}
              indicatorColor="secondary"
              textColor="secondary"
              variant="fullWidth"
              scrollButtons="on"
              aria-label="icon label tabs example"
            >
              <Tab label="Local" icon={<LocationOnIcon />} {...a11yProps(0)} />
              <Tab
                label="International"
                icon={<FlightIcon />}
                {...a11yProps(1)}
              />
            </Tabs>
          </AppBar>
          <TabPanel value={value} index={0}>
            <Button onClick={() => handleOpen("local")} color="danger">
              <Add /> Add Local Zone
            </Button>

            <Table
              searchKeyword={searchHandler1}
              term={searchTerm1}
              setTerm={setSearchTerm1}
              tableHeaderColor="gray"
              tableHead={[
                "Name",
                "Number of Locations",
                "Rates",
                "Locations",
                "Edit Zone",
                "Delete",
              ]}
              tableData={
                localzones.length == 0 ? (
                  <TableRow>
                    <TableCell
                      style={{ textAlign: "center" }}
                      colSpan={6}
                      className={classes.tableCell}
                    >
                      No Local Zones
                    </TableCell>
                  </TableRow>
                ) : (
                  localzones.map((prop) => {
                    return (
                      <TableRow key={prop.id} className={classes.tableBodyRow}>
                        <TableCell className={classes.tableCell} key={prop.id}>
                          {prop.zone_name}
                        </TableCell>
                        <TableCell className={classes.tableCell} key={prop.id}>
                          {prop.locations_count}
                        </TableCell>
                        <TableCell className={classes.tableCell} key={prop.id}>
                          <Link
                            style={{ color: "black" }}
                            to={`/admin/pricing/${prop.id}`}
                          >
                            <SyncAltIcon />
                          </Link>
                        </TableCell>
                        <TableCell className={classes.tableCell} key={prop.id}>
                          <Link to={`/admin/locations/${prop.id}`}>
                            <Details
                              style={{ color: "black", cursor: "pointer" }}
                              key={prop.id}
                            />
                          </Link>
                        </TableCell>
                        <TableCell className={classes.tableCell} key={prop.id}>
                          <Edit
                            onClick={() => handleClickOpen(prop.id, "local")}
                            style={{ color: "black", cursor: "pointer" }}
                            key={prop.id}
                          />
                        </TableCell>
                        <TableCell className={classes.tableCell} key={prop.id}>
                          <Delete
                            style={{ cursor: "pointer" }}
                            onClick={() => confirmdeleteZone(prop.id, "local")}
                            key={prop.id}
                          />
                        </TableCell>
                      </TableRow>
                    );
                  })
                )
              }
            />
            <TablePagination
      component="div"
      count={totalcount1}
      page={page1}
      onPageChange={handleChangePage1}
      rowsPerPage={rowsPerPage1}
      onRowsPerPageChange={handleChangeRowsPerPage1}
    />
          </TabPanel>
          <TabPanel value={value} index={1}>
            <Button onClick={() => handleOpen("intl")} color="danger">
              <Add /> Add International Zone
            </Button>

            <Table
              searchKeyword={searchHandler2}
              term={searchTerm2}
              setTerm={setSearchTerm2}
              tableHeaderColor="gray"
              tableHead={[
                "Name",
                "Number of Locations",
                "Items Shipping Rates",
                "Locations",
                "Edit Zone",
                "Delete",
              ]}
              tableData={
                intlzones.length == 0 ? (
                  <TableRow>
                    <TableCell
                      style={{ textAlign: "center" }}
                      colSpan={6}
                      className={classes.tableCell}
                    >
                      No International Zones
                    </TableCell>
                  </TableRow>
                ) : (
                  intlzones.map((prop) => {
                    return (
                      <TableRow key={prop.id} className={classes.tableBodyRow}>
                        <TableCell className={classes.tableCell} key={prop.id}>
                          {prop.zone_name}
                        </TableCell>
                        <TableCell className={classes.tableCell} key={prop.id}>
                          {prop.locations_count}
                        </TableCell>
                        <TableCell className={classes.tableCell} key={prop.id}>
                          <Link
                            style={{ color: "black" }}
                            to={`/admin/shipping/${prop.id}`}
                          >
                            <SyncAltIcon />
                          </Link>
                        </TableCell>
                        <TableCell className={classes.tableCell} key={prop.id}>
                          <Link to={`/admin/locations/${prop.id}`}>
                            <Details
                              style={{ color: "black", cursor: "pointer" }}
                              key={prop.id}
                            />
                          </Link>
                        </TableCell>
                        <TableCell className={classes.tableCell} key={prop.id}>
                          <Edit
                            onClick={() => handleClickOpen(prop.id, "intl")}
                            style={{ color: "black", cursor: "pointer" }}
                            key={prop.id}
                          />
                        </TableCell>
                        <TableCell className={classes.tableCell} key={prop.id}>
                          <Delete
                            style={{ cursor: "pointer" }}
                            onClick={() => confirmdeleteZone(prop.id, "intl")}
                            key={prop.id}
                          />
                        </TableCell>
                      </TableRow>
                    );
                  })
                )
              }
            />
            <TablePagination
      component="div"
      count={totalcount2}
      page={page2}
      onPageChange={handleChangePage2}
      rowsPerPage={rowsPerPage2}
      onRowsPerPageChange={handleChangeRowsPerPage2}
    />
          </TabPanel>
        </div>
      </CardBody>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="form-dialog-title"
      >
        <DialogTitle id="form-dialog-title">
          {!addloading ? "Create Zone" : "Creating Zone"}
        </DialogTitle>
        {addloading ? (
          <DialogContentText style={{ textAlign: "center" }}>
            <CircularProgress color="secondary" />
          </DialogContentText>
        ) : (
          <DialogContent>
            <DialogContentText>
              Enter the name of the zone and click on create. You can then add
              and update locations to the zone by visiting the edit page of the
              zone
            </DialogContentText>
            <form onSubmit={handleaddClose}>
              <TextField
                onChange={handleChange}
                value={zone.name}
                autoFocus
                margin="dense"
                id="name"
                label="Zone Name"
                type="text"
                fullWidth
                error={errors.zone}
                helperText={errors.zone && errors.zone}
              />
            </form>
          </DialogContent>
        )}
        {!addloading && (
          <DialogActions>
            <Button
              style={{ display: "block", marginLeft: "0", marginRight: "auto" }}
              onClick={handleClose}
              color="danger"
            >
              <Close />
              Cancel
            </Button>
            <Button
              style={{ float: "right" }}
              color="danger"
              onClick={handleaddClose}
            >
              Create <Arrow />
            </Button>
          </DialogActions>
        )}
      </Dialog>
      <Dialog
        open={editopen}
        onClose={edithandleClose}
        aria-labelledby="form-dialog-title"
      >
        <DialogTitle id="form-dialog-title">
          {!addloading ? "Update Zone" : "Updating Zone"}
        </DialogTitle>
        {addloading ? (
          <DialogContentText style={{ textAlign: "center" }}>
            <CircularProgress color="secondary" />
          </DialogContentText>
        ) : (
          <DialogContent>
            <DialogContentText>
              Update the name of the zone and click on create. You can then add
              and update locations to the zone by visiting the edit page of the
              zone
            </DialogContentText>
            <form onSubmit={(e) => edithandleupdateClose(e)}>
              <TextField
                onChange={handleChange}
                autoFocus
                margin="dense"
                id="name"
                value={zone.name}
                label="Zone Name"
                type="text"
                fullWidth
                error={errors.zone}
                helperText={errors.zone && errors.zone}
              />
            </form>
          </DialogContent>
        )}
        {!addloading && (
          <DialogActions>
            <Button
              style={{ display: "block", marginLeft: "0", marginRight: "auto" }}
              onClick={edithandleClose}
              color="danger"
            >
              <Close />
              Cancel
            </Button>
            <Button
              style={{ float: "right" }}
              color="danger"
              onClick={(e) => edithandleupdateClose(e)}
            >
              Update <Arrow />
            </Button>
          </DialogActions>
        )}
      </Dialog>
      <Dialog
        fullWidth={true}
        maxWidth="sm"
        open={openconfirm}
        TransitionComponent={Transition}
        keepMounted
        classes={{ scrollPaper: classes.scrollPaper }}
        onClose={handleconfirmClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">{"DELETE ZONE"}</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            Are you sure you want to delete this zone?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button
            style={{ display: "block", marginLeft: "0", marginRight: "auto" }}
            onClick={handleconfirmClose}
            color="danger"
          >
            <Close />
            Cancel
          </Button>
          <Button
            style={{ float: "right" }}
            color="danger"
            onClick={deleteZone}
          >
            Proceed <Arrow />
          </Button>
        </DialogActions>
      </Dialog>
    </Card>
  );
}

Zones.propTypes = {
  url: PropTypes.string,
  exit: PropTypes.string,
};
