import React, { useState, useEffect } from "react";
import Backdrop from "@material-ui/core/Backdrop";
import { toast } from "react-toastify";
import Danger from "components/Typography/Danger.js";
import "react-toastify/dist/ReactToastify.css";
import Close from "@material-ui/icons/Close";
import Arrow from "@material-ui/icons/DoubleArrow";
import { makeStyles } from "@material-ui/core/styles";
import InputLabel from "@material-ui/core/InputLabel";
import FormControl from "@material-ui/core/FormControl";
import Select from "@material-ui/core/Select";
import TablePagination from '@material-ui/core/TablePagination';
import Switch from '@material-ui/core/Switch';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import { Link } from "react-router-dom";
import Card from "components/Card/Card.js";
import CircularProgress from "@material-ui/core/CircularProgress";
import Table from "components/Table/Table.js";
import CardHeader from "components/Card/CardHeader.js";
import CardBody from "components/Card/CardBody.js";
import Add from "@material-ui/icons/Add";
import Edit from "@material-ui/icons/Edit";
import MenuItem from "@material-ui/core/MenuItem";
import Details from "@material-ui/icons/Visibility";
import Delete from "@material-ui/icons/DeleteOutline";
import Button from "components/CustomButtons/Button.js";
import TextField from "@material-ui/core/TextField";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import TableRow from "@material-ui/core/TableRow";
import TableCell from "@material-ui/core/TableCell";
import LocationOnIcon from "@material-ui/icons/LocationOn";
import PropTypes from "prop-types";
import FlightIcon from "@material-ui/icons/Flight";
import Typography from "@material-ui/core/Typography";
import Box from "@material-ui/core/Box";
import Slide from "@material-ui/core/Slide";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import AppBar from "@material-ui/core/AppBar";

const styles = {
  typo: {
    paddingLeft: "25%",
    marginBottom: "40px",
    position: "relative",
  },
  tableCell: {
    textAlign: "center",
  },
  scrollPaper: {
    alignItems: "baseline", // default center
  },
  note: {
    fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
    bottom: "10px",
    color: "#c0c1c2",
    display: "block",
    fontWeight: "400",
    fontSize: "13px",
    lineHeight: "13px",
    left: "0",
    marginLeft: "20px",
    position: "absolute",
    width: "260px",
  },
  cardCategoryWhite: {
    color: "rgba(255,255,255,.62)",
    margin: "0",
    fontSize: "14px",
    marginTop: "0",
    marginBottom: "0",
  },
  cardTitleWhite: {
    color: "#FFFFFF",
    marginTop: "0px",
    minHeight: "auto",
    fontWeight: "300",
    fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif",
    marginBottom: "3px",
    textDecoration: "none",
  },
  backdrop: {
    zIndex: 5,
    color: "#fff",
  },
};

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`scrollable-force-tabpanel-${index}`}
      aria-labelledby={`scrollable-force-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box p={3}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};

function a11yProps(index) {
  return {
    id: `scrollable-force-tab-${index}`,
    "aria-controls": `scrollable-force-tabpanel-${index}`,
  };
}

const useStyles = makeStyles(styles);
const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="down" ref={ref} {...props} />;
});

export default function Sorting(props) {
  toast.configure();
  const classes = useStyles();
  const [open, setOpen] = useState(false);
  const [editopen, setEditopen] = useState(false);
  const [editid, setEditid] = useState(false);
  const [loading, setLoading] = useState(true);
  const [addloading, setAddloading] = useState(false);
  const [localfacilities, setLocalfacilities] = useState([]);
  const [intlfacilities, setIntlfacilities] = useState([]);
  const [localzones, setLocalzones] = useState([]);
  const [intlzones, setIntlzones] = useState([]);
  const [errors, seterrors] = useState([]);
  const [facility, setFacility] = useState({
    name: "",
    zone: "",
    primary: "",
  });
  const [sortingvalue, setSortingvalue] = useState(0);
  const [value, setValue] = useState(0);
  const [current_primary, setCurrent_primary] = useState(false);
  const [searchTerm1, setSearchTerm1] = useState("");
  const [searchTerm2, setSearchTerm2] = useState("");
  const [deleteid, setDeleteid] = useState(0);
  const [openconfirm, setOpenconfirm] = useState(false);
  const [page1, setPage1] = React.useState(0);
  const [totalcount1, setTotalcount1] = React.useState(0);
  const [rowsPerPage1, setRowsPerPage1] = React.useState(10);
  const [page2, setPage2] = React.useState(0);
  const [totalcount2, setTotalcount2] = React.useState(0);
  const [rowsPerPage2, setRowsPerPage2] = React.useState(10);

  const handleChangePage1 = (event, newPage) => {
    setLoading(true)
    setPage1(newPage);
  };

  const handleChangePage2 = (event, newPage) => {
    setLoading(true)
    setPage2(newPage);
  };


  const handletabChange = (event, newValue) => {
    setValue(newValue);
  };

  const handleChangeRowsPerPage1 = (event) => {
    setRowsPerPage1(parseInt(event.target.value, 10));
    setPage1(0);
    setLoading(true)
  };

  const handleChangeRowsPerPage2 = (event) => {
    setRowsPerPage2(parseInt(event.target.value, 10));
    setPage2(0);
    setLoading(true)
  };

  const handleconfirmClose = () => {
    setDeleteid(0);
    setOpenconfirm(false);
  };

  const validate = () => {
    let temp = [];
    temp.name = facility.name ? "" : "This field is required";
    temp.zone = facility.zone ? "" : "This field is required";
    seterrors({ ...temp });
    return Object.values(temp).every((x) => x == "");
  };

  const getLocalFacilities = async () => {
    let response = await fetch(props.url + `/get-local-sorting-facilities?${searchTerm1 && 'search='+searchTerm1+'&'}page=${page1+1}&perpage=${rowsPerPage1}`, {
      credentials: "include",
    });
    let res = await response.json();
    if (res.success) {
      setLocalfacilities(res.data.data);
      setPage1(res.data.current_page - 1)
      setTotalcount1(res.data.total)
    } else {
      if( res.message == 'unauthenticated'){
        window.location.replace(props.exit);
        return;
      }
    }
    setLoading(false);
  };

  const getIntlFacilities = async () => {
    let response = await fetch(props.url + `/get-intl-sorting-facilities?${searchTerm2 && 'search='+searchTerm2+'&'}page=${page2+1}&perpage=${rowsPerPage2}`, {
      credentials: "include",
    });
    let res = await response.json();
    if (res.success) {
      setIntlfacilities(res.data.data);
      setPage2(res.data.current_page - 1)
      setTotalcount2(res.data.total)
    } else {
      if( res.message == 'unauthenticated'){
        window.location.replace(props.exit);
        return;
      }
    }
    setLoading(false);
  };

  const getData = async () => {
    let response2 = await fetch(props.url + "/get-separated-zones", { credentials: "include" });
    let res2 = await response2.json();
    if (res2.success) {
      setLocalzones(res2.data[0]);
      setIntlzones(res2.data[1]);
    } else {
      if( res2.message == 'unauthenticated'){
        window.location.replace(props.exit);
        return;
      }
    }
  };
  useEffect(() => {
    getData();
    getLocalFacilities();
    getIntlFacilities();
  }, [page1,rowsPerPage1,page2,rowsPerPage2]);

  const handleChange = (event) => {
    setFacility({ ...facility, [event.target.id]: event.target.value });
  };

  const confirmdeleteSortingFacility = (id) => {
    setDeleteid(id);
    setOpenconfirm(true);
  };

  const deleteSortingFacility = async () => {
    setLoading(true);
    const cid = deleteid;
    let response = await fetch(props.url + "/delete-sorting-facility", {
      method: "DELETE",
      headers: {
        "Content-type": "application/json",
      },
      body: JSON.stringify({ id: deleteid }),
      credentials: "include",
    });
    let res = await response.json();
    if (res) {
      setDeleteid(0);
      setOpenconfirm(false);
      setLoading(false);
      if (res.success) {
        setLocalfacilities(localfacilities.filter((current) => cid !== current.id));
        setIntlfacilities(intlfacilities.filter((current) => cid !== current.id));
        toast.success("Sorting Facility deleted");
      } else {
        if( res.message == 'unauthenticated'){
          window.location.replace(props.exit);
          return;
        }
        toast.error(res.message);
      }
    }
  };
  const handleClickOpen = async (id,sortingtype) => {
    setSortingvalue(sortingtype);
    setLoading(true);
    let response = await fetch(props.url + `/get-sorting-facility/${id}`, {
      credentials: "include",
    });
    let res = await response.json();
    if (res) {
      setLoading(false);
      if (res.success) {
        setFacility({
          name: res.data.facility_name,
          zone: res.data.zone_id,
          primary: res.data.primary,
        });
        setCurrent_primary(res.data.primary == 1 ? true : false)
        setEditid(res.data.id);
        setEditopen(true);
      } else {
        if( res.message == 'unauthenticated'){
          window.location.replace(props.exit);
          return;
        }
        toast.error(res.message);
      }
    }
  };

  const handleOpen = (sortingtype) => {
    setSortingvalue(sortingtype);
    setOpen(true);
  };
  const handleClose = () => {
    setFacility({
      name: "",
      zone: "",
      primary: "",
    });
    seterrors([]);
    setCurrent_primary(false)
    setOpen(false);
    setAddloading(false);
    setSortingvalue(0);
  };
  const edithandleClose = () => {
    setFacility({
      name: "",
      zone: "",
      primary: "",
    });
    seterrors([]);
    setAddloading(false);
    setEditopen(false);
    setAddloading(false);
    setSortingvalue(0);
  };
  const edithandleupdateClose = async (event) => {
    event.preventDefault();
    if (validate()) {
      setAddloading(true);
      let response = await fetch(props.url + `/update-sorting-facility/${editid}`, {
        method: "PUT",
        headers: {
          "Content-type": "application/json",
        },
        body: JSON.stringify(facility),
        credentials: "include",
      });
      let res = await response.json();
      if (res) {
        if (res.success) {
          if(sortingvalue == 1 ){
          setLocalfacilities(
            localfacilities.map((prop) =>
              prop.id === editid ? { ...res.data } : res.data.primary == 1 ? {...prop,primary:0} : prop
            )
          ) ;
        }
          else if(sortingvalue == 2){
          setIntlfacilities(
            intlfacilities.map((prop) =>
              prop.id === editid ? { ...res.data } : prop
            )
          );
          }
          edithandleClose();
          toast.success("Sorting Facility Updated");
        } else {
          if( res.message == 'unauthenticated'){
            window.location.replace(props.exit);
            return;
          }
          toast.error(res.message);
        }
      }
    }
  };
  const searchHandler1 = async () => {
    getLocalFacilities()
  };

  const searchHandler2 = async () => {
    setPage2(0);
    getIntlFacilities()
  };

  const handleaddClose = async (event) => {
    event.preventDefault();
    if (validate()) {
      setAddloading(true);
      let response = await fetch(props.url + "/create-sorting-facility", {
        method: "POST",
        headers: {
          "Content-type": "application/json",
        },
        body: JSON.stringify(facility),
        credentials: "include",
      });
      let res = await response.json();
      if (res) {
        if (res.success) {
          if(sortingvalue == 1){
          const temp = localfacilities.map((prop) => res.data.primary == 1 ? {...prop,primary:0} : prop)
          setLocalfacilities([...temp, { ...res.data }]);
          }
          else if(sortingvalue == 2){
          setIntlfacilities([...intlfacilities, { ...res.data }]);
          }
          handleClose();
          toast.success("Sorting Facility added");
        } else {
          if( res.message == 'unauthenticated'){
            window.location.replace(props.exit);
            return;
          }
          toast.error(res.message);
        }
      }
    }
  };
  return (
    <Card>
      {loading && (
        <Backdrop className={classes.backdrop} open>
          <CircularProgress color="inherit" />
        </Backdrop>
      )}
      <CardHeader color="danger">
        <h4 className={classes.cardTitleWhite}>Sorting Facility</h4>
      </CardHeader>
      <CardBody>
      <div className={classes.root}>
          <AppBar position="static" color="default">
            <Tabs
              value={value}
              onChange={handletabChange}
              indicatorColor="secondary"
              textColor="secondary"
              variant="fullWidth"
              scrollButtons="on"
              aria-label="icon label tabs example"
            >
              <Tab label="Local" icon={<LocationOnIcon />} {...a11yProps(0)} />
              <Tab
                label="International"
                icon={<FlightIcon />}
                {...a11yProps(1)}
              />
            </Tabs>
          </AppBar>
          <TabPanel value={value} index={0}>
        <Button onClick={() => handleOpen(1)} color="danger">
          <Add /> Add Local Sorting Facility
        </Button>

        <Table
          searchKeyword={searchHandler1}
          term={searchTerm1}
          setTerm={setSearchTerm1}
          tableHeaderColor="gray"
          tableHead={[
            "Name",
            "Zone",
            "Unassigned Deliveries",
            "Deliveries Waiting Pick-Up",
            "Deliveries",
            "Edit Sorting Facility",
            "Delete",
          ]}
          tableData={
            localfacilities.length == 0 ? (
              <TableRow>
                <TableCell
                  style={{ textAlign: "center" }}
                  colSpan={7}
                  className={classes.tableCell}
                >
                  No Local Sorting Facilities
                </TableCell>
              </TableRow>
            ) : (
              localfacilities.map((prop) => {
                return (
                  <TableRow style={{
                    borderLeft:
                    prop.primary == 1 && '10px solid #02960c'}}  key={prop.id} className={classes.tableBodyRow}>
                    <TableCell className={classes.tableCell} key={prop.id}>
                      {prop.facility_name}
                    </TableCell>
                    <TableCell className={classes.tableCell} key={prop.id}>
                      {prop.zone.zone_name}
                    </TableCell>
                    <TableCell className={classes.tableCell} key={prop.id}>
                      {prop.unassigned_deliveries_count}
                    </TableCell>
                    <TableCell className={classes.tableCell} key={prop.id}>
                      {prop.pending_pickup_deliveries_count}
                    </TableCell>
                    <TableCell className={classes.tableCell} key={prop.id}>
                      <Link
                        style={{ color: "black" }}
                        to={`/admin/sorting-facility-deliveries/${prop.id}`}
                      >
                        <Details
                          style={{ color: "black", cursor: "pointer" }}
                        />
                      </Link>
                    </TableCell>
                    <TableCell className={classes.tableCell} key={prop.id}>
                      <Edit
                        onClick={() => handleClickOpen(prop.id,1)}
                        style={{ color: "black", cursor: "pointer" }}
                        key={prop.id}
                      />
                    </TableCell>
                    <TableCell className={classes.tableCell} key={prop.id}>
                      <Delete
                        style={{ cursor: "pointer" }}
                        onClick={() => confirmdeleteSortingFacility(prop.id)}
                        key={prop.id}
                      />
                    </TableCell>
                  </TableRow>
                );
              })
            )
          }
          sortinglabel={true}
        />
      <TablePagination
      component="div"
      count={totalcount1}
      page={page1}
      onPageChange={handleChangePage1}
      rowsPerPage={rowsPerPage1}
      onRowsPerPageChange={handleChangeRowsPerPage1}
    />
     </TabPanel>
     <TabPanel value={value} index={1}>
        <Button onClick={() => handleOpen(2)} color="danger">
          <Add /> Add International Sorting Facility
        </Button>

        <Table
          searchKeyword={searchHandler2}
          term={searchTerm2}
          setTerm={setSearchTerm2}
          tableHeaderColor="gray"
          tableHead={[
            "Name",
            "Zone",
            "Unassigned Deliveries",
            "Deliveries Waiting Pick-Up",
            "Deliveries",
            "Edit Sorting Facility",
            "Delete",
          ]}
          tableData={
            intlfacilities.length == 0 ? (
              <TableRow>
                <TableCell
                  style={{ textAlign: "center" }}
                  colSpan={7}
                  className={classes.tableCell}
                >
                  No International Sorting Facilities
                </TableCell>
              </TableRow>
            ) : (
              intlfacilities.map((prop) => {
                return (
                  <TableRow style={{
                    borderLeft:
                    prop.primary == 1 && '10px solid #02960c'}}  key={prop.id} className={classes.tableBodyRow}>
                    <TableCell className={classes.tableCell} key={prop.id}>
                      {prop.facility_name}
                    </TableCell>
                    <TableCell className={classes.tableCell} key={prop.id}>
                      {prop.zone.zone_name}
                    </TableCell>
                    <TableCell className={classes.tableCell} key={prop.id}>
                      {prop.unassigned_deliveries_count}
                    </TableCell>
                    <TableCell className={classes.tableCell} key={prop.id}>
                      {prop.pending_pickup_deliveries_count}
                    </TableCell>
                    <TableCell className={classes.tableCell} key={prop.id}>
                      <Link
                        style={{ color: "black" }}
                        to={`/admin/sorting-facility-deliveries/${prop.id}`}
                      >
                        <Details
                          style={{ color: "black", cursor: "pointer" }}
                        />
                      </Link>
                    </TableCell>
                    <TableCell className={classes.tableCell} key={prop.id}>
                      <Edit
                        onClick={() => handleClickOpen(prop.id,2)}
                        style={{ color: "black", cursor: "pointer" }}
                        key={prop.id}
                      />
                    </TableCell>
                    <TableCell className={classes.tableCell} key={prop.id}>
                      <Delete
                        style={{ cursor: "pointer" }}
                        onClick={() => confirmdeleteSortingFacility(prop.id)}
                        key={prop.id}
                      />
                    </TableCell>
                  </TableRow>
                );
              })
            )
          }
        />
      <TablePagination
      component="div"
      count={totalcount2}
      page={page2}
      onPageChange={handleChangePage2}
      rowsPerPage={rowsPerPage2}
      onRowsPerPageChange={handleChangeRowsPerPage2}
    />
     </TabPanel>
          </div>
      </CardBody>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="form-dialog-title"
      >
        <DialogTitle id="form-dialog-title">
          {!addloading
            ? "Create Sorting Facility"
            : "Creating Sorting Facility"}
        </DialogTitle>
        {addloading ? (
          <DialogContentText style={{ textAlign: "center" }}>
            <CircularProgress color="secondary" />
          </DialogContentText>
        ) : (
          <DialogContent>
            <DialogContentText>
              Enter the name of the Sorting Facility, Set its Zone and click on
              create. You can then view all the packages and their statuses at
              each Sorting Facility
            </DialogContentText>
            <form onSubmit={handleaddClose}>
              <TextField
                onChange={handleChange}
                value={facility.name}
                autoFocus
                margin="dense"
                id="name"
                label="Sorting Facility Name"
                type="text"
                fullWidth
                error={errors.name}
                helperText={errors.name && errors.name}
              />
              <FormControl
                style={{ minWidth: "100%", marginTop: 20 }}
                className={classes.formControl}
              >
                <InputLabel id="demo-simple-select-label">Zone</InputLabel>
                <Select
                  value={facility.zone}
                  onChange={(e) =>
                    setFacility({ ...facility, zone: e.target.value })
                  }
                  labelId="demo-simple-select-label"
                  id="zone"
                >
                  {((sortingvalue == 1 && localzones.length == 0) || (sortingvalue == 2 && intlzones.length == 0) ) ? (
                    <MenuItem>No Zones</MenuItem>
                  ) : (
                    sortingvalue == 1 ?
                    localzones.map((prop) => {
                      return (
                        <MenuItem key={prop.id} value={prop.id}>
                          {prop.zone_name}
                        </MenuItem>
                      );
                    }) :
                    intlzones.map((prop) => {
                      return (
                        <MenuItem key={prop.id} value={prop.id}>
                          {prop.zone_name}
                        </MenuItem>
                      );
                    })
                  )}
                </Select>
              </FormControl>
              <Danger>{errors.zone}</Danger>
              {sortingvalue == 1 && <FormControl
                style={{ minWidth: "100%", marginTop: 20 }}
                className={classes.formControl}
              >
              <FormControlLabel
          value={1}
          control={<Switch onChange={(e) =>
            setFacility({ ...facility, primary: e.target.value })
          } color="secondary" />}
          label="Set as Primary Sorting Facility"
          labelPlacement="start"
        /></FormControl> }
            </form>
          </DialogContent>
        )}
        {!addloading && (
          <DialogActions>
            <Button
              style={{ display: "block", marginLeft: "0", marginRight: "auto" }}
              onClick={handleClose}
              color="danger"
            >
              <Close />
              Cancel
            </Button>
            <Button
              style={{ float: "right" }}
              color="danger"
              onClick={handleaddClose}
            >
              Create <Arrow />
            </Button>
          </DialogActions>
        )}
      </Dialog>
      <Dialog
        open={editopen}
        onClose={edithandleClose}
        aria-labelledby="form-dialog-title"
      >
        <DialogTitle id="form-dialog-title">
          {!addloading
            ? "Update Sorting Facility"
            : "Updating Sorting Facility"}
        </DialogTitle>
        {addloading ? (
          <DialogContentText style={{ textAlign: "center" }}>
            <CircularProgress color="secondary" />
          </DialogContentText>
        ) : (
          <DialogContent>
            <DialogContentText>
              Update the name of the Sorting Facility and set its Zone. You can
              then view the packages at each sorting facility and their status
            </DialogContentText>
            <form onSubmit={(e) => edithandleupdateClose(e)}>
              <TextField
                onChange={handleChange}
                value={facility.name}
                autoFocus
                margin="dense"
                id="name"
                label="Sorting Facility Name"
                type="text"
                fullWidth
                error={errors.name}
                helperText={errors.name && errors.name}
              />
              <FormControl
                style={{ minWidth: "100%", marginTop: 20 }}
                className={classes.formControl}
              >
                <InputLabel id="demo-simple-select-label">Zone</InputLabel>
                <Select
                  value={facility.zone}
                  onChange={(e) =>
                    setFacility({ ...facility, zone: e.target.value })
                  }
                  labelId="demo-simple-select-label"
                  id="zone"
                >
                  {((sortingvalue == 1 && localzones.length == 0) || (sortingvalue == 2 && intlzones.length == 0) ) ? (
                    <MenuItem>No Zones</MenuItem>
                  ) : (
                    sortingvalue == 1 ?
                    localzones.map((prop) => {
                      return (
                        <MenuItem key={prop.id} value={prop.id}>
                          {prop.zone_name}
                        </MenuItem>
                      );
                    }) :
                    intlzones.map((prop) => {
                      return (
                        <MenuItem key={prop.id} value={prop.id}>
                          {prop.zone_name}
                        </MenuItem>
                      );
                    })
                  )}
                </Select>
              </FormControl>
              <Danger>{errors.zone}</Danger>
              {(!current_primary && sortingvalue == 1) && <FormControl
                style={{ minWidth: "100%", marginTop: 20 }}
                className={classes.formControl}
              >
              <FormControlLabel
          value={1}
          control={<Switch onChange={(e) =>
            setFacility({ ...facility, primary: e.target.value })
          } color="secondary" />}
          label="Set as Primary Sorting Facility"
          labelPlacement="start"
        /></FormControl>}
            </form>
          </DialogContent>
        )}
        {!addloading && (
          <DialogActions>
            <Button
              style={{ display: "block", marginLeft: "0", marginRight: "auto" }}
              onClick={edithandleClose}
              color="danger"
            >
              <Close />
              Cancel
            </Button>
            <Button
              style={{ float: "right" }}
              color="danger"
              onClick={(e) => edithandleupdateClose(e)}
            >
              Update <Arrow />
            </Button>
          </DialogActions>
        )}
      </Dialog>
      <Dialog
        fullWidth={true}
        maxWidth="sm"
        open={openconfirm}
        TransitionComponent={Transition}
        keepMounted
        classes={{ scrollPaper: classes.scrollPaper }}
        onClose={handleconfirmClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          {"DELETE SORTING FACILITY"}
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            Are you sure you want to delete this sorting facility?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button
            style={{ display: "block", marginLeft: "0", marginRight: "auto" }}
            onClick={handleconfirmClose}
            color="danger"
          >
            <Close />
            Cancel
          </Button>
          <Button
            style={{ float: "right" }}
            color="danger"
            onClick={deleteSortingFacility}
          >
            Proceed <Arrow />
          </Button>
        </DialogActions>
      </Dialog>
    </Card>
  );
}
Sorting.propTypes = {
  url: PropTypes.string,
  exit: PropTypes.string,
};
