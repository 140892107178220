import React, { useState, useEffect } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faBoxOpen, faLink } from "@fortawesome/free-solid-svg-icons";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import { faMailBulk } from "@fortawesome/free-solid-svg-icons";
import Backdrop from "@material-ui/core/Backdrop";
import Close from "@material-ui/icons/Close";
import { Link } from "react-router-dom";
import InputAdornment from "@material-ui/core/InputAdornment";
import Arrow from "@material-ui/icons/DoubleArrow";
import { makeStyles } from "@material-ui/core/styles";
import SyncAltIcon from "@material-ui/icons/SyncAlt";
import TextField from "@material-ui/core/TextField";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import TablePagination from '@material-ui/core/TablePagination';
import PropTypes from "prop-types";
import DialogTitle from "@material-ui/core/DialogTitle";
import LocationOn from "@material-ui/icons/LocationOn";
import AddToPhotosIcon from "@material-ui/icons/AddToPhotos";
import LocalShipping from "@material-ui/icons/LocalShipping";
import AppBar from "@material-ui/core/AppBar";
import Typography from "@material-ui/core/Typography";
import Box from "@material-ui/core/Box";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Danger from "components/Typography/Danger.js";
import GridItem from "components/Grid/GridItem.js";
import GridContainer from "components/Grid/GridContainer.js";
import Table from "components/Table/Table.js";
import Edit from "@material-ui/icons/Edit";
import Delete from "@material-ui/icons/DeleteOutline";
import Card from "components/Card/Card.js";
import CardHeader from "components/Card/CardHeader.js";
import Slide from "@material-ui/core/Slide";
import Button from "components/CustomButtons/Button.js";
import CardBody from "components/Card/CardBody.js";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import Add from "@material-ui/icons/Add";
import TableRow from "@material-ui/core/TableRow";
import TableCell from "@material-ui/core/TableCell";
import CircularProgress from "@material-ui/core/CircularProgress";

const styles = {
  backdrop: {
    zIndex: 5,
    color: "#fff",
  },
  dividetab: {
    fontSize: "9px",
  },
  avatarimg: {
    display: "block",
    height: 50,
    width: 50,
    marginLeft: "auto",
    marginRight: "auto",
  },
  tableCell: {
    textAlign: "center",
  },
  rowposters: {
    display: "flex",
    flexWrap: "wrap",
  },
  rowposter: {
    objectFit: "contain",
    maxHeight: "70px",
    border: "10px solid black",
  },
  scrollPaper: {
    alignItems: "baseline", // default center
  },
  cardCategoryWhite: {
    "&,& a,& a:hover,& a:focus": {
      color: "rgba(255,255,255,.62)",
      margin: "0",
      fontSize: "14px",
      marginTop: "0",
      marginBottom: "0",
    },
    "& a,& a:hover,& a:focus": {
      color: "#FFFFFF",
    },
  },
  cardTitleWhite: {
    color: "#FFFFFF",
    marginTop: "0px",
    minHeight: "auto",
    fontWeight: "300",
    fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif",
    marginBottom: "3px",
    textDecoration: "none",
    "& small": {
      color: "#777",
      fontSize: "65%",
      fontWeight: "400",
      lineHeight: "1",
    },
  },
  cardTitleBlack: {
    color: "#000000",
    marginTop: "0px",
    minHeight: "auto",
    fontWeight: "300",
    fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif",
    marginBottom: "3px",
    textDecoration: "none",
    "& small": {
      color: "#777",
      fontSize: "65%",
      fontWeight: "400",
      lineHeight: "1",
    },
  },
};

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`scrollable-force-tabpanel-${index}`}
      aria-labelledby={`scrollable-force-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box p={3}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};

function a11yProps(index) {
  return {
    id: `scrollable-force-tab-${index}`,
    "aria-controls": `scrollable-force-tabpanel-${index}`,
  };
}
const useStyles = makeStyles(styles);
const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="down" ref={ref} {...props} />;
});

export default function Configurations(props) {
  toast.configure();
  const dividermatch = useMediaQuery("(max-width:600px)");
  const classes = useStyles();
  const [searchTerm, setSearchTerm] = useState("");
  const [loading, setLoading] = useState(true);
  const [addloading, setAddloading] = useState(false);
  const [vehicles, setVehicles] = useState([]);
  const [items, setItems] = useState([]);
  const [links, setLinks] = useState([]);
  const [services, setServices] = useState([]);
  const [packages, setPackages] = useState([]);
  const [boundries, setBoundries] = useState([]);
  const [itemdata, setItemdata] = useState({
    name: "",
    img: "",
    desc: "",
  });
  const [vehicledata, setVehicledata] = useState({
    name: "",
    img: "",
    weight: "",
    cbm: "",
  });
  const [packagedata, setPackagedata] = useState({
    name: "",
    img: "",
  });
  const [servicedata, setServicedata] = useState({
    name: "",
    img: "",
  });
  const [boundarydata, setBoundarydata] = useState({
    name: "",
    img: "",
  });
  const [editid, setEditid] = useState(0);
  const [preview, setPreview] = useState(null);
  const [addveh, setaddveh] = React.useState(false);
  const [editveh, seteditveh] = React.useState(false);
  const [additem, setadditem] = React.useState(false);
  const [edititem, setedititem] = React.useState(false);
  const [addbound, setaddbound] = React.useState(false);
  const [editbound, seteditbound] = React.useState(false);
  const [addpack, setaddpack] = React.useState(false);
  const [editpack, seteditpack] = React.useState(false);
  const [addserv, setaddserv] = React.useState(false);
  const [editserv, seteditserv] = React.useState(false);
  const [packageerrors, setpackageerrors] = useState([]);
  const [serviceerrors, setserviceerrors] = useState([]);
  const [boundaryerrors, setboundaryerrors] = useState([]);
  const [vehicleerrors, setvehicleerrors] = useState([]);
  const [itemerrors, setitemerrors] = useState([]);
  const [value, setValue] = React.useState(0);
  const [modtype, setModtype] = useState("");
  const [deleteid, setDeleteid] = useState(0);
  const [openconfirm, setOpenconfirm] = useState(false);
  const [page, setPage] = React.useState(0);
  const [totalcount, setTotalcount] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);
  const [updateLinks, setUpdateLinks] = useState(false);

  const handleChangePage = (event, newPage) => {
    setLoading(true)
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
    setLoading(true)
  };

  const handleconfirmClose = () => {
    setDeleteid(0);
    setModtype("");
    setOpenconfirm(false);
  };

  const processDeletion = () => {
    if (modtype == "vehicle") {
      deleteVehicle();
    } else if (modtype == "service") {
      deleteService();
    } else if (modtype == "package") {
      deletePackage();
    } else if (modtype == "boundary") {
      deleteBoundary();
    } else if (modtype == "item") {
      deleteItem();
    }
  };

  const handletabChange = (event, newValue) => {
    setValue(newValue);
  };

  const getItems = async (paged=0) => {
    if(paged){
      setPage(paged-1)
    }
    let response6 = await fetch(props.url + `/get-items?${searchTerm && 'search='+searchTerm+'&'}page=${paged?paged-1:page+1}&perpage=${rowsPerPage}`, { credentials: 'include' });
    let res6 = await response6.json();
    if (res6) {
      if (res6.success) {
        setItems([...res6.data.data]);
        setPage(res6.data.current_page - 1)
        setTotalcount(res6.data.total)
      } else {
        if( res6.message == 'unauthenticated'){
          window.location.replace(props.exit);
          return;
        }
        toast.error(res6.message);
      }
    }
  };


  
  const getData = async () => {
    let response1 = await fetch(props.url + "/get-boundries", {
      credentials: "include",
    });
    let res1 = await response1.json();
    let response2 = await fetch(props.url + "/get-vehicles", {
      credentials: "include",
    });
    let res2 = await response2.json();
    let response3 = await fetch(props.url + "/get-packages", {
      credentials: "include",
    });
    let res3 = await response3.json();
    let response5 = await fetch(props.url + "/get-services", {
      credentials: "include",
    });
    let res5 = await response5.json();
    
    let response7 = await fetch(props.url + `/get-links`, { 
      credentials: 'include'
     });
    let res7 = await response7.json();

    if (res1) {
      setLoading(false);
      if (res1.success) {
        setBoundries([...res1.data]);
      } else {
      if( res1.message == 'unauthenticated'){
        window.location.replace(props.exit);
        return;
      }
        toast.error(res1.message);
      }
    }
    if (res2) {
      setLoading(false);
      if (res2.success) {
        setVehicles([...res2.data]);
      } else {
        if( res2.message == 'unauthenticated'){
          window.location.replace(props.exit);
          return;
        }
        toast.error(res2.message);
      }
    }
    if (res3) {
      setLoading(false);
      if (res3.success) {
        setPackages([...res3.data]);
      } else {
        if( res3.message == 'unauthenticated'){
          window.location.replace(props.exit);
          return;
        }
        toast.error(res3.message);
      }
    }
    if (res5) {
      setLoading(false);
      if (res5.success) {
        setServices([...res5.data]);
      } else {
        if( res5.message == 'unauthenticated'){
          window.location.replace(props.exit);
          return;
        }
        toast.error(res5.message);
      }
    }

    if (res7) {
      setLoading(false);
      if (res7.success) {
        setLinks([...res7.data]);
      } else {
        if( res7.message == 'unauthenticated'){
          window.location.replace(props.exit);
          return;
        }
        toast.error(res7.message);
      }
    }

  };
  

  useEffect(() => {
    getItems()
    getData();
  }, [page,rowsPerPage]);

  const vehiclevalidate = () => {
    let temp = [];
    temp.vehiclename = vehicledata.name ? "" : "This field is required";
    temp.vehicleimg = vehicledata.img ? "" : "This field is required";
    temp.vehicleweight = vehicledata.weight ? "" : "This field is required";
    temp.vehiclecbm = vehicledata.cbm ? "" : "This field is required";
    if (vehicledata.img == "invalid") {
      temp.vehicleimg = "Please select a valid image (JPG|JPEG|PNG|GIF)";
    }
    if (vehicledata.img == "none") {
      temp.vehicleimg = "This field is required";
    }
    setvehicleerrors({ ...temp });
    return Object.values(temp).every((x) => x == "");
  };
  const vehicleChange = (event) => {
    setVehicledata({ ...vehicledata, [event.target.id]: event.target.value });
  };
  const onVehicleImageChange = (e) => {
    let files = e.target.files || e.dataTransfer.files;
    if (!files.length) {
      setVehicledata({ ...vehicledata, img: "none" });
      return;
    }
    if (!files[0].name.match(/\.(jpg|jpeg|png|gif)$/)) {
      setVehicledata({ ...vehicledata, img: "invalid" });
      return;
    }
    let reader = new FileReader();
    reader.onload = (e) => {
      setVehicledata({ ...vehicledata, img: e.target.result });
    };
    reader.readAsDataURL(files[0]);
    const objectUrl = URL.createObjectURL(files[0]);
    setPreview(objectUrl);
  };

  const handlevehuneditClose = () => {
    setVehicledata({
      name: "",
      img: "",
      weight: "",
      cbm: "",
    });
    setPreview(null);
    setvehicleerrors([]);
    setAddloading(false);
    document.getElementById("vehaddimg").value = null;
    setaddveh(false);
  };
  const edithandlevehuneditClose = () => {
    setVehicledata({
      name: "",
      img: "",
      weight: "",
      cbm: "",
    });
    setPreview(null);
    setvehicleerrors([]);
    setAddloading(false);
    document.getElementById("veheditimg").value = null;
    seteditveh(false);
  };

  const handlevehaddClose = async (e) => {
    e.preventDefault();
    if (vehiclevalidate()) {
      setAddloading(true);
      let response = await fetch(props.url + "/create-vehicle", {
        method: "POST",
        headers: {
          "Content-type": "application/json",
        },
        body: JSON.stringify(vehicledata),
        credentials: "include",
      });
      let res = await response.json();
      if (res) {
        if (res.success) {
          setVehicles([...vehicles, res.data]);
          handlevehuneditClose();
          toast.success("Vehicle added");
        } else {
          if( res.message == 'unauthenticated'){
            window.location.replace(props.exit);
            return;
          }
          toast.error(res.message);
        }
      }
    }
  };

  const handleveheditClose = async (event) => {
    event.preventDefault();
    if (vehiclevalidate()) {
      setAddloading(true);
      let response = await fetch(props.url + `/update-vehicle/${editid}`, {
        method: "PUT",
        headers: {
          "Content-type": "application/json",
        },
        body: JSON.stringify(vehicledata),
        credentials: "include",
      });
      let res = await response.json();
      if (res) {
        if (res.success) {
          setVehicles(
            vehicles.map((prop) =>
              prop.id === editid ? { ...res.data } : prop
            )
          );
          edithandlevehuneditClose();
          toast.success("Vehicle updated");
        } else {
          setAddloading(false);
          if( res.message == 'unauthenticated'){
            window.location.replace(props.exit);
            return;
          }
          toast.error(res.message);
        }
      }
    }
  };

  const handleveheditOpen = async (id) => {
    setLoading(true);
    let response = await fetch(props.url + `/get-vehicle/${id}`, {
      credentials: "include",
    });
    let res = await response.json();
    if (res) {
      if (res.success) {
        setVehicledata({
          name: res.data.vehicle_name,
          img: "valid",
          weight: res.data.max_weight,
          cbm: res.data.max_cbm,
        });
        setPreview(props.imgurl + res.data.img);
        setEditid(res.data.id);
        setLoading(false);
        seteditveh(true);
      }
      else {
        if( res.message == 'unauthenticated'){
          window.location.replace(props.exit);
          return;
        }
      }
    }
  };

  const confirmdeleteVehicle = (id) => {
    setDeleteid(id);
    setModtype("vehicle");
    setOpenconfirm(true);
  };

  const deleteVehicle = async () => {
    setLoading(true);
    const cid = deleteid;
    let response = await fetch(props.url + "/delete-vehicle", {
      method: "DELETE",
      headers: {
        "Content-type": "application/json",
      },
      body: JSON.stringify({ id: deleteid }),
      credentials: "include",
    });
    let res = await response.json();
    if (res) {
      setDeleteid(0);
      setModtype("");
      setOpenconfirm(false);
      setLoading(false);
      if (res.success) {
        setVehicles(vehicles.filter((current) => cid !== current.id));
        toast.success("Vehicle deleted");
      } else {
        if( res.message == 'unauthenticated'){
          window.location.replace(props.exit);
          return;
        }
        toast.error(res.message);
      }
    }
  };

  const searchHandler = async () => {
    getItems(1)
  };

  ////////////////////////////////////////
  const itemvalidate = () => {
    let temp = [];
    temp.itemname = itemdata.name ? "" : "This field is required";
    temp.itemimg = itemdata.img ? "" : "This field is required";
    temp.itemdesc = itemdata.desc ? "" : "This field is required";
    if (itemdata.img == "invalid") {
      temp.itemimg = "Please select a valid image (JPG|JPEG|PNG|GIF)";
    }
    if (itemdata.img == "none") {
      temp.itemimg = "This field is required";
    }
    setitemerrors({ ...temp });
    return Object.values(temp).every((x) => x == "");
  };
  const itemChange = (event) => {
    setItemdata({ ...itemdata, [event.target.id]: event.target.value });
  };
  const onItemImageChange = (e) => {
    let files = e.target.files || e.dataTransfer.files;
    if (!files.length) {
      setItemdata({ ...itemdata, img: "none" });
      return;
    }
    if (!files[0].name.match(/\.(jpg|jpeg|png|gif)$/)) {
      setItemdata({ ...itemdata, img: "invalid" });
      return;
    }
    let reader = new FileReader();
    reader.onload = (e) => {
      setItemdata({ ...itemdata, img: e.target.result });
    };
    reader.readAsDataURL(files[0]);
    const objectUrl = URL.createObjectURL(files[0]);
    setPreview(objectUrl);
  };

  const handleitemuneditClose = () => {
    setItemdata({
      name: "",
      img: "",
      desc: "",
    });
    setPreview(null);
    setitemerrors([]);
    setAddloading(false);
    document.getElementById("itemaddimg").value = null;
    setadditem(false);
  };
  const edithandleitemuneditClose = () => {
    setItemdata({
      name: "",
      img: "",
      desc: "",
    });
    setPreview(null);
    setitemerrors([]);
    setAddloading(false);
    document.getElementById("itemeditimg").value = null;
    setedititem(false);
  };

  const handleitemaddClose = async (e) => {
    e.preventDefault();
    if (itemvalidate()) {
      setAddloading(true);
      let response = await fetch(props.url + "/create-item", {
        method: "POST",
        headers: {
          "Content-type": "application/json",
        },
        body: JSON.stringify(itemdata),
        credentials: "include",
      });
      let res = await response.json();
      if (res) {
        if (res.success) {
          setItems([...items, res.data]);
          handleitemuneditClose();
          toast.success("Item added");
        } else {
          if( res.message == 'unauthenticated'){
            window.location.replace(props.exit);
            return;
          }
          toast.error(res.message);
        }
      }
    }
  };

  const handleitemeditClose = async (event) => {
    event.preventDefault();
    if (itemvalidate()) {
      setAddloading(true);
      let response = await fetch(props.url + `/update-item/${editid}`, {
        method: "PUT",
        headers: {
          "Content-type": "application/json",
        },
        body: JSON.stringify(itemdata),
        credentials: "include",
      });
      let res = await response.json();
      if (res) {
        if (res.success) {
          setItems(
            items.map((prop) => (prop.id === editid ? { ...res.data } : prop))
          );
          edithandleitemuneditClose();
          toast.success("Item updated");
        } else {
          if( res.message == 'unauthenticated'){
            window.location.replace(props.exit);
            return;
          }
          toast.error(res.message);
        }
      }
    }
  };
  ////////////////////////////////////////

  const handleitemeditOpen = async (id) => {
    setLoading(true);
    let response = await fetch(props.url + `/get-item/${id}`, {
      credentials: "include",
    });
    let res = await response.json();
    if (res) {
      if (res.success) {
        setItemdata({
          name: res.data.item_name,
          img: "valid",
          desc: res.data.desc,
        });
        setPreview(props.imgurl + res.data.img);
        setEditid(res.data.id);
        setLoading(false);
        setedititem(true);
      }
      else {
        if( res.message == 'unauthenticated'){
          window.location.replace(props.exit);
          return;
        }
      }
    }
  };

  const handleLinkchange = (event, link_id) => {
    const target = event.target;
    setLinks(
      links.map((link) =>
        link.id === link_id
          ? {...link,value:target.value}
          : link
      )
    );
    setUpdateLinks(true);
  };

  const UpdateContactLinks = async (e) => {
    setLoading(true);
    e.preventDefault();
    let response = await fetch(props.url + `/edit-links`, {
      method: "PUT",
      headers: {
        "Content-type": "application/json",
      },
      body: JSON.stringify({ links: links }),
      credentials: "include",
    });
    let data = await response.json();
    if (data.success) {
      setLinks(data.data);
      setUpdateLinks(false);
      setLoading(false);
      toast.success("Links updated");
    } else {
      setLoading(false);
      if( data.message == 'unauthenticated'){
        window.location.replace(props.exit);
        return;
      }
      toast.error(data.message);
    }
  };

  const confirmdeleteItem = (id) => {
    setDeleteid(id);
    setModtype("item");
    setOpenconfirm(true);
  };

  const deleteItem = async () => {
    setLoading(true);
    const cid = deleteid;
    let response = await fetch(props.url + "/delete-item", {
      method: "DELETE",
      headers: {
        "Content-type": "application/json",
      },
      body: JSON.stringify({ id: deleteid }),
      credentials: "include",
    });
    let res = await response.json();
    if (res) {
      setDeleteid(0);
      setModtype("");
      setOpenconfirm(false);
      setLoading(false);
      if (res.success) {
        setItems(items.filter((current) => cid !== current.id));
        toast.success("Item deleted");
      } else {
        if( res.message == 'unauthenticated'){
          window.location.replace(props.exit);
          return;
        }
        toast.error(res.message);
      }
    }
  };
  const packagevalidate = () => {
    let temp = [];
    temp.packagename = packagedata.name ? "" : "This field is required";
    temp.packageimg = packagedata.img ? "" : "This field is required";
    if (packagedata.img == "invalid") {
      temp.packageimg = "Please select a valid image (JPG|JPEG|PNG|GIF)";
    }
    if (packagedata.img == "none") {
      temp.packageimg = "This field is required";
    }
    setpackageerrors({ ...temp });
    return Object.values(temp).every((x) => x == "");
  };
  const packageChange = (event) => {
    setPackagedata({ ...packagedata, [event.target.id]: event.target.value });
  };
  const onPackageImageChange = (e) => {
    let files = e.target.files || e.dataTransfer.files;
    if (!files.length) {
      setPackagedata({ ...packagedata, img: "none" });
      return;
    }
    if (!files[0].name.match(/\.(jpg|jpeg|png|gif)$/)) {
      setPackagedata({ ...packagedata, img: "invalid" });
      return;
    }
    let reader = new FileReader();
    reader.onload = (e) => {
      setPackagedata({ ...packagedata, img: e.target.result });
    };
    reader.readAsDataURL(files[0]);
    const objectUrl = URL.createObjectURL(files[0]);
    setPreview(objectUrl);
  };
  const handlepackuneditClose = () => {
    setPackagedata({
      name: "",
      img: "",
    });
    setPreview(null);
    setpackageerrors([]);
    setAddloading(false);
    document.getElementById("packaddimg").value = null;
    setaddpack(false);
  };
  const edithandlepackuneditClose = () => {
    setPackagedata({
      name: "",
      img: "",
    });
    setPreview(null);
    setpackageerrors([]);
    setAddloading(false);
    document.getElementById("packeditimg").value = null;
    seteditpack(false);
  };
  const handlepackaddClose = async (e) => {
    e.preventDefault();
    if (packagevalidate()) {
      setAddloading(true);
      let response = await fetch(props.url + "/create-package", {
        method: "POST",
        headers: {
          "Content-type": "application/json",
        },
        body: JSON.stringify(packagedata),
        credentials: "include",
      });
      let res = await response.json();
      if (res) {
        if (res.success) {
          setPackages([...packages, res.data]);
          handlepackuneditClose();
          toast.success("Package added");
        } else {
          if( res.message == 'unauthenticated'){
            window.location.replace(props.exit);
            return;
          }
          toast.error(res.message);
        }
      }
    }
  };
  const handlepackeditClose = async (event) => {
    event.preventDefault();
    if (packagevalidate()) {
      setAddloading(true);
      let response = await fetch(props.url + `/update-package/${editid}`, {
        method: "PUT",
        headers: {
          "Content-type": "application/json",
        },
        body: JSON.stringify(packagedata),
        credentials: "include",
      });
      let res = await response.json();
      if (res) {
        if (res.success) {
          setPackages(
            packages.map((prop) =>
              prop.id === editid ? { ...res.data } : prop
            )
          );
          edithandlepackuneditClose();
          toast.success("Package updated");
        } else {
          if( res.message == 'unauthenticated'){
            window.location.replace(props.exit);
            return;
          }
          toast.error(res.message);
        }
      }
    }
  };
  const handlepackeditOpen = async (id) => {
    setLoading(true);
    let response = await fetch(props.url + `/get-package/${id}`, {
      credentials: "include",
    });
    let res = await response.json();
    if (res) {
      if (res.success) {
        setPackagedata({
          name: res.data.package_name,
          img: "valid",
        });
        setPreview(props.imgurl + res.data.img);
        setEditid(res.data.id);
        setLoading(false);
        seteditpack(true);
      }
      else {
        if( res.message == 'unauthenticated'){
          window.location.replace(props.exit);
          return;
        }
      }
    }
  };

  // const confirmdeletePackage = (id) => {
  //   setDeleteid(id);
  //   setModtype('package');
  //   setOpenconfirm(true);
  // }

  const deletePackage = async () => {
    setLoading(true);
    const cid = deleteid;
    let response = await fetch(props.url + "/delete-package", {
      method: "DELETE",
      headers: {
        "Content-type": "application/json",
      },
      body: JSON.stringify({ id: deleteid }),
      credentials: "include",
    });
    let res = await response.json();
    if (res) {
      setDeleteid(0);
      setModtype("");
      setOpenconfirm(false);
      setLoading(false);
      if (res.success) {
        setPackages(packages.filter((current) => cid !== current.id));
        toast.success("Package deleted");
      } else {
        if( res.message == 'unauthenticated'){
          window.location.replace(props.exit);
          return;
        }
        toast.error(res.message);
      }
    }
  };
  const servicevalidate = () => {
    let temp = [];
    temp.servicename = servicedata.name ? "" : "This field is required";
    temp.serviceimg = servicedata.img ? "" : "This field is required";
    if (servicedata.img == "invalid") {
      temp.serviceimg = "Please select a valid image (JPG|JPEG|PNG|GIF)";
    }
    if (servicedata.img == "none") {
      temp.serviceimg = "This field is required";
    }
    setserviceerrors({ ...temp });
    return Object.values(temp).every((x) => x == "");
  };
  const serviceChange = (event) => {
    setServicedata({ ...servicedata, [event.target.id]: event.target.value });
  };
  const onServiceImageChange = (e) => {
    let files = e.target.files || e.dataTransfer.files;
    if (!files.length) {
      setServicedata({ ...servicedata, img: "none" });
      return;
    }
    if (!files[0].name.match(/\.(jpg|jpeg|png|gif)$/)) {
      setServicedata({ ...servicedata, img: "invalid" });
      return;
    }
    let reader = new FileReader();
    reader.onload = (e) => {
      setServicedata({ ...servicedata, img: e.target.result });
    };
    reader.readAsDataURL(files[0]);
    const objectUrl = URL.createObjectURL(files[0]);
    setPreview(objectUrl);
  };
  const handleservuneditClose = () => {
    setServicedata({
      name: "",
      img: "",
    });
    setPreview(null);
    setserviceerrors([]);
    setAddloading(false);
    document.getElementById("servaddimg").value = null;
    setaddserv(false);
  };

  const edithandleservuneditClose = () => {
    setServicedata({
      name: "",
      img: "",
    });
    setPreview(null);
    setserviceerrors([]);
    setAddloading(false);
    document.getElementById("serveditimg").value = null;
    seteditserv(false);
  };
  const handleservaddClose = async (e) => {
    e.preventDefault();
    if (servicevalidate()) {
      setAddloading(true);
      let response = await fetch(props.url + "/create-service", {
        method: "POST",
        headers: {
          "Content-type": "application/json",
        },
        body: JSON.stringify(servicedata),
        credentials: "include",
      });
      let res = await response.json();
      if (res) {
        if (res.success) {
          setServices([...services, res.data]);
          handleservuneditClose();
          toast.success("Service added");
        } else {
          if( res.message == 'unauthenticated'){
            window.location.replace(props.exit);
            return;
          }
          toast.error(res.message);
        }
      }
    }
  };
  const handleserveditClose = async (event) => {
    event.preventDefault();
    if (servicevalidate()) {
      setAddloading(true);
      let response = await fetch(props.url + `/update-service/${editid}`, {
        method: "PUT",
        headers: {
          "Content-type": "application/json",
        },
        body: JSON.stringify(servicedata),
        credentials: "include",
      });
      let res = await response.json();
      if (res) {
        if (res.success) {
          setServices(
            services.map((prop) =>
              prop.id === editid
                ? { ...res.data, name: res.data.service_name }
                : prop
            )
          );
          edithandleservuneditClose();
          toast.success("Service updated");
        } else {
          if( res.message == 'unauthenticated'){
            window.location.replace(props.exit);
            return;
          }
          toast.error(res.message);
        }
      }
    }
  };
  const handleserveditOpen = async (id) => {
    setLoading(true);
    let response = await fetch(props.url + `/get-service/${id}`, {
      credentials: "include",
    });
    let res = await response.json();
    if (res) {
      if (res.success) {
        setServicedata({
          name: res.data.service_name,
          img: "valid",
        });
        setPreview(props.imgurl + res.data.img);
        setEditid(res.data.id);
        setLoading(false);
        seteditserv(true);
      }
      else {
        if( res.message == 'unauthenticated'){
          window.location.replace(props.exit);
          return;
        }
      }
    }
  };

  // const confirmdeleteService = (id) => {
  //   setDeleteid(id);
  //   setModtype('service');
  //   setOpenconfirm(true);
  // }

  const deleteService = async () => {
    setLoading(true);
    const cid = deleteid;
    let response = await fetch(props.url + "/delete-service", {
      method: "DELETE",
      headers: {
        "Content-type": "application/json",
      },
      body: JSON.stringify({ id: deleteid }),
      credentials: "include",
    });
    let res = await response.json();
    if (res) {
      setDeleteid(0);
      setModtype("");
      setOpenconfirm(false);
      setLoading(false);
      if (res.success) {
        setServices(services.filter((current) => cid !== current.id));
        toast.success("Service deleted");
      } else {
        if( res.message == 'unauthenticated'){
          window.location.replace(props.exit);
          return;
        }
        toast.error(res.message);
      }
    }
  };
  const boundaryvalidate = () => {
    let temp = [];
    temp.boundaryname = boundarydata.name ? "" : "This field is required";
    temp.boundaryimg = boundarydata.img ? "" : "This field is required";
    if (boundarydata.img == "invalid") {
      temp.boundaryimg = "Please select a valid image (JPG|JPEG|PNG|GIF)";
    }
    if (boundarydata.img == "none") {
      temp.boundaryimg = "This field is required";
    }
    setboundaryerrors({ ...temp });
    return Object.values(temp).every((x) => x == "");
  };
  const boundaryChange = (event) => {
    setBoundarydata({ ...boundarydata, [event.target.id]: event.target.value });
  };
  const onBoundaryImageChange = (e) => {
    let files = e.target.files || e.dataTransfer.files;
    if (!files.length) {
      setBoundarydata({ ...boundarydata, img: "none" });
      return;
    }
    if (!files[0].name.match(/\.(jpg|jpeg|png|gif)$/)) {
      setBoundarydata({ ...boundarydata, img: "invalid" });
      return;
    }
    let reader = new FileReader();
    reader.onload = (e) => {
      setBoundarydata({ ...boundarydata, img: e.target.result });
    };
    reader.readAsDataURL(files[0]);
    const objectUrl = URL.createObjectURL(files[0]);
    setPreview(objectUrl);
  };
  const handlebounduneditClose = () => {
    setBoundarydata({
      name: "",
      img: "",
    });
    setPreview(null);
    setboundaryerrors([]);
    setAddloading(false);
    document.getElementById("boundaddimg").value = null;
    setaddbound(false);
  };
  const edithandlebounduneditClose = () => {
    setBoundarydata({
      name: "",
      img: "",
    });
    setPreview(null);
    setboundaryerrors([]);
    setAddloading(false);
    document.getElementById("boundeditimg").value = null;
    seteditbound(false);
  };
  const handleboundaddClose = async (e) => {
    e.preventDefault();
    if (boundaryvalidate()) {
      setAddloading(true);
      let response = await fetch(props.url + "/create-boundary", {
        method: "POST",
        headers: {
          "Content-type": "application/json",
        },
        body: JSON.stringify(boundarydata),
        credentials: "include",
      });
      let res = await response.json();
      if (res) {
        if (res.success) {
          setBoundries([...boundries, res.data]);
          handlebounduneditClose();
          toast.success("Boundary added");
        } else {
          if( res.message == 'unauthenticated'){
            window.location.replace(props.exit);
            return;
          }
          toast.error(res.message);
        }
      }
    }
  };

  const handleboundeditClose = async (event) => {
    event.preventDefault();
    if (boundaryvalidate()) {
      setAddloading(true);
      let response = await fetch(props.url + `/update-boundary/${editid}`, {
        method: "PUT",
        headers: {
          "Content-type": "application/json",
        },
        body: JSON.stringify(boundarydata),
        credentials: "include",
      });
      let res = await response.json();
      if (res) {
        if (res.success) {
          setBoundries(
            boundries.map((prop) =>
              prop.id === editid
                ? { ...res.data, name: res.data.boundary_name }
                : prop
            )
          );
          edithandlebounduneditClose();
          toast.success("Boundary updated");
        } else {
          if( res.message == 'unauthenticated'){
            window.location.replace(props.exit);
            return;
          }
          toast.error(res.message);
        }
      }
    }
  };
  const handleboundeditOpen = async (id) => {
    setLoading(true);
    let response = await fetch(props.url + `/get-boundary/${id}`, {
      credentials: "include",
    });
    let res = await response.json();
    if (res) {
      if (res.success) {
        setBoundarydata({
          name: res.data.boundary_name,
          img: "valid",
        });
        setPreview(props.imgurl + res.data.img);
        setEditid(res.data.id);
        setLoading(false);
        seteditbound(true);
      }
      else {
        if( res.message == 'unauthenticated'){
          window.location.replace(props.exit);
          return;
        }
        toast.error(res.message);
      }
    }
  };

  // const confirmdeleteBoundary = (id) => {
  //   setDeleteid(id);
  //   setModtype('boundary');
  //   setOpenconfirm(true);
  // }

  const deleteBoundary = async () => {
    setLoading(true);
    const cid = deleteid;
    let response = await fetch(props.url + "/delete-boundary", {
      method: "DELETE",
      headers: {
        "Content-type": "application/json",
      },
      body: JSON.stringify({ id: deleteid }),
      credentials: "include",
    });
    let res = await response.json();
    if (res) {
      setDeleteid(0);
      setModtype("");
      setOpenconfirm(false);
      setLoading(false);
      if (res.success) {
        setBoundries(boundries.filter((current) => cid !== current.id));
        toast.success("Boundary deleted");
      } else {
        if( res.message == 'unauthenticated'){
          window.location.replace(props.exit);
          return;
        }
        toast.error(res.message);
      }
    }
  };

  const handlevehaddOpen = () => {
    setaddveh(true);
  };
  const handleitemaddOpen = () => {
    setadditem(true);
  };
  // const handleservaddOpen = () => {
  //   setaddserv(true);
  // };
  // const handlepackaddOpen = () => {
  //   setaddpack(true);
  // };
  // const handleboundaddOpen = () => {
  //   setaddbound(true);
  // };

  return (
    <GridContainer>
      {loading && (
        <Backdrop className={classes.backdrop} open>
          <CircularProgress color="inherit" />
        </Backdrop>
      )}
      <GridItem xs={12} sm={12} md={12}>
        <div className={classes.root}>
          <AppBar
            style={{ background: "transparent", boxShadow: "none" }}
            position="static"
            color="transparent"
          >
            <Tabs
              value={value}
              onChange={handletabChange}
              variant="fullWidth"
              scrollButtons="on"
              indicatorColor="secondary"
              textColor="secondary"
              aria-label="scrollable force tabs example"
            >
              <Tab
                className={dividermatch && classes.dividetab}
                label="Vehicles"
                icon={<LocalShipping style={{ fontSize: 25 }} />}
                {...a11yProps(0)}
              />
              <Tab
                className={dividermatch && classes.dividetab}
                label="Delivery Items"
                icon={<AddToPhotosIcon style={{ fontSize: 25 }} />}
                {...a11yProps(1)}
              />
              <Tab
                className={dividermatch && classes.dividetab}
                label="Services"
                icon={
                  <FontAwesomeIcon style={{ fontSize: 25 }} icon={faMailBulk} />
                }
                {...a11yProps(2)}
              />
              <Tab
                className={dividermatch && classes.dividetab}
                label="Boundaries"
                icon={<LocationOn style={{ fontSize: 25 }} />}
                {...a11yProps(3)}
              />
              <Tab
                className={dividermatch && classes.dividetab}
                label="Packages"
                icon={
                  <FontAwesomeIcon style={{ fontSize: 25 }} icon={faBoxOpen} />
                }
                {...a11yProps(4)}
              />
              <Tab
                className={dividermatch && classes.dividetab}
                label="Contact"
                icon={
                  <FontAwesomeIcon style={{ fontSize: 25 }} icon={faLink} />
                }
                {...a11yProps(5)}
              />
            </Tabs>
          </AppBar>
          <TabPanel value={value} index={0}>
            <GridItem xs={12} sm={12} md={12}>
              <Card>
                <CardHeader color="danger">
                  <h4 className={classes.cardTitleWhite}>Vehicles</h4>
                </CardHeader>
                <CardBody>
                  <Button onClick={handlevehaddOpen} color="danger">
                    <Add /> Add Vehicle
                  </Button>
                  <Table
                    tableHeaderColor="gray"
                    tableHead={[
                      "Name",
                      "Image",
                      "Max Weight",
                      "Max CBM",
                      "Edit",
                      "Delete",
                    ]}
                    tableData={
                      vehicles.length == 0 ? (
                        <TableRow>
                          <TableCell
                            style={{ textAlign: "center" }}
                            colSpan={6}
                            className={classes.tableCell}
                          >
                            No Vehicles
                          </TableCell>
                        </TableRow>
                      ) : (
                        vehicles.map((prop) => {
                          return (
                            <TableRow
                              key={prop.id}
                              className={classes.tableBodyRow}
                            >
                              <TableCell
                                className={classes.tableCell}
                                key={prop.id}
                              >
                                {prop.vehicle_name}
                              </TableCell>
                              <TableCell
                                className={classes.tableCell}
                                key={prop.id}
                              >
                                <img
                                  className={classes.avatarimg}
                                  variant="square"
                                  key={prop.id}
                                  alt="Service"
                                  src={props.imgurl + prop.img}
                                />
                              </TableCell>
                              <TableCell
                                className={classes.tableCell}
                                key={prop.id}
                              >
                                {parseFloat(prop.max_weight).toFixed(2)}kg
                              </TableCell>
                              <TableCell
                                className={classes.tableCell}
                                key={prop.id}
                              >
                                {parseFloat(prop.max_cbm).toFixed(3)}m&#xb3;
                              </TableCell>
                              <TableCell
                                className={classes.tableCell}
                                key={prop.id}
                              >
                                <Edit
                                  style={{ cursor: "pointer" }}
                                  onClick={() => handleveheditOpen(prop.id)}
                                  key={prop.id}
                                />
                              </TableCell>
                              <TableCell
                                className={classes.tableCell}
                                key={prop.id}
                              >
                                <Delete
                                  style={{ cursor: "pointer" }}
                                  onClick={() => confirmdeleteVehicle(prop.id)}
                                  key={prop.id}
                                />
                              </TableCell>
                            </TableRow>
                          );
                        })
                      )
                    }
                    search={false}
                  />
                </CardBody>
              </Card>
            </GridItem>
          </TabPanel>
          <TabPanel value={value} index={1}>
            <GridItem xs={12} sm={12} md={12}>
              <Card>
                <CardHeader color="danger">
                  <h4 className={classes.cardTitleWhite}>
                    International Delivery Items
                  </h4>
                </CardHeader>
                <CardBody>
                  <Button onClick={handleitemaddOpen} color="danger">
                    <Add /> Add Item
                  </Button>
                  <Table
                  searchKeyword={searchHandler}
                  term={searchTerm}
                  setTerm={setSearchTerm}
                    tableHeaderColor="gray"
                    tableHead={[
                      "Name",
                      "Image",
                      "Description",
                      "International Shipping Rate",
                      "Edit",
                      "Delete",
                    ]}
                    tableData={
                      items.length == 0 ? (
                        <TableRow>
                          <TableCell
                            style={{ textAlign: "center" }}
                            colSpan={6}
                            className={classes.tableCell}
                          >
                            No Items
                          </TableCell>
                        </TableRow>
                      ) : (
                        items.map((prop) => {
                          return (
                            <TableRow
                              key={prop.id}
                              className={classes.tableBodyRow}
                            >
                              <TableCell
                                className={classes.tableCell}
                                key={prop.id}
                              >
                                {prop.item_name}
                              </TableCell>
                              <TableCell
                                className={classes.tableCell}
                                key={prop.id}
                              >
                                <img
                                  className={classes.avatarimg}
                                  variant="square"
                                  key={prop.id}
                                  alt="Image"
                                  src={props.imgurl + prop.img}
                                />
                              </TableCell>
                              <TableCell
                                className={classes.tableCell}
                                key={prop.id}
                              >
                                {prop.desc}
                              </TableCell>
                              <TableCell
                                className={classes.tableCell}
                                key={prop.id}
                              >
                                <Link
                                  style={{ color: "black" }}
                                  to={`/admin/internationalpricing/${prop.id}`}
                                >
                                  <SyncAltIcon />
                                </Link>
                              </TableCell>
                              <TableCell
                                className={classes.tableCell}
                                key={prop.id}
                              >
                                <Edit
                                  style={{ cursor: "pointer" }}
                                  onClick={() => handleitemeditOpen(prop.id)}
                                  key={prop.id}
                                />
                              </TableCell>
                              <TableCell
                                className={classes.tableCell}
                                key={prop.id}
                              >
                                <Delete
                                  style={{ cursor: "pointer" }}
                                  onClick={() => confirmdeleteItem(prop.id)}
                                  key={prop.id}
                                />
                              </TableCell>
                            </TableRow>
                          );
                        })
                      )
                    }
                  />
                </CardBody>
                <TablePagination
      component="div"
      count={totalcount}
      page={page}
      onPageChange={handleChangePage}
      rowsPerPage={rowsPerPage}
      onRowsPerPageChange={handleChangeRowsPerPage}
    />
              </Card>
            </GridItem>
          </TabPanel>
          <TabPanel value={value} index={2}>
            <GridItem xs={12} sm={12} md={12}>
              <Card>
                <CardHeader color="danger">
                  <h4 className={classes.cardTitleWhite}>Services</h4>
                </CardHeader>
                <CardBody>
                  {/* <Button onClick={handleservaddOpen} color="danger"><Add /> Add Service</Button> */}
                  <Table
                    tableHeaderColor="gray"
                    tableHead={["Name", "Image", "Edit"]}
                    tableData={
                      services.length == 0 ? (
                        <TableRow>
                          <TableCell
                            style={{ textAlign: "center" }}
                            colSpan={3}
                            className={classes.tableCell}
                          >
                            No Services
                          </TableCell>
                        </TableRow>
                      ) : (
                        services.map((prop) => {
                          return (
                            <TableRow
                              key={prop.id}
                              className={classes.tableBodyRow}
                            >
                              <TableCell
                                className={classes.tableCell}
                                key={prop.id}
                              >
                                {prop.service_name}
                              </TableCell>
                              <TableCell
                                className={classes.tableCell}
                                key={prop.id}
                              >
                                <img
                                  className={classes.avatarimg}
                                  variant="square"
                                  key={prop.id}
                                  alt="Service"
                                  src={props.imgurl + prop.img}
                                />
                              </TableCell>
                              <TableCell
                                className={classes.tableCell}
                                key={prop.id}
                              >
                                <Edit
                                  style={{ cursor: "pointer" }}
                                  onClick={() => handleserveditOpen(prop.id)}
                                  key={prop.id}
                                />
                              </TableCell>
                              {/* <TableCell className={classes.tableCell} key={prop.id}>
                        <Delete style={{ cursor: "pointer" }} onClick={() => confirmdeleteService(prop.id)} key={prop.id} />
                      </TableCell> */}
                            </TableRow>
                          );
                        })
                      )
                    }
                    search={false}
                  />
                </CardBody>
              </Card>
            </GridItem>
          </TabPanel>

          <TabPanel value={value} index={3}>
            <GridItem xs={12} sm={12} md={12}>
              <Card>
                <CardHeader color="danger">
                  <h4 className={classes.cardTitleWhite}>Boundries</h4>
                </CardHeader>
                <CardBody>
                  {/* <Button onClick={handleboundaddOpen} color="danger"><Add /> Add Boundry</Button> */}
                  <Table
                    tableHeaderColor="gray"
                    tableHead={["Name", "Image", "Edit"]}
                    tableData={
                      boundries.length == 0 ? (
                        <TableRow>
                          <TableCell
                            style={{ textAlign: "center" }}
                            colSpan={3}
                            className={classes.tableCell}
                          >
                            No Boundries
                          </TableCell>
                        </TableRow>
                      ) : (
                        boundries.map((prop) => {
                          return (
                            <TableRow
                              key={prop.id}
                              className={classes.tableBodyRow}
                            >
                              <TableCell
                                className={classes.tableCell}
                                key={prop.id}
                              >
                                {prop.boundary_name}
                              </TableCell>
                              <TableCell
                                className={classes.tableCell}
                                key={prop.id}
                              >
                                <img
                                  className={classes.avatarimg}
                                  variant="square"
                                  key={prop.id}
                                  alt="Boundary"
                                  src={props.imgurl + prop.img}
                                />
                              </TableCell>
                              <TableCell
                                className={classes.tableCell}
                                key={prop.id}
                              >
                                <Edit
                                  style={{ cursor: "pointer" }}
                                  onClick={() => handleboundeditOpen(prop.id)}
                                  key={prop.id}
                                />
                              </TableCell>
                              {/* <TableCell className={classes.tableCell} key={prop.id}>
                        <Delete style={{ cursor: "pointer" }} onClick={() => confirmdeleteBoundary(prop.id)} key={prop.id} />
                      </TableCell> */}
                            </TableRow>
                          );
                        })
                      )
                    }
                    search={false}
                  />
                </CardBody>
              </Card>
            </GridItem>
          </TabPanel>

          <TabPanel value={value} index={4}>
            <GridItem xs={12} sm={12} md={12}>
              <Card>
                <CardHeader color="danger">
                  <h4 className={classes.cardTitleWhite}>Packages</h4>
                </CardHeader>
                <CardBody>
                  {/* <Button onClick={handlepackaddOpen} color="danger"><Add /> Add Package</Button> */}
                  <Table
                    tableHeaderColor="gray"
                    tableHead={["Name", "Image", "Edit"]}
                    tableData={
                      packages.length == 0 ? (
                        <TableRow>
                          <TableCell
                            style={{ textAlign: "center" }}
                            colSpan={3}
                            className={classes.tableCell}
                          >
                            No Packages
                          </TableCell>
                        </TableRow>
                      ) : (
                        packages.map((prop) => {
                          return (
                            <TableRow
                              key={prop.id}
                              className={classes.tableBodyRow}
                            >
                              <TableCell
                                className={classes.tableCell}
                                key={prop.id}
                              >
                                {prop.package_name}
                              </TableCell>
                              <TableCell
                                className={classes.tableCell}
                                key={prop.id}
                              >
                                <img
                                  className={classes.avatarimg}
                                  variant="square"
                                  key={prop.id}
                                  alt="Package"
                                  src={props.imgurl + prop.img}
                                />
                              </TableCell>
                              <TableCell
                                className={classes.tableCell}
                                key={prop.id}
                              >
                                <Edit
                                  style={{ cursor: "pointer" }}
                                  onClick={() => handlepackeditOpen(prop.id)}
                                  key={prop.id}
                                />
                              </TableCell>
                              {/* <TableCell className={classes.tableCell} key={prop.id}>
                        <Delete style={{ cursor: "pointer" }} onClick={() => confirmdeletePackage(prop.id)} key={prop.id} />
                      </TableCell> */}
                            </TableRow>
                          );
                        })
                      )
                    }
                    search={false}
                  />
                </CardBody>
              </Card>
            </GridItem>
          </TabPanel>

          <TabPanel value={value} index={5}>
            <GridItem xs={12} sm={12} md={12}>
              <Card>
                <CardHeader color="danger">
                  <h4 className={classes.cardTitleWhite}>Contact Details</h4>
                </CardHeader>
                <CardBody>
                  {/* <Button onClick={handlepackaddOpen} color="danger"><Add /> Add Package</Button> */}
                  <Table
                    tableHeaderColor="gray"
                    tableHead={["Name", "Value"]}
                    tableData={
                      links.length == 0 ? (
                        <TableRow>
                          <TableCell
                            style={{ textAlign: "center" }}
                            colSpan={2}
                            className={classes.tableCell}
                          >
                            No Links
                          </TableCell>
                        </TableRow>
                      ) : (
                        links.map((prop) => {
                          return (
                            <TableRow
                              key={prop.id}
                              className={classes.tableBodyRow}
                            >
                              <TableCell
                                className={classes.tableCell}
                                key={prop.id}
                              >
                                {prop.name}
                              </TableCell>
                              <TableCell
                                className={classes.tableCell}
                                key={prop.id}
                              >
                               <TextField
                  style={{ width: "100%" }}
                  value={prop.value}
                  type={prop.type}
                  onChange={(e) => handleLinkchange(e, prop.id)}
                  id="standard-start-adornment"
                />
                              </TableCell>
                            </TableRow>
                          );
                        })
                      )
                    }
                    search={false}
                  />
                  {updateLinks && (
          <Button
            onClick={UpdateContactLinks}
            style={{ float: "right" }}
            color="danger"
          >
            Save Changes
          </Button>
        )}
                </CardBody>
              </Card>
            </GridItem>
          </TabPanel>

        </div>
      </GridItem>
      <Dialog
        fullWidth={true}
        maxWidth={!addloading ? "sm" : "xs"}
        open={addserv}
        TransitionComponent={Transition}
        keepMounted
        onClose={handleservuneditClose}
        aria-labelledby="alert-dialog-slide-title"
        aria-describedby="alert-dialog-slide-description"
      >
        <DialogContent>
          <DialogContentText id="alert-dialog-slide-description">
            <GridContainer>
              <GridItem xs={12} sm={12} md={12}>
                <form onSubmit={handleservaddClose}>
                  <CardHeader color={!addloading && "danger"}>
                    <h4
                      style={{ textAlign: "center" }}
                      className={
                        !addloading
                          ? classes.cardTitleWhite
                          : classes.cardTitleBlack
                      }
                    >
                      {addloading ? "Adding Service" : "Add Service"}
                    </h4>
                  </CardHeader>
                  {addloading ? (
                    <CardBody>
                      <GridContainer>
                        <GridItem
                          style={{ textAlign: "center" }}
                          xs={12}
                          sm={12}
                          md={12}
                        >
                          <CircularProgress color="secondary" />
                        </GridItem>
                      </GridContainer>
                    </CardBody>
                  ) : (
                    <CardBody>
                      <GridContainer>
                        <form
                          style={{ width: "100%" }}
                          onSubmit={handleservaddClose}
                        >
                          <GridItem xs={12} sm={12} md={12}>
                            <TextField
                              onChange={serviceChange}
                              value={servicedata.name}
                              autoFocus
                              margin="dense"
                              id="name"
                              label="Service Name"
                              type="text"
                              fullWidth
                              error={serviceerrors.servicename}
                              helperText={
                                serviceerrors.servicename &&
                                serviceerrors.servicename
                              }
                            />
                          </GridItem>
                          <GridItem xs={12} sm={12} md={12}>
                            <label>Image</label>
                            <br />
                            <input
                              onChange={onServiceImageChange}
                              id="servaddimg"
                              type="file"
                            />
                            <Danger>{serviceerrors.serviceimg}</Danger>
                          </GridItem>
                        </form>
                      </GridContainer>
                      <GridContainer>
                        <GridItem xs={12} sm={12} md={2} />
                        <GridItem xs={12} sm={12} md={8}>
                          {preview != null && (
                            <img width="100%" src={preview} />
                          )}
                        </GridItem>
                        <GridItem xs={12} sm={12} md={2} />
                      </GridContainer>
                    </CardBody>
                  )}
                </form>
              </GridItem>
            </GridContainer>
          </DialogContentText>
        </DialogContent>
        {!addloading && (
          <DialogActions>
            <Button
              style={{ display: "block", marginLeft: "0", marginRight: "auto" }}
              onClick={handleservuneditClose}
              color="danger"
            >
              <Close />
              Cancel
            </Button>
            <Button
              style={{ float: "right" }}
              color="danger"
              onClick={handleservaddClose}
            >
              Create <Arrow />
            </Button>
          </DialogActions>
        )}
      </Dialog>
      <Dialog
        fullWidth={true}
        maxWidth={!addloading ? "sm" : "xs"}
        open={editserv}
        TransitionComponent={Transition}
        keepMounted
        onClose={edithandleservuneditClose}
        aria-labelledby="alert-dialog-slide-title"
        aria-describedby="alert-dialog-slide-description"
      >
        <DialogContent>
          <DialogContentText id="alert-dialog-slide-description">
            <GridContainer>
              <GridItem xs={12} sm={12} md={12}>
                <>
                  <CardHeader color={!addloading && "danger"}>
                    <h4
                      style={{ textAlign: "center" }}
                      className={
                        !addloading
                          ? classes.cardTitleWhite
                          : classes.cardTitleBlack
                      }
                    >
                      {addloading ? "Updating Service" : "Update Service"}
                    </h4>
                  </CardHeader>
                  {addloading ? (
                    <CardBody>
                      <GridContainer>
                        <GridItem
                          style={{ textAlign: "center" }}
                          xs={12}
                          sm={12}
                          md={12}
                        >
                          <CircularProgress color="secondary" />
                        </GridItem>
                      </GridContainer>
                    </CardBody>
                  ) : (
                    <CardBody>
                      <GridContainer>
                        <form
                          style={{ width: "100%" }}
                          onSubmit={(e) => handleserveditClose(e)}
                        >
                          <GridItem xs={12} sm={12} md={12}>
                            <TextField
                              disabled
                              onChange={serviceChange}
                              value={servicedata.name}
                              autoFocus
                              margin="dense"
                              id="name"
                              label="Service Name"
                              type="text"
                              fullWidth
                              error={serviceerrors.servicename}
                              helperText={
                                serviceerrors.servicename &&
                                serviceerrors.servicename
                              }
                            />
                          </GridItem>
                          <GridItem xs={12} sm={12} md={12}>
                            <label>Image</label>
                            <br />
                            <input
                              onChange={onServiceImageChange}
                              id="serveditimg"
                              type="file"
                            />
                            <Danger>{serviceerrors.serviceimg}</Danger>
                          </GridItem>
                        </form>
                      </GridContainer>
                      <GridContainer>
                        <GridItem xs={12} sm={12} md={2} />
                        <GridItem xs={12} sm={12} md={8}>
                          {preview != null && (
                            <img width="100%" src={preview} />
                          )}
                        </GridItem>
                        <GridItem xs={12} sm={12} md={2} />
                      </GridContainer>
                    </CardBody>
                  )}
                </>
              </GridItem>
            </GridContainer>
          </DialogContentText>
        </DialogContent>
        {!addloading && (
          <DialogActions>
            <Button
              style={{ display: "block", marginLeft: "0", marginRight: "auto" }}
              onClick={edithandleservuneditClose}
              color="danger"
            >
              <Close />
              Cancel
            </Button>
            <Button
              style={{ float: "right" }}
              color="danger"
              onClick={(e) => handleserveditClose(e)}
            >
              Update <Arrow />
            </Button>
          </DialogActions>
        )}
      </Dialog>
      <Dialog
        fullWidth={true}
        maxWidth={!addloading ? "sm" : "xs"}
        open={addveh}
        TransitionComponent={Transition}
        keepMounted
        onClose={handlevehuneditClose}
        aria-labelledby="alert-dialog-slide-title"
        aria-describedby="alert-dialog-slide-description"
      >
        <DialogContent>
          <DialogContentText id="alert-dialog-slide-description">
            <GridContainer>
              <GridItem xs={12} sm={12} md={12}>
                <>
                  <CardHeader color={!addloading && "danger"}>
                    <h4
                      style={{ textAlign: "center" }}
                      className={
                        !addloading
                          ? classes.cardTitleWhite
                          : classes.cardTitleBlack
                      }
                    >
                      {addloading ? "Adding Vehicle" : "Add Vehicle"}
                    </h4>
                  </CardHeader>
                  {addloading ? (
                    <CardBody>
                      <GridContainer>
                        <GridItem
                          style={{ textAlign: "center" }}
                          xs={12}
                          sm={12}
                          md={12}
                        >
                          <CircularProgress color="secondary" />
                        </GridItem>
                      </GridContainer>
                    </CardBody>
                  ) : (
                    <CardBody>
                      <GridContainer>
                        <form
                          style={{ width: "100%" }}
                          onSubmit={handlevehaddClose}
                        >
                          <GridItem xs={12} sm={12} md={12}>
                            <TextField
                              onChange={vehicleChange}
                              value={vehicledata.name}
                              autoFocus
                              margin="dense"
                              id="name"
                              label="Vehicle Name"
                              type="text"
                              fullWidth
                              error={vehicleerrors.vehiclename}
                              helperText={
                                vehicleerrors.vehiclename &&
                                vehicleerrors.vehiclename
                              }
                            />
                          </GridItem>

                          <GridItem xs={12} sm={12} md={12}>
                            <TextField
                              onChange={vehicleChange}
                              id="weight"
                              value={vehicledata.weight}
                              label="Max Weight"
                              style={{ marginTop: 20 }}
                              fullWidth
                              type="number"
                              margin="normal"
                              InputProps={{
                                endAdornment: (
                                  <InputAdornment position="start">
                                    Kg
                                  </InputAdornment>
                                ),
                              }}
                              error={vehicleerrors.vehicleweight}
                              helperText={
                                vehicleerrors.vehicleweight &&
                                vehicleerrors.vehicleweight
                              }
                            />
                          </GridItem>
                          <GridItem xs={12} sm={12} md={12}>
                            <TextField
                              onChange={vehicleChange}
                              id="cbm"
                              value={vehicledata.cbm}
                              label="Max CBM"
                              style={{ marginTop: 20 }}
                              fullWidth
                              type="number"
                              margin="normal"
                              InputProps={{
                                endAdornment: (
                                  <InputAdornment position="start">
                                    M&#xb3;
                                  </InputAdornment>
                                ),
                              }}
                              error={vehicleerrors.vehiclecbm}
                              helperText={
                                vehicleerrors.vehiclecbm &&
                                vehicleerrors.vehiclecbm
                              }
                            />
                          </GridItem>
                          <GridItem xs={12} sm={12} md={12}>
                            <label>Image</label>
                            <br />
                            <input
                              required
                              onChange={onVehicleImageChange}
                              id="vehaddimg"
                              type="file"
                            />
                            <Danger>{vehicleerrors.vehicleimg}</Danger>
                          </GridItem>
                        </form>
                      </GridContainer>
                      <GridContainer>
                        <GridItem xs={12} sm={12} md={2} />
                        <GridItem xs={12} sm={12} md={8}>
                          {preview != null && (
                            <img width="100%" src={preview} />
                          )}
                        </GridItem>
                        <GridItem xs={12} sm={12} md={2} />
                      </GridContainer>
                    </CardBody>
                  )}
                </>
              </GridItem>
            </GridContainer>
          </DialogContentText>
        </DialogContent>
        {!addloading && (
          <DialogActions>
            <Button
              style={{ display: "block", marginLeft: "0", marginRight: "auto" }}
              onClick={handlevehuneditClose}
              color="danger"
            >
              <Close />
              Cancel
            </Button>
            <Button
              style={{ float: "right" }}
              color="danger"
              onClick={handlevehaddClose}
            >
              Create <Arrow />
            </Button>
          </DialogActions>
        )}
      </Dialog>
      <Dialog
        fullWidth={true}
        maxWidth={!addloading ? "sm" : "xs"}
        open={editveh}
        TransitionComponent={Transition}
        keepMounted
        onClose={edithandlevehuneditClose}
        aria-labelledby="alert-dialog-slide-title"
        aria-describedby="alert-dialog-slide-description"
      >
        <DialogContent>
          <DialogContentText id="alert-dialog-slide-description">
            <GridContainer>
              <GridItem xs={12} sm={12} md={12}>
                <>
                  <CardHeader color={!addloading && "danger"}>
                    <h4
                      style={{ textAlign: "center" }}
                      className={
                        !addloading
                          ? classes.cardTitleWhite
                          : classes.cardTitleBlack
                      }
                    >
                      {addloading ? "Updating Vehicle" : "Update Vehicle"}
                    </h4>
                  </CardHeader>
                  {addloading ? (
                    <CardBody>
                      <GridContainer>
                        <GridItem
                          style={{ textAlign: "center" }}
                          xs={12}
                          sm={12}
                          md={12}
                        >
                          <CircularProgress color="secondary" />
                        </GridItem>
                      </GridContainer>
                    </CardBody>
                  ) : (
                    <CardBody>
                      <GridContainer>
                        <form
                          style={{ width: "100%" }}
                          onSubmit={(e) => handleveheditClose(e)}
                        >
                          <GridItem xs={12} sm={12} md={12}>
                            <TextField
                              onChange={vehicleChange}
                              value={vehicledata.name}
                              autoFocus
                              margin="dense"
                              id="name"
                              label="Vehicle Name"
                              type="text"
                              fullWidth
                              error={vehicleerrors.vehiclename}
                              helperText={
                                vehicleerrors.vehiclename &&
                                vehicleerrors.vehiclename
                              }
                            />
                          </GridItem>
                          <GridItem xs={12} sm={12} md={12}>
                            <TextField
                              onChange={vehicleChange}
                              id="weight"
                              value={vehicledata.weight}
                              label="Max Weight"
                              style={{ marginTop: 20 }}
                              fullWidth
                              type="number"
                              margin="normal"
                              InputProps={{
                                endAdornment: (
                                  <InputAdornment position="start">
                                    Kg
                                  </InputAdornment>
                                ),
                              }}
                              error={vehicleerrors.vehicleweight}
                              helperText={
                                vehicleerrors.vehicleweight &&
                                vehicleerrors.vehicleweight
                              }
                            />
                          </GridItem>
                          <GridItem xs={12} sm={12} md={12}>
                            <TextField
                              onChange={vehicleChange}
                              id="cbm"
                              value={vehicledata.cbm}
                              label="Max CBM"
                              style={{ marginTop: 20 }}
                              fullWidth
                              type="number"
                              margin="normal"
                              InputProps={{
                                endAdornment: (
                                  <InputAdornment position="start">
                                    M&#xb3;
                                  </InputAdornment>
                                ),
                              }}
                              error={vehicleerrors.vehiclecbm}
                              helperText={
                                vehicleerrors.vehiclecbm &&
                                vehicleerrors.vehiclecbm
                              }
                            />
                          </GridItem>
                          <GridItem xs={12} sm={12} md={12}>
                            <label>Image</label>
                            <br />
                            <input
                              onChange={onVehicleImageChange}
                              id="veheditimg"
                              type="file"
                            />
                            <Danger>{vehicleerrors.vehicleimg}</Danger>
                          </GridItem>
                        </form>
                      </GridContainer>
                      <GridContainer>
                        <GridItem xs={12} sm={12} md={2} />
                        <GridItem xs={12} sm={12} md={8}>
                          {preview != null && (
                            <img width="100%" src={preview} />
                          )}
                        </GridItem>
                        <GridItem xs={12} sm={12} md={2} />
                      </GridContainer>
                    </CardBody>
                  )}
                </>
              </GridItem>
            </GridContainer>
          </DialogContentText>
        </DialogContent>
        {!addloading && (
          <DialogActions>
            <Button
              style={{ display: "block", marginLeft: "0", marginRight: "auto" }}
              onClick={edithandlevehuneditClose}
              color="danger"
            >
              <Close />
              Cancel
            </Button>
            <Button
              style={{ float: "right" }}
              color="danger"
              onClick={(e) => handleveheditClose(e)}
            >
              Update <Arrow />
            </Button>
          </DialogActions>
        )}
      </Dialog>

      <Dialog
        fullWidth={true}
        maxWidth={!addloading ? "sm" : "xs"}
        open={additem}
        TransitionComponent={Transition}
        keepMounted
        onClose={handleitemuneditClose}
        aria-labelledby="alert-dialog-slide-title"
        aria-describedby="alert-dialog-slide-description"
      >
        <DialogContent>
          <DialogContentText id="alert-dialog-slide-description">
            <GridContainer>
              <GridItem xs={12} sm={12} md={12}>
                <>
                  <CardHeader color={!addloading && "danger"}>
                    <h4
                      style={{ textAlign: "center" }}
                      className={
                        !addloading
                          ? classes.cardTitleWhite
                          : classes.cardTitleBlack
                      }
                    >
                      {addloading ? "Adding Item" : "Add Item"}
                    </h4>
                  </CardHeader>
                  {addloading ? (
                    <CardBody>
                      <GridContainer>
                        <GridItem
                          style={{ textAlign: "center" }}
                          xs={12}
                          sm={12}
                          md={12}
                        >
                          <CircularProgress color="secondary" />
                        </GridItem>
                      </GridContainer>
                    </CardBody>
                  ) : (
                    <CardBody>
                      <GridContainer>
                        <form
                          style={{ width: "100%" }}
                          onSubmit={handleitemaddClose}
                        >
                          <GridItem xs={12} sm={12} md={12}>
                            <TextField
                              onChange={itemChange}
                              value={itemdata.name}
                              autoFocus
                              margin="dense"
                              id="name"
                              label="Item Name"
                              type="text"
                              fullWidth
                              error={itemerrors.itemname}
                              helperText={
                                itemerrors.itemname && itemerrors.itemname
                              }
                            />
                          </GridItem>

                          <GridItem xs={12} sm={12} md={12}>
                            <TextField
                              onChange={itemChange}
                              id="desc"
                              value={itemdata.desc}
                              label="Description"
                              style={{ marginTop: 20 }}
                              fullWidth
                              type="text"
                              margin="normal"
                              InputProps={{
                                endAdornment: (
                                  <InputAdornment position="start">
                                    <Edit />
                                  </InputAdornment>
                                ),
                              }}
                              error={itemerrors.itemdesc}
                              helperText={
                                itemerrors.itemdesc && itemerrors.itemdesc
                              }
                            />
                          </GridItem>
                          <GridItem xs={12} sm={12} md={12}>
                            <label>Image</label>
                            <br />
                            <input
                              required
                              onChange={onItemImageChange}
                              id="itemaddimg"
                              type="file"
                            />
                            <Danger>{itemerrors.itemimg}</Danger>
                          </GridItem>
                        </form>
                      </GridContainer>
                      <GridContainer>
                        <GridItem xs={12} sm={12} md={2} />
                        <GridItem xs={12} sm={12} md={8}>
                          {preview != null && (
                            <img width="100%" src={preview} />
                          )}
                        </GridItem>
                        <GridItem xs={12} sm={12} md={2} />
                      </GridContainer>
                    </CardBody>
                  )}
                </>
              </GridItem>
            </GridContainer>
          </DialogContentText>
        </DialogContent>
        {!addloading && (
          <DialogActions>
            <Button
              style={{ display: "block", marginLeft: "0", marginRight: "auto" }}
              onClick={handleitemuneditClose}
              color="danger"
            >
              <Close />
              Cancel
            </Button>
            <Button
              style={{ float: "right" }}
              color="danger"
              onClick={handleitemaddClose}
            >
              Create <Arrow />
            </Button>
          </DialogActions>
        )}
      </Dialog>
      <Dialog
        fullWidth={true}
        maxWidth={!addloading ? "sm" : "xs"}
        open={edititem}
        TransitionComponent={Transition}
        keepMounted
        onClose={edithandleitemuneditClose}
        aria-labelledby="alert-dialog-slide-title"
        aria-describedby="alert-dialog-slide-description"
      >
        <DialogContent>
          <DialogContentText id="alert-dialog-slide-description">
            <GridContainer>
              <GridItem xs={12} sm={12} md={12}>
                <>
                  <CardHeader color={!addloading && "danger"}>
                    <h4
                      style={{ textAlign: "center" }}
                      className={
                        !addloading
                          ? classes.cardTitleWhite
                          : classes.cardTitleBlack
                      }
                    >
                      {addloading ? "Updating Item" : "Update Item"}
                    </h4>
                  </CardHeader>
                  {addloading ? (
                    <CardBody>
                      <GridContainer>
                        <GridItem
                          style={{ textAlign: "center" }}
                          xs={12}
                          sm={12}
                          md={12}
                        >
                          <CircularProgress color="secondary" />
                        </GridItem>
                      </GridContainer>
                    </CardBody>
                  ) : (
                    <CardBody>
                      <GridContainer>
                        <form
                          style={{ width: "100%" }}
                          onSubmit={(e) => handleitemeditClose(e)}
                        >
                          <GridItem xs={12} sm={12} md={12}>
                            <TextField
                              onChange={itemChange}
                              value={itemdata.name}
                              autoFocus
                              margin="dense"
                              id="name"
                              label="Item Name"
                              type="text"
                              fullWidth
                              error={itemerrors.itemname}
                              helperText={
                                itemerrors.itemname && itemerrors.itemname
                              }
                            />
                          </GridItem>
                          <GridItem xs={12} sm={12} md={12}>
                            <TextField
                              onChange={itemChange}
                              id="desc"
                              value={itemdata.desc}
                              label="Description"
                              style={{ marginTop: 20 }}
                              fullWidth
                              type="text"
                              margin="normal"
                              InputProps={{
                                endAdornment: (
                                  <InputAdornment position="start">
                                    <Edit />
                                  </InputAdornment>
                                ),
                              }}
                              error={itemerrors.itemdesc}
                              helperText={
                                itemerrors.itemdesc && itemerrors.itemdesc
                              }
                            />
                          </GridItem>
                          <GridItem xs={12} sm={12} md={12}>
                            <label>Image</label>
                            <br />
                            <input
                              onChange={onItemImageChange}
                              id="itemeditimg"
                              type="file"
                            />
                            <Danger>{itemerrors.itemimg}</Danger>
                          </GridItem>
                        </form>
                      </GridContainer>
                      <GridContainer>
                        <GridItem xs={12} sm={12} md={2} />
                        <GridItem xs={12} sm={12} md={8}>
                          {preview != null && (
                            <img width="100%" src={preview} />
                          )}
                        </GridItem>
                        <GridItem xs={12} sm={12} md={2} />
                      </GridContainer>
                    </CardBody>
                  )}
                </>
              </GridItem>
            </GridContainer>
          </DialogContentText>
        </DialogContent>
        {!addloading && (
          <DialogActions>
            <Button
              style={{ display: "block", marginLeft: "0", marginRight: "auto" }}
              onClick={edithandleitemuneditClose}
              color="danger"
            >
              <Close />
              Cancel
            </Button>
            <Button
              style={{ float: "right" }}
              color="danger"
              onClick={(e) => handleitemeditClose(e)}
            >
              Update <Arrow />
            </Button>
          </DialogActions>
        )}
      </Dialog>

      <Dialog
        fullWidth={true}
        maxWidth={!addloading ? "sm" : "xs"}
        open={addbound}
        TransitionComponent={Transition}
        keepMounted
        onClose={handlebounduneditClose}
        aria-labelledby="alert-dialog-slide-title"
        aria-describedby="alert-dialog-slide-description"
      >
        <DialogContent>
          <DialogContentText id="alert-dialog-slide-description">
            <GridContainer>
              <GridItem xs={12} sm={12} md={12}>
                <>
                  <CardHeader color={!addloading && "danger"}>
                    <h4
                      style={{ textAlign: "center" }}
                      className={
                        !addloading
                          ? classes.cardTitleWhite
                          : classes.cardTitleBlack
                      }
                    >
                      {addloading ? "Adding Boundary" : "Add Boundary"}
                    </h4>
                  </CardHeader>
                  {addloading ? (
                    <CardBody>
                      <GridContainer>
                        <GridItem
                          style={{ textAlign: "center" }}
                          xs={12}
                          sm={12}
                          md={12}
                        >
                          <CircularProgress color="secondary" />
                        </GridItem>
                      </GridContainer>
                    </CardBody>
                  ) : (
                    <CardBody>
                      <GridContainer>
                        <form
                          style={{ width: "100%" }}
                          onSubmit={handleboundaddClose}
                        >
                          <GridItem xs={12} sm={12} md={12}>
                            <TextField
                              onChange={boundaryChange}
                              value={boundarydata.name}
                              autoFocus
                              margin="dense"
                              id="name"
                              label="Boundary Name"
                              type="text"
                              fullWidth
                              error={boundaryerrors.boundaryname}
                              helperText={
                                boundaryerrors.boundaryname &&
                                boundaryerrors.boundaryname
                              }
                            />
                          </GridItem>
                          <GridItem xs={12} sm={12} md={12}>
                            <label>Image</label>
                            <br />
                            <input
                              onChange={onBoundaryImageChange}
                              id="boundaddimg"
                              type="file"
                            />
                            <Danger>{boundaryerrors.boundaryimg}</Danger>
                          </GridItem>
                        </form>
                      </GridContainer>
                      <GridContainer>
                        <GridItem xs={12} sm={12} md={2} />
                        <GridItem xs={12} sm={12} md={8}>
                          {preview != null && (
                            <img width="100%" src={preview} />
                          )}
                        </GridItem>
                        <GridItem xs={12} sm={12} md={2} />
                      </GridContainer>
                    </CardBody>
                  )}
                </>
              </GridItem>
            </GridContainer>
          </DialogContentText>
        </DialogContent>
        {!addloading && (
          <DialogActions>
            <Button
              style={{ display: "block", marginLeft: "0", marginRight: "auto" }}
              onClick={handlebounduneditClose}
              color="danger"
            >
              <Close />
              Cancel
            </Button>
            <Button
              style={{ float: "right" }}
              color="danger"
              onClick={handleboundaddClose}
            >
              Create <Arrow />
            </Button>
          </DialogActions>
        )}
      </Dialog>
      <Dialog
        fullWidth={true}
        maxWidth={!addloading ? "sm" : "xs"}
        open={editbound}
        TransitionComponent={Transition}
        keepMounted
        onClose={edithandlebounduneditClose}
        aria-labelledby="alert-dialog-slide-title"
        aria-describedby="alert-dialog-slide-description"
      >
        <DialogContent>
          <DialogContentText id="alert-dialog-slide-description">
            <GridContainer>
              <GridItem xs={12} sm={12} md={12}>
                <>
                  <CardHeader color={!addloading && "danger"}>
                    <h4
                      style={{ textAlign: "center" }}
                      className={
                        !addloading
                          ? classes.cardTitleWhite
                          : classes.cardTitleBlack
                      }
                    >
                      {addloading ? "Updating Boundary" : "Update Boundary"}
                    </h4>
                  </CardHeader>
                  {addloading ? (
                    <CardBody>
                      <GridContainer>
                        <GridItem
                          style={{ textAlign: "center" }}
                          xs={12}
                          sm={12}
                          md={12}
                        >
                          <CircularProgress color="secondary" />
                        </GridItem>
                      </GridContainer>
                    </CardBody>
                  ) : (
                    <CardBody>
                      <GridContainer>
                        <form
                          style={{ width: "100%" }}
                          onSubmit={(e) => handleboundeditClose(e)}
                        >
                          <GridItem xs={12} sm={12} md={12}>
                            <TextField
                              disabled
                              onChange={boundaryChange}
                              value={boundarydata.name}
                              autoFocus
                              margin="dense"
                              id="name"
                              label="Boundary Name"
                              type="text"
                              fullWidth
                              error={boundaryerrors.boundaryname}
                              helperText={
                                boundaryerrors.boundaryname &&
                                boundaryerrors.boundaryname
                              }
                            />
                          </GridItem>
                          <GridItem xs={12} sm={12} md={12}>
                            <label>Image</label>
                            <br />
                            <input
                              onChange={onBoundaryImageChange}
                              id="boundeditimg"
                              type="file"
                            />
                            <Danger>{boundaryerrors.boundaryimg}</Danger>
                          </GridItem>
                        </form>
                      </GridContainer>
                      <GridContainer>
                        <GridItem xs={12} sm={12} md={2} />
                        <GridItem xs={12} sm={12} md={8}>
                          {preview != null && (
                            <img width="100%" src={preview} />
                          )}
                        </GridItem>
                        <GridItem xs={12} sm={12} md={2} />
                      </GridContainer>
                    </CardBody>
                  )}
                </>
              </GridItem>
            </GridContainer>
          </DialogContentText>
        </DialogContent>
        {!addloading && (
          <DialogActions>
            <Button
              style={{ display: "block", marginLeft: "0", marginRight: "auto" }}
              onClick={edithandlebounduneditClose}
              color="danger"
            >
              <Close />
              Cancel
            </Button>
            <Button
              style={{ float: "right" }}
              color="danger"
              onClick={(e) => handleboundeditClose(e)}
            >
              Update <Arrow />
            </Button>
          </DialogActions>
        )}
      </Dialog>
      <Dialog
        fullWidth={true}
        maxWidth={!addloading ? "sm" : "xs"}
        open={addpack}
        TransitionComponent={Transition}
        keepMounted
        onClose={handlepackuneditClose}
        aria-labelledby="alert-dialog-slide-title"
        aria-describedby="alert-dialog-slide-description"
      >
        <DialogContent>
          <DialogContentText id="alert-dialog-slide-description">
            <GridContainer>
              <GridItem xs={12} sm={12} md={12}>
                <>
                  <CardHeader color={!addloading && "danger"}>
                    <h4
                      style={{ textAlign: "center" }}
                      className={
                        !addloading
                          ? classes.cardTitleWhite
                          : classes.cardTitleBlack
                      }
                    >
                      {addloading ? "Adding Package" : "Add Package"}
                    </h4>
                  </CardHeader>
                  {addloading ? (
                    <CardBody>
                      <GridContainer>
                        <GridItem
                          style={{ textAlign: "center" }}
                          xs={12}
                          sm={12}
                          md={12}
                        >
                          <CircularProgress color="secondary" />
                        </GridItem>
                      </GridContainer>
                    </CardBody>
                  ) : (
                    <CardBody>
                      <GridContainer>
                        <form
                          style={{ width: "100%" }}
                          onSubmit={handlepackaddClose}
                        >
                          <GridItem xs={12} sm={12} md={12}>
                            <TextField
                              onChange={packageChange}
                              value={packagedata.name}
                              autoFocus
                              margin="dense"
                              id="name"
                              label="Package Name"
                              type="text"
                              fullWidth
                              error={packageerrors.packagename}
                              helperText={
                                packageerrors.packagename &&
                                packageerrors.packagename
                              }
                            />
                          </GridItem>
                          <GridItem xs={12} sm={12} md={12}>
                            <label>Image</label>
                            <br />
                            <input
                              onChange={onPackageImageChange}
                              id="packaddimg"
                              type="file"
                            />
                            <Danger>{packageerrors.packageimg}</Danger>
                          </GridItem>
                        </form>
                      </GridContainer>
                      <GridContainer>
                        <GridItem xs={12} sm={12} md={2} />
                        <GridItem xs={12} sm={12} md={8}>
                          {preview != null && (
                            <img width="100%" src={preview} />
                          )}
                        </GridItem>
                        <GridItem xs={12} sm={12} md={2} />
                      </GridContainer>
                    </CardBody>
                  )}
                </>
              </GridItem>
            </GridContainer>
          </DialogContentText>
        </DialogContent>

        {!addloading && (
          <DialogActions>
            <Button
              style={{ display: "block", marginLeft: "0", marginRight: "auto" }}
              onClick={handlepackuneditClose}
              color="danger"
            >
              <Close />
              Cancel
            </Button>
            <Button
              style={{ float: "right" }}
              color="danger"
              onClick={handlepackaddClose}
            >
              Create <Arrow />
            </Button>
          </DialogActions>
        )}
      </Dialog>
      <Dialog
        fullWidth={true}
        maxWidth={!addloading ? "sm" : "xs"}
        open={editpack}
        TransitionComponent={Transition}
        keepMounted
        onClose={edithandlepackuneditClose}
        aria-labelledby="alert-dialog-slide-title"
        aria-describedby="alert-dialog-slide-description"
      >
        <DialogContent>
          <DialogContentText id="alert-dialog-slide-description">
            <GridContainer>
              <GridItem xs={12} sm={12} md={12}>
                <>
                  <CardHeader color={!addloading && "danger"}>
                    <h4
                      style={{ textAlign: "center" }}
                      className={
                        !addloading
                          ? classes.cardTitleWhite
                          : classes.cardTitleBlack
                      }
                    >
                      {addloading ? "Updating Package" : "Update Package"}
                    </h4>
                  </CardHeader>
                  {addloading ? (
                    <CardBody>
                      <GridContainer>
                        <GridItem
                          style={{ textAlign: "center" }}
                          xs={12}
                          sm={12}
                          md={12}
                        >
                          <CircularProgress color="secondary" />
                        </GridItem>
                      </GridContainer>
                    </CardBody>
                  ) : (
                    <CardBody>
                      <GridContainer>
                        <form
                          style={{ width: "100%" }}
                          onSubmit={(e) => handlepackeditClose(e)}
                        >
                          <GridItem xs={12} sm={12} md={12}>
                            <TextField
                              disabled
                              onChange={packageChange}
                              value={packagedata.name}
                              autoFocus
                              margin="dense"
                              id="name"
                              label="Package Name"
                              type="text"
                              fullWidth
                              error={packageerrors.packagename}
                              helperText={
                                packageerrors.packagename &&
                                packageerrors.packagename
                              }
                            />
                          </GridItem>
                          <GridItem xs={12} sm={12} md={12}>
                            <label>Image</label>
                            <br />
                            <input
                              onChange={onPackageImageChange}
                              id="packeditimg"
                              type="file"
                            />
                            <Danger>{packageerrors.packageimg}</Danger>
                          </GridItem>
                        </form>
                      </GridContainer>
                      <GridContainer>
                        <GridItem xs={12} sm={12} md={2} />
                        <GridItem xs={12} sm={12} md={8}>
                          {preview != null && (
                            <img width="100%" src={preview} />
                          )}
                        </GridItem>
                        <GridItem xs={12} sm={12} md={2} />
                      </GridContainer>
                    </CardBody>
                  )}
                </>
              </GridItem>
            </GridContainer>
          </DialogContentText>
        </DialogContent>
        {!addloading && (
          <DialogActions>
            <Button
              style={{ display: "block", marginLeft: "0", marginRight: "auto" }}
              onClick={edithandlepackuneditClose}
              color="danger"
            >
              <Close />
              Cancel
            </Button>
            <Button
              style={{ float: "right" }}
              color="danger"
              onClick={(e) => handlepackeditClose(e)}
            >
              Update <Arrow />
            </Button>
          </DialogActions>
        )}
      </Dialog>
      <Dialog
        fullWidth={true}
        maxWidth="sm"
        open={openconfirm}
        TransitionComponent={Transition}
        keepMounted
        classes={{ scrollPaper: classes.scrollPaper }}
        onClose={handleconfirmClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          {"DELETE "}
          {modtype == "vehicle" && "VEHICLE"}
          {modtype == "boundary" && "BOUNDARY"}
          {modtype == "item" && "ITEM"}
          {modtype == "package" && "PACKAGE"}
          {modtype == "service" && "SERVICE"}
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            Are you sure you want to delete this{" "}
            {modtype == "vehicle" && "vehicle"}
            {modtype == "item" && "Item"}
            {modtype == "boundary" && "boundary"}
            {modtype == "package" && "package"}
            {modtype == "service" && "service"}?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button
            style={{ display: "block", marginLeft: "0", marginRight: "auto" }}
            onClick={handleconfirmClose}
            color="danger"
          >
            <Close />
            Cancel
          </Button>
          <Button
            style={{ float: "right" }}
            color="danger"
            onClick={processDeletion}
          >
            Proceed <Arrow />
          </Button>
        </DialogActions>
      </Dialog>
    </GridContainer>
  );
}

Configurations.propTypes = {
  url: PropTypes.string,
  imgurl: PropTypes.string,
  exit: PropTypes.string,
};
