import React, { useEffect, useState } from "react";
import PersonOutlineIcon from "@material-ui/icons/PersonOutline";
import DialogContent from "@material-ui/core/DialogContent";
import { makeStyles } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import FlightIcon from "@material-ui/icons/Flight";
import TablePagination from '@material-ui/core/TablePagination';
import Danger from "components/Typography/Danger.js";
import MenuItem from "@material-ui/core/MenuItem";
import InputLabel from "@material-ui/core/InputLabel";
import FormControl from "@material-ui/core/FormControl";
import { faBoxOpen } from "@fortawesome/free-solid-svg-icons";
import Select from "@material-ui/core/Select";
import LocationOnIcon from "@material-ui/icons/LocationOn";
import Box from "@material-ui/core/Box";
import PropTypes from "prop-types";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import VisibilityOffIcon from "@material-ui/icons/VisibilityOff";
import PhoneIcon from "@material-ui/icons/Phone";
import { Link } from "react-router-dom";
import LockOpenIcon from "@material-ui/icons/LockOpen";
import MailOutlineIcon from "@material-ui/icons/MailOutline";
import InputAdornment from "@material-ui/core/InputAdornment";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import clsx from "clsx";
import DialogTitle from "@material-ui/core/DialogTitle";
import Divider from "@material-ui/core/Divider";
import Drawer from "@material-ui/core/Drawer";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText";
import Details from "@material-ui/icons/Visibility";
import Close from "@material-ui/icons/Close";
import Arrow from "@material-ui/icons/DoubleArrow";
import Avatar from "@material-ui/core/Avatar";
import CircularProgress from "@material-ui/core/CircularProgress";
import TableRow from "@material-ui/core/TableRow";
import View from "@material-ui/icons/Visibility";
import TableCell from "@material-ui/core/TableCell";
import TextField from "@material-ui/core/TextField";
import Table from "components/Table/Table.js";
import Card from "components/Card/Card.js";
import CardHeader from "components/Card/CardHeader.js";
import Backdrop from "@material-ui/core/Backdrop";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import CardBody from "components/Card/CardBody.js";
import Edit from "@material-ui/icons/Edit";
import Delete from "@material-ui/icons/DeleteOutline";
import Button from "components/CustomButtons/Button.js";
import Add from "@material-ui/icons/Add";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContentText from "@material-ui/core/DialogContentText";
import GridItem from "components/Grid/GridItem.js";
import GridContainer from "components/Grid/GridContainer.js";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import IconButton from "@material-ui/core/IconButton";
import CloseIcon from "@material-ui/icons/Close";
import Slide from "@material-ui/core/Slide";
import ChartistGraph from "react-chartist";
import { monthlyDeliveriesChart } from "variables/charts";

const styles = {
  appBar: {
    position: "relative",
    background: "#ec4c48",
  },
  scrollPaper: {
    alignItems: "baseline", // default center
  },
  title: {
    flex: 1,
  },
  detailcell: {
    display: "flex",
    alignItems: "center",
    flexWrap: "wrap",
    marginBottom: "15px",
  },
  avatarimg: {
    display: "block",
    marginLeft: "auto",
    marginRight: "auto",
  },
  centertext: {
    textAlign: "center",
  },
  flexContainer: {
    display: "flex",
    flexFlow: "row wrap",
    justifyContent: "space-around",
    padding: 0,
    margin: 0,
    listStyle: "none",
  },
  flexItem: {
    position: "relative",
    width: "150px",
    height: "150px",
    marginTop: "5px",
    lineHeight: "150px",
    textAlign: "center",
  },
  vehicleimg: {
    display: "block",
    height: 50,
    width: 50,
    marginLeft: "auto",
    marginRight: "auto",
  },
  vehimg: {
    width: "100%",
    height: "100%",
  },

  overlay: {
    display: "none",
  },
  playIcon: {
    position: "absolute",
    fontSize: "50px",
    color: "green",
    cursor: "pointer",
    left: "0",
    right: "0",
    marginLeft: "auto",
    marginRight: "auto",
    top: "0",
    bottom: "0",
    marginTop: "auto",
    marginBottom: "auto",
    width: "150px",
  },
  selected: {
    position: "absolute",
    opacity: "1",
    color: "green",
    top: "0",
    bottom: "0",
    left: "0",
    right: "0",
  },
  list: {
    width: "100%",
    position: "relative",
  },
  detailhead: {
    marginTop: "10",
    marginBottom: "0",
  },

  backdrop: {
    zIndex: 9999,
    color: "#fff",
  },
  tableCell: {
    textAlign: "center",
  },
  cardCategoryWhite: {
    "&,& a,& a:hover,& a:focus": {
      color: "rgba(255,255,255,.62)",
      margin: "0",
      fontSize: "14px",
      marginTop: "0",
      marginBottom: "0",
    },
    "& a,& a:hover,& a:focus": {
      color: "#FFFFFF",
    },
  },
  cardTitleWhite: {
    color: "#FFFFFF",
    marginTop: "0px",
    minHeight: "auto",
    fontWeight: "300",
    fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif",
    marginBottom: "3px",
    textDecoration: "none",
    "& small": {
      color: "#777",
      fontSize: "65%",
      fontWeight: "400",
      lineHeight: "1",
    },
  },
  cardTitleBlack: {
    color: "#000000",
    marginTop: "0px",
    minHeight: "auto",
    fontWeight: "300",
    fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif",
    marginBottom: "3px",
    textDecoration: "none",
    "& small": {
      color: "#777",
      fontSize: "65%",
      fontWeight: "400",
      lineHeight: "1",
    },
  },
};

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`scrollable-force-tabpanel-${index}`}
      aria-labelledby={`scrollable-force-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box p={3}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};

function a11yProps(index) {
  return {
    id: `scrollable-force-tab-${index}`,
    "aria-controls": `scrollable-force-tabpanel-${index}`,
  };
}

const useStyles = makeStyles(styles);
const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="down" ref={ref} {...props} />;
});
const Transition2 = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

export default function Agents(props) {
  toast.configure();
  const classes = useStyles();
  const [addlocalopen, setAddlocalopen] = useState(false);
  const [editlocalopen, setEditlocalopen] = useState(false);
  const [addintlopen, setAddintlopen] = useState(false);
  const [editintlopen, setEditintlopen] = useState(false);
  const [agentdel, setAgentdel] = useState([]);
  const [editid, setEditid] = useState(0);
  const [agent_id, setAgent_id] = useState(0);
  const [packages, setPackages] = useState([]);
  const [opendrawer, setOpendrawer] = React.useState(false);
  const [searchTerm, setSearchTerm] = useState("");
  const [searchTerm2, setSearchTerm2] = useState("");
  const [searchTerm3, setSearchTerm3] = useState("");
  const [loading, setLoading] = useState(true);
  const [locations, setLocations] = useState([]);
  const [facilities, setFacilities] = useState([]);
  const [localAgents, setLocalAgents] = useState([]);
  const [intlAgents, setIntlAgents] = useState([]);
  const [addloading, setAddloading] = useState(false);
  const [agentdetopen, setagentdetopen] = useState(false);
  const [selagent, setSelagent] = useState("");
  const [value, setValue] = useState(0);
  const [userData, setUserData] = useState({
    firstname: "",
    lastname: "",
    email: "",
    phonenumber: "",
    assigned:0,
    password: "",
    confirm: "",
  });
  const [compagentdelprop, setCompagentdelprop] = useState({
    name: "",
    deliveries: 0,
    chart: [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
  });
  const [errors, seterrors] = useState([]);
  const [deleteid, setDeleteid] = useState(0);
  const [openconfirm, setOpenconfirm] = useState(false);

  const [page1, setPage1] = React.useState(0);
  const [totalcount1, setTotalcount1] = React.useState(0);
  const [rowsPerPage1, setRowsPerPage1] = React.useState(10);
  const [page2, setPage2] = React.useState(0);
  const [totalcount2, setTotalcount2] = React.useState(0);
  const [rowsPerPage2, setRowsPerPage2] = React.useState(10);
  const [page3, setPage3] = React.useState(0);
  const [totalcount3, setTotalcount3] = React.useState(0);
  const [rowsPerPage3, setRowsPerPage3] = React.useState(10);

  const handleChangePage1 = (event, newPage) => {
    setLoading(true)
    setPage1(newPage);
  };

  const handleChangeRowsPerPage1 = (event) => {
    setRowsPerPage1(parseInt(event.target.value, 10));
    setPage1(0);
    setLoading(true)
  };

  const handleChangePage2 = (event, newPage) => {
    setLoading(true)
    setPage2(newPage);
  };

  const handleChangeRowsPerPage2 = (event) => {
    setRowsPerPage2(parseInt(event.target.value, 10));
    setPage2(0);
    setLoading(true)
  };



  const handleChangePage3 = (event, newPage) => {
    setPage3(newPage);
    setLoading(true)
    getAgentDeliveries(agent_id,newPage+1,rowsPerPage3)
  };

  const handleChangeRowsPerPage3 = (event) => {
    setRowsPerPage3(parseInt(event.target.value, 10));
    setPage3(0);
    setLoading(true)
    getAgentDeliveries(agent_id,1,parseInt(event.target.value, 10))
  };


  const handletabChange = (event, newValue) => {
    setValue(newValue);
  };

  const handleconfirmClose = () => {
    setDeleteid(0);
    setOpenconfirm(false);
  };

  const getLocalAgents = async (paged=0) => {
    if(paged){
      setPage1(paged-1)
    }
    let response = await fetch(props.url + `/get-local-agents?${searchTerm && 'search='+searchTerm+'&'}page=${paged?paged-1:page1+1}&perpage=${rowsPerPage1}`, { credentials: "include" });
    let data = await response.json();
    if (data.success) {
      setLocalAgents(data.data.data);
      setPage1(data.data.current_page - 1)
      setTotalcount1(data.data.total)
      setLoading(false);
    } else {
      setLoading(false);
      if( data.message == 'unauthenticated'){
        window.location.replace(props.exit);
        return;
      }
      toast.error(data.message);
    }
  };

  const getInternationalAgents = async (paged=0) => {
    if(paged){
      setPage2(paged-1)
    }
    let response = await fetch(props.url + `/get-international-agents?${searchTerm3 && 'search='+searchTerm3+'&'}page=${paged?paged-1:page2+1}&perpage=${rowsPerPage2}`, { credentials: "include" });
    let data = await response.json();
    if (data.success) {
      setIntlAgents(data.data.data);
      setPage2(data.data.current_page - 1)
      setTotalcount2(data.data.total)
      setLoading(false);
    } else {
      setLoading(false);
      if( data.message == 'unauthenticated'){
        window.location.replace(props.exit);
        return;
      }
      toast.error(data.message);
    }
  };

  const getData = async () => {
    let response = await fetch(props.url + "/get-agents-data", {
      credentials: "include",
    });
    let data = await response.json();
    if (data.success) {
      setLocations(data.data[0])
      setFacilities(data.data[1])
      setLoading(false);
    } else {
      setLoading(false);
      if( data.message == 'unauthenticated'){
        window.location.replace(props.exit);
        return;
      }
      toast.error(data.message);
    }
  };

  useEffect(() => {
    getLocalAgents()
    getInternationalAgents()
    getData();
  }, [page1,rowsPerPage1,page2,rowsPerPage2]);

  const handleChange = (event) => {
    setUserData({ ...userData, [event.target.id]: event.target.value });
  };


  const validate = () => {
    let temp = [];
    temp.firstname = userData.firstname ? "" : "This field is required";
    temp.lastname = userData.lastname ? "" : "This field is required";
    temp.assigned = userData.assigned ? "" : "This field is required";
    if (!userData.email) {
      temp.email = "This field is required";
    } else if (!/\S+@\S+\.\S+/.test(userData.email)) {
      temp.email = "Email address is invalid";
    }
    if (!userData.phonenumber) {
      temp.phonenumber = "This field is required";
    } else if (userData.phonenumber.length < 7) {
      temp.phonenumber = "Phone number should be at least 7 digits";
    }
    temp.password = userData.password ? "" : "This field is required";
    if (!userData.confirm) {
      temp.confirm = "This field is required";
    } else if (userData.confirm !== userData.password) {
      temp.confirm = "Passwords dont match";
    }
    seterrors({ ...temp });
    return Object.values(temp).every((x) => x == "");
  };

  const updatevalidate = () => {
    let temp = [];
    temp.firstname = userData.firstname ? "" : "This field is required";
    temp.lastname = userData.lastname ? "" : "This field is required";
    temp.assigned = userData.assigned ? "" : "This field is required";
    if (!userData.email) {
      temp.email = "This field is required";
    } else if (!/\S+@\S+\.\S+/.test(userData.email)) {
      temp.email = "Email address is invalid";
    }
    if (!userData.phonenumber) {
      temp.phonenumber = "This field is required";
    } else if (userData.phonenumber.length < 7) {
      temp.phonenumber = "Phone number should be at least 7 digits";
    }

    if (userData.password) {
      if (!userData.confirm) {
        temp.confirm = "This field is required";
      } else {
        if (userData.confirm !== userData.password) {
          temp.confirm = "Passwords dont match";
        }
      }
    }
    seterrors({ ...temp });
    return Object.values(temp).every((x) => x == "");
  };

  const toggleDraweropen = async (event, $id) => {
    if (
      event.type === "keydown" &&
      (event.key === "Tab" || event.key === "Shift")
    ) {
      return;
    }
    setLoading(true);
    let response = await fetch(props.url + `/get-delivery-packages/${$id}`, {
      credentials: "include",
    });
    let res = await response.json();
    if (res) {
      setLoading(false);
      if (res.success) {
        setPackages(res.data);
        console.log(packages);
        setOpendrawer(true);
      } else {
        if( res.message == 'unauthenticated'){
          window.location.replace(props.exit);
          return;
        }
        toast.error(res.message);
      }
    }
  };

  const toggleDrawerclose = (event) => {
    if (
      event.type === "keydown" &&
      (event.key === "Tab" || event.key === "Shift")
    ) {
      return;
    }
    setPackages([]);
    setOpendrawer(false);
  };

  const handleagentdelClose = () => {
    setAgentdel([]);
    setCompagentdelprop({
      name: "",
      deliveries: 0,
      chart: [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
    });
    setAgent_id(0)
    setSearchTerm2("")
    setagentdetopen(false);
  };


  const searchHandler = async () => {
    getLocalAgents(1)
  };

  const searchHandler3 = async () => {
    getInternationalAgents(1)
  };


  const handleAddLocalClose = async (event) => {
    event.preventDefault();
    if (validate()) {
      setAddloading(true);
      let response = await fetch(props.url + "/create-local-agent", {
        method: "POST",
        headers: {
          "Content-type": "application/json",
        },
        body: JSON.stringify(userData),
        credentials: "include",
      });
      let res = await response.json();
      if (res) {
        if (res.success) {
          setLocalAgents([...localAgents, { ...res.data }]);
          addhandleLocalClose();
          toast.success("Adent added");
        } else {
          setAddloading(false);
          if( res.message == 'unauthenticated'){
            window.location.replace(props.exit);
            return;
          }
          else if (res.message.email[0]) {
            setAddloading(false);
            seterrors({ ...errors, email: res.message.email[0] });
          } else {
            addhandleLocalClose();
            toast.error(res.message);
          }
        }
      }
    }
  };

  const handleAddIntlClose = async (event) => {
    event.preventDefault();
    if (validate()) {
      setAddloading(true);
      let response = await fetch(props.url + "/create-international-agent", {
        method: "POST",
        headers: {
          "Content-type": "application/json",
        },
        body: JSON.stringify(userData),
        credentials: "include",
      });
      let res = await response.json();
      if (res) {
        if (res.success) {
            setIntlAgents([...intlAgents, { ...res.data }]);
          addhandleIntlClose();
          toast.success("Adent added");
        } else {
          setAddloading(false);
          if( res.message == 'unauthenticated'){
            window.location.replace(props.exit);
            return;
          }
          else if (res.message.email[0]) {
            seterrors({ ...errors, email: res.message.email[0] });
          } else {
            addhandleIntlClose();
            toast.error(res.message);
          }
        }
      }
    }
  };

  const handleEditLocalClose = async (event) => {
    event.preventDefault();
    if (updatevalidate()) {
      setAddloading(true);
      let response = await fetch(props.url + `/update-local-agent/${editid}`, {
        method: "PUT",
        headers: {
          "Content-type": "application/json",
        },
        body: JSON.stringify(userData),
        credentials: "include",
      });
      let res = await response.json();
      if (res) {
        if (res.success) {
          setLocalAgents(localAgents.map((prop) => (prop.id === editid ? { ...res.data } : prop)));
          edithandleLocalClose();
          toast.success("Agent Updated");
        } else {
          setAddloading(false);
          if( res.message == 'unauthenticated'){
            window.location.replace(props.exit);
            return;
          }
          else if (res.message.email[0]) {
            seterrors({ ...errors, email: res.message.email[0] });
          } else {
            edithandleLocalClose();
            toast.error(res.message);
          }
        }
      }
    }
  };

  const handleEditIntlClose = async (event) => {
    event.preventDefault();
    if (updatevalidate()) {
      setAddloading(true);
      let response = await fetch(props.url + `/update-international-agent/${editid}`, {
        method: "PUT",
        headers: {
          "Content-type": "application/json",
        },
        body: JSON.stringify(userData),
        credentials: "include",
      });
      let res = await response.json();
      if (res) {
        if (res.success) {
            setIntlAgents(intlAgents.map((prop) => (prop.id === editid ? { ...res.data } : prop)));
          edithandleIntlClose();
          toast.success("Agent Updated");
        } else {
          setAddloading(false);
          if( res.message == 'unauthenticated'){
            window.location.replace(props.exit);
            return;
          }
          else if (res.message.email[0]) {
            seterrors({ ...errors, email: res.message.email[0] });
          } else {
            edithandleIntlClose();
            toast.error(res.message);
          }
        }
      }
    }
  };

  const handleClickLocalOpen = async (id) => {
    setLoading(true);
    let response = await fetch(props.url + `/get-agent/${id}`, {
      credentials: "include",
    });
    let res = await response.json();
    if (res) {
      setLoading(false);
      if (res.success) {
        setUserData({
          firstname: res.data.first_name,
          lastname: res.data.last_name,
          email: res.data.email,
          phonenumber: res.data.phone_number,
          assigned: res.data.agent_facility ? res.data.agent_facility.facility.id : res.data.agent_location.location.id,
          password: "",
          confirm: "",
        });
        setEditid(id);
        setEditlocalopen(true);
      } else {
          if( res.message == 'unauthenticated'){
            window.location.replace(props.exit);
            return;
          }
        toast.error(res.message);
      }
    }
  };

  const handleClickIntlOpen = async (id) => {
    setLoading(true);
    let response = await fetch(props.url + `/get-agent/${id}`, {
      credentials: "include",
    });
    let res = await response.json();
    if (res) {
      setLoading(false);
      if (res.success) {
        setUserData({
          firstname: res.data.first_name,
          lastname: res.data.last_name,
          email: res.data.email,
          phonenumber: res.data.phone_number,
          assigned: res.data.agent_facility ? res.data.agent_facility.facility.id : res.data.agent_location.location.id,
          password: "",
          confirm: "",
        });
        setEditid(id);
        setEditintlopen(true);
      } else {
        if( res.message == 'unauthenticated'){
          window.location.replace(props.exit);
          return;
        }
        toast.error(res.message);
      }
    }
  };

  const confirmdeleteAgent = (id,agenttype) => {
    setSelagent(agenttype)
    setDeleteid(id);
    setOpenconfirm(true);
  };

  const deleteAgent = async () => {
    setLoading(true);
    const cid = deleteid;
    let response = await fetch(props.url + "/delete-agent", {
      method: "DELETE",
      headers: {
        "Content-type": "application/json",
      },
      body: JSON.stringify({ id: deleteid }),
      credentials: "include",
    });
    let res = await response.json();
    if (res) {
      setDeleteid(0);
      setOpenconfirm(false);
      setLoading(false);
      if (res.success) {
        if(selagent=='local'){
          setLocalAgents(localAgents.filter((current) => cid !== current.id));
        }
        else if(selagent=='international'){
        setIntlAgents(intlAgents.filter((current) => cid !== current.id));
        }
        toast.success("Agent deleted");
      } else {
        if( res.message == 'unauthenticated'){
          window.location.replace(props.exit);
          return;
        }
        toast.error(res.message);
      }
      setSelagent("")
    }
  };

  
  const addhandleLocalClose = () => {
    setUserData({
      firstname: "",
      lastname: "",
      email: "",
      phonenumber: "",
      assigned:0,
      password: "",
      confirm: "",
    });
    seterrors([]);
    setAddlocalopen(false);
    setAddloading(false);
  };

  const addhandleIntlClose = () => {
    setUserData({
      firstname: "",
      lastname: "",
      email: "",
      phonenumber: "",
      assigned:0,
      password: "",
      confirm: "",
    });
    seterrors([]);
    setAddintlopen(false);
    setAddloading(false);
  };

  const edithandleLocalClose = () => {
    setUserData({
      firstname: "",
      lastname: "",
      email: "",
      phonenumber: "",
      assigned:0,
      password: "",
      confirm: "",
    });
    seterrors([]);
    setEditlocalopen(false);
    setAddloading(false);
  };

  const edithandleIntlClose = () => {
    setUserData({
      firstname: "",
      lastname: "",
      email: "",
      phonenumber: "",
      assigned:0,
      password: "",
      confirm: "",
    });
    seterrors([]);
    setEditintlopen(false);
    setAddloading(false);
  };


  const getAgentDeliveries = async (id,paged=0,rowed=0) => {
    if(paged){
      setPage3(paged-1)
    }
    setLoading(true);
    let response = await fetch(props.url + `/get-agent-delivery-requests/${id}?${searchTerm2 && 'search='+searchTerm2+'&'}page=${paged?paged:page3+1}&perpage=${rowed? rowed :rowsPerPage3}`, {
      credentials: "include",
    });
    let data = await response.json();
    if (data.success) {
      setAgentdel(data.data.data);
      setPage3(data.data.current_page - 1)
      setTotalcount3(data.data.total)
      setLoading(false);
    } else {
      setLoading(false);
      if( data.message == 'unauthenticated'){
        window.location.replace(props.exit);
        return;
      }
      toast.error(data.message);
    }
  };

  const getAgentDeliveriesStats = async (id) => {
    setLoading(true);
    let response = await fetch(props.url + `/get-agent-delivery-requests-stats/${id}`, {
      credentials: "include",
    });
    let data = await response.json();
    if (data) {
      setLoading(false);
    if (data.success) {
      setAgent_id(id);
      setCompagentdelprop({
          name: data.data.name,
          deliveries: data.data.total_del,
          chart: data.data.monthly_del,
        });
        setagentdetopen(true);
    } else {
      if( data.message == 'unauthenticated'){
        window.location.replace(props.exit);
        return;
      }
      toast.error(data.message);
    }
  }
  };

  const detailsOpen = (id) => {
    setLoading(true)
    getAgentDeliveries(id)
    getAgentDeliveriesStats(id)
  };

  const searchHandler2 =  () => {
    getAgentDeliveries(agent_id,1)
};


  
  const addhandleLocal = () => {
    setAddlocalopen(true);
  };
  const addhandleIntl = () => {
    setAddintlopen(true);
  };
  return (
    <Card>
      {loading && (
        <Backdrop className={classes.backdrop} open>
          <CircularProgress color="inherit" />
        </Backdrop>
      )}
      <CardHeader color="danger">
        <h4 className={classes.cardTitleWhite}>Delivery Agents</h4>
      </CardHeader>
      <CardBody>
        <div className={classes.root}>
          <AppBar position="static" color="default">
            <Tabs
              value={value}
              onChange={handletabChange}
              indicatorColor="secondary"
              textColor="secondary"
              variant="fullWidth"
              scrollButtons="on"
              aria-label="icon label tabs example"
            >
              <Tab
                label="Local Agents"
                icon={<LocationOnIcon />}
                {...a11yProps(0)}
              />
              <Tab
                label="International Agents"
                icon={<FlightIcon />}
                {...a11yProps(1)}
              />
            </Tabs>
          </AppBar>
          <TabPanel value={value} index={0}>
            <Button onClick={addhandleLocal} color="danger">
              <Add /> Add Local Agent
            </Button>
            <Table
              searchKeyword={searchHandler}
              term={searchTerm}
              setTerm={setSearchTerm}
              tableHeaderColor="gray"
              tableHead={[
                "",
                "Name",
                "Email",
                "Telephone",
                "Assigned Sorting Facility",
                "Deliveries",
                "Edit",
                "Delete",
              ]}
              tableData={
                localAgents.length == 0 ? (
                  <TableRow>
                    <TableCell
                      style={{ textAlign: "center" }}
                      colSpan={8}
                      className={classes.tableCell}
                    >
                      No Local Agents
                    </TableCell>
                  </TableRow>
                ) : (
                  localAgents.map((prop) => {
                    return (
                      <TableRow key={prop.id} className={classes.tableBodyRow}>
                        <TableCell className={classes.tableCell} key={prop.id}>
                          <Avatar
                            alt={prop.first_name}
                            src="/static/images/avatar/1.jpg"
                            sx={{ width: 56, height: 56 }}
                          />
                        </TableCell>
                        <TableCell className={classes.tableCell} key={prop.id}>
                          {prop.first_name + " " + prop.last_name}
                        </TableCell>
                        <TableCell className={classes.tableCell} key={prop.id}>
                          {prop.email}
                        </TableCell>
                        <TableCell className={classes.tableCell} key={prop.id}>
                          {prop.phone_number}
                        </TableCell>
                        <TableCell className={classes.tableCell} key={prop.id}>
                          {prop.agent_facility.facility.facility_name}
                        </TableCell>
                        <TableCell className={classes.tableCell} key={prop.id}>
                          <Details
                            onClick={() => detailsOpen(prop.id)}
                            style={{ color: "black", cursor: "pointer" }}
                            key={prop.id}
                          />
                        </TableCell>
                        <TableCell className={classes.tableCell} key={prop.id}>
                          <Edit
                            onClick={() => handleClickLocalOpen(prop.id)}
                            style={{ color: "black", cursor: "pointer" }}
                            key={prop.id}
                          />
                        </TableCell>
                        <TableCell className={classes.tableCell} key={prop.id}>
                          <Delete
                            style={{ cursor: "pointer" }}
                            onClick={() => confirmdeleteAgent(prop.id,"local")}
                            key={prop.id}
                          />
                        </TableCell>
                      </TableRow>
                    );
                  })
                )
              }
            />
            <TablePagination
      component="div"
      count={totalcount1}
      page={page1}
      onPageChange={handleChangePage1}
      rowsPerPage={rowsPerPage1}
      onRowsPerPageChange={handleChangeRowsPerPage1}
    />
          </TabPanel>
          <TabPanel value={value} index={1}>
          <Button onClick={addhandleIntl} color="danger">
              <Add /> Add International Agent
            </Button>
            <Table
              searchKeyword={searchHandler3}
              term={searchTerm3}
              setTerm={setSearchTerm3}
              tableHeaderColor="gray"
              tableHead={[
                "",
                "Name",
                "Email",
                "Telephone",
                "Location",
                "Deliveries",
                "Edit",
                "Delete",
              ]}
              tableData={
                intlAgents.length == 0 ? (
                  <TableRow>
                    <TableCell
                      style={{ textAlign: "center" }}
                      colSpan={8}
                      className={classes.tableCell}
                    >
                      No International Agents
                    </TableCell>
                  </TableRow>
                ) : (
                  intlAgents.map((prop) => {
                    return (
                      <TableRow key={prop.id} className={classes.tableBodyRow}>
                        <TableCell className={classes.tableCell} key={prop.id}>
                          <Avatar
                            alt={prop.first_name}
                            src="/static/images/avatar/1.jpg"
                            sx={{ width: 56, height: 56 }}
                          />
                        </TableCell>
                        <TableCell className={classes.tableCell} key={prop.id}>
                          {prop.first_name + " " + prop.last_name}
                        </TableCell>
                        <TableCell className={classes.tableCell} key={prop.id}>
                          {prop.email}
                        </TableCell>
                        <TableCell className={classes.tableCell} key={prop.id}>
                          {prop.phone_number}
                        </TableCell>
                        <TableCell className={classes.tableCell} key={prop.id}>
                        {prop.agent_location.location.location_name}
                        </TableCell>
                        <TableCell
                              className={classes.tableCell}
                              key={prop.id}
                            >
                              <Details
                                onClick={() => detailsOpen(prop.id)}
                                style={{ color: "black", cursor: "pointer" }}
                                key={prop.id}
                              />
                            </TableCell>
                        <TableCell className={classes.tableCell} key={prop.id}>
                          <Edit
                            onClick={() => handleClickIntlOpen(prop.id)}
                            style={{ color: "black", cursor: "pointer" }}
                            key={prop.id}
                          />
                        </TableCell>
                        <TableCell className={classes.tableCell} key={prop.id}>
                          <Delete
                            style={{ cursor: "pointer" }}
                            onClick={() => confirmdeleteAgent(prop.id,"international")}
                            key={prop.id}
                          />
                        </TableCell>
                      </TableRow>
                    );
                  })
                )
              }
            />
            <TablePagination
      component="div"
      count={totalcount2}
      page={page2}
      onPageChange={handleChangePage2}
      rowsPerPage={rowsPerPage2}
      onRowsPerPageChange={handleChangeRowsPerPage2}
    />
          </TabPanel>
        </div>
      </CardBody>
      <Dialog
        fullWidth={true}
        maxWidth={!addloading ? "sm" : "xs"}
        open={addintlopen}
        TransitionComponent={Transition}
        keepMounted
        onClose={addhandleIntlClose}
        aria-labelledby="alert-dialog-slide-title"
        aria-describedby="alert-dialog-slide-description"
      >
        <DialogContent>
          <DialogContentText id="alert-dialog-slide-description">
            <GridContainer>
              <GridItem xs={12} sm={12} md={12}>
                <>
                  <CardHeader
                    style={{ textAlign: "center" }}
                    color={!addloading ? "danger" : ""}
                  >
                    <h4
                      className={
                        !addloading
                          ? classes.cardTitleWhite
                          : classes.cardTitleBlack
                      }
                    >
                      {!addloading ? "Add International Agent" : "Adding International Agent"}
                    </h4>
                  </CardHeader>
                  <CardBody>
                    {addloading ? (
                      <DialogContentText style={{ textAlign: "center" }}>
                        <CircularProgress color="secondary" />
                      </DialogContentText>
                    ) : (
                      <form onSubmit={handleAddIntlClose}>
                        <GridContainer>
                          <GridItem xs={12} sm={12} md={6}>
                            <TextField
                              onChange={handleChange}
                              id="firstname"
                              value={userData.firstname}
                              label="First Name"
                              style={{ marginTop: 20 }}
                              fullWidth
                              margin="normal"
                              InputProps={{
                                startAdornment: (
                                  <InputAdornment position="start">
                                    <PersonOutlineIcon />
                                  </InputAdornment>
                                ),
                              }}
                              error={errors.firstname}
                              helperText={
                                errors.firstname && errors.firstname
                              }
                            />
                          </GridItem>
                          <GridItem xs={12} sm={12} md={6}>
                            <TextField
                              onChange={handleChange}
                              id="lastname"
                              value={userData.lastname}
                              label="Last Name"
                              style={{ marginTop: 20 }}
                              fullWidth
                              margin="normal"
                              InputProps={{
                                startAdornment: (
                                  <InputAdornment position="start">
                                    <PersonOutlineIcon />
                                  </InputAdornment>
                                ),
                              }}
                              error={errors.lastname}
                              helperText={
                                errors.lastname && errors.lastname
                              }
                            />
                          </GridItem>
                        </GridContainer>


                        <GridContainer>
                          <GridItem xs={12} sm={12} md={12}>
                            <TextField
                              onChange={handleChange}
                              id="email"
                              value={userData.email}
                              label="Email"
                              style={{ marginTop: 20 }}
                              fullWidth
                              type="email"
                              margin="normal"
                              InputProps={{
                                startAdornment: (
                                  <InputAdornment position="start">
                                    <MailOutlineIcon />
                                  </InputAdornment>
                                ),
                              }}
                              error={errors.email}
                              helperText={errors.email && errors.email}
                            />
                          </GridItem>
                        </GridContainer>
                        <GridContainer>
                          <GridItem xs={12} sm={12} md={12}>
                            <TextField
                              onChange={handleChange}
                              id="phonenumber"
                              value={userData.phonenumber}
                              label="Phone Number"
                              style={{ marginTop: 20 }}
                              fullWidth
                              type="number"
                              margin="normal"
                              InputProps={{
                                startAdornment: (
                                  <InputAdornment position="start">
                                    <PhoneIcon />
                                  </InputAdornment>
                                ),
                              }}
                              error={errors.phonenumber}
                              helperText={
                                errors.phonenumber && errors.phonenumber
                              }
                            />
                          </GridItem>
                          </GridContainer>
                        <GridContainer>
                          <GridItem xs={12} sm={12} md={12}>
                            <FormControl
                              style={{ minWidth: "100%", marginTop: 20 }}
                              className={classes.formControl}
                            >
                              <InputLabel id="demo-simple-select-label">
                                Location
                              </InputLabel>
                              <Select
                                value={userData.assigned}
                                onChange={(e) =>
                                  setUserData({
                                    ...userData,
                                    assigned: e.target.value,
                                  })
                                }
                                labelId="demo-simple-select-label"
                                id="assigned"
                              >
                                {locations.length == 0 ? (
                                  <MenuItem>No Locations</MenuItem>
                                ) : (
                                  locations.map((prop) => {
                                    return (
                                      <MenuItem key={prop.id} value={prop.id}>
                                        {prop.location_name}
                                      </MenuItem>
                                    );
                                  })
                                )}
                              </Select>
                            </FormControl>
                            <Danger>{errors.assigned}</Danger>
                          </GridItem>
                        </GridContainer>
                        <GridContainer>
                          <GridItem xs={12} sm={12} md={6}>
                            <TextField
                              onChange={handleChange}
                              value={userData.password}
                              label="Password"
                              type="password"
                              style={{ marginTop: 20 }}
                              id="password"
                              fullWidth
                              margin="normal"
                              InputProps={{
                                startAdornment: (
                                  <InputAdornment position="start">
                                    <LockOpenIcon />
                                  </InputAdornment>
                                ),
                              }}
                              error={errors.password}
                              helperText={
                                errors.password && errors.password
                              }
                            />
                          </GridItem>
                          <GridItem xs={12} sm={12} md={6}>
                            <TextField
                              onChange={handleChange}
                              value={userData.confirm}
                              type="password"
                              label="Confirm Password"
                              style={{ marginTop: 20 }}
                              id="confirm"
                              fullWidth
                              margin="normal"
                              InputProps={{
                                startAdornment: (
                                  <InputAdornment position="start">
                                    <LockOpenIcon />
                                  </InputAdornment>
                                ),
                              }}
                              error={errors.confirm}
                              helperText={
                                errors.confirm && errors.confirm
                              }
                            />
                          </GridItem>
                        </GridContainer>
                      </form>
                    )}
                  </CardBody>
                </>
              </GridItem>
            </GridContainer>
          </DialogContentText>
        </DialogContent>
        {!addloading && (
          <DialogActions>
            <Button
              style={{ display: "block", marginLeft: "0", marginRight: "auto" }}
              onClick={addhandleIntlClose}
              color="danger"
            >
              <Close />
              Cancel
            </Button>
            <Button
              style={{ float: "right" }}
              color="danger"
              onClick={handleAddIntlClose}
            >
              Create <Arrow />
            </Button>
          </DialogActions>
        )}
      </Dialog>
      <Dialog
        fullWidth={true}
        maxWidth={!addloading ? "sm" : "xs"}
        open={editintlopen}
        TransitionComponent={Transition}
        keepMounted
        onClose={edithandleIntlClose}
        aria-labelledby="alert-dialog-slide-title"
        aria-describedby="alert-dialog-slide-description"
      >
        <DialogContent>
          <DialogContentText id="alert-dialog-slide-description">
            <GridContainer>
              <GridItem xs={12} sm={12} md={12}>
                <>
                  <CardHeader
                    style={{ textAlign: "center" }}
                    color={!addloading ? "danger" : ""}
                  >
                    <h4
                      className={
                        !addloading
                          ? classes.cardTitleWhite
                          : classes.cardTitleBlack
                      }
                    >
                      {!addloading ? "Update International Agent" : "Updating International Agent"}
                    </h4>
                  </CardHeader>
                  <CardBody>
                    {addloading ? (
                      <DialogContentText style={{ textAlign: "center" }}>
                        <CircularProgress color="secondary" />
                      </DialogContentText>
                    ) : (
                      <form onSubmit={handleEditIntlClose}>
                       <GridContainer>
                          <GridItem xs={12} sm={12} md={6}>
                            <TextField
                              onChange={handleChange}
                              id="firstname"
                              value={userData.firstname}
                              label="First Name"
                              style={{ marginTop: 20 }}
                              fullWidth
                              margin="normal"
                              InputProps={{
                                startAdornment: (
                                  <InputAdornment position="start">
                                    <PersonOutlineIcon />
                                  </InputAdornment>
                                ),
                              }}
                              error={errors.firstname}
                              helperText={
                                errors.firstname && errors.firstname
                              }
                            />
                          </GridItem>
                          <GridItem xs={12} sm={12} md={6}>
                            <TextField
                              onChange={handleChange}
                              id="lastname"
                              value={userData.lastname}
                              label="Last Name"
                              style={{ marginTop: 20 }}
                              fullWidth
                              margin="normal"
                              InputProps={{
                                startAdornment: (
                                  <InputAdornment position="start">
                                    <PersonOutlineIcon />
                                  </InputAdornment>
                                ),
                              }}
                              error={errors.lastname}
                              helperText={
                                errors.lastname && errors.lastname
                              }
                            />
                          </GridItem>
                        </GridContainer>


                        <GridContainer>
                          <GridItem xs={12} sm={12} md={12}>
                            <TextField
                              onChange={handleChange}
                              id="email"
                              value={userData.email}
                              label="Email"
                              style={{ marginTop: 20 }}
                              fullWidth
                              type="email"
                              margin="normal"
                              InputProps={{
                                startAdornment: (
                                  <InputAdornment position="start">
                                    <MailOutlineIcon />
                                  </InputAdornment>
                                ),
                              }}
                              error={errors.email}
                              helperText={errors.email && errors.email}
                            />
                          </GridItem>
                        </GridContainer>
                        <GridContainer>
                          <GridItem xs={12} sm={12} md={12}>
                            <TextField
                              onChange={handleChange}
                              id="phonenumber"
                              value={userData.phonenumber}
                              label="Phone Number"
                              style={{ marginTop: 20 }}
                              fullWidth
                              type="number"
                              margin="normal"
                              InputProps={{
                                startAdornment: (
                                  <InputAdornment position="start">
                                    <PhoneIcon />
                                  </InputAdornment>
                                ),
                              }}
                              error={errors.phonenumber}
                              helperText={
                                errors.phonenumber && errors.phonenumber
                              }
                            />
                          </GridItem>
                          </GridContainer>
                        <GridContainer>
                          <GridItem xs={12} sm={12} md={12}>
                            <FormControl
                              style={{ minWidth: "100%", marginTop: 20 }}
                              className={classes.formControl}
                            >
                              <InputLabel id="demo-simple-select-label">
                                Location
                              </InputLabel>
                              <Select
                                value={userData.assigned}
                                onChange={(e) =>
                                  setUserData({
                                    ...userData,
                                    assigned: e.target.value,
                                  })
                                }
                                labelId="demo-simple-select-label"
                                id="assigned"
                              >
                                {locations.length == 0 ? (
                                  <MenuItem>No Locations</MenuItem>
                                ) : (
                                  locations.map((prop) => {
                                    return (
                                      <MenuItem key={prop.id} value={prop.id}>
                                        {prop.location_name}
                                      </MenuItem>
                                    );
                                  })
                                )}
                              </Select>
                            </FormControl>
                            <Danger>{errors.assigned}</Danger>
                          </GridItem>
                        </GridContainer>
                        <GridContainer>
                          <GridItem xs={12} sm={12} md={6}>
                            <TextField
                              onChange={handleChange}
                              value={userData.password}
                              label="Password"
                              type="password"
                              style={{ marginTop: 20 }}
                              id="password"
                              fullWidth
                              margin="normal"
                              InputLabelProps={{
                                shrink: true,
                              }}
                              placeholder="Enter to change current password"
                              error={errors.password}
                              helperText={
                                errors.password && errors.password
                              }
                            />
                          </GridItem>
                          <GridItem xs={12} sm={12} md={6}>
                            <TextField
                              onChange={handleChange}
                              value={userData.confirm}
                              type="password"
                              label="Confirm Password"
                              style={{ marginTop: 20 }}
                              id="confirm"
                              fullWidth
                              margin="normal"
                              
                              InputLabelProps={{
                                shrink: true,
                              }}
                              placeholder="Enter to confirm change"
                              error={errors.confirm}
                              helperText={
                                errors.confirm && errors.confirm
                              }
                            />
                          </GridItem>
                        </GridContainer>
                      </form>
                    )}
                  </CardBody>
                </>
              </GridItem>
            </GridContainer>
          </DialogContentText>
        </DialogContent>
        {!addloading && (
          <DialogActions>
            <Button
              style={{ display: "block", marginLeft: "0", marginRight: "auto" }}
              onClick={edithandleIntlClose}
              color="danger"
            >
              <Close />
              Cancel
            </Button>
            <Button
              style={{ float: "right" }}
              color="danger"
              onClick={handleEditIntlClose}
            >
              Update <Arrow />
            </Button>
          </DialogActions>
        )}
      </Dialog>

      <Dialog
        fullWidth={true}
        maxWidth={!addloading ? "sm" : "xs"}
        open={addlocalopen}
        TransitionComponent={Transition}
        keepMounted
        onClose={addhandleLocalClose}
        aria-labelledby="alert-dialog-slide-title"
        aria-describedby="alert-dialog-slide-description"
      >
        <DialogContent>
          <DialogContentText id="alert-dialog-slide-description">
            <GridContainer>
              <GridItem xs={12} sm={12} md={12}>
                <>
                  <CardHeader
                    style={{ textAlign: "center" }}
                    color={!addloading ? "danger" : ""}
                  >
                    <h4
                      className={
                        !addloading
                          ? classes.cardTitleWhite
                          : classes.cardTitleBlack
                      }
                    >
                      {!addloading ? "Add Local Agent" : "Adding Local Agent"}
                    </h4>
                  </CardHeader>
                  <CardBody>
                    {addloading ? (
                      <DialogContentText style={{ textAlign: "center" }}>
                        <CircularProgress color="secondary" />
                      </DialogContentText>
                    ) : (
                      <form onSubmit={handleAddLocalClose}>
                        <GridContainer>
                          <GridItem xs={12} sm={12} md={6}>
                            <TextField
                              onChange={handleChange}
                              id="firstname"
                              value={userData.firstname}
                              label="First Name"
                              style={{ marginTop: 20 }}
                              fullWidth
                              margin="normal"
                              InputProps={{
                                startAdornment: (
                                  <InputAdornment position="start">
                                    <PersonOutlineIcon />
                                  </InputAdornment>
                                ),
                              }}
                              error={errors.firstname}
                              helperText={
                                errors.firstname && errors.firstname
                              }
                            />
                          </GridItem>
                          <GridItem xs={12} sm={12} md={6}>
                            <TextField
                              onChange={handleChange}
                              id="lastname"
                              value={userData.lastname}
                              label="Last Name"
                              style={{ marginTop: 20 }}
                              fullWidth
                              margin="normal"
                              InputProps={{
                                startAdornment: (
                                  <InputAdornment position="start">
                                    <PersonOutlineIcon />
                                  </InputAdornment>
                                ),
                              }}
                              error={errors.lastname}
                              helperText={
                                errors.lastname && errors.lastname
                              }
                            />
                          </GridItem>
                        </GridContainer>

                        <GridContainer>
                          <GridItem xs={12} sm={12} md={12}>
                            <TextField
                              onChange={handleChange}
                              id="email"
                              value={userData.email}
                              label="Email"
                              style={{ marginTop: 20 }}
                              fullWidth
                              type="email"
                              margin="normal"
                              InputProps={{
                                startAdornment: (
                                  <InputAdornment position="start">
                                    <MailOutlineIcon />
                                  </InputAdornment>
                                ),
                              }}
                              error={errors.email}
                              helperText={errors.email && errors.email}
                            />
                          </GridItem>
                        </GridContainer>
                        <GridContainer>
                          <GridItem xs={12} sm={12} md={12}>
                            <TextField
                              onChange={handleChange}
                              id="phonenumber"
                              value={userData.phonenumber}
                              label="Phone Number"
                              style={{ marginTop: 20 }}
                              fullWidth
                              type="number"
                              margin="normal"
                              InputProps={{
                                startAdornment: (
                                  <InputAdornment position="start">
                                    <PhoneIcon />
                                  </InputAdornment>
                                ),
                              }}
                              error={errors.phonenumber}
                              helperText={
                                errors.phonenumber && errors.phonenumber
                              }
                            />
                          </GridItem>
                        </GridContainer>
                        <GridContainer>
                          <GridItem xs={12} sm={12} md={12}>
                            <FormControl
                              style={{ minWidth: "100%", marginTop: 20 }}
                              className={classes.formControl}
                            >
                              <InputLabel id="demo-simple-select-label">
                                Sorting Facility
                              </InputLabel>
                              <Select
                                value={userData.assigned}
                                onChange={(e) =>
                                  setUserData({
                                    ...userData,
                                    assigned: e.target.value,
                                  })
                                }
                                labelId="demo-simple-select-label"
                                id="assigned"
                              >
                                {facilities.length == 0 ? (
                                  <MenuItem>No Sorting Facilities</MenuItem>
                                ) : (
                                  facilities.map((prop) => {
                                    return (
                                      <MenuItem key={prop.id} value={prop.id}>
                                        {prop.facility_name}
                                      </MenuItem>
                                    );
                                  })
                                )}
                              </Select>
                            </FormControl>
                            <Danger>{errors.assigned}</Danger>
                          </GridItem>
                        </GridContainer>
                        <GridContainer>
                          <GridItem xs={12} sm={12} md={6}>
                            <TextField
                              onChange={handleChange}
                              value={userData.password}
                              label="Password"
                              type="password"
                              style={{ marginTop: 20 }}
                              id="password"
                              fullWidth
                              margin="normal"
                              InputProps={{
                                startAdornment: (
                                  <InputAdornment position="start">
                                    <LockOpenIcon />
                                  </InputAdornment>
                                ),
                              }}
                              error={errors.password}
                              helperText={
                                errors.password && errors.password
                              }
                            />
                          </GridItem>
                          <GridItem xs={12} sm={12} md={6}>
                            <TextField
                              onChange={handleChange}
                              value={userData.confirm}
                              type="password"
                              label="Confirm Password"
                              style={{ marginTop: 20 }}
                              id="confirm"
                              fullWidth
                              margin="normal"
                              InputProps={{
                                startAdornment: (
                                  <InputAdornment position="start">
                                    <LockOpenIcon />
                                  </InputAdornment>
                                ),
                              }}
                              error={errors.confirm}
                              helperText={
                                errors.confirm && errors.confirm
                              }
                            />
                          </GridItem>
                        </GridContainer>
                      </form>
                    )}
                  </CardBody>
                </>
              </GridItem>
            </GridContainer>
          </DialogContentText>
        </DialogContent>
        {!addloading && (
          <DialogActions>
            <Button
              style={{ display: "block", marginLeft: "0", marginRight: "auto" }}
              onClick={addhandleLocalClose}
              color="danger"
            >
              <Close />
              Cancel
            </Button>
            <Button
              style={{ float: "right" }}
              color="danger"
              onClick={handleAddLocalClose}
            >
              Create <Arrow />
            </Button>
          </DialogActions>
        )}
      </Dialog>
      <Dialog
        fullWidth={true}
        maxWidth={!addloading ? "sm" : "xs"}
        open={editlocalopen}
        TransitionComponent={Transition}
        keepMounted
        onClose={edithandleLocalClose}
        aria-labelledby="alert-dialog-slide-title"
        aria-describedby="alert-dialog-slide-description"
      >
        <DialogContent>
          <DialogContentText id="alert-dialog-slide-description">
            <GridContainer>
              <GridItem xs={12} sm={12} md={12}>
                <>
                  <CardHeader
                    style={{ textAlign: "center" }}
                    color={!addloading ? "danger" : ""}
                  >
                    <h4
                      className={
                        !addloading
                          ? classes.cardTitleWhite
                          : classes.cardTitleBlack
                      }
                    >
                      {!addloading ? "Update Local Agent" : "Updating Local Agent"}
                    </h4>
                  </CardHeader>
                  <CardBody>
                    {addloading ? (
                      <DialogContentText style={{ textAlign: "center" }}>
                        <CircularProgress color="secondary" />
                      </DialogContentText>
                    ) : (
                      <form onSubmit={handleEditLocalClose}>
                        <GridContainer>
                          <GridItem xs={12} sm={12} md={6}>
                            <TextField
                              onChange={handleChange}
                              id="firstname"
                              value={userData.firstname}
                              label="First Name"
                              style={{ marginTop: 20 }}
                              fullWidth
                              margin="normal"
                              InputProps={{
                                startAdornment: (
                                  <InputAdornment position="start">
                                    <PersonOutlineIcon />
                                  </InputAdornment>
                                ),
                              }}
                              error={errors.firstname}
                              helperText={
                                errors.firstname && errors.firstname
                              }
                            />
                          </GridItem>
                          <GridItem xs={12} sm={12} md={6}>
                            <TextField
                              onChange={handleChange}
                              id="lastname"
                              value={userData.lastname}
                              label="Last Name"
                              style={{ marginTop: 20 }}
                              fullWidth
                              margin="normal"
                              InputProps={{
                                startAdornment: (
                                  <InputAdornment position="start">
                                    <PersonOutlineIcon />
                                  </InputAdornment>
                                ),
                              }}
                              error={errors.lastname}
                              helperText={
                                errors.lastname && errors.lastname
                              }
                            />
                          </GridItem>
                        </GridContainer>

                        <GridContainer>
                          <GridItem xs={12} sm={12} md={12}>
                            <TextField
                              onChange={handleChange}
                              id="email"
                              value={userData.email}
                              label="Email"
                              style={{ marginTop: 20 }}
                              fullWidth
                              type="email"
                              margin="normal"
                              InputProps={{
                                startAdornment: (
                                  <InputAdornment position="start">
                                    <MailOutlineIcon />
                                  </InputAdornment>
                                ),
                              }}
                              error={errors.email}
                              helperText={errors.email && errors.email}
                            />
                          </GridItem>
                        </GridContainer>
                        <GridContainer>
                          <GridItem xs={12} sm={12} md={12}>
                            <TextField
                              onChange={handleChange}
                              id="phonenumber"
                              value={userData.phonenumber}
                              label="Phone Number"
                              style={{ marginTop: 20 }}
                              fullWidth
                              type="number"
                              margin="normal"
                              InputProps={{
                                startAdornment: (
                                  <InputAdornment position="start">
                                    <PhoneIcon />
                                  </InputAdornment>
                                ),
                              }}
                              error={errors.phonenumber}
                              helperText={
                                errors.phonenumber && errors.phonenumber
                              }
                            />
                          </GridItem>
                        </GridContainer>
                        <GridContainer>
                          <GridItem xs={12} sm={12} md={12}>
                            <FormControl
                              style={{ minWidth: "100%", marginTop: 20 }}
                              className={classes.formControl}
                            >
                              <InputLabel id="demo-simple-select-label">
                                Sorting Facility
                              </InputLabel>
                              <Select
                                value={userData.assigned}
                                onChange={(e) =>
                                  setUserData({
                                    ...userData,
                                    assigned: e.target.value,
                                  })
                                }
                                labelId="demo-simple-select-label"
                                id="assigned"
                              >
                                {facilities.length == 0 ? (
                                  <MenuItem>No Sorting Facilities</MenuItem>
                                ) : (
                                  facilities.map((prop) => {
                                    return (
                                      <MenuItem key={prop.id} value={prop.id}>
                                        {prop.facility_name}
                                      </MenuItem>
                                    );
                                  })
                                )}
                              </Select>
                            </FormControl>
                            <Danger>{errors.assigned}</Danger>
                          </GridItem>
                        </GridContainer>
                        
                        <GridContainer>
                          <GridItem xs={12} sm={12} md={6}>
                            <TextField
                              onChange={handleChange}
                              value={userData.password}
                              label="Password"
                              type="password"
                              style={{ marginTop: 20 }}
                              id="password"
                              fullWidth
                              margin="normal"
                              InputLabelProps={{
                                shrink: true,
                              }}
                              placeholder="Enter to change current password"
                              error={errors.password}
                              helperText={
                                errors.password && errors.password
                              }
                            />
                          </GridItem>
                          <GridItem xs={12} sm={12} md={6}>
                            <TextField
                              onChange={handleChange}
                              value={userData.confirm}
                              type="password"
                              label="Confirm Password"
                              style={{ marginTop: 20 }}
                              id="confirm"
                              fullWidth
                              margin="normal"
                              InputLabelProps={{
                                shrink: true,
                              }}
                              placeholder="Enter to confirm change"
                              error={errors.confirm}
                              helperText={
                                errors.confirm && errors.confirm
                              }
                            />
                          </GridItem>
                        </GridContainer>
                      </form>
                    )}
                  </CardBody>
                </>
              </GridItem>
            </GridContainer>
          </DialogContentText>
        </DialogContent>
        {!addloading && (
          <DialogActions>
            <Button
              style={{ display: "block", marginLeft: "0", marginRight: "auto" }}
              onClick={edithandleLocalClose}
              color="danger"
            >
              <Close />
              Cancel
            </Button>
            <Button
              style={{ float: "right" }}
              color="danger"
              onClick={handleEditLocalClose}
            >
              Update <Arrow />
            </Button>
          </DialogActions>
        )}
      </Dialog>

     
      <Dialog
        fullScreen
        open={agentdetopen}
        onClose={handleagentdelClose}
        TransitionComponent={Transition2}
      >
        <AppBar className={classes.appBar}>
          <Toolbar>
            <IconButton
              edge="start"
              color="inherit"
              onClick={handleagentdelClose}
              aria-label="close"
            >
              <CloseIcon />
            </IconButton>
            <Typography variant="h6" className={classes.title}>
              Deliveries Requests Made By {compagentdelprop.name}
            </Typography>
            <Button color="#ec4c48">
              {compagentdelprop.deliveries} Request
              {compagentdelprop.deliveries != 1 && "s"}
            </Button>
          </Toolbar>
        </AppBar>
        <DialogContent style={{ background: "#EEEEEE" }}>
          <CardHeader style={{ marginTop: 10, marginBottom: 10 }} color="danger">
            <h4 style={{ textAlign: "center" }}>
              Monthly Deliveries for {1900 + new Date().getYear()}{" "}
            </h4>
            <ChartistGraph
              className="ct-chart"
              data={{
                labels: [
                  "Jan",
                  "Feb",
                  "Mar",
                  "Apr",
                  "May",
                  "Jun",
                  "Jul",
                  "Aug",
                  "Sep",
                  "Oct",
                  "Nov",
                  "Dec",
                ],
                series: [compagentdelprop.chart],
              }}
              type="Bar"
              options={{
                ...monthlyDeliveriesChart.options,
                high: Math.max(...compagentdelprop.chart) + 5,
              }}
              responsiveOptions={monthlyDeliveriesChart.responsiveOptions}
              listener={monthlyDeliveriesChart.animation}
            />
          </CardHeader>
          <Table
            searchKeyword={searchHandler2}
            term={searchTerm2}
            setTerm={setSearchTerm2}
            tableHeaderColor="gray"
            tableHead={[
              "Order ID",
              "Service",
              "Sender",
              "Recipient",
              "From",
              "To",
              "Status",
              "Packages",
            ]}
            tableData={
              agentdel.length == 0 ? (
                <TableRow>
                  <TableCell
                    style={{ textAlign: "center" }}
                    colSpan={8}
                    className={classes.tableCell}
                  >
                    No Deliveries
                  </TableCell>
                </TableRow>
              ) : (
                agentdel.map((prop) => {
                  return (
                    <TableRow key={prop.id} className={classes.tableBodyRow}>
                      <TableCell className={classes.tableCell} key={prop.id}>
                      <Link to={{pathname: "/admin/searchdeliveries", property: { orderId: prop.order_id }}} >
                        {prop.order_id}
                        </Link>
                      </TableCell>
                      <TableCell className={classes.tableCell} key={prop.id}>
                        {prop.service.service_name}
                      </TableCell>
                      <TableCell className={classes.tableCell} key={prop.id}>
                      {prop.creator ? prop.creator.first_name : prop.sender_name}{" "}
                       {(prop.creator && prop.creator.last_name) && prop.creator.last_name}
                      </TableCell>
                      <TableCell className={classes.tableCell} key={prop.id}>
                      {prop.reciepient.first_name +
                              " " +
                              prop.reciepient.last_name}
                    </TableCell>
                      <TableCell className={classes.tableCell} key={prop.id}>
                        {prop.from_id &&
                          prop.from_location.location_name}{" "}
                        {prop.fromAddress && "(" + prop.fromAddress + ")"}
                        {prop.facility_id &&
                          prop.to_id &&
                          prop.facility.facility_name +
                            "(" +
                            prop.facility.zone.zone_name +
                            ")"}
                        {prop.from_facility_id &&
                          prop.from_facility.facility_name +
                            "(" +
                            prop.from_facility.zone.zone_name +
                            ")"}
                      </TableCell>
                      <TableCell className={classes.tableCell} key={prop.id}>
                        {prop.to_id && prop.to_location.location_name}{" "}
                        {prop.toAddress && "(" + prop.toAddress + ")"}
                        {prop.facility_id &&
                          prop.from_id &&
                          prop.facility.facility_name +
                            "(" +
                            prop.facility.zone.zone_name +
                            ")"}
                        {prop.to_facility_id &&
                          prop.to_facility.facility_name +
                            "(" +
                            prop.to_facility.zone.zone_name +
                            ")"}
                      </TableCell>
                      <TableCell className={classes.tableCell} key={prop.id}>
                      {prop.status == "unassigned" && "Unassigned"}
                        {prop.status == "atfacility" &&
                          "At " +
                            prop.unassigned_facility.facility.facility_name}
                        {prop.status == "pendingpickup" &&
                          "Pending Pickup from Client"}
                        {prop.status == "pendingpickupatfacility" &&
                          "Pending Pickup from " +
                            (prop.pendingfacilityfacility
                              ? prop.pendingfacilityfacility.from_facility
                                  .facility_name
                              : prop.pendingfacilityrecipient.facility
                                  .facility_name)}
                        {prop.status == "outfordelivery" && "Out For Delivery"}
                        {prop.status == "delivered" && "Delivered"}
                        {prop.status == "tofirstfacility" &&
                          "On-Route to " +
                            prop.onrouteclientfacility.facility.facility_name}
                        {prop.status == "tofacility" &&
                          "On-Route to " +
                            prop.onroutefacilityfacility.to_facility
                              .facility_name}
                      </TableCell>
                      <TableCell className={classes.tableCell} key={prop.id}>
                        {prop.service.service_name ==
                        "Food Service" ? (
                          <VisibilityOffIcon
                            style={{ cursor: "not-allowed" }}
                          />
                        ) : (
                          <View
                            onClick={(e) =>
                              toggleDraweropen(e, prop.id)
                            }
                            style={{ cursor: "pointer" }}
                          />
                        )}
                      </TableCell>
                    </TableRow>
                  );
                })
              )
            }
          />
          <TablePagination
      component="div"
      count={totalcount3}
      page={page3}
      onPageChange={handleChangePage3}
      rowsPerPage={rowsPerPage3}
      onRowsPerPageChange={handleChangeRowsPerPage3}
    />
        </DialogContent>
      </Dialog>



     <Drawer anchor={"right"} open={opendrawer} onClose={toggleDrawerclose}>
        <div className={clsx(classes.list)} role="presentation">
          <List style={{ background: "#EEEEEE" }}>
            <ListItem>
              <ListItemIcon>
                <Close
                  style={{ cursor: "pointer" }}
                  onClick={toggleDrawerclose}
                />
              </ListItemIcon>
              <ListItemIcon>
                <FontAwesomeIcon icon={faBoxOpen} />
              </ListItemIcon>
              <ListItemText
                primary={
                  (packages.packages && packages.packages.length > 0) ? packages.packages.length + " Package" + (packages.packages.length!=1 ? "s" : "") : packages.items ? packages.items.length + " Item"  + (packages.items.length!=1 ? "s" : "") : ""
                }
              />
            </ListItem>
          </List>
          <Divider />
          <Divider style={{ marginBottom: "10px" }} />
          {packages.packages &&
            packages.packages.map((prop) => {
              return (
                <>
                  <ListItem>
                    <GridContainer>
                      <GridItem xs={4} sm={4} md={4}>
                        <Avatar
                          className={classes.avatarimg}
                          variant="square"
                          key={prop.id}
                          alt="Package"
                          src={props.imgurl + prop.packages.img}
                        />
                      </GridItem>
                      <GridItem xs={4} sm={4} md={4}>
                        <Typography
                          align="center"
                          variant="subtitle2"
                          gutterBottom
                        >
                          Package Type
                        </Typography>
                        <Typography
                          align="center"
                          variant="caption"
                          display="block"
                          gutterBottom
                        >
                          {prop.packages.package_name}{" "}
                        </Typography>
                      </GridItem>
                      <GridItem xs={4} sm={4} md={4}>
                        <Typography
                          align="center"
                          variant="subtitle2"
                          gutterBottom
                        >
                          Package ID
                        </Typography>
                        <Typography
                          align="center"
                          variant="caption"
                          display="block"
                          gutterBottom
                        >
                          {prop.package_reference_id
                            ? prop.package_reference_id
                            : "N/A"}
                        </Typography>
                      </GridItem>
                    </GridContainer>
                  </ListItem>
                  <Divider style={{ marginBottom: "10px" }} />
                </>
              );
            })}
            {packages.items &&
            packages.items.map((prop) => {
              return (
                <>
                  <ListItem>
                    <GridContainer style={{ width: "100%" }}>
                      <GridItem xs={4} sm={4} md={4}>
                      <img
                          className={classes.vehicleimg}
                          key={prop.id}
                          alt="Item"
                          src={props.imgurl + prop.items.img}
                        />
                      </GridItem>
                      <GridItem xs={4} sm={4} md={4}>
                        <Typography variant="subtitle2" gutterBottom>
                          Package Type
                        </Typography>
                        <Typography
                          variant="caption"
                          display="block"
                          gutterBottom
                        >
                          {prop.items.item_name}{" "}
                        </Typography>
                      </GridItem>
                      <GridItem xs={4} sm={4} md={4}>
                        <Typography variant="subtitle2" gutterBottom>
                          Package ID
                        </Typography>
                        <Typography
                          variant="caption"
                          display="block"
                          gutterBottom
                        >
                          {prop.package_reference_id
                            ? prop.package_reference_id
                            : "N/A"}
                        </Typography>
                      </GridItem>
                    </GridContainer>
                  </ListItem>

                  <Divider style={{ marginBottom: "10px" }} />
                </>
              );
            })}
        </div>
      </Drawer>
      <Dialog
        fullWidth={true}
        maxWidth="sm"
        open={openconfirm}
        TransitionComponent={Transition}
        keepMounted
        classes={{ scrollPaper: classes.scrollPaper }}
        onClose={handleconfirmClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">{"DELETE Agent"}</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            Are you sure you want to delete this agent?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button
            style={{ display: "block", marginLeft: "0", marginRight: "auto" }}
            onClick={handleconfirmClose}
            color="danger"
          >
            <Close />
            Cancel
          </Button>
          <Button
            style={{ float: "right" }}
            color="danger"
            onClick={deleteAgent}
          >
            Proceed <Arrow />
          </Button>
        </DialogActions>
      </Dialog>
    </Card>
  );
}

Agents.propTypes = {
  url: PropTypes.string,
  imgurl: PropTypes.string,
  exit: PropTypes.string,
};
