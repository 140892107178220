import React, { useState, useEffect } from "react";
import Backdrop from "@material-ui/core/Backdrop";
import PropTypes from "prop-types";
import { makeStyles } from "@material-ui/core/styles";
import { Link } from "react-router-dom";
import Close from "@material-ui/icons/Close";
import Arrow from "@material-ui/icons/DoubleArrow";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import InputLabel from "@material-ui/core/InputLabel";
import FormControl from "@material-ui/core/FormControl";
import TablePagination from '@material-ui/core/TablePagination';
import Select from "@material-ui/core/Select";
import Card from "components/Card/Card.js";
import Table from "components/Table/Table.js";
import CardHeader from "components/Card/CardHeader.js";
import CardBody from "components/Card/CardBody.js";
import Add from "@material-ui/icons/Add";
import Edit from "@material-ui/icons/Edit";
import Delete from "@material-ui/icons/DeleteOutline";
import Button from "components/CustomButtons/Button.js";
import Grid from "@material-ui/core/Grid";
import GridItem from "components/Grid/GridItem.js";
import ArrowBackIcon from "@material-ui/icons/ArrowBack";
import TextField from "@material-ui/core/TextField";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import CircularProgress from "@material-ui/core/CircularProgress";
import TableRow from "@material-ui/core/TableRow";
import TableCell from "@material-ui/core/TableCell";
import Slide from "@material-ui/core/Slide";

const styles = {
  backdrop: {
    zIndex: 5,
    color: "#fff",
  },
  tableCell: {
    textAlign: "center",
  },
  scrollPaper: {
    alignItems: "baseline", // default center
  },
  typo: {
    paddingLeft: "25%",
    marginBottom: "40px",
    position: "relative",
  },
  note: {
    fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
    bottom: "10px",
    color: "#c0c1c2",
    display: "block",
    fontWeight: "400",
    fontSize: "13px",
    lineHeight: "13px",
    left: "0",
    marginLeft: "20px",
    position: "absolute",
    width: "260px",
  },
  cardCategoryWhite: {
    color: "rgba(255,255,255,.62)",
    margin: "0",
    fontSize: "14px",
    marginTop: "0",
    marginBottom: "0",
  },
  cardTitleWhite: {
    color: "#FFFFFF",
    marginTop: "0px",
    minHeight: "auto",
    fontWeight: "300",
    fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif",
    marginBottom: "3px",
    textDecoration: "none",
  },
};

const useStyles = makeStyles(styles);
const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="down" ref={ref} {...props} />;
});

export default function ZoneAreas({ match, url, exit }) {
  toast.configure();
  const classes = useStyles();
  const [zonedata, setZonedata] = useState("");
  const {
    params: { zid },
  } = match;
  const [locations, setLocations] = useState([]);
  const [searchTerm, setSearchTerm] = useState("");
  const [locationname, setLocationname] = useState("");
  const [addloading, setAddloading] = useState(false);
  const [locationtype, setLocationtype] = useState("Area");
  const [loading, setLoading] = useState(true);
  const [editid, setEditid] = useState(0);
  const [errors, seterrors] = useState([]);
  const [searchcategoryTerm, setSearchcategoryTerm] = useState("");
  const [editdialogopen, seteditdialogopen] = React.useState(null);
  const [adddialogopen, setadddialogopen] = React.useState(null);
  const [deleteid, setDeleteid] = useState(0);
  const [openconfirm, setOpenconfirm] = useState(false);
  const [page, setPage] = React.useState(0);
  const [totalcount, setTotalcount] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);

  const handleChangePage = (event, newPage) => {
    setLoading(true)
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
    setLoading(true)
  };
  

  const handleconfirmClose = () => {
    setDeleteid(0);
    setOpenconfirm(false);
  };

  const getLocations = async (termed = '',paged=0) => {
    if(paged){
      setPage(paged-1)
    }
    let response = await fetch(url + `/get-locations/${zid}?${searchTerm && 'search='+searchTerm+'&'}${termed ? 'category='+termed+'&' : searchcategoryTerm ? 'category='+searchcategoryTerm+'&' : ''}page=${paged?paged-1:page+1}&perpage=${rowsPerPage}`, {
      credentials: "include",
    });
    let data = await response.json();
    if (data.success) {
      setLocations(data.data.data);
      setPage(data.data.current_page - 1)
      setTotalcount(data.data.total)
      setLoading(false);
    } else {
      if( data.message == 'unauthenticated'){
        window.location.replace(exit);
        return;
      }
      toast.error(data.message);
    }
  };
  const getZonedata = async () => {
    let response = await fetch(url + `/get-zone-data/${zid}`, {
      credentials: "include",
    });
    let data = await response.json();
    if (data.success) {
      setZonedata(data.data[0]);
    } else {
      if( data.message == 'unauthenticated'){
        window.location.replace(exit);
        return;
      }
      toast.error(data.message);
    }
  };
  useEffect(() => {
    getLocations();
    getZonedata();
  }, [page,rowsPerPage]);

  const addhandleClickOpen = () => {
    setadddialogopen(true);
  };

  const confirmdeleteLocation = (id) => {
    setDeleteid(id);
    setOpenconfirm(true);
  };

  const deleteLocation = async () => {
    setLoading(true);
    const cid = deleteid;
    let response = await fetch(url + "/delete-location", {
      method: "DELETE",
      headers: {
        "Content-type": "application/json",
      },
      body: JSON.stringify({ id: deleteid }),
      credentials: "include",
    });
    let res = await response.json();
    if (res) {
      setDeleteid(0);
      setOpenconfirm(false);
      setLoading(false);
      if (res.success) {
        setLocations(locations.filter((current) => cid !== current.id));
        toast.success("Location deleted");
      } else {
        if( res.message == 'unauthenticated'){
          window.location.replace(exit);
          return;
        }
        toast.error(res.message);
      }
    }
  };
  const edithandleClickOpen = async (id) => {
    let response = await fetch(url + `/get-location/${id}`, {
      credentials: "include",
    });
    let res = await response.json();
    if (res) {
      if (res.success) {
        setLocationtype(res.data.location_type);
        setLocationname(res.data.location_name);
        setEditid(res.data.id);
        seteditdialogopen(true);
      } else {
        if( res.message == 'unauthenticated'){
          window.location.replace(exit);
          return;
        }
        toast.error(res.message);
      }
    }
  };
  const validate = () => {
    let temp = [];
    temp.locationname = locationname ? "" : "This field is required";
    seterrors({ ...temp });
    return Object.values(temp).every((x) => x == "");
  };
  const edithandleClose = () => {
    setLocationtype("Area");
    setLocationname("");
    seteditdialogopen(false);
    setAddloading(false);
  };
  const edithandleaddClose = async (event) => {
    event.preventDefault();
    if (validate()) {
      setAddloading(true);
      let response = await fetch(url + `/update-location/${editid}`, {
        method: "PUT",
        headers: {
          "Content-type": "application/json",
        },
        body: JSON.stringify({ name: locationname, type: locationtype }),
        credentials: "include",
      });
      let res = await response.json();
      if (res) {
        if (res.success) {
          setLocations(
            locations.map((prop) =>
              prop.id === editid ? { ...res.data } : prop
            )
          );
          edithandleClose(false);
          toast.success("Location updated");
        } else {
          if( res.message == 'unauthenticated'){
            window.location.replace(exit);
            return;
          }
          toast.error(res.message);
        }
      }
    }
  };
  const addhandleClose = () => {
    setLocationtype("Area");
    setLocationname("");
    setadddialogopen(false);
    setAddloading(false);
  };
  const searchHandler = async () => {
    getLocations(searchcategoryTerm,1)
  };

  const searchcategoryHandler = async (termed) => {
    setSearchcategoryTerm(termed);
    getLocations(termed,1)
  };
  const addhandleaddClose = async (event) => {
    event.preventDefault();
    if (validate()) {
      setAddloading(true);
      let response = await fetch(url + "/create-location", {
        method: "POST",
        headers: {
          "Content-type": "application/json",
        },
        body: JSON.stringify({
          name: locationname,
          type: locationtype,
          zone_id: zid,
        }),
        credentials: "include",
      });
      let res = await response.json();
      if (res) {
        if (res.success) {
          setLocations([...locations, res.data]);
          addhandleClose(false);
          toast.success("Location added");
        } else {
          if( res.message == 'unauthenticated'){
            window.location.replace(exit);
            return;
          }
          toast.error(res.message);
        }
      }
    }
  };
  return (
    <Card>
      {loading && (
        <Backdrop className={classes.backdrop} open>
          <CircularProgress color="inherit" />
        </Backdrop>
      )}
      <CardHeader color="danger">
        <h4 className={classes.cardTitleWhite}>{zonedata} Locations</h4>
      </CardHeader>
      <CardBody>
        <Grid container>
          <GridItem xs={12} sm={4} md={4}>
            <Link to="/admin/zones">
              <Button style={{ float: "left" }} color="danger">
                <ArrowBackIcon />
                Back
              </Button>{" "}
            </Link>
          </GridItem>
          <GridItem xs={12} sm={4} md={4}></GridItem>
          <GridItem xs={12} sm={4} md={4}>
            <Button
              onClick={addhandleClickOpen}
              style={{ float: "left" }}
              color="danger"
            >
              <Add /> Add Location
            </Button>
          </GridItem>
        </Grid>
        <Table
          searchKeyword={searchHandler}
          term={searchTerm}
          setTerm={setSearchTerm}
          searchcategoryKeyword={searchcategoryHandler}
          location_type={searchcategoryTerm}
          tableHeaderColor="gray"
          tableHead={["Name", "Type", "Edit", "Delete"]}
          tableData={
            locations.length == 0 ? (
              <TableRow>
                <TableCell
                  style={{ textAlign: "center" }}
                  colSpan={4}
                  className={classes.tableCell}
                >
                  No Locations
                </TableCell>
              </TableRow>
            ) : (
              locations.map((prop) => {
                return (
                  <TableRow key={prop.id} className={classes.tableBodyRow}>
                    <TableCell className={classes.tableCell} key={prop.id}>
                      {prop.location_name}
                    </TableCell>
                    <TableCell className={classes.tableCell} key={prop.id}>
                      {prop.location_type}
                    </TableCell>
                    <TableCell className={classes.tableCell} key={prop.id}>
                      <Edit
                        onClick={() => edithandleClickOpen(prop.id)}
                        style={{ color: "black", cursor: "pointer" }}
                        key={prop.id}
                      />
                    </TableCell>
                    <TableCell className={classes.tableCell} key={prop.id}>
                      <Delete
                        style={{ cursor: "pointer" }}
                        onClick={() => confirmdeleteLocation(prop.id)}
                      />
                    </TableCell>
                  </TableRow>
                );
              })
            )
          }
          category={true}/>
      </CardBody>
      <TablePagination
      component="div"
      count={totalcount}
      page={page}
      onPageChange={handleChangePage}
      rowsPerPage={rowsPerPage}
      onRowsPerPageChange={handleChangeRowsPerPage}
    />
      <Dialog
        open={editdialogopen}
        onClose={edithandleClose}
        aria-labelledby="form-dialog-title"
      >
        <DialogTitle id="form-dialog-title">
          {!addloading ? "Update Location" : "Updating Location"}
        </DialogTitle>
        {addloading ? (
          <DialogContentText style={{ textAlign: "center" }}>
            <CircularProgress color="secondary" />
          </DialogContentText>
        ) : (
          <DialogContent>
            <DialogContentText>
              Update the Name of the Location for this Zone.
            </DialogContentText>
            <form onSubmit={edithandleaddClose}>
              <TextField
                onChange={(event) => {
                  setLocationname(event.target.value);
                }}
                value={locationname}
                autoFocus
                margin="dense"
                id="name"
                label="Location Name"
                type="text"
                fullWidth
                error={errors.locationname}
                helperText={errors.locationname && errors.locationname}
              />
              <FormControl
                style={{ width: "100%", marginTop: "20px" }}
                className={classes.formControl}
              >
                <InputLabel htmlFor="location-native-simple">
                  Location Type
                </InputLabel>
                <Select
                  value={locationtype}
                  onChange={(e) => setLocationtype(e.target.value)}
                  native
                  inputProps={{ name: "age", id: "location-native-simple" }}
                >
                  <option value="Geographical Area">Area</option>
                  <option value="Company">Corporation</option>
                  <option value="Restaurant">Restaurant</option>
                </Select>
              </FormControl>
            </form>
          </DialogContent>
        )}
        {!addloading && (
          <DialogActions>
            <Button
              style={{ display: "block", marginLeft: "0", marginRight: "auto" }}
              onClick={edithandleClose}
              color="danger"
            >
              <Close />
              Cancel
            </Button>
            <Button
              style={{ float: "right" }}
              color="danger"
              onClick={edithandleaddClose}
            >
              Update <Arrow />
            </Button>
          </DialogActions>
        )}
      </Dialog>
      <Dialog
        open={adddialogopen}
        onClose={addhandleClose}
        aria-labelledby="form-dialog-title"
      >
        <DialogTitle id="form-dialog-title">
          {!addloading ? "Add Location" : "Adding Location"}
        </DialogTitle>
        {addloading ? (
          <DialogContentText style={{ textAlign: "center" }}>
            <CircularProgress color="secondary" />
          </DialogContentText>
        ) : (
          <DialogContent>
            <DialogContentText>
              Add a new Location to this Zone by filling in the Name
            </DialogContentText>
            <form onSubmit={addhandleaddClose}>
              <TextField
                onChange={(event) => {
                  setLocationname(event.target.value);
                }}
                autoFocus
                margin="dense"
                id="name"
                label="Location Name"
                type="text"
                fullWidth
                error={errors.locationname}
                helperText={errors.locationname && errors.locationname}
              />
              <FormControl
                style={{ width: "100%", marginTop: "20px" }}
                className={classes.formControl}
              >
                <InputLabel htmlFor="location-native-simple">
                  Location Type
                </InputLabel>
                <Select
                  value={locationtype}
                  onChange={(e) => setLocationtype(e.target.value)}
                  native
                  inputProps={{ name: "age", id: "location-native-simple" }}
                >
                  <option value="Area">Geographical Area</option>
                  <option value="Corporation">Company</option>
                  <option value="Restaurant">Restaurant</option>
                </Select>
              </FormControl>
            </form>
          </DialogContent>
        )}
        {!addloading && (
          <DialogActions>
            <Button
              style={{ display: "block", marginLeft: "0", marginRight: "auto" }}
              onClick={addhandleClose}
              color="danger"
            >
              <Close />
              Cancel
            </Button>
            <Button
              style={{ float: "right" }}
              color="danger"
              onClick={addhandleaddClose}
            >
              Create <Arrow />
            </Button>
          </DialogActions>
        )}
      </Dialog>
      <Dialog
        fullWidth={true}
        maxWidth="sm"
        open={openconfirm}
        TransitionComponent={Transition}
        keepMounted
        classes={{ scrollPaper: classes.scrollPaper }}
        onClose={handleconfirmClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          {"DELETE ZONE LOCATION"}
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            Are you sure you want to delete this location?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button
            style={{ display: "block", marginLeft: "0", marginRight: "auto" }}
            onClick={handleconfirmClose}
            color="danger"
          >
            <Close />
            Cancel
          </Button>
          <Button
            style={{ float: "right" }}
            color="danger"
            onClick={deleteLocation}
          >
            Proceed <Arrow />
          </Button>
        </DialogActions>
      </Dialog>
    </Card>
  );
}
ZoneAreas.propTypes = {
  url: PropTypes.string,
  exit: PropTypes.string,
  match: PropTypes.shape({
    params: PropTypes.shape({
      zid: PropTypes.number.isRequired,
    }),
  }),
};
