import React, { useState, useEffect } from "react";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import PropTypes from "prop-types";
// core components
import GridItem from "components/Grid/GridItem.js";
import Avatar from "@material-ui/core/Avatar";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import { faBoxOpen, faBuilding } from "@fortawesome/free-solid-svg-icons";
import clsx from "clsx";
import DialogTitle from "@material-ui/core/DialogTitle";
import TablePagination from '@material-ui/core/TablePagination';
import View from "@material-ui/icons/Visibility";
import Divider from "@material-ui/core/Divider";
import PhoneIcon from "@material-ui/icons/Phone";
import LockOpenIcon from "@material-ui/icons/LockOpen";
import MailOutlineIcon from "@material-ui/icons/MailOutline";
import InputAdornment from "@material-ui/core/InputAdornment";
import PersonOutlineIcon from "@material-ui/icons/PersonOutline";
import Drawer from "@material-ui/core/Drawer";
import VisibilityOffIcon from "@material-ui/icons/VisibilityOff";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText";
import Close from "@material-ui/icons/Close";
import Arrow from "@material-ui/icons/DoubleArrow";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import IconButton from "@material-ui/core/IconButton";
import Typography from "@material-ui/core/Typography";
import CloseIcon from "@material-ui/icons/Close";
import { Link } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCoins } from "@fortawesome/free-solid-svg-icons";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import DoneAllIcon from "@material-ui/icons/DoneAll";
import HourglassEmptyIcon from "@material-ui/icons/HourglassEmpty";
import Box from "@material-ui/core/Box";
import CircularProgress from "@material-ui/core/CircularProgress";
import TableRow from "@material-ui/core/TableRow";
import TableCell from "@material-ui/core/TableCell";
import TextField from "@material-ui/core/TextField";
import Backdrop from "@material-ui/core/Backdrop";
import Danger from "components/Typography/Danger.js";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import GridContainer from "components/Grid/GridContainer.js";
import Table from "components/Table/Table.js";
import Details from "@material-ui/icons/Visibility";
import Edit from "@material-ui/icons/Edit";
import Delete from "@material-ui/icons/DeleteOutline";
import Card from "components/Card/Card.js";
import CardHeader from "components/Card/CardHeader.js";
import Slide from "@material-ui/core/Slide";
import Button from "components/CustomButtons/Button.js";
import CardBody from "components/Card/CardBody.js";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import MenuItem from "@material-ui/core/MenuItem";
import InputLabel from "@material-ui/core/InputLabel";
import FormControl from "@material-ui/core/FormControl";
import Select from "@material-ui/core/Select";
import Add from "@material-ui/icons/Add";
import ChartistGraph from "react-chartist";
import { monthlyDeliveriesChart } from "variables/charts";

const styles = {
  backdrop: {
    zIndex: 9999,
    color: "#fff",
  },
  scrollPaper: {
    alignItems: "baseline", // default center
  },
  avatarimg: {
    display: "block",
    marginLeft: "auto",
    marginRight: "auto",
  },
  vehicleimg: {
    display: "block",
    height: 50,
    width: 50,
    marginLeft: "auto",
    marginRight: "auto",
  },
  list: {
    width: "100%",
    position: "relative",
  },
  appBar: {
    position: "relative",
    background: "#ec4c48",
  },
  title: {
    // marginLeft: theme.spacing(2),
    flex: 1,
  },
  dividetab: {},
  tableCell: {
    textAlign: "center",
  },
  root: {
    flexGrow: 1,
  },
  cardCategoryWhite: {
    "&,& a,& a:hover,& a:focus": {
      color: "rgba(255,255,255,.62)",
      margin: "0",
      fontSize: "14px",
      marginTop: "0",
      marginBottom: "0",
    },
    "& a,& a:hover,& a:focus": {
      color: "#FFFFFF",
    },
  },
  cardTitleWhite: {
    color: "#FFFFFF",
    marginTop: "0px",
    minHeight: "auto",
    fontWeight: "300",
    fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif",
    marginBottom: "3px",
    textDecoration: "none",
    "& small": {
      color: "#777",
      fontSize: "65%",
      fontWeight: "400",
      lineHeight: "1",
    },
  },
};

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`scrollable-force-tabpanel-${index}`}
      aria-labelledby={`scrollable-force-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box p={3}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};

function a11yProps(index) {
  return {
    id: `scrollable-force-tab-${index}`,
    "aria-controls": `scrollable-force-tabpanel-${index}`,
  };
}

const useStyles = makeStyles(styles);
const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="down" ref={ref} {...props} />;
});
const Transition2 = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

export default function Clients(props) {
  toast.configure();
  const dividermatch = useMediaQuery("(max-width:600px)");
  const classes = useStyles();
  const [indcompdelopen, setindcompdelopen] = useState(false);
  const [corpcompdelopen, setcorpcompdelopen] = useState(false);
  const [addopen, setaddOpen] = React.useState(false);
  const [editopen, seteditopen] = React.useState(false);
  const [editpendopen, seteditpendopen] = React.useState(false);
  const [addindopen, setaddindOpen] = React.useState(false);
  const [editindopen, seteditindopen] = React.useState(false);
  const [loading, setLoading] = useState(true);
  const [editid, setEditid] = useState(0);
  const [client_id, setClient_id] = useState(0);
  const [individuals, setIndividuals] = useState([]);
  const [corporations, setCorporations] = useState([]);
  const [addloading, setAddloading] = useState(false);
  const [userDataind, setUserDataind] = useState({
    firstname: "",
    lastname: "",
    email: "",
    phonenumber: "",
    password: "",
    confirm: "",
  });
  const [userDatacorp, setUserDatacorp] = useState({
    name: "",
    email: "",
    phonenumber: "",
    threshold: "",
    location: "",
    password: "",
    confirm: "",
  });
  const [modtype, setModtype] = useState("");
  const [zones, setZones] = useState([]);
  const [inderrors, setinderrors] = useState([]);
  const [corperrors, setcorperrors] = useState([]);
  const [pendingcorp, setPendingcorp] = useState([]);
  const [compinddel, setCompinddel] = useState([]);
  const [compinddelprop, setCompinddelprop] = useState({
    name: "",
    deliveries: 0,
    chart: [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
  });
  const [compcorpdel, setCompcorpdel] = useState([]);
  const [compcorpdelprop, setCompcorpdelprop] = useState({
    name: "",
    deliveries: 0,
    chart: [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
  });
  const [packages, setPackages] = useState([]);
  const [opendrawer, setOpendrawer] = React.useState(false);
  const [searchTerm1, setSearchTerm1] = useState("");
  const [searchTerm2, setSearchTerm2] = useState("");
  const [searchTerm3, setSearchTerm3] = useState("");
  const [searchTerm4, setSearchTerm4] = useState("");
  const [value, setValue] = React.useState(0);
  const [deleteid, setDeleteid] = useState(0);
  const [openconfirm, setOpenconfirm] = useState(false);
  const [page1, setPage1] = React.useState(0);
  const [totalcount1, setTotalcount1] = React.useState(0);
  const [rowsPerPage1, setRowsPerPage1] = React.useState(10);
  const [page2, setPage2] = React.useState(0);
  const [totalcount2, setTotalcount2] = React.useState(0);
  const [rowsPerPage2, setRowsPerPage2] = React.useState(10);
  const [page3, setPage3] = React.useState(0);
  const [totalcount3, setTotalcount3] = React.useState(0);
  const [rowsPerPage3, setRowsPerPage3] = React.useState(10);
  const [page4, setPage4] = React.useState(0);
  const [totalcount4,setTotalcount4] = React.useState(0);
  const [rowsPerPage4, setRowsPerPage4] = React.useState(10);

  const handleconfirmClose = () => {
    setDeleteid(0);
    setModtype("");
    setOpenconfirm(false);
  };

  const handletabChange = (event, newValue) => {
    setValue(newValue);
  };

  const handleChangePage1 = (event, newPage) => {
    setPage1(newPage);
    setLoading(true)
  };

  const handleChangeRowsPerPage1 = (event) => {
    setRowsPerPage1(parseInt(event.target.value, 10));
    setPage1(0);
    setLoading(true)
  };

  const handleChangePage2 = (event, newPage) => {
    setPage2(newPage);
    setLoading(true)
  };

  const handleChangeRowsPerPage2 = (event) => {
    setRowsPerPage2(parseInt(event.target.value, 10));
    setPage2(0);
    setLoading(true)
  };

  const handleChangePage3 = (event, newPage) => {
    setPage3(newPage);
    setLoading(true)
    getIndividualDeliveries(client_id,newPage+1,rowsPerPage3)
  };

  const handleChangeRowsPerPage3 = (event) => {
    setRowsPerPage3(parseInt(event.target.value, 10));
    setPage3(0);
    setLoading(true)
    getIndividualDeliveries(client_id,1,parseInt(event.target.value, 10))
  };

  const handleChangePage4 = (event, newPage) => {
    setPage4(newPage);
    setLoading(true)
    getCompanyDeliveries(client_id,newPage+1,rowsPerPage4)
  };

  const handleChangeRowsPerPage4 = (event) => {
    setRowsPerPage4(parseInt(event.target.value, 10));
    setPage4(0);
    setLoading(true)
    getCompanyDeliveries(client_id,1,parseInt(event.target.value, 10))
  };

  const getIndividualClients = async (paged=0) => {
    if(paged){
      setPage1(paged-1)
    }
    setLoading(true);
    let response1 = await fetch(props.url + `/get-individuals?${searchTerm1 && 'search='+searchTerm1+'&'}page=${paged?paged-1:page1+1}&perpage=${rowsPerPage1}`, {
      credentials: "include",
    });
    let res1 = await response1.json();
    if (res1.success) {
      setLoading(false);
      setIndividuals(res1.data.data);
      setPage1(res1.data.current_page - 1)
      setTotalcount1(res1.data.total)
    }
    else {
      setLoading(false);
      if( res1.message == 'unauthenticated'){
        window.location.replace(props.exit);
        return;
      }
    }
  };

  const getApprovedCorprateClients = async (paged=0) => {
    if(paged){
      setPage2(paged-1)
    }
    setLoading(true);
    let response2 = await fetch(props.url + `/get-approved-corporations?${searchTerm2 && 'search='+searchTerm2+'&'}page=${paged?paged-1:page2+1}&perpage=${rowsPerPage2}`, {
      credentials: "include",
    });
    let res2 = await response2.json();
    if (res2.success) {
      setLoading(false);
      setCorporations(res2.data.data);
      setPage2(res2.data.current_page - 1)
      setTotalcount2(res2.data.total)
    }
    else {
      setLoading(false);
      if( res2.message == 'unauthenticated'){
        window.location.replace(props.exit);
        return;
      }
    }
  };

  const getPendingCorprateClients = async () => {
    let response3 = await fetch(props.url + "/get-pending-corporations", {
      credentials: "include",
    });
    let res3 = await response3.json();
    if (res3.success) {
      setPendingcorp(res3.data);
    }
    else {
      setLoading(false);
      if( res3.message == 'unauthenticated'){
        window.location.replace(props.exit);
        return;
      }
    }
  };

  const getZones = async () => {
    let response4 = await fetch(props.url + "/get-clear-zones", { credentials: "include" });
    let res4 = await response4.json();
    if (res4.success) {
      setLoading(false);
      setZones(res4.data);
    }
    else {
      setLoading(false);
      if( res4.message == 'unauthenticated'){
        window.location.replace(props.exit);
        return;
      }
    }
  };

  useEffect(() => {
    getIndividualClients()
    getApprovedCorprateClients();
    getPendingCorprateClients();
    getZones()
  }, [page1,rowsPerPage1,page2,rowsPerPage2,page3,rowsPerPage3,page4,rowsPerPage4]);

  const handleindChange = (event) => {
    setUserDataind({ ...userDataind, [event.target.id]: event.target.value });
  };
  const handlecorpChange = (event) => {
    setUserDatacorp({ ...userDatacorp, [event.target.id]: event.target.value });
  };

  const validateind = () => {
    let temp = [];
    temp.firstname = userDataind.firstname ? "" : "This field is required";
    temp.lastname = userDataind.lastname ? "" : "This field is required";
    if (!userDataind.email) {
      temp.email = "This field is required";
    } else if (!/\S+@\S+\.\S+/.test(userDataind.email)) {
      temp.email = "Email address is invalid";
    }
    if (!userDataind.phonenumber) {
      temp.phonenumber = "This field is required";
    } else if (userDataind.phonenumber.length < 7) {
      temp.phonenumber = "Phone number should be at least 7 digits";
    }
    temp.password = userDataind.password ? "" : "This field is required";
    if (!userDataind.confirm) {
      temp.confirm = "This field is required";
    } else if (userDataind.confirm !== userDataind.password) {
      temp.confirm = "Passwords dont match";
    }
    setinderrors({ ...temp });
    return Object.values(temp).every((x) => x == "");
  };

  const validatecorp = () => {
    let temp = [];
    temp.name = userDatacorp.name ? "" : "This field is required";
    temp.location = userDatacorp.location ? "" : "This field is required";
    if (!userDatacorp.email) {
      temp.email = "This field is required";
    } else if (!/\S+@\S+\.\S+/.test(userDatacorp.email)) {
      temp.email = "Email address is invalid";
    }
    if (!userDatacorp.phonenumber) {
      temp.phonenumber = "This field is required";
    } else if (userDatacorp.phonenumber.length < 7) {
      temp.phonenumber = "Phone number should be at least 7 digits";
    }
    if (!userDatacorp.threshold) {
      temp.threshold = "This field is required";
    } else if (userDatacorp.threshold < 1) {
      temp.threshold = "Threshold should be greater than zero";
    }
    temp.password = userDatacorp.password ? "" : "This field is required";
    if (!userDatacorp.confirm) {
      temp.confirm = "This field is required";
    } else if (userDatacorp.confirm !== userDatacorp.password) {
      temp.confirm = "Passwords dont match";
    }
    setcorperrors({ ...temp });
    return Object.values(temp).every((x) => x == "");
  };

  const updatevalidateind = () => {
    let temp = [];
    temp.firstname = userDataind.firstname ? "" : "This field is required";
    temp.lastname = userDataind.lastname ? "" : "This field is required";
    if (!userDataind.email) {
      temp.email = "This field is required";
    } else if (!/\S+@\S+\.\S+/.test(userDataind.email)) {
      temp.email = "Email address is invalid";
    }
    if (!userDataind.phonenumber) {
      temp.phonenumber = "This field is required";
    } else if (userDataind.phonenumber.length < 7) {
      temp.phonenumber = "Phone number should be at least 7 digits";
    }
    if (userDataind.password) {
      if (!userDataind.confirm) {
        temp.confirm = "This field is required";
      } else {
        if (userDataind.confirm !== userDataind.password) {
          temp.confirm = "Passwords dont match";
        }
      }
    }
    setinderrors({ ...temp });
    return Object.values(temp).every((x) => x == "");
  };

  const updatevalidatecorp = () => {
    let temp = [];
    temp.name = userDatacorp.name ? "" : "This field is required";
    temp.location = userDatacorp.location ? "" : "This field is required";
    if (!userDatacorp.email) {
      temp.email = "This field is required";
    } else if (!/\S+@\S+\.\S+/.test(userDatacorp.email)) {
      temp.email = "Email address is invalid";
    }
    if (!userDatacorp.phonenumber) {
      temp.phonenumber = "This field is required";
    } else if (userDatacorp.phonenumber.length < 7) {
      temp.phonenumber = "Phone number should be at least 7 digits";
    }
    if (!userDatacorp.threshold) {
      temp.threshold = "This field is required";
    } else if (userDatacorp.threshold < 1) {
      temp.threshold = "Threshold should be greater than zero";
    }
    if (userDatacorp.password) {
      if (!userDatacorp.confirm) {
        temp.confirm = "This field is required";
      } else {
        if (userDatacorp.confirm !== userDatacorp.password) {
          temp.confirm = "Passwords dont match";
        }
      }
    }
    setcorperrors({ ...temp });
    return Object.values(temp).every((x) => x == "");
  };

  const handleindcompdelClose = () => {
    setCompinddel([]);
    setCompinddelprop({
      name: "",
      deliveries: 0,
      chart: [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
    });
    setClient_id(0)
    setSearchTerm3("")
    setindcompdelopen(false);
  };

  const handlecorpcompdelClose = () => {
    setCompcorpdel([]);
    setCompcorpdelprop({
      name: "",
      deliveries: 0,
      chart: [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
    });
    setClient_id(0)
    setSearchTerm4("")
    setcorpcompdelopen(false);
  };

  const getCompanyDeliveries = async (id,paged=0,rowed=0) => {
    if(paged){
      setPage4(paged-1)
    }
    setLoading(true);
    let response = await fetch(props.url + `/get-client-delivery-requests/${id}?${searchTerm4 && 'search='+searchTerm4+'&'}page=${paged? paged :page4+1}&perpage=${rowed? rowed :rowsPerPage4}`, {
      credentials: "include",
    });
    let data = await response.json();
    if (data.success) {
      setCompcorpdel(data.data.data);
      setPage4(data.data.current_page - 1)
      setTotalcount4(data.data.total)
      setLoading(false);
    } else {
      setLoading(false);
      if( data.message == 'unauthenticated'){
        window.location.replace(props.exit);
        return;
      }
    }
  };

  const getCompanyDeliveriesStats = async (id) => {
    setLoading(true);
    let response = await fetch(props.url + `/get-client-delivery-requests-stats/${id}`, {
      credentials: "include",
    });
    let data = await response.json();
    if (data) {
      setLoading(false);
    if (data.success) {
      setClient_id(id)
      setCompcorpdelprop({
        name: data.data.name,
        deliveries: data.data.total_del,
        chart: data.data.monthly_del,
      });
      setcorpcompdelopen(true);
    } else {
      setLoading(false);
      if( data.message == 'unauthenticated'){
        window.location.replace(props.exit);
        return;
      }
    }
  }
  };


  const handlecompdelOpen = (id) => {
    setLoading(true);
    getCompanyDeliveries(id)
    getCompanyDeliveriesStats(id)
  };

  const searchHandler1 = async () => {
    getIndividualClients(1)
  };

  const searchHandler2 = async () => {
    getApprovedCorprateClients(1)
  };

  const searchHandler3 =  () => {
      getIndividualDeliveries(client_id,1)
  };

  const searchHandler4 =  () => {
    getCompanyDeliveries(client_id,1)
  };

  const toggleDraweropen = async (event, $id) => {
    if (
      event.type === "keydown" &&
      (event.key === "Tab" || event.key === "Shift")
    ) {
      return;
    }
    setLoading(true);
    let response = await fetch(props.url + `/get-delivery-packages/${$id}`, {
      credentials: "include",
    });
    let res = await response.json();
    if (res) {
      setLoading(false);
      if (res.success) {
        setPackages(res.data);
        console.log(packages);
        setOpendrawer(true);
      } else {
        setLoading(false);
        if( res.message == 'unauthenticated'){
          window.location.replace(props.exit);
          return;
        }
      }
    }
  };

  const toggleDrawerclose = (event) => {
    if (
      event.type === "keydown" &&
      (event.key === "Tab" || event.key === "Shift")
    ) {
      return;
    }
    setPackages([]);
    setOpendrawer(false);
  };

  const handleaddClose = async (event) => {
    event.preventDefault();
    if (validatecorp()) {
      setAddloading(true);
      let response = await fetch(props.url + "/create-corporation", {
        method: "POST",
        headers: {
          "Content-type": "application/json",
        },
        body: JSON.stringify(userDatacorp),
        credentials: "include",
      });
      let res = await response.json();
      if (res) {
        if (res.success) {
          setCorporations([...corporations, { ...res.data }]);
          addhandleClose();
          toast.success("Company added");
        } else {
          if( res.message == 'unauthenticated'){
            window.location.replace(props.exit);
            return;
          }
          else if (res.message.email[0]) {
            setAddloading(false);
            setcorperrors({ ...corperrors, email: res.message.email[0] });
          } else {
            addhandleClose();
            toast.error(res.message);
          }
        }
      }
    }
  };

  const processDeletion = () => {
    if (modtype == "individual") {
      deleteIndividual();
    } else if (modtype == "company") {
      deleteCorporation();
    } else if (modtype == "pendcomp") {
      deletePendingcorp();
    }
  };

  const confirmdeleteIndividual = (id) => {
    setDeleteid(id);
    setModtype("individual");
    setOpenconfirm(true);
  };

  const deleteIndividual = async () => {
    setLoading(true);
    const cid = deleteid;
    let response = await fetch(props.url + "/delete-individual", {
      method: "DELETE",
      headers: {
        "Content-type": "application/json",
      },
      body: JSON.stringify({ id: deleteid }),
      credentials: "include",
    });
    let res = await response.json();
    if (res) {
      setDeleteid(0);
      setModtype("");
      setOpenconfirm(false);
      setLoading(false);
      if (res.success) {
        setIndividuals(individuals.filter((current) => cid !== current.id));
        toast.success("Client deleted");
      } else {
        if( res.message == 'unauthenticated'){
          window.location.replace(props.exit);
          return;
        }
        toast.error(res.message);
      }
    }
  };

  const confirmdeleteCorporation = (id) => {
    setDeleteid(id);
    setModtype("company");
    setOpenconfirm(true);
  };

  const deleteCorporation = async () => {
    setLoading(true);
    const cid = deleteid;
    let response = await fetch(props.url + "/delete-corporation", {
      method: "DELETE",
      headers: {
        "Content-type": "application/json",
      },
      body: JSON.stringify({ id: deleteid }),
      credentials: "include",
    });
    let res = await response.json();
    if (res) {
      setDeleteid(0);
      setModtype("");
      setOpenconfirm(false);
      setLoading(false);
      if (res.success) {
        setCorporations(corporations.filter((current) => cid !== current.id));
        toast.success("Comapny deleted");
      } else {
        if( res.message == 'unauthenticated'){
          window.location.replace(props.exit);
          return;
        }
        toast.error(res.message);
      }
    }
  };

  const confirmdeletePendingcorp = (id) => {
    setDeleteid(id);
    setModtype("pendcomp");
    setOpenconfirm(true);
  };

  const deletePendingcorp = async () => {
    setLoading(true);
    const cid = deleteid;
    let response = await fetch(props.url + "/delete-corporation", {
      method: "DELETE",
      headers: {
        "Content-type": "application/json",
      },
      body: JSON.stringify({ id: deleteid }),
      credentials: "include",
    });
    let res = await response.json();
    if (res) {
      setDeleteid(0);
      setModtype("");
      setOpenconfirm(false);
      setLoading(false);
      if (res.success) {
        setPendingcorp(pendingcorp.filter((current) => cid !== current.id));
        toast.success("Pending Request deleted");
      } else {
        if( res.message == 'unauthenticated'){
          window.location.replace(props.exit);
          return;
        }
        toast.error(res.message);
      }
    }
  };

  const approvePendingcorp = async (id) => {
    setLoading(true);
    let response = await fetch(props.url + "/approve-corporation", {
      method: "PUT",
      headers: {
        "Content-type": "application/json",
      },
      body: JSON.stringify({ id: id }),
      credentials: "include",
    });
    let res = await response.json();
    if (res) {
      if (res.success) {
        setLoading(false);
        setPendingcorp(pendingcorp.filter((current) => id !== current.id));
        setCorporations([...corporations, { ...res.data }]);
        toast.success("Pending Request approved");
      } else {
        setLoading(false);
        if( res.message == 'unauthenticated'){
          window.location.replace(props.exit);
          return;
        }
        toast.error(res.message.message);
      }
    }
  };

  const handleindaddClose = async (event) => {
    event.preventDefault();
    if (validateind()) {
      setAddloading(true);
      let response = await fetch(props.url + "/create-individual", {
        method: "POST",
        headers: {
          "Content-type": "application/json",
        },
        body: JSON.stringify(userDataind),
        credentials: "include",
      });
      let res = await response.json();
      if (res) {
        if (res.success) {
          setIndividuals([...individuals, { ...res.data }]);
          addindhandleClose();
          toast.success("Client added");
        } else {
          if( res.message == 'unauthenticated'){
            window.location.replace(props.exit);
            return;
          }
          else if (res.message.email[0]) {
            setAddloading(false);
            setinderrors({ ...inderrors, email: res.message.email[0] });
          } else {
            addindhandleClose();
            toast.error(res.message);
          }
        }
      }
    }
  };

  const handleindeditClose = async (event) => {
    event.preventDefault();
    if (updatevalidateind()) {
      setAddloading(true);
      let response = await fetch(props.url + `/update-individual/${editid}`, {
        method: "PUT",
        headers: {
          "Content-type": "application/json",
        },
        body: JSON.stringify(userDataind),
        credentials: "include",
      });
      let res = await response.json();
      if (res) {
        if (res.success) {
          setIndividuals(
            individuals.map((prop) =>
              prop.id === editid ? { ...res.data } : prop
            )
          );
          editindhandleClose();
          toast.success("Client Updated");
        } else {
          if( res.message == 'unauthenticated'){
            window.location.replace(props.exit);
            return;
          }
          else if (res.message.email[0]) {
            setAddloading(false);
            setinderrors({ ...inderrors, email: res.message.email[0] });
          } else {
            editindhandleClose();
            toast.error(res.message);
          }
        }
      }
    }
  };
  const handleClickOpen = async (id, status) => {
    setLoading(true);
    let response = await fetch(props.url + `/get-corporation/${id}`, {
      credentials: "include",
    });
    let res = await response.json();
    if (res) {
      setLoading(false);
      if (res.success) {
        setUserDatacorp({
          name: res.data.first_name,
          email: res.data.email,
          phonenumber: res.data.phone_number,
          threshold: res.data.corp_details.threshold,
          location: res.data.corp_details.zone_id,
          password: "",
          confirm: "",
        });
        setEditid(id);
        if (status == "app") {
          seteditopen(true);
        } else {
          seteditpendopen(true);
        }
      } else {
        if( res.message == 'unauthenticated'){
          window.location.replace(props.exit);
          return;
        }
        toast.error(res.message);
      }
    }
  };

  const handlecorpClose = () => {
    setUserDatacorp({
      name: "",
      email: "",
      phonenumber: "",
      threshold: "",
      location: "",
      password: "",
      confirm: "",
    });
    setEditid(0);
    setcorperrors([]);
    seteditpendopen(false);
    seteditopen(false);
    setAddloading(false);
  };

  const handleeditClose = async (event) => {
    event.preventDefault();
    if (updatevalidatecorp()) {
      setAddloading(true);
      let response = await fetch(props.url + `/update-corporation/${editid}`, {
        method: "PUT",
        headers: {
          "Content-type": "application/json",
        },
        body: JSON.stringify(userDatacorp),
        credentials: "include",
      });
      let res = await response.json();
      if (res) {
        if (res.success) {
          setCorporations(
            corporations.map((prop) =>
              prop.id === editid ? { ...res.data } : prop
            )
          );
          handlecorpClose();
          toast.success("Company Updated");
        } else {
          if( res.message == 'unauthenticated'){
            window.location.replace(props.exit);
            return;
          }
          else if (res.message.email[0]) {
            setAddloading(false);
            setcorperrors({ ...corperrors, email: res.message.email[0] });
          } else {
            handlecorpClose();
            toast.error(res.message);
          }
        }
      }
    }
  };

  const handleeditpendClose = async (event) => {
    event.preventDefault();
    if (updatevalidatecorp()) {
      setAddloading(true);
      let response = await fetch(props.url + `/update-corporation/${editid}`, {
        method: "PUT",
        headers: {
          "Content-type": "application/json",
        },
        body: JSON.stringify({ ...userDatacorp }),
        credentials: "include",
      });
      let res = await response.json();
      if (res) {
        if (res.success) {
          setPendingcorp(
            pendingcorp.map((prop) =>
              prop.id === editid ? { ...res.data } : prop
            )
          );
          handlecorpClose();
          toast.success("Pending Request Updated");
        } else {
          if( res.message == 'unauthenticated'){
            window.location.replace(props.exit);
            return;
          }
          else if (res.message.email[0]) {
            setAddloading(false);
            setcorperrors({ ...corperrors, email: res.message.email[0] });
          } else {
            handlecorpClose();
            toast.error(res.message);
          }
        }
      }
    }
  };

  const getIndividualDeliveries = async (id,paged=0,rowed=0) => {
    if(paged){
      setPage3(paged-1)
    }
    setLoading(true);
    let response = await fetch(props.url + `/get-client-delivery-requests/${id}?${searchTerm3 && 'search='+searchTerm3+'&'}page=${paged?paged:page3+1}&perpage=${rowed? rowed :rowsPerPage3}`, {
      credentials: "include",
    });
    let data = await response.json();
    if (data.success) {
      setCompinddel(data.data.data);
      setPage3(data.data.current_page - 1)
      setTotalcount3(data.data.total)
      setLoading(false);
    } else {
      setLoading(false);
      if( data.message == 'unauthenticated'){
        window.location.replace(props.exit);
        return;
      }
      toast.error(data.message);
    }
  };

  const getIndividualDeliveriesStats = async (id) => {
    setLoading(true);
    let response = await fetch(props.url + `/get-client-delivery-requests-stats/${id}`, {
      credentials: "include",
    });
    let data = await response.json();
    if (data) {
      setLoading(false);
    if (data.success) {
      setClient_id(id);
        setCompinddelprop({
          name: data.data.name,
          deliveries: data.data.total_del,
          chart: data.data.monthly_del,
        });
        setindcompdelopen(true);
    } else {
      if( data.message == 'unauthenticated'){
        window.location.replace(props.exit);
        return;
      }
      toast.error(data.message);
    }
  }
  };

  const detailsOpen = (id) => {
    setLoading(true)
    getIndividualDeliveries(id)
    getIndividualDeliveriesStats(id)
  };

  // const getIndividualDeliveries = async () => {
  //   let response1 = await fetch(props.url + "/get-individuals", {
  //     credentials: "include",
  //   });
  //   let res1 = await response1.json();
  //   if (res1.success) {
  //     setIndividuals(res1.data);
  //     setPage1(res1.data.current_page - 1)
  //     setTotalcount1(res1.data.total)
  //   }
  // };

  const handleeditOpen = async (id) => {
    setLoading(true);
    let response = await fetch(props.url + `/get-individual/${id}`, {
      credentials: "include",
    });
    let res = await response.json();
    if (res) {
      setLoading(false);
      if (res.success) {
        setUserDataind({
          firstname: res.data.first_name,
          lastname: res.data.last_name,
          email: res.data.email,
          phonenumber: res.data.phone_number,
          password: "",
          confirm: "",
        });
        setEditid(id);
        seteditindopen(true);
      } else {
        if( res.message == 'unauthenticated'){
          window.location.replace(props.exit);
          return;
        }
        toast.error(res.message);
      }
    }
  };
  const addhandleClose = () => {
    setUserDatacorp({
      name: "",
      email: "",
      phonenumber: "",
      threshold: "",
      location: "",
      password: "",
      confirm: "",
    });
    setcorperrors([]);
    setaddOpen(false);
    setAddloading(false);
  };

  const addindhandleClose = () => {
    setUserDataind({
      firstname: "",
      lastname: "",
      email: "",
      phonenumber: "",
      password: "",
      confirm: "",
    });
    setinderrors([]);
    setaddindOpen(false);
    setAddloading(false);
  };

  const editindhandleClose = () => {
    setUserDataind({
      firstname: "",
      lastname: "",
      email: "",
      phonenumber: "",
      password: "",
      confirm: "",
    });
    setEditid(0);
    setinderrors([]);
    seteditindopen(false);
    setAddloading(false);
  };

  const addClickOpen = () => {
    setaddOpen(true);
  };
  const addindClickOpen = () => {
    setaddindOpen(true);
  };
  return (
    <GridContainer>
      {loading && (
        <Backdrop className={classes.backdrop} open>
          <CircularProgress color="inherit" />
        </Backdrop>
      )}
      <GridItem xs={12} sm={12} md={12}>
        <Card>
          <CardHeader color="danger">
            <h4 className={classes.cardTitleWhite}>Individuals</h4>
          </CardHeader>
          <CardBody>
            <Button onClick={addindClickOpen} color="danger">
              <Add /> Add Client
            </Button>
            <Table
              searchKeyword={searchHandler1}
              term={searchTerm1}
              setTerm={setSearchTerm1}
              tableHeaderColor="gray"
              tableHead={[
                "",
                "Client Name",
                "Email",
                "Telephone",
                "Delivery Requests",
                "Totoal Revenue",
                "Points",
                "Deliveries",
                "Edit",
                "Delete",
              ]}
              tableData={
                individuals.length == 0 ? (
                  <TableRow>
                    <TableCell
                      style={{ textAlign: "center" }}
                      colSpan={9}
                      className={classes.tableCell}
                    >
                      No Individuals
                    </TableCell>
                  </TableRow>
                ) : (
                  individuals.map((prop) => {
                    return (
                      <TableRow key={prop.id} className={classes.tableBodyRow}>
                        <TableCell className={classes.tableCell} key={prop.id}>
                          <Avatar
                            alt={prop.first_name}
                            src={props.imgurl + prop.avatar}
                            sx={{ width: 56, height: 56 }}
                          />
                        </TableCell>
                        <TableCell className={classes.tableCell} key={prop.id}>
                          {prop.first_name + " " + prop.last_name}
                        </TableCell>
                        <TableCell className={classes.tableCell} key={prop.id}>
                          {prop.email}
                        </TableCell>
                        <TableCell className={classes.tableCell} key={prop.id}>
                          {prop.phone_number}
                        </TableCell>
                        <TableCell className={classes.tableCell} key={prop.id}>
                          {prop.delivery_count}
                        </TableCell>
                        <TableCell className={classes.tableCell} key={prop.id}>
                          GMD{" "}
                          {prop.delivery_sum_price
                            ? prop.delivery_sum_price
                            : 0}
                        </TableCell>
                        <TableCell className={classes.tableCell} key={prop.id}>
                          {prop.points
                            ? prop.points.points
                            : 0}
                        </TableCell>

                        <TableCell className={classes.tableCell} key={prop.id}>
                          <Details
                            onClick={() => detailsOpen(prop.id)}
                            style={{ color: "black", cursor: "pointer" }}
                            key={prop.id}
                          />
                        </TableCell>
                        <TableCell className={classes.tableCell} key={prop.id}>
                          <Edit
                            onClick={() => handleeditOpen(prop.id)}
                            style={{ color: "black", cursor: "pointer" }}
                            key={prop.id}
                          />
                        </TableCell>
                        <TableCell className={classes.tableCell} key={prop.id}>
                          <Delete
                            onClick={() => confirmdeleteIndividual(prop.id)}
                            style={{ cursor: "pointer" }}
                            key={prop.id}
                          />
                        </TableCell>
                      </TableRow>
                    );
                  })
                )
              }
            />
          </CardBody>
          <TablePagination
      component="div"
      count={totalcount1}
      page={page1}
      onPageChange={handleChangePage1}
      rowsPerPage={rowsPerPage1}
      onRowsPerPageChange={handleChangeRowsPerPage1}
    />
        </Card>
      </GridItem>
      <GridItem xs={12} sm={12} md={12}>
        <Card>
          <CardHeader color="danger">
            <h4 className={classes.cardTitleWhite}>Corporations</h4>
          </CardHeader>
          <CardBody>
            <div className={classes.root}>
              <AppBar position="static" color="default">
                <Tabs
                  value={value}
                  onChange={handletabChange}
                  variant="fullWidth"
                  scrollButtons="on"
                  indicatorColor="secondary"
                  textColor="secondary"
                  aria-label="scrollable force tabs example"
                >
                  <Tab
                    className={dividermatch && classes.dividetab}
                    label="Approved Companies"
                    icon={<DoneAllIcon />}
                    {...a11yProps(0)}
                  />
                  <Tab
                    label="Pending Company Requests"
                    icon={<HourglassEmptyIcon />}
                    {...a11yProps(1)}
                  />
                </Tabs>
              </AppBar>
              <TabPanel value={value} index={0}>
                <Button onClick={addClickOpen} color="danger">
                  <Add /> Add Company
                </Button>

                <Table
                  searchKeyword={searchHandler2}
                  term={searchTerm2}
                  setTerm={setSearchTerm2}
                  tableHeaderColor="gray"
                  tableHead={[
                    "",
                    "Company Name",
                    "Email",
                    "Telephone",
                    "Location",
                    "Threshold",
                    "Delivery Requests",
                    "Total Revenue",
                    "Deliveries",
                    "Payments",
                    "Edit",
                    "Delete",
                  ]}
                  tableData={
                    corporations.length == 0 ? (
                      <TableRow>
                        <TableCell
                          style={{ textAlign: "center" }}
                          colSpan={12}
                          className={classes.tableCell}
                        >
                          No Companies
                        </TableCell>
                      </TableRow>
                    ) : (
                      corporations.map((prop) => {
                        return (
                          <TableRow
                            key={prop.id}
                            className={classes.tableBodyRow}
                          >
                            <TableCell
                              className={classes.tableCell}
                              key={prop.id}
                            >
                              <Avatar
                                alt={prop.first_name}
                                src={props.imgurl + prop.avatar}
                                sx={{ width: 56, height: 56 }}
                              />
                            </TableCell>
                            <TableCell
                              className={classes.tableCell}
                              key={prop.id}
                            >
                              {prop.first_name}
                            </TableCell>
                            <TableCell
                              className={classes.tableCell}
                              key={prop.id}
                            >
                              {prop.email}
                            </TableCell>
                            <TableCell
                              className={classes.tableCell}
                              key={prop.id}
                            >
                              {prop.phone_number}
                            </TableCell>
                            <TableCell
                              className={classes.tableCell}
                              key={prop.id}
                            >
                              {prop.corp_details.zone.zone_name}
                            </TableCell>
                            <TableCell
                              className={classes.tableCell}
                              key={prop.id}
                            >
                              GMD {prop.corp_details.threshold}
                            </TableCell>
                            <TableCell
                              className={classes.tableCell}
                              key={prop.id}
                            >
                              {prop.delivery_count}
                            </TableCell>
                            <TableCell
                              className={classes.tableCell}
                              key={prop.id}
                            >
                              GMD{" "}
                              {prop.delivery_sum_price
                                ? prop.delivery_sum_price
                                : 0}
                            </TableCell>
                            <TableCell
                              className={classes.tableCell}
                              key={prop.id}
                            >
                              <Details
                                onClick={() => handlecompdelOpen(prop.id)}
                                style={{ color: "black", cursor: "pointer" }}
                                key={prop.id}
                              />
                            </TableCell>
                            <TableCell
                              className={classes.tableCell}
                              key={prop.id}
                            >
                              <Link to={`/admin/invoices/${prop.id}`}>
                                <FontAwesomeIcon
                                  style={{
                                    fontSize: "23px",
                                    color: "black",
                                    cursor: "pointer",
                                  }}
                                  icon={faCoins}
                                />
                              </Link>
                            </TableCell>
                            <TableCell
                              className={classes.tableCell}
                              key={prop.id}
                            >
                              <Edit
                                onClick={() => handleClickOpen(prop.id, "app")}
                                style={{ color: "black", cursor: "pointer" }}
                                key={prop.id}
                              />
                            </TableCell>
                            <TableCell
                              className={classes.tableCell}
                              key={prop.id}
                            >
                              <Delete
                                onClick={() =>
                                  confirmdeleteCorporation(prop.id)
                                }
                                style={{ cursor: "pointer" }}
                                key={prop.id}
                              />
                            </TableCell>
                          </TableRow>
                        );
                      })
                    )
                  }
                />
                <TablePagination
      component="div"
      count={totalcount2}
      page={page2}
      onPageChange={handleChangePage2}
      rowsPerPage={rowsPerPage2}
      onRowsPerPageChange={handleChangeRowsPerPage2}
    />
              </TabPanel>
              <TabPanel value={value} index={1}>
                <Table
                  tableHeaderColor="gray"
                  tableHead={[
                    "Name",
                    "Email",
                    "Telephone",
                    "Address",
                    "Threshold",
                    "Approve",
                    "Edit",
                    "Delete",
                  ]}
                  tableData={
                    pendingcorp.length == 0 ? (
                      <TableRow>
                        <TableCell
                          style={{ textAlign: "center" }}
                          colSpan={8}
                          className={classes.tableCell}
                        >
                          No Pending Requests
                        </TableCell>
                      </TableRow>
                    ) : (
                      pendingcorp.map((prop) => {
                        return (
                          <TableRow
                            key={prop.id}
                            className={classes.tableBodyRow}
                          >
                            <TableCell
                              className={classes.tableCell}
                              key={prop.id}
                            >
                              {prop.first_name}
                            </TableCell>
                            <TableCell
                              className={classes.tableCell}
                              key={prop.id}
                            >
                              {prop.email}
                            </TableCell>
                            <TableCell
                              className={classes.tableCell}
                              key={prop.id}
                            >
                              {prop.phone_number}
                            </TableCell>
                            <TableCell
                              className={classes.tableCell}
                              key={prop.id}
                            >
                              {prop.address}
                            </TableCell>
                            <TableCell
                              className={classes.tableCell}
                              key={prop.id}
                            >
                              GMD {prop.corp_details.threshold}
                            </TableCell>
                            <TableCell
                              className={classes.tableCell}
                              key={prop.id}
                            >
                              <Button
                                variant="contained"
                                onClick={() => approvePendingcorp(prop.id)}
                                style={{ padding: "10px" }}
                                color="success"
                                size="small"
                              >
                                <DoneAllIcon />
                              </Button>
                            </TableCell>
                            <TableCell
                              className={classes.tableCell}
                              key={prop.id}
                            >
                              <Edit
                                onClick={() => handleClickOpen(prop.id, "pend")}
                                style={{ cursor: "pointer" }}
                                key={prop.id}
                              />
                            </TableCell>
                            <TableCell
                              className={classes.tableCell}
                              key={prop.id}
                            >
                              <Delete
                                onClick={() =>
                                  confirmdeletePendingcorp(prop.id)
                                }
                                style={{ cursor: "pointer" }}
                                key={prop.id}
                              />
                            </TableCell>
                          </TableRow>
                        );
                      })
                    )
                  }
                  search={false}
                />
              </TabPanel>
            </div>
          </CardBody>
        </Card>
      </GridItem>

      <Dialog
        fullWidth={true}
        maxWidth={!addloading ? "sm" : "xs"}
        open={addopen}
        TransitionComponent={Transition}
        keepMounted
        onClose={addhandleClose}
        aria-labelledby="alert-dialog-slide-title"
        aria-describedby="alert-dialog-slide-description"
      >
        <DialogContent>
          <DialogContentText id="alert-dialog-slide-description">
            <GridContainer>
              <GridItem xs={12} sm={12} md={12}>
                <>
                  <CardHeader
                    style={{ textAlign: "center" }}
                    color={!addloading ? "danger" : ""}
                  >
                    <h4
                      className={
                        !addloading
                          ? classes.cardTitleWhite
                          : classes.cardTitleBlack
                      }
                    >
                      {!addloading ? "Add Corporation" : "Adding Corporation"}
                    </h4>
                  </CardHeader>
                  <CardBody>
                    {addloading ? (
                      <DialogContentText style={{ textAlign: "center" }}>
                        <CircularProgress color="secondary" />
                      </DialogContentText>
                    ) : (
                      <form onSubmit={handleaddClose}>
                        <GridContainer>
                          <GridItem xs={12} sm={12} md={12}>
                            <TextField
                              onChange={handlecorpChange}
                              id="name"
                              value={userDatacorp.name}
                              label="Company Name"
                              style={{ marginTop: 20 }}
                              fullWidth
                              margin="normal"
                              InputProps={{
                                startAdornment: (
                                  <InputAdornment position="start">
                                    <FontAwesomeIcon icon={faBuilding} />
                                  </InputAdornment>
                                ),
                              }}
                              error={corperrors.name}
                              helperText={corperrors.name && corperrors.name}
                            />
                          </GridItem>
                        </GridContainer>

                        <GridContainer>
                          <GridItem xs={12} sm={12} md={12}>
                            <TextField
                              onChange={handlecorpChange}
                              id="email"
                              value={userDatacorp.email}
                              label="Email"
                              style={{ marginTop: 20 }}
                              fullWidth
                              type="email"
                              margin="normal"
                              InputProps={{
                                startAdornment: (
                                  <InputAdornment position="start">
                                    <MailOutlineIcon />
                                  </InputAdornment>
                                ),
                              }}
                              error={corperrors.email}
                              helperText={corperrors.email && corperrors.email}
                            />
                          </GridItem>
                        </GridContainer>
                        <GridContainer>
                          <GridItem xs={12} sm={12} md={6}>
                            <TextField
                              onChange={handlecorpChange}
                              id="phonenumber"
                              value={userDatacorp.phonenumber}
                              label="Phone Number"
                              style={{ marginTop: 20 }}
                              fullWidth
                              type="number"
                              margin="normal"
                              InputProps={{
                                startAdornment: (
                                  <InputAdornment position="start">
                                    <PhoneIcon />
                                  </InputAdornment>
                                ),
                              }}
                              error={corperrors.phonenumber}
                              helperText={
                                corperrors.phonenumber && corperrors.phonenumber
                              }
                            />
                          </GridItem>
                          <GridItem xs={12} sm={12} md={6}>
                            <TextField
                              onChange={handlecorpChange}
                              id="threshold"
                              value={userDatacorp.threshold}
                              label="Threshold"
                              style={{ marginTop: 20 }}
                              fullWidth
                              type="number"
                              margin="normal"
                              InputProps={{
                                startAdornment: (
                                  <InputAdornment position="start">
                                    GMD
                                  </InputAdornment>
                                ),
                              }}
                              error={corperrors.threshold}
                              helperText={
                                corperrors.threshold && corperrors.threshold
                              }
                            />
                          </GridItem>
                        </GridContainer>
                        <GridContainer>
                          <GridItem xs={12} sm={12} md={12}>
                            <FormControl
                              style={{ minWidth: "100%", marginTop: 20 }}
                              className={classes.formControl}
                            >
                              <InputLabel id="demo-simple-select-label">
                                Location
                              </InputLabel>
                              <Select
                                value={userDatacorp.location}
                                onChange={(e) =>
                                  setUserDatacorp({
                                    ...userDatacorp,
                                    location: e.target.value,
                                  })
                                }
                                labelId="demo-simple-select-label"
                                id="location"
                              >
                                {zones.length == 0 ? (
                                  <MenuItem>No Zones</MenuItem>
                                ) : (
                                  zones.map((prop) => {
                                    return (
                                      <MenuItem key={prop.id} value={prop.id}>
                                        {prop.zone_name}
                                      </MenuItem>
                                    );
                                  })
                                )}
                              </Select>
                            </FormControl>
                            <Danger>{corperrors.location}</Danger>
                          </GridItem>
                        </GridContainer>
                        <GridContainer>
                          <GridItem xs={12} sm={12} md={6}>
                            <TextField
                              onChange={handlecorpChange}
                              value={userDatacorp.password}
                              label="Password"
                              type="password"
                              style={{ marginTop: 20 }}
                              id="password"
                              fullWidth
                              margin="normal"
                              InputProps={{
                                startAdornment: (
                                  <InputAdornment position="start">
                                    <LockOpenIcon />
                                  </InputAdornment>
                                ),
                              }}
                              error={corperrors.password}
                              helperText={
                                corperrors.password && corperrors.password
                              }
                            />
                          </GridItem>
                          <GridItem xs={12} sm={12} md={6}>
                            <TextField
                              onChange={handlecorpChange}
                              value={userDatacorp.confirm}
                              type="password"
                              label="Confirm Password"
                              style={{ marginTop: 20 }}
                              id="confirm"
                              fullWidth
                              margin="normal"
                              InputProps={{
                                startAdornment: (
                                  <InputAdornment position="start">
                                    <LockOpenIcon />
                                  </InputAdornment>
                                ),
                              }}
                              error={corperrors.confirm}
                              helperText={
                                corperrors.confirm && corperrors.confirm
                              }
                            />
                          </GridItem>
                        </GridContainer>
                      </form>
                    )}
                  </CardBody>
                </>
              </GridItem>
            </GridContainer>
          </DialogContentText>
        </DialogContent>
        {!addloading && (
          <DialogActions>
            <Button
              style={{ display: "block", marginLeft: "0", marginRight: "auto" }}
              onClick={addhandleClose}
              color="danger"
            >
              <Close />
              Cancel
            </Button>
            <Button
              style={{ float: "right" }}
              color="danger"
              onClick={handleaddClose}
            >
              Create <Arrow />
            </Button>
          </DialogActions>
        )}
      </Dialog>
      <Dialog
        fullWidth={true}
        maxWidth={!addloading ? "sm" : "xs"}
        open={editopen}
        TransitionComponent={Transition}
        keepMounted
        onClose={handlecorpClose}
        aria-labelledby="alert-dialog-slide-title"
        aria-describedby="alert-dialog-slide-description"
      >
        <DialogContent>
          <DialogContentText id="alert-dialog-slide-description">
            <GridContainer>
              <GridItem xs={12} sm={12} md={12}>
                <>
                  <CardHeader
                    style={{ textAlign: "center" }}
                    color={!addloading ? "danger" : ""}
                  >
                    <h4
                      className={
                        !addloading
                          ? classes.cardTitleWhite
                          : classes.cardTitleBlack
                      }
                    >
                      {!addloading ? "Update Company" : "Updating Company"}
                    </h4>
                  </CardHeader>
                  <CardBody>
                    {addloading ? (
                      <DialogContentText style={{ textAlign: "center" }}>
                        <CircularProgress color="secondary" />
                      </DialogContentText>
                    ) : (
                      <form onSubmit={handleeditClose}>
                        <GridContainer>
                          <GridItem xs={12} sm={12} md={12}>
                            <TextField
                              onChange={handlecorpChange}
                              id="name"
                              value={userDatacorp.name}
                              label="Company Name"
                              style={{ marginTop: 20 }}
                              fullWidth
                              margin="normal"
                              InputProps={{
                                startAdornment: (
                                  <InputAdornment position="start">
                                    <FontAwesomeIcon icon={faBuilding} />
                                  </InputAdornment>
                                ),
                              }}
                              error={corperrors.name}
                              helperText={corperrors.name && corperrors.name}
                            />
                          </GridItem>
                        </GridContainer>

                        <GridContainer>
                          <GridItem xs={12} sm={12} md={12}>
                            <TextField
                              onChange={handlecorpChange}
                              id="email"
                              value={userDatacorp.email}
                              label="Email"
                              style={{ marginTop: 20 }}
                              fullWidth
                              type="email"
                              margin="normal"
                              InputProps={{
                                startAdornment: (
                                  <InputAdornment position="start">
                                    <MailOutlineIcon />
                                  </InputAdornment>
                                ),
                              }}
                              error={corperrors.email}
                              helperText={corperrors.email && corperrors.email}
                            />
                          </GridItem>
                        </GridContainer>
                        <GridContainer>
                          <GridItem xs={12} sm={12} md={6}>
                            <TextField
                              onChange={handlecorpChange}
                              id="phonenumber"
                              value={userDatacorp.phonenumber}
                              label="Phone Number"
                              style={{ marginTop: 20 }}
                              fullWidth
                              type="number"
                              margin="normal"
                              InputProps={{
                                startAdornment: (
                                  <InputAdornment position="start">
                                    <PhoneIcon />
                                  </InputAdornment>
                                ),
                              }}
                              error={corperrors.phonenumber}
                              helperText={
                                corperrors.phonenumber && corperrors.phonenumber
                              }
                            />
                          </GridItem>
                          <GridItem xs={12} sm={12} md={6}>
                            <TextField
                              onChange={handlecorpChange}
                              id="threshold"
                              value={userDatacorp.threshold}
                              label="Threshold"
                              style={{ marginTop: 20 }}
                              fullWidth
                              type="number"
                              margin="normal"
                              InputProps={{
                                startAdornment: (
                                  <InputAdornment position="start">
                                    GMD
                                  </InputAdornment>
                                ),
                              }}
                              error={corperrors.threshold}
                              helperText={
                                corperrors.threshold && corperrors.threshold
                              }
                            />
                          </GridItem>
                        </GridContainer>
                        <GridContainer>
                          <GridItem xs={12} sm={12} md={12}>
                            <FormControl
                              style={{ minWidth: "100%", marginTop: 20 }}
                              className={classes.formControl}
                            >
                              <InputLabel id="demo-simple-select-label">
                                Location
                              </InputLabel>
                              <Select
                                value={userDatacorp.location}
                                onChange={(e) =>
                                  setUserDatacorp({
                                    ...userDatacorp,
                                    location: e.target.value,
                                  })
                                }
                                labelId="demo-simple-select-label"
                                id="location"
                              >
                                {zones.length == 0 ? (
                                  <MenuItem>No Zones</MenuItem>
                                ) : (
                                  zones.map((prop) => {
                                    return (
                                      <MenuItem key={prop.id} value={prop.id}>
                                        {prop.zone_name}
                                      </MenuItem>
                                    );
                                  })
                                )}
                              </Select>
                            </FormControl>
                            <Danger>{corperrors.location}</Danger>
                          </GridItem>
                        </GridContainer>
                        <GridContainer>
                          <GridItem xs={12} sm={12} md={6}>
                            <TextField
                              onChange={handlecorpChange}
                              value={userDatacorp.password}
                              label="Password"
                              type="password"
                              style={{ marginTop: 20 }}
                              id="password"
                              fullWidth
                              margin="normal"
                              InputProps={{
                                startAdornment: (
                                  <InputAdornment position="start">
                                    <LockOpenIcon />
                                  </InputAdornment>
                                ),
                              }}
                              error={corperrors.password}
                              helperText={
                                corperrors.password && corperrors.password
                              }
                            />
                          </GridItem>
                          <GridItem xs={12} sm={12} md={6}>
                            <TextField
                              onChange={handlecorpChange}
                              value={userDatacorp.confirm}
                              type="password"
                              label="Confirm Password"
                              style={{ marginTop: 20 }}
                              id="confirm"
                              fullWidth
                              margin="normal"
                              InputProps={{
                                startAdornment: (
                                  <InputAdornment position="start">
                                    <LockOpenIcon />
                                  </InputAdornment>
                                ),
                              }}
                              error={corperrors.confirm}
                              helperText={
                                corperrors.confirm && corperrors.confirm
                              }
                            />
                          </GridItem>
                        </GridContainer>
                      </form>
                    )}
                  </CardBody>
                </>
              </GridItem>
            </GridContainer>
          </DialogContentText>
        </DialogContent>
        {!addloading && (
          <DialogActions>
            <Button
              style={{ display: "block", marginLeft: "0", marginRight: "auto" }}
              onClick={handlecorpClose}
              color="danger"
            >
              <Close />
              Cancel
            </Button>
            <Button
              style={{ float: "right" }}
              color="danger"
              onClick={handleeditClose}
            >
              Update <Arrow />
            </Button>
          </DialogActions>
        )}
      </Dialog>

      <Dialog
        fullWidth={true}
        maxWidth={!addloading ? "sm" : "xs"}
        open={editpendopen}
        TransitionComponent={Transition}
        keepMounted
        onClose={handlecorpClose}
        aria-labelledby="alert-dialog-slide-title"
        aria-describedby="alert-dialog-slide-description"
      >
        <DialogContent>
          <DialogContentText id="alert-dialog-slide-description">
            <GridContainer>
              <GridItem xs={12} sm={12} md={12}>
                <>
                  <CardHeader
                    style={{ textAlign: "center" }}
                    color={!addloading ? "danger" : ""}
                  >
                    <h4
                      className={
                        !addloading
                          ? classes.cardTitleWhite
                          : classes.cardTitleBlack
                      }
                    >
                      {!addloading
                        ? "Update Pending Request"
                        : "Updating Pending Request"}
                    </h4>
                  </CardHeader>
                  <CardBody>
                    {addloading ? (
                      <DialogContentText style={{ textAlign: "center" }}>
                        <CircularProgress color="secondary" />
                      </DialogContentText>
                    ) : (
                      <form onSubmit={handleeditpendClose}>
                        <GridContainer>
                          <GridItem xs={12} sm={12} md={12}>
                            <TextField
                              onChange={handlecorpChange}
                              id="name"
                              value={userDatacorp.name}
                              label="Company Name"
                              style={{ marginTop: 20 }}
                              fullWidth
                              margin="normal"
                              InputProps={{
                                startAdornment: (
                                  <InputAdornment position="start">
                                    <FontAwesomeIcon icon={faBuilding} />
                                  </InputAdornment>
                                ),
                              }}
                              error={corperrors.name}
                              helperText={corperrors.name && corperrors.name}
                            />
                          </GridItem>
                        </GridContainer>

                        <GridContainer>
                          <GridItem xs={12} sm={12} md={12}>
                            <TextField
                              onChange={handlecorpChange}
                              id="email"
                              value={userDatacorp.email}
                              label="Email"
                              style={{ marginTop: 20 }}
                              fullWidth
                              type="email"
                              margin="normal"
                              InputProps={{
                                startAdornment: (
                                  <InputAdornment position="start">
                                    <MailOutlineIcon />
                                  </InputAdornment>
                                ),
                              }}
                              error={corperrors.email}
                              helperText={corperrors.email && corperrors.email}
                            />
                          </GridItem>
                        </GridContainer>
                        <GridContainer>
                          <GridItem xs={12} sm={12} md={6}>
                            <TextField
                              onChange={handlecorpChange}
                              id="phonenumber"
                              value={userDatacorp.phonenumber}
                              label="Phone Number"
                              style={{ marginTop: 20 }}
                              fullWidth
                              type="number"
                              margin="normal"
                              InputProps={{
                                startAdornment: (
                                  <InputAdornment position="start">
                                    <PhoneIcon />
                                  </InputAdornment>
                                ),
                              }}
                              error={corperrors.phonenumber}
                              helperText={
                                corperrors.phonenumber && corperrors.phonenumber
                              }
                            />
                          </GridItem>
                          <GridItem xs={12} sm={12} md={6}>
                            <TextField
                              onChange={handlecorpChange}
                              id="threshold"
                              value={userDatacorp.threshold}
                              label="Threshold"
                              style={{ marginTop: 20 }}
                              fullWidth
                              type="number"
                              margin="normal"
                              InputProps={{
                                startAdornment: (
                                  <InputAdornment position="start">
                                    GMD
                                  </InputAdornment>
                                ),
                              }}
                              error={corperrors.threshold}
                              helperText={
                                corperrors.threshold && corperrors.threshold
                              }
                            />
                          </GridItem>
                        </GridContainer>
                        <GridContainer>
                          <GridItem xs={12} sm={12} md={12}>
                            <FormControl
                              style={{ minWidth: "100%", marginTop: 20 }}
                              className={classes.formControl}
                            >
                              <InputLabel id="demo-simple-select-label">
                                Location
                              </InputLabel>
                              <Select
                                value={userDatacorp.location}
                                onChange={(e) =>
                                  setUserDatacorp({
                                    ...userDatacorp,
                                    location: e.target.value,
                                  })
                                }
                                labelId="demo-simple-select-label"
                                id="location"
                              >
                                {zones.length == 0 ? (
                                  <MenuItem>No Zones</MenuItem>
                                ) : (
                                  zones.map((prop) => {
                                    return (
                                      <MenuItem key={prop.id} value={prop.id}>
                                        {prop.zone_name}
                                      </MenuItem>
                                    );
                                  })
                                )}
                              </Select>
                            </FormControl>
                            <Danger>{corperrors.location}</Danger>
                          </GridItem>
                        </GridContainer>
                        <GridContainer>
                          <GridItem xs={12} sm={12} md={6}>
                            <TextField
                              onChange={handlecorpChange}
                              value={userDatacorp.password}
                              label="Password"
                              type="password"
                              style={{ marginTop: 20 }}
                              id="password"
                              fullWidth
                              margin="normal"
                              InputLabelProps={{
                                shrink: true,
                              }}
                              placeholder="Enter to change current password"
                              error={corperrors.password}
                              helperText={
                                corperrors.password && corperrors.password
                              }
                            />
                          </GridItem>
                          <GridItem xs={12} sm={12} md={6}>
                            <TextField
                              onChange={handlecorpChange}
                              value={userDatacorp.confirm}
                              type="password"
                              label="Confirm Password"
                              style={{ marginTop: 20 }}
                              id="confirm"
                              fullWidth
                              margin="normal"
                              InputLabelProps={{
                                shrink: true,
                              }}
                              placeholder="Enter to confirm change"
                              error={corperrors.confirm}
                              helperText={
                                corperrors.confirm && corperrors.confirm
                              }
                            />
                          </GridItem>
                        </GridContainer>
                      </form>
                    )}
                  </CardBody>
                </>
              </GridItem>
            </GridContainer>
          </DialogContentText>
        </DialogContent>
        {!addloading && (
          <DialogActions>
            <Button
              style={{ display: "block", marginLeft: "0", marginRight: "auto" }}
              onClick={handlecorpClose}
              color="danger"
            >
              <Close />
              Cancel
            </Button>
            <Button
              style={{ float: "right" }}
              color="danger"
              onClick={handleeditpendClose}
            >
              Update <Arrow />
            </Button>
          </DialogActions>
        )}
      </Dialog>

      <Dialog
        fullWidth={true}
        maxWidth={!addloading ? "sm" : "xs"}
        open={addindopen}
        TransitionComponent={Transition}
        keepMounted
        onClose={addindhandleClose}
        aria-labelledby="alert-dialog-slide-title"
        aria-describedby="alert-dialog-slide-description"
      >
        <DialogContent>
          <DialogContentText id="alert-dialog-slide-description">
            <GridContainer>
              <GridItem xs={12} sm={12} md={12}>
                <>
                  <CardHeader
                    style={{ textAlign: "center" }}
                    color={!addloading ? "danger" : ""}
                  >
                    <h4
                      className={
                        !addloading
                          ? classes.cardTitleWhite
                          : classes.cardTitleBlack
                      }
                    >
                      {!addloading ? "Add Client" : "Adding Client"}
                    </h4>
                  </CardHeader>
                  <CardBody>
                    {addloading ? (
                      <DialogContentText style={{ textAlign: "center" }}>
                        <CircularProgress color="secondary" />
                      </DialogContentText>
                    ) : (
                      <form onSubmit={handleindaddClose}>
                        <GridContainer>
                          <GridItem xs={12} sm={12} md={6}>
                            <TextField
                              onChange={handleindChange}
                              id="firstname"
                              value={userDataind.firstname}
                              label="First Name"
                              style={{ marginTop: 20 }}
                              fullWidth
                              margin="normal"
                              InputProps={{
                                startAdornment: (
                                  <InputAdornment position="start">
                                    <PersonOutlineIcon />
                                  </InputAdornment>
                                ),
                              }}
                              error={inderrors.firstname}
                              helperText={
                                inderrors.firstname && inderrors.firstname
                              }
                            />
                          </GridItem>
                          <GridItem xs={12} sm={12} md={6}>
                            <TextField
                              onChange={handleindChange}
                              id="lastname"
                              value={userDataind.lastname}
                              label="Last Name"
                              style={{ marginTop: 20 }}
                              fullWidth
                              margin="normal"
                              InputProps={{
                                startAdornment: (
                                  <InputAdornment position="start">
                                    <PersonOutlineIcon />
                                  </InputAdornment>
                                ),
                              }}
                              error={inderrors.lastname}
                              helperText={
                                inderrors.lastname && inderrors.lastname
                              }
                            />
                          </GridItem>
                        </GridContainer>

                        <GridContainer>
                          <GridItem xs={12} sm={12} md={12}>
                            <TextField
                              onChange={handleindChange}
                              id="email"
                              value={userDataind.email}
                              label="Email"
                              style={{ marginTop: 20 }}
                              fullWidth
                              type="email"
                              margin="normal"
                              InputProps={{
                                startAdornment: (
                                  <InputAdornment position="start">
                                    <MailOutlineIcon />
                                  </InputAdornment>
                                ),
                              }}
                              error={inderrors.email}
                              helperText={inderrors.email && inderrors.email}
                            />
                          </GridItem>
                        </GridContainer>
                        <GridContainer>
                          <GridItem xs={12} sm={12} md={12}>
                            <TextField
                              onChange={handleindChange}
                              id="phonenumber"
                              value={userDataind.phonenumber}
                              label="Phone Number"
                              style={{ marginTop: 20 }}
                              fullWidth
                              type="number"
                              margin="normal"
                              InputProps={{
                                startAdornment: (
                                  <InputAdornment position="start">
                                    <PhoneIcon />
                                  </InputAdornment>
                                ),
                              }}
                              error={inderrors.phonenumber}
                              helperText={
                                inderrors.phonenumber && inderrors.phonenumber
                              }
                            />
                          </GridItem>
                        </GridContainer>
                        <GridContainer>
                          <GridItem xs={12} sm={12} md={6}>
                            <TextField
                              onChange={handleindChange}
                              value={userDataind.password}
                              label="Password"
                              type="password"
                              style={{ marginTop: 20 }}
                              id="password"
                              fullWidth
                              margin="normal"
                              InputProps={{
                                startAdornment: (
                                  <InputAdornment position="start">
                                    <LockOpenIcon />
                                  </InputAdornment>
                                ),
                              }}
                              error={inderrors.password}
                              helperText={
                                inderrors.password && inderrors.password
                              }
                            />
                          </GridItem>
                          <GridItem xs={12} sm={12} md={6}>
                            <TextField
                              onChange={handleindChange}
                              value={userDataind.confirm}
                              type="password"
                              label="Confirm Password"
                              style={{ marginTop: 20 }}
                              id="confirm"
                              fullWidth
                              margin="normal"
                              InputProps={{
                                startAdornment: (
                                  <InputAdornment position="start">
                                    <LockOpenIcon />
                                  </InputAdornment>
                                ),
                              }}
                              error={inderrors.confirm}
                              helperText={
                                inderrors.confirm && inderrors.confirm
                              }
                            />
                          </GridItem>
                        </GridContainer>
                      </form>
                    )}
                  </CardBody>
                </>
              </GridItem>
            </GridContainer>
          </DialogContentText>
        </DialogContent>
        {!addloading && (
          <DialogActions>
            <Button
              style={{ display: "block", marginLeft: "0", marginRight: "auto" }}
              onClick={addindhandleClose}
              color="danger"
            >
              <Close />
              Cancel
            </Button>
            <Button
              style={{ float: "right" }}
              color="danger"
              onClick={handleindaddClose}
            >
              Create <Arrow />
            </Button>
          </DialogActions>
        )}
      </Dialog>
      <Dialog
        fullWidth={true}
        maxWidth={!addloading ? "sm" : "xs"}
        open={editindopen}
        TransitionComponent={Transition}
        keepMounted
        onClose={editindhandleClose}
        aria-labelledby="alert-dialog-slide-title"
        aria-describedby="alert-dialog-slide-description"
      >
        <DialogContent>
          <DialogContentText id="alert-dialog-slide-description">
            <GridContainer>
              <GridItem xs={12} sm={12} md={12}>
                <>
                  <CardHeader
                    style={{ textAlign: "center" }}
                    color={!addloading ? "danger" : ""}
                  >
                    <h4
                      className={
                        !addloading
                          ? classes.cardTitleWhite
                          : classes.cardTitleBlack
                      }
                    >
                      {!addloading ? "Update Client" : "Updating Client"}
                    </h4>
                  </CardHeader>
                  <CardBody>
                    {addloading ? (
                      <DialogContentText style={{ textAlign: "center" }}>
                        <CircularProgress color="secondary" />
                      </DialogContentText>
                    ) : (
                      <form onSubmit={handleindeditClose}>
                        <GridContainer>
                          <GridItem xs={12} sm={12} md={6}>
                            <TextField
                              onChange={handleindChange}
                              id="firstname"
                              value={userDataind.firstname}
                              label="First Name"
                              style={{ marginTop: 20 }}
                              fullWidth
                              margin="normal"
                              InputProps={{
                                startAdornment: (
                                  <InputAdornment position="start">
                                    <PersonOutlineIcon />
                                  </InputAdornment>
                                ),
                              }}
                              error={inderrors.firstname}
                              helperText={
                                inderrors.firstname && inderrors.firstname
                              }
                            />
                          </GridItem>
                          <GridItem xs={12} sm={12} md={6}>
                            <TextField
                              onChange={handleindChange}
                              id="lastname"
                              value={userDataind.lastname}
                              label="Last Name"
                              style={{ marginTop: 20 }}
                              fullWidth
                              margin="normal"
                              InputProps={{
                                startAdornment: (
                                  <InputAdornment position="start">
                                    <PersonOutlineIcon />
                                  </InputAdornment>
                                ),
                              }}
                              error={inderrors.lastname}
                              helperText={
                                inderrors.lastname && inderrors.lastname
                              }
                            />
                          </GridItem>
                        </GridContainer>

                        <GridContainer>
                          <GridItem xs={12} sm={12} md={12}>
                            <TextField
                              onChange={handleindChange}
                              id="email"
                              value={userDataind.email}
                              label="Email"
                              style={{ marginTop: 20 }}
                              fullWidth
                              type="email"
                              margin="normal"
                              InputProps={{
                                startAdornment: (
                                  <InputAdornment position="start">
                                    <MailOutlineIcon />
                                  </InputAdornment>
                                ),
                              }}
                              error={inderrors.email}
                              helperText={inderrors.email && inderrors.email}
                            />
                          </GridItem>
                        </GridContainer>
                        <GridContainer>
                          <GridItem xs={12} sm={12} md={12}>
                            <TextField
                              onChange={handleindChange}
                              id="phonenumber"
                              value={userDataind.phonenumber}
                              label="Phone Number"
                              style={{ marginTop: 20 }}
                              fullWidth
                              type="number"
                              margin="normal"
                              InputProps={{
                                startAdornment: (
                                  <InputAdornment position="start">
                                    <PhoneIcon />
                                  </InputAdornment>
                                ),
                              }}
                              error={inderrors.phonenumber}
                              helperText={
                                inderrors.phonenumber && inderrors.phonenumber
                              }
                            />
                          </GridItem>
                        </GridContainer>
                        <GridContainer>
                          <GridItem xs={12} sm={12} md={6}>
                            <TextField
                              onChange={handleindChange}
                              value={userDataind.password}
                              label="Password"
                              type="password"
                              style={{ marginTop: 20 }}
                              id="password"
                              fullWidth
                              margin="normal"
                              InputLabelProps={{
                                shrink: true,
                              }}
                              placeholder="Enter to change current password"
                              error={inderrors.password}
                              helperText={
                                inderrors.password && inderrors.password
                              }
                            />
                          </GridItem>
                          <GridItem xs={12} sm={12} md={6}>
                            <TextField
                              onChange={handleindChange}
                              value={userDataind.confirm}
                              type="password"
                              label="Confirm Password"
                              style={{ marginTop: 20 }}
                              id="confirm"
                              fullWidth
                              margin="normal"
                              InputLabelProps={{
                                shrink: true,
                              }}
                              placeholder="Enter to confirm change"
                              error={inderrors.confirm}
                              helperText={
                                inderrors.confirm && inderrors.confirm
                              }
                            />
                          </GridItem>
                        </GridContainer>
                      </form>
                    )}
                  </CardBody>
                </>
              </GridItem>
            </GridContainer>
          </DialogContentText>
        </DialogContent>
        {!addloading && (
          <DialogActions>
            <Button
              style={{ display: "block", marginLeft: "0", marginRight: "auto" }}
              onClick={editindhandleClose}
              color="danger"
            >
              <Close />
              Cancel
            </Button>
            <Button
              style={{ float: "right" }}
              color="danger"
              onClick={handleindeditClose}
            >
              Update <Arrow />
            </Button>
          </DialogActions>
        )}
      </Dialog>
      <Dialog
        fullScreen
        open={indcompdelopen}
        onClose={handleindcompdelClose}
        TransitionComponent={Transition2}
      >
        <AppBar className={classes.appBar}>
          <Toolbar>
            <IconButton
              edge="start"
              color="inherit"
              onClick={handleindcompdelClose}
              aria-label="close"
            >
              <CloseIcon />
            </IconButton>
            <Typography variant="h6" className={classes.title}>
              Deliveries Requests Made By {compinddelprop.name}
            </Typography>
            <Button color="#ec4c48">
              {compinddelprop.deliveries} Request
              {compinddelprop.deliveries != 1 && "s"}
            </Button>
          </Toolbar>
        </AppBar>
        <DialogContent style={{ background: "#EEEEEE" }}>
          <CardHeader style={{ marginTop: 10, marginBottom: 10 }} color="danger">
            <h4 style={{ textAlign: "center" }}>
              Monthly Delivery for {1900 + new Date().getYear()}{" "}
            </h4>
            <ChartistGraph
              className="ct-chart"
              data={{
                labels: [
                  "Jan",
                  "Feb",
                  "Mar",
                  "Apr",
                  "May",
                  "Jun",
                  "Jul",
                  "Aug",
                  "Sep",
                  "Oct",
                  "Nov",
                  "Dec",
                ],
                series: [compinddelprop.chart],
              }}
              type="Bar"
              options={{
                ...monthlyDeliveriesChart.options,
                high: Math.max(...compinddelprop.chart) + 5,
              }}
              responsiveOptions={monthlyDeliveriesChart.responsiveOptions}
              listener={monthlyDeliveriesChart.animation}
            />
          </CardHeader>
          <Table
            searchKeyword={searchHandler3}
            term={searchTerm3}
            setTerm={setSearchTerm3}
            tableHeaderColor="gray"
            tableHead={[
              "Order ID",
              "Reciepient",
              "Service",
              "Boundary",
              "From",
              "To",
              "Current Status",
              "Price",
              "Packages",
            ]}
            tableData={
              compinddel.length == 0 ? (
                <TableRow>
                  <TableCell
                    style={{ textAlign: "center" }}
                    colSpan={9}
                    className={classes.tableCell}
                  >
                    No Deliveries
                  </TableCell>
                </TableRow>
              ) : (
                compinddel.map((prop) => {
                  return (
                    <TableRow key={prop.id} className={classes.tableBodyRow}>
                      <TableCell className={classes.tableCell} key={prop.id}>
                      <Link to={{pathname: "/admin/searchdeliveries", property: { orderId: prop.order_id }}} >
                        {prop.order_id}
                        </Link>
                      </TableCell>
                      <TableCell className={classes.tableCell} key={prop.id}>
                      {prop.reciepient ? prop.reciepient.first_name : prop.creator ? prop.creator.first_name : prop.sender_name}{" "}
                             {prop.reciepient ? prop.reciepient.last_name : prop.creator ? prop.creator.last_name ? prop.creator.last_name : ""  : ""}
                      
                      </TableCell>
                      <TableCell className={classes.tableCell} key={prop.id}>
                        {prop.service.service_name}
                      </TableCell>
                      <TableCell className={classes.tableCell} key={prop.id}>
                        {prop.boundary.boundary_name}
                      </TableCell>
                      <TableCell className={classes.tableCell} key={prop.id}>
                        {prop.from_location.location_name}{" "}
                        {prop.fromAddress && "(" + prop.fromAddress + ")"}
                      </TableCell>
                      <TableCell className={classes.tableCell} key={prop.id}>
                        {prop.to_location.location_name}{" "}
                        {prop.toAddress && "(" + prop.toAddress + ")"}
                      </TableCell>
                      <TableCell className={classes.tableCell} key={prop.id}>
                        {prop.status == "unassigned" && "Unassigned"}
                        {prop.status == "atfacility" &&
                          "At " +
                            prop.unassigned_facility.facility.facility_name}
                        {prop.status == "pendingpickup" &&
                          "Pending Pickup from Client"}
                        {prop.status == "pendingpickupatfacility" &&
                          "Pending Pickup from " +
                            (prop.pendingfacilityfacility
                              ? prop.pendingfacilityfacility.from_facility
                                  .facility_name
                              : prop.pendingfacilityrecipient.facility
                                  .facility_name)}
                        {prop.status == "outfordelivery" && "Out For Delivery"}
                        {prop.status == "delivered" && "Delivered"}
                        {prop.status == "tofirstfacility" &&
                          "On-Route to " +
                            prop.onrouteclientfacility.facility.facility_name}
                        {prop.status == "tofacility" &&
                          "On-Route to " +
                            prop.onroutefacilityfacility.to_facility
                              .facility_name}
                      </TableCell>
                      <TableCell className={classes.tableCell} key={prop.id}>
                        GMD {prop.price}
                      </TableCell>
                      <TableCell className={classes.tableCell} key={prop.id}>
                        {prop.service.service_name == "Food Service" ? (
                          <VisibilityOffIcon
                            style={{ cursor: "not-allowed" }}
                          />
                        ) : (
                          <View
                            onClick={(e) => toggleDraweropen(e, prop.id)}
                            style={{ cursor: "pointer" }}
                          />
                        )}
                      </TableCell>
                    </TableRow>
                  );
                })
              )
            }
          />
          <TablePagination
      component="div"
      count={totalcount3}
      page={page3}
      onPageChange={handleChangePage3}
      rowsPerPage={rowsPerPage3}
      onRowsPerPageChange={handleChangeRowsPerPage3}
    />
        </DialogContent>
      </Dialog>
      <Dialog
        fullScreen
        open={corpcompdelopen}
        onClose={handlecorpcompdelClose}
        TransitionComponent={Transition2}
      >
        <AppBar className={classes.appBar}>
          <Toolbar>
            <IconButton
              edge="start"
              color="inherit"
              onClick={handlecorpcompdelClose}
              aria-label="close"
            >
              <CloseIcon />
            </IconButton>
            <Typography variant="h6" className={classes.title}>
              Deliveries Requests Made By {compcorpdelprop.name}
            </Typography>
            <Button color="#ec4c48">
              {compcorpdelprop.deliveries} Request
              {compcorpdelprop.deliveries != 1 && "s"}
            </Button>
          </Toolbar>
        </AppBar>
        <DialogContent style={{ background: "#EEEEEE" }}>
          <CardHeader style={{ marginTop: 10, marginBottom: 10 }} color="danger">
            <h4 style={{ textAlign: "center" }}>
              Monthly Deliveries for {1900 + new Date().getYear()}{" "}
            </h4>
            <ChartistGraph
              className="ct-chart"
              data={{
                labels: [
                  "Jan",
                  "Feb",
                  "Mar",
                  "Apr",
                  "May",
                  "Jun",
                  "Jul",
                  "Aug",
                  "Sep",
                  "Oct",
                  "Nov",
                  "Dec",
                ],
                series: [compcorpdelprop.chart],
              }}
              type="Bar"
              options={{
                ...monthlyDeliveriesChart.options,
                high: Math.max(...compcorpdelprop.chart) + 5,
              }}
              responsiveOptions={monthlyDeliveriesChart.responsiveOptions}
              listener={monthlyDeliveriesChart.animation}
            />
          </CardHeader>
          <Table
            searchKeyword={searchHandler4}
            term={searchTerm4}
            setTerm={setSearchTerm4}
            tableHeaderColor="gray"
            tableHead={[
              "Order ID",
              "Reciepient",
              "Service",
              "Boundary",
              "From",
              "To",
              "Current Status",
              "Price",
              "Packages",
            ]}
            tableData={
              compcorpdel.length == 0 ? (
                <TableRow>
                  <TableCell
                    style={{ textAlign: "center" }}
                    colSpan={9}
                    className={classes.tableCell}
                  >
                    No Deliveries
                  </TableCell>
                </TableRow>
              ) : (
                compcorpdel.map((prop) => {
                  return (
                    <TableRow key={prop.id} className={classes.tableBodyRow}>
                      <TableCell className={classes.tableCell} key={prop.id}>
                      <Link to={{pathname: "/admin/searchdeliveries", property: { orderId: prop.order_id }}} >
                        {prop.order_id}
                        </Link>
                      </TableCell>
                      <TableCell className={classes.tableCell} key={prop.id}>
                      {prop.reciepient ? prop.reciepient.first_name : prop.creator ? prop.creator.first_name : prop.sender_name}{" "}
                             {prop.reciepient ? prop.reciepient.last_name : prop.creator ? prop.creator.last_name ? prop.creator.last_name : ""  : ""}
                      
                      </TableCell>
                      <TableCell className={classes.tableCell} key={prop.id}>
                        {prop.service.service_name}
                      </TableCell>
                      <TableCell className={classes.tableCell} key={prop.id}>
                        {prop.boundary.boundary_name}
                      </TableCell>
                      <TableCell className={classes.tableCell} key={prop.id}>
                        {prop.from_location.location_name}{" "}
                        {prop.fromAddress && "(" + prop.fromAddress + ")"}
                      </TableCell>
                      <TableCell className={classes.tableCell} key={prop.id}>
                        {prop.to_location.location_name}{" "}
                        {prop.toAddress && "(" + prop.toAddress + ")"}
                      </TableCell>
                      <TableCell className={classes.tableCell} key={prop.id}>
                        {prop.status == "unassigned" && "Unassigned to Driver"}
                        {prop.status == "atfacility" &&
                          "At " +
                            prop.unassigned_facility.facility.facility_name}
                        {prop.status == "pendingpickup" &&
                          "Pending Pickup from Client"}
                        {prop.status == "pendingpickupatfacility" &&
                          "Pending Pickup from" +
                            (prop.pendingfacilityfacility
                              ? prop.pendingfacilityfacility.from_facility
                                  .facility_name
                              : prop.pendingfacilityrecipient.facility
                                  .facility_name)}
                        {prop.status == "outfordelivery" && "Out For Delivery"}
                        {prop.status == "delivered" && "Delivered"}
                        {prop.status == "tofirstfacility" &&
                          "On-Route to " +
                            prop.onrouteclientfacility.facility.facility_name}
                        {prop.status == "tofacility" &&
                          "On-Route to " +
                            prop.onroutefacilityfacility.to_facility
                              .facility_name}
                      </TableCell>
                      <TableCell className={classes.tableCell} key={prop.id}>
                        GMD {prop.price}
                      </TableCell>
                      <TableCell className={classes.tableCell} key={prop.id}>
                        {prop.service.service_name == "Food Service" ? (
                          <VisibilityOffIcon style={{ cursor: "pointer" }} />
                        ) : (
                          <View
                            onClick={(e) => toggleDraweropen(e, prop.id)}
                            style={{ cursor: "pointer" }}
                          />
                        )}
                      </TableCell>
                    </TableRow>
                  );
                })
              )
            }
          />
          <TablePagination
      component="div"
      count={totalcount4}
      page={page4}
      onPageChange={handleChangePage4}
      rowsPerPage={rowsPerPage4}
      onRowsPerPageChange={handleChangeRowsPerPage4}
    />
        </DialogContent>
      </Dialog>

      <Drawer anchor={"right"} open={opendrawer} onClose={toggleDrawerclose}>
        <div className={clsx(classes.list)} role="presentation">
          <List style={{ background: "#EEEEEE" }}>
            <ListItem>
              <ListItemIcon>
                <Close
                  style={{ cursor: "pointer" }}
                  onClick={toggleDrawerclose}
                />
              </ListItemIcon>
              <ListItemIcon>
                <FontAwesomeIcon icon={faBoxOpen} />
              </ListItemIcon>
              <ListItemText
                primary={
                  packages.packages ? packages.packages.length + " Packages" : packages.items ? packages.items.length + " Items" : ""
                }
              />
            </ListItem>
          </List>
          <Divider />
          <Divider style={{ marginBottom: "10px" }} />
          {packages.packages &&
            packages.packages.map((prop) => {
              return (
                <>
                  <ListItem>
                    <GridContainer>
                      <GridItem xs={4} sm={4} md={4}>
                        <Avatar
                          className={classes.avatarimg}
                          variant="square"
                          key={prop.id}
                          alt="Package"
                          src={props.imgurl + prop.packages.img}
                        />
                      </GridItem>
                      <GridItem xs={4} sm={4} md={4}>
                        <Typography
                          align="center"
                          variant="subtitle2"
                          gutterBottom
                        >
                          Package Type
                        </Typography>
                        <Typography
                          align="center"
                          variant="caption"
                          display="block"
                          gutterBottom
                        >
                          {prop.packages.package_name}{" "}
                        </Typography>
                      </GridItem>
                      <GridItem xs={4} sm={4} md={4}>
                        <Typography
                          align="center"
                          variant="subtitle2"
                          gutterBottom
                        >
                          Package ID
                        </Typography>
                        <Typography
                          align="center"
                          variant="caption"
                          display="block"
                          gutterBottom
                        >
                          {prop.package_reference_id
                            ? prop.package_reference_id
                            : "N/A"}
                        </Typography>
                      </GridItem>
                    </GridContainer>
                  </ListItem>
                  <Divider style={{ marginBottom: "10px" }} />
                </>
              );
            })}
            {packages.items &&
            packages.items.map((prop) => {
              return (
                <>
                  <ListItem>
                    <GridContainer style={{ width: "100%" }}>
                      <GridItem xs={4} sm={4} md={4}>
                      <img
                          className={classes.vehicleimg}
                          key={prop.id}
                          alt="Item"
                          src={props.imgurl + prop.items.img}
                        />
                      </GridItem>
                      <GridItem xs={4} sm={4} md={4}>
                        <Typography variant="subtitle2" gutterBottom>
                          Package Type
                        </Typography>
                        <Typography
                          variant="caption"
                          display="block"
                          gutterBottom
                        >
                          {prop.items.item_name}{" "}
                        </Typography>
                      </GridItem>
                      <GridItem xs={4} sm={4} md={4}>
                        <Typography variant="subtitle2" gutterBottom>
                          Package ID
                        </Typography>
                        <Typography
                          variant="caption"
                          display="block"
                          gutterBottom
                        >
                          {prop.package_reference_id
                            ? prop.package_reference_id
                            : "N/A"}
                        </Typography>
                      </GridItem>
                    </GridContainer>
                  </ListItem>

                  <Divider style={{ marginBottom: "10px" }} />
                </>
              );
            })}
        </div>
      </Drawer>
      <Dialog
        fullWidth={true}
        maxWidth="sm"
        open={openconfirm}
        TransitionComponent={Transition}
        keepMounted
        classes={{ scrollPaper: classes.scrollPaper }}
        onClose={handleconfirmClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          {"DELETE "}
          {modtype == "individual" && "INDIVIDUAL"}
          {modtype == "company" && "COMPANY"}
          {modtype == "pendcomp" && "PENDING REQUEST"}
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            Are you sure you want to delete this{" "}
            {modtype == "individual" && "individual"}
            {modtype == "company" && "company"}
            {modtype == "pendcomp" && "pending company request"}?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button
            style={{ display: "block", marginLeft: "0", marginRight: "auto" }}
            onClick={handleconfirmClose}
            color="danger"
          >
            <Close />
            Cancel
          </Button>
          <Button
            style={{ float: "right" }}
            color="danger"
            onClick={processDeletion}
          >
            Proceed <Arrow />
          </Button>
        </DialogActions>
      </Dialog>
    </GridContainer>
  );
}

Clients.propTypes = {
  url: PropTypes.string,
  imgurl: PropTypes.string,
  exit: PropTypes.string,
};
