import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import CircularProgress from "@material-ui/core/CircularProgress";
import Dialog from "@material-ui/core/Dialog";
import DoneAllIcon from "@material-ui/icons/DoneAll";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import GridContainer from "components/Grid/GridContainer.js";
import TextField from "@material-ui/core/TextField";
import Details from "@material-ui/icons/Visibility";
import DialogTitle from "@material-ui/core/DialogTitle";
import TablePagination from '@material-ui/core/TablePagination';
import TableRow from "@material-ui/core/TableRow";
import Chip from "@material-ui/core/Chip";
import TableCell from "@material-ui/core/TableCell";
import Backdrop from "@material-ui/core/Backdrop";
import { toast } from "react-toastify";
import Moment from "react-moment";
import Add from "@material-ui/icons/Add";
import "react-toastify/dist/ReactToastify.css";
import { makeStyles } from "@material-ui/core/styles";
import Card from "components/Card/Card.js";
import Table from "components/Table/Table.js";
import CardHeader from "components/Card/CardHeader.js";
import CardBody from "components/Card/CardBody.js";
import Button from "components/CustomButtons/Button.js";
import Grid from "@material-ui/core/Grid";
import GridItem from "components/Grid/GridItem.js";
import ArrowBackIcon from "@material-ui/icons/ArrowBack";
import { Link } from "react-router-dom";

const styles = {
  backdrop: {
    zIndex: 5,
    color: "#fff",
  },
  tableCell: {
    textAlign: "center",
  },
  typo: {
    paddingLeft: "25%",
    marginBottom: "40px",
    position: "relative",
  },
  note: {
    fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
    bottom: "10px",
    color: "#c0c1c2",
    display: "block",
    fontWeight: "400",
    fontSize: "13px",
    lineHeight: "13px",
    left: "0",
    marginLeft: "20px",
    position: "absolute",
    width: "260px",
  },
  cardCategoryWhite: {
    color: "rgba(255,255,255,.62)",
    margin: "0",
    fontSize: "14px",
    marginTop: "0",
    marginBottom: "0",
  },
  cardTitleWhite: {
    color: "#FFFFFF",
    marginTop: "0px",
    minHeight: "auto",
    fontWeight: "300",
    fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif",
    marginBottom: "3px",
    textDecoration: "none",
  },
};

const useStyles = makeStyles(styles);

export default function Invoices({ match, url, exit }) {
  toast.configure();
  const classes = useStyles();
  const [invoices, setInvoices] = useState([]);
  const {
    params: { compid },
  } = match;
  const [addloading, setAddloading] = useState(false);
  const [searchTerm, setSearchTerm] = useState("");
  const [company, setCompany] = useState("");
  const [amount, setAmount] = useState(0);
  const [selectedDate, handleDateChange] = useState(null);
  const [addid, setAddid] = useState(0);
  const [amounterror, setAmounterror] = useState("");
  const [searchstatusTerm, setSearchstatusTerm] = useState("");
  const [loading, setLoading] = useState(true);
  const [openSelect, setopenSelect] = React.useState(false);
  const [page, setPage] = React.useState(0);
  const [totalcount, setTotalcount] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);

  const handleChangePage = (event, newPage) => {
    setLoading(true)
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
    setLoading(true)
  };

  const getInvoices = async (cat = "",stat="") => {
    let response = await fetch(url + `/get-invoices/${compid}?${searchTerm && 'search='+searchTerm+'&'}${cat && 'category='+cat+'&'}${stat && 'date=true&month='+(stat.getMonth()+1)+'&year='+stat.getUTCFullYear()+'&'}page=${page+1}&perpage=${rowsPerPage}`, {
      credentials: "include",
    });
    let res = await response.json();
    if (res.success) {
      setLoading(false);
      setInvoices(res.data[0].data);
      setPage(res.data[0].current_page - 1)
      setTotalcount(res.data[0].total)
      setCompany(res.data[1]);
    }
    else {
      if( res.message == 'unauthenticated'){
        window.location.replace(exit);
        return;
      }
    }
  };

  useEffect(() => {
    getInvoices();
  }, []);

  const handleSelectClose = () => {
    setopenSelect(false);
    setAddid(0);
    setAmount(0);
    setAmounterror("");
    setAddloading(false);
  };
  const validate = () => {
    if (!amount) {
      setAmounterror("This field is required");
      return false;
    } else if (amount < 1) {
      setAmounterror("Amount should be positive");
      return false;
    } else {
      setAmounterror("");
      return true;
    }
  };

  const handleSubmitClose = async (event) => {
    event.preventDefault();
    if (validate()) {
      setAddloading(true);
      let response = await fetch(url + "/add-payment", {
        method: "POST",
        headers: {
          "Content-type": "application/json",
        },
        body: JSON.stringify({ amount: amount, invid: addid }),
        credentials: "include",
      });
      let res = await response.json();
      if (res) {
        if (res.success) {
          setInvoices(
            invoices.map((prop) => (prop.id === addid ? { ...res.data } : prop))
          );
          handleSelectClose();
          toast.success("Payment added");
        } else {
          setAddloading(false);
          if( res.message == 'unauthenticated'){
            window.location.replace(exit);
            return;
          }
          toast.error(res.message);
        }
      }
    }
  };

  const handleSelectOpen = (id) => {
    setAddid(id);
    setopenSelect(true);
  };

  const searchHandler = () => {
    getInvoices(searchstatusTerm,selectedDate)
  };
  const searchstatusHandler = (termed) => {
    setSearchstatusTerm(termed)
    getInvoices(termed,selectedDate)
  };

  const searchdateHandler = (e) => {
    handleDateChange(e)
    getInvoices(searchstatusTerm,e)
  };
  return (
    <Card>
      {loading && (
        <Backdrop className={classes.backdrop} open>
          <CircularProgress color="inherit" />
        </Backdrop>
      )}
      <CardHeader color="danger">
        <h4 className={classes.cardTitleWhite}>
          Monthly Invoices for {company}
        </h4>
      </CardHeader>
      <CardBody>
        <Grid container>
          <GridItem xs={12} sm={12} md={4}>
            <Link to="/admin/clients">
              <Button style={{ float: "left" }} color="danger">
                <ArrowBackIcon />
                Back
              </Button>{" "}
            </Link>
          </GridItem>
        </Grid>
        <Table
          searchKeyword={searchHandler}
          term={searchTerm}
          setTerm={setSearchTerm}
          selectedDate={selectedDate}
          searchdateKeyword={searchdateHandler}
          searchstatusKeyword={searchstatusHandler}
          status={searchstatusTerm}
          tableHeaderColor="gray"
          tableHead={[
            "ID",
            "Period",
            "Total Amount",
            "Amount Paid",
            "Balance",
            "Status",
            "Details",
            "Make Payment",
          ]}
          tableData={
            invoices.length == 0 ? (
              <TableRow>
                <TableCell
                  style={{ textAlign: "center" }}
                  colSpan={8}
                  className={classes.tableCell}
                >
                  No Invoices
                </TableCell>
              </TableRow>
            ) : (
              invoices.map((prop) => {
                return (
                  <TableRow key={prop.id} className={classes.tableBodyRow}>
                    <TableCell className={classes.tableCell} key={prop.id}>
                      {prop.id}
                    </TableCell>
                    <TableCell className={classes.tableCell} key={prop.id}>
                      <Moment format="MMMM YYYY">{prop.created_at}</Moment>
                    </TableCell>
                    <TableCell className={classes.tableCell} key={prop.id}>
                      GMD {prop.total_amount}
                    </TableCell>
                    <TableCell className={classes.tableCell} key={prop.id}>
                      GMD {prop.amount_paid}
                    </TableCell>
                    <TableCell className={classes.tableCell} key={prop.id}>
                      GMD {prop.total_amount - prop.amount_paid}
                    </TableCell>
                    <TableCell className={classes.tableCell} key={prop.id}>
                      <Chip
                        label={
                          prop.amount_paid == prop.total_amount
                            ? "Paid"
                            : "Incomplete"
                        }
                        color={
                          prop.amount_paid == prop.total_amount
                            ? "default"
                            : "secondary"
                        }
                      />
                    </TableCell>
                    <TableCell className={classes.tableCell} key={prop.id}>
                      <Link
                        style={{ color: "black" }}
                        to={`/admin/invoice-details/${prop.id}/${compid}`}
                      >
                        <Details
                          style={{ color: "black", cursor: "pointer" }}
                        />
                      </Link>
                    </TableCell>
                    <TableCell className={classes.tableCell} key={prop.id}>
                      {prop.amount_paid == prop.total_amount ? (
                        <DoneAllIcon />
                      ) : (
                        <Button
                          onClick={() => handleSelectOpen(prop.id)}
                          style={{ padding: "10px" }}
                          color="success"
                          size="small"
                        >
                          <Add /> Pay{" "}
                        </Button>
                      )}
                    </TableCell>
                  </TableRow>
                );
              })
            )
          }
          invoice={true}
          search={false}
        />
      </CardBody>
      <TablePagination
      component="div"
      count={totalcount}
      page={page}
      onPageChange={handleChangePage}
      rowsPerPage={rowsPerPage}
      onRowsPerPageChange={handleChangeRowsPerPage}
    />
      <Dialog
        disableEscapeKeyDown
        open={openSelect}
        onClose={handleSelectClose}
      >
        <DialogTitle>
          {!addloading ? "Enter Payment" : "Making Payment"}
        </DialogTitle>
        {addloading ? (
          <DialogContentText style={{ textAlign: "center" }}>
            <CircularProgress color="secondary" />
          </DialogContentText>
        ) : (
          <DialogContent>
            <form onSubmit={handleSubmitClose} className={classes.container}>
              <GridContainer>
                <GridItem xs={12} sm={12} md={12}>
                  <TextField
                    onChange={(e) => setAmount(e.target.value)}
                    value={amount}
                    label="Amount"
                    fullWidth
                    type="number"
                    margin="normal"
                    error={amounterror}
                    helperText={amounterror && amounterror}
                  />
                </GridItem>
              </GridContainer>
            </form>
          </DialogContent>
        )}
        {!addloading && (
          <DialogActions>
            <Button onClick={handleSubmitClose} color="success">
              Ok
            </Button>
          </DialogActions>
        )}
      </Dialog>
    </Card>
  );
}
Invoices.propTypes = {
  url: PropTypes.string,
  exit: PropTypes.string,
  match: PropTypes.shape({
    params: PropTypes.shape({
      compid: PropTypes.number.isRequired,
    }),
  }),
};
