/* eslint-disable*/
import React from "react";
import { Switch, Route, Redirect } from "react-router-dom";
// creates a beautiful scrollbar
import PerfectScrollbar from "perfect-scrollbar";
import "perfect-scrollbar/css/perfect-scrollbar.css";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
// core components
import Navbar from "components/Navbars/Navbar.js";
import Footer from "components/Footer/Footer.js";
import Sidebar from "components/Sidebar/Sidebar.js";

import routes from "routes.js";

import styles from "assets/jss/material-dashboard-react/layouts/adminStyle.js";

import bgImage from "assets/img/sidebar-2.jpg";
import logo from "assets/img/fatt-logo.jpeg";


const useStyles = makeStyles(styles);

export default function Admin({ history, ...rest }) {
  const classes = useStyles();
  const mainPanel = React.createRef();
  const [image] = React.useState(bgImage);
  const [color] = React.useState("red");
  const [username, setusername] = React.useState("");
  const [mobileOpen, setMobileOpen] = React.useState(false);
  const [value, setValue] = React.useState(location.pathname);
 
let ps;
let prod_server = false;

  let imgurl =
  process.env.NODE_ENV === "development"
    ? process.env.REACT_APP_DEVELOPMENT_IMAGE_URL
    : 
    prod_server ?
    process.env.REACT_APP_PRODUCTION_IMAGE_URL_CLOUD :
    process.env.REACT_APP_PRODUCTION_IMAGE_URL_CAYOR;

let url =
  process.env.NODE_ENV === "development"
    ? process.env.REACT_APP_DEVELOPMENT_URL
    : 
    prod_server ?
    process.env.REACT_APP_PRODUCTION_URL_CLOUD :
    process.env.REACT_APP_PRODUCTION_URL_CAYOR;

  let exit =
  process.env.NODE_ENV === "development"
    ? process.env.REACT_APP_DEVELOPMENT_URL_EXIT
    : 
    prod_server ?
    process.env.REACT_APP_PRODUCTION_URL_CLOUD_EXIT :
    process.env.REACT_APP_PRODUCTION_URL_CAYOR_EXIT;

  const switchRoutes = (
    <Switch>
      {routes.map((prop, key) => {
        if (prop.layout === "/admin") {
          return (
            <Route
              path={prop.layout + prop.path}
              render={(props) => (
                <prop.component {...props} url={url} exit={exit} imgurl={imgurl} updateusername={setusername} />
              )}
              key={key}
            />
          );
        }
        return null;
      })}
      <Redirect from="/admin" to="/admin/dashboard" />
    </Switch>
  );

  const isAuthenticated = async () => {
    let response = await fetch(url + "/user", { credentials: "include" });
    let data = await response.json();
    if (data) {
      if (data.success) {
        if (data.roles[0] != "admin") {
          window.location.replace(exit);
        } else {
          setusername(data.user.first_name + " " + data.user.last_name);
        }
      } else {
        window.location.replace(exit);
      }
      return;
    }
  };

  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };
  const getRoute = () => {
    return window.location.pathname !== "/admin/maps";
  };
  const resizeFunction = () => {
    if (window.innerWidth >= 960) {
      setMobileOpen(false);
    }
  };
  // initialize and destroy the PerfectScrollbar plugin
  React.useEffect(() => {
    isAuthenticated();
    if (navigator.platform.indexOf("Win") > -1) {
      ps = new PerfectScrollbar(mainPanel.current, {
        suppressScrollX: true,
        suppressScrollY: false,
      });
      document.body.style.overflow = "hidden";
    }
    window.addEventListener("resize", resizeFunction);
    // Specify how to clean up after this effect:
    return function cleanup() {
      if (navigator.platform.indexOf("Win") > -1) {
        ps.destroy();
      }
      window.removeEventListener("resize", resizeFunction);
    };
  }, [mainPanel]);
  return (
    <div className={classes.wrapper}>
      <Sidebar
        routes={routes}
        logoText={"Fatt Fatt"}
        logo={logo}
        image={image}
        handleDrawerToggle={handleDrawerToggle}
        open={mobileOpen}
        color={color}
        {...rest}
      />
      <div className={classes.mainPanel} ref={mainPanel}>
        <Navbar
          user={username}
          routes={routes}
          handleDrawerToggle={handleDrawerToggle}
          {...rest}
        />
        {/* On the /maps route we want the map to be on full screen - this is not possible if the content and conatiner classes are present because they have some paddings which would make the map smaller */}
        {getRoute() ? (
          <div className={classes.content}>
            <div className={classes.container}>{switchRoutes}</div>
          </div>
        ) : (
          <div className={classes.map}>{switchRoutes}</div>
        )}
        {getRoute() ? <Footer /> : null}
      </div>
    </div>
  );
}
