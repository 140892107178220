import Dashboard from "@material-ui/icons/Dashboard";
import SettingsIcon from "@material-ui/icons/Settings";
import Person from "@material-ui/icons/Person";
import ListIcon from "@material-ui/icons/List";
import ChromeReaderModeIcon from '@material-ui/icons/ChromeReaderMode';
import LocationOnIcon from "@material-ui/icons/LocationOn";
import ExitToAppIcon from "@material-ui/icons/ExitToApp";
import Star from "@material-ui/icons/Star";
import LocationOn from "@material-ui/icons/LocationOn";
import DashboardPage from "views/Dashboard.js";
import Search from "@material-ui/icons/Search";
import Local_Deliveries from "views/Local_Deliveries.js";
import Intl_Deliveries from "views/Intl_Deliveries.js";
import Search_Deliveries from "views/Search_Deliveries.js";
import FlightIcon from "@material-ui/icons/Flight";
import Configurations from "views/Configurations.js";
import Clients from "views/Clients.js";
import Ratings from "views/Ratings.js";
import Invoices from "views/Invoices.js";
import Logs from "views/Logs.js";
import Drivers from "views/Drivers.js";
import ItemPricing from "views/ItemPricing.js";
import ItemShipping from "views/ItemShipping.js";
import Zones from "views/Zones.js";
import ZoneAreas from "views/ZoneAreas.js";
import ZonePricing from "views/ZonePricing.js";
import UserProfile from "views/UserProfile.js";
import Team from "views/Team.js";
import Make_Request from "views/Make_Request.js";
import Sorting from "views/Sorting.js";
import TransformIcon from "@material-ui/icons/Transform";
import SortingDeliveries from "views/SortingDeliveries.js";
import Logout from "views/Logout.js";
import InvoiceDetails from "views/InvoiceDetails";
import Agents from "views/Agents";
import Loyalty from "views/Loyalty";
import LocalShippingIcon from '@material-ui/icons/LocalShipping';
import SentimentVerySatisfiedIcon from '@material-ui/icons/SentimentVerySatisfied';

const dashboardRoutes = [
  {
    path: "/dashboard",
    name: "Dashboard",
    rtlName: "لوحة القيادة",
    icon: Dashboard,
    component: DashboardPage,
    layout: "/admin",
  },
  {
    path: "/makedelivery",
    name: "Create Order",
    rtlName: "ملف تعريفي للمستخدم",
    icon: LocalShippingIcon,
    component: Make_Request,
    layout: "/admin",
  },
  {
    path: "/localdeliveries",
    name: "Local Orders",
    rtlName: "ملف تعريفي للمستخدم",
    icon: LocationOnIcon,
    component: Local_Deliveries,
    layout: "/admin",
  },
  {
    path: "/internationaldeliveries",
    name: "International Orders",
    rtlName: "ملف تعريفي للمستخدم",
    icon: FlightIcon,
    component: Intl_Deliveries,
    layout: "/admin",
  },
  {
    path: "/searchdeliveries",
    name: "Search Delivery",
    rtlName: "ملف تعريفي للمستخدم",
    icon: Search,
    component: Search_Deliveries,
    layout: "/admin",
  },
  {
    path: "/sorting-facilities",
    name: "Sorting Facilities",
    rtlName: "ملف تعريفي للمستخدم",
    icon: TransformIcon,
    component: Sorting,
    layout: "/admin",
  },
  {
    path: "/sorting-facility-deliveries/:sid",
    name: "zoneothers",
    rtlName: "طباعة",
    icon: LocationOn,
    component: SortingDeliveries,
    layout: "/admin",
  },
  {
    path: "/locations/:zid",
    name: "zoneothers",
    rtlName: "طباعة",
    icon: LocationOn,
    component: ZoneAreas,
    layout: "/admin",
  },
  {
    path: "/profile",
    name: "zoneothers",
    rtlName: "طباعة",
    icon: LocationOn,
    component: UserProfile,
    layout: "/admin",
  },
  {
    path: "/pricing/:zid",
    name: "zoneothers",
    rtlName: "طباعة",
    icon: LocationOn,
    component: ZonePricing,
    layout: "/admin",
  },
  {
    path: "/shipping/:zid",
    name: "zoneothers",
    rtlName: "طباعة",
    icon: LocationOn,
    component: ItemShipping,
    layout: "/admin",
  },
  {
    path: "/internationalpricing/:itemid",
    name: "zoneothers",
    rtlName: "طباعة",
    icon: LocationOn,
    component: ItemPricing,
    layout: "/admin",
  },
  {
    path: "/invoices/:compid",
    name: "zoneothers",
    rtlName: "قائمة الجدول",
    icon: ListIcon,
    component: Invoices,
    layout: "/admin",
  },
  {
    path: "/invoice-details/:invid/:compid",
    name: "zoneothers",
    rtlName: "قائمة الجدول",
    icon: ListIcon,
    component: InvoiceDetails,
    layout: "/admin",
  },
  {
    path: "/loyalty",
    name: "Loyalty & Redemption",
    rtlName: "طباعة",
    icon: SentimentVerySatisfiedIcon,
    component: Loyalty,
    layout: "/admin",
  },
  {
    path: "/clients",
    name: "Clients",
    rtlName: "طباعة",
    icon: Person,
    component: Clients,
    layout: "/admin",
  },
  {
    path: "/drivers",
    name: "Drivers",
    rtlName: "الرموز",
    icon: Person,
    component: Drivers,
    layout: "/admin",
  },
  {
    path: "/agents",
    name: "Agents",
    rtlName: "الرموز",
    icon: Person,
    component: Agents,
    layout: "/admin",
  },
  {
    path: "/team",
    name: "Managemant Team",
    rtlName: "پشتیبانی از راست به چپ",
    icon: Person,
    component: Team,
    layout: "/admin",
  },
  {
    path: "/zones",
    name: "Zones",
    rtlName: "طباعة",
    icon: LocationOn,
    component: Zones,
    layout: "/admin",
  },
  {
    path: "/configurations",
    name: "Configurations",
    rtlName: "طباعة",
    icon: SettingsIcon,
    component: Configurations,
    layout: "/admin",
  },{
    path: "/ratings",
    name: "Ratings",
    rtlName: "طباعة",
    icon: Star,
    component: Ratings,
    layout: "/admin",
  },
  {
    path: "/logs",
    name: "Logs",
    rtlName: "طباعة",
    icon: ChromeReaderModeIcon,
    component: Logs,
    layout: "/admin",
  },
  {
    path: "/logout",
    name: "zoneothers",
    rtlName: "إخطارات",
    icon: ExitToAppIcon,
    component: Logout,
    layout: "/admin",
  },
];

export default dashboardRoutes;
