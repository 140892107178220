import React, { useState, useEffect } from "react";
import CircularProgress from "@material-ui/core/CircularProgress";
import Button from "@material-ui/core/Button";
import Backdrop from "@material-ui/core/Backdrop";
import Fab from '@material-ui/core/Fab';
import TextField from '@material-ui/core/TextField';
import { toast } from "react-toastify";
import Divider from '@material-ui/core/Divider';
import PhoneIcon from "@material-ui/icons/Phone";
import InputAdornment from "@material-ui/core/InputAdornment";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControl from '@material-ui/core/FormControl';
import PersonOutlineIcon from "@material-ui/icons/PersonOutline";
import GridItem from "components/Grid/GridItem.js";
import InputSpinner from 'react-bootstrap-input-spinner' 
import GridContainer from "components/Grid/GridContainer.js";
import Box from '@material-ui/core/Box';
import Select from 'react-select';
import incoming from "assets/img/incoming.png";
import outgoing from "assets/img/outgoing.png";
import Typography from "@material-ui/core/Typography";
import PropTypes from "prop-types";
import "react-toastify/dist/ReactToastify.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faArrowLeft,
  faArrowRight,
  faCheck,
  faPlus,
  faTimes,
} from "@fortawesome/free-solid-svg-icons";
import { makeStyles } from "@material-ui/core/styles";
import Card from "components/Card/Card.js";
import CardHeader from "components/Card/CardHeader.js";
import CardBody from "components/Card/CardBody.js";

const styles = {
  label: {
    backgroundColor: "#FAFAFA"
    },
  backdrop: {
    zIndex: 5,
    color: "#fff",
  },
  tableCell: {
    textAlign: "center",
  },
  typo: {
    paddingLeft: "25%",
    marginBottom: "40px",
    position: "relative",
  },
  progbuttons:{
    color:"white",
    background:"#0095e8",
    padding:"10px",
    "&:hover": {
      background: "#026399",
    },
  },
  incbutton:{
    color:"white",
    background:"#48BB78",
    height:'30px',
    borderRadius:'20px',
    "&:hover": {
      background: "#2f8252",
    },
  },
  decbutton:{
    color:"white",
    background:"#0095e8",
    height:'30px',
    borderRadius:'20px',
    "&:hover": {
      background: "#026399",
    },
  },
  vehicleimg: {
    display: "block",
    height: "100px",
    width: "100px",
    marginLeft: "auto",
    marginRight: "auto",
  },
  vehicleimg2: {
    display: "block",
    height: "70px",
    width: "250px",
    marginLeft: "auto",
    marginRight: "auto",
  },
  vehicleimg3: {
    display: "block",
    height: "100px",
    width: "100px",
    marginLeft: "auto",
    marginRight: "auto",
  },
  outerbordered:{
    border:"3px dashed #0095e8",
    borderRadius:"5px",
    background: "#F7FAFC",
    padding:'20px',
    "&:hover": {
      background: "#c8e7fa",
    },
  },
  outerbordered2:{
    border:"3px dashed #0095e8",
    borderRadius:"5px",
    background: "#edf4f7",
    padding:'20px',
  },
  selected: {
    border:"3px dashed #0095e8",
    borderRadius:"5px",
    background: "#74c9f7",
    padding:'20px',
    "&:hover": {
      background: "#74c9f7",
    },
  },
  note: {
    fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
    bottom: "10px",
    color: "#c0c1c2",
    display: "block",
    fontWeight: "400",
    fontSize: "13px",
    lineHeight: "13px",
    left: "0",
    marginLeft: "20px",
    position: "absolute",
    width: "260px",
  },
  cardCategoryWhite: {
    color: "rgba(255,255,255,.62)",
    margin: "0",
    fontSize: "14px",
    marginTop: "0",
    marginBottom: "0",
  },
  cardTitleWhite: {
    color: "#FFFFFF",
    marginTop: "0px",
    minHeight: "auto",
    fontWeight: "300",
    fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif",
    marginBottom: "3px",
    textDecoration: "none",
  },
};

const useStyles = makeStyles(styles);

export default function Make_Request(props) {
  toast.configure();
  const classes = useStyles();
  const [services, setServices] = useState([]);
  const [boundries, setBoundries] = useState([]);
  const [packages, setPackages] = useState([]);
  const [vehicles, setVehicles] = useState([]);
  const [restaurants, setRestaurants] = useState([]);
  const [locallocations, setLocallocations] = useState([]);
  const [intllocations, setIntllocations] = useState([]);
  const [intlitems, setIntlitems] = useState([]);
  const [selectedpayment, setSelectedpayment] = useState(null);
  const [selectedservice, setSelectedservice] = useState('');
  const [selectedvehicle, setSelectedVehicle] = useState(0);
  const [selecteddirection, setSelecteddirection] = useState(0);
  const [selecteditems, setSelecteditems] = useState([]);
  const [selectedboundary, setSelectedboundary] = useState('');
  const [fromlocation, setFromlocation] = useState(0);
  const [fromaddress, setFromaddress] = useState('');
  const [tolocation, setTolocation] = useState(0);
  const [toaddress, setToaddress] = useState('');
  const [selectedpackages, setSelectedpackages] = useState({});
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState('');
  const [packageerror, setPackageerror] = useState('');
  const [itemerror, setItemerror] = useState('');
  const [selectedvehicleerror, setSelectedvehicleerror] = useState('');
  const [packagelisterror, setPackagelisterror] = useState('');
  const [directionerror, setDirectionerror] = useState('');
  const [recfnameerror, setRecfnameerror] = useState('');
  const [reclnameerror, setReclnameerror] = useState('');
  const [recphoneerror, setRecphoneerror] = useState('');
  const [sendfnameerror, setSendfnameerror] = useState('');
  const [sendlnameerror, setSendlnameerror] = useState('');
  const [sendphoneerror, setSendphoneerror] = useState('');
  const [fromlocationerror, setFromlocationerror] = useState('');
  const [fromaddresserror, setFromaddresserror] = useState('');
  const [tolocationerror, setTolocationerror] = useState('');
  const [toaddresserror, setToaddresserror] = useState('');
  const [paymenterror, setPaymenterror] = useState('');
  const [reciepient, setReciepient] = useState({
    fname: '',
    lname: '',
    phone: null
  });
  const [sender, setSender] = useState({
    fname: '',
    lname: '',
    phone: null
  });
  const [price, setPrice] = useState(0)
  const [step, setStep] = useState(1)

  const getMakeDeliveryData = async () => {
    let response = await fetch(props.url + '/get-make-delivery-data', { credentials: 'include' });
    let data = await response.json();
    if (data.success) {
      setBoundries(data.data[0])
      setServices(data.data[1])
      setPackages(data.data[2])
      const options1 = [];
      const options2 = [];
      const options3 = [];
      const options4 = {};
      const options5 = {};
      const options6 = [];
      data.data[2].forEach(pack => options4[pack.package_name] = 0)
      data.data[3].forEach(loc => options1.push({ label: loc.location_name, value: loc.id }))
      data.data[4].forEach(loc => options2.push({ label: loc.location_name, value: loc.id }))
      data.data[5].forEach(loc => options3.push({ label: loc.location_name, value: loc.id }))
      data.data[6].forEach(loc => options5[loc.id] = loc.location_name)
      setVehicles(data.data[7])
      data.data[8].forEach(item => options6.push({ label: item.item_name + ' ( ' + item.desc + ' )'
      , value: [item.id,item.img]}))
      setSelectedpackages(options4)
      setLocallocations(options1)
      setIntllocations(options2)
      setRestaurants(options3)
      setIntlitems(options6)
      setLoading(false);
    }
    else {
      setLoading(false);
      if( data.message == 'unauthenticated'){
        window.location.replace(props.exit);
        return;
      }
      toast.error(data.message);
    }
  };

  useEffect(() => {
    getMakeDeliveryData()
  }, []);

  const checkDeliveryPrice = async () => {
    setLoading(true);
    let response = await fetch(props.url + '/calculate-delivery-price', {
      method: 'POST',
      headers: {
        'Content-type': 'application/json'
      },
      body: JSON.stringify({ from: fromlocation.value, to: tolocation.value, vehicle: selectedvehicle, boundary: selectedboundary, items: selecteditems, int_loc: selectedboundary=='International'? selecteddirection == 1 ? tolocation.value : fromlocation.value : 0 }), credentials: 'include'
    });
    let res = await response.json();
    if (res) {
      if (res.success) {
        setPrice(res.data)
        setLoading(false);
      }
      else {
        setLoading(false);
        if( res.message == 'unauthenticated'){
          window.location.replace(props.exit);
          return;
        }
        toast.error(res.message);
      }
    }
  };

  const makeDeliveryRequest = async () => {
    if(selectedpayment == null){
      setPaymenterror('Please select a payment option')
      return;
    }
    else{
      setPaymenterror('')
    }
    setLoading(true);
    let response = await fetch(props.url + '/make-request', {
      method: 'POST',
      headers: {
        'Content-type': 'application/json'
      },
      body: JSON.stringify({ service: selectedservice, sender: sender,boundary: selectedboundary ? selectedboundary : 'Local', from: fromlocation.value, to: tolocation.value, fromAddress: fromaddress, toAddress: toaddress, letters: selectedpackages['Letter'] ,packages: selectedpackages['Parcel'], reciepient: reciepient, vehicle: selectedvehicle, items: selecteditems, int_loc: selectedboundary=='International'? selecteddirection == 1 ? tolocation.value : fromlocation.value : 0, payment: selectedpayment }), credentials: 'include'
    });
    let res = await response.json();
    if (res) {
      if (res.success) {
        setLoading(false);
        if(selectedboundary=='International'){
          props.history.push('/admin/internationaldeliveries');
        }
        else{
          props.history.push('/admin/localdeliveries');
        }
        toast.success("Delivery Request made successfully");
      }
      else {
        setLoading(false);
        if( res.message == 'unauthenticated'){
          window.location.replace(props.exit);
          return;
        }
        toast.error(res.message);
      }
    }
  };
  const removeItem = (id) => {
   const temp = selecteditems.filter((val,indo) => indo != id )
    setSelecteditems(temp)
  }

  const addItem = () => {
  setSelecteditems([...selecteditems,[0,1]])
  }

  const changeItem = (value,pos) => {
    setSelecteditems(selecteditems.map((val,ind)=> ind == pos ? [value,val[1]] : val));
  };

  const changeItemQuantity = (value,pos) => {
    setSelecteditems(selecteditems.map((val,ind)=> ind == pos ? [val[0],value] : val));
  };

  const handleRecChange = (event) => {
    setReciepient({ ...reciepient, [event.target.id]: event.target.value });
  };

  const handleSendChange = (event) => {
    setSender({ ...sender, [event.target.id]: event.target.value });
  };

  const handlePackageInc = (pack) => {
    setSelectedpackages({ ...selectedpackages, [pack]: selectedpackages[pack] + 1 });
  };

  const handlePackageDec = (pack) => {
    setSelectedpackages({ ...selectedpackages, [pack]: selectedpackages[pack] - 1 });
  };

  const handlePaymentChange = (event) => {
    setSelectedpayment(event.target.value);
  };



  const forward = () => {
    if (step == 1) {
      if (!selectedservice) {
        setError('Select a Service')
        return
      }
      else if (selectedservice == 'Food Service') {
        setSelectedboundary('')
        setSelectedpackages({
          'Letter': 0,
          'Parcel' : 0
        })
        setStep(step => step + 2)
      }
      setSelectedVehicle(0)
      setFromlocation(0)
      setFromaddress('')
      setTolocation(0)
      setToaddress('')
    }
    if (step == 2) {
      if (!selectedboundary) {
        setError('Select a Boundary')
        return
      }
      else if (selectedboundary == 'Local') {
        setSelectedpackages({
          'Letter': 0,
          'Parcel' : 0
        })
        setStep(step => step + 1)
      }
      else if (selectedboundary == 'International') {
        setSelectedVehicle(0)
      }
      setFromlocation(0)
      setFromaddress('')
      setTolocation(0)
      setToaddress('')
    }
    if (step == 3) {
      if (!selecteddirection) {
        setDirectionerror('Select International delivery direction')
        return
      }
      setSelecteditems([[0,1]])
      setFromlocation(0)
      setFromaddress('')
      setTolocation(0)
      setToaddress('')
    }
    if (step == 4) {
      if (!fromlocation || !fromaddress || !tolocation || !toaddress) {
        if (!fromlocation) {
          setFromlocationerror('Select the pickup location')
        }
        else {
          setFromlocationerror('')
        }
        if (!fromaddress) {
          setFromaddresserror('Give a specific description of the pickup location')
        }
        else {
          setFromaddresserror('')
        }
        if (!tolocation) {
          setTolocationerror('Select the delivery location')
        }
        else {
          setTolocationerror('')
        }
        if (!toaddress) {
          setToaddresserror('Give a specific description of the delivery location')
        }
        else {
          setToaddresserror('')
        }
        return
      }
      else{
        if(selectedservice == 'Food Service'){
          setStep(step => step + 1)
        }
      }
    }

    if (step == 5) {
      if(selectedboundary == "Local"){
      if (Object.values(selectedpackages).every(x => x == 0)) {
        setPackageerror('Select the packages to be delivered')
        return
      }
    }
      else if(selectedboundary == "International"){
        console.log(selecteditems)
        console.log(Object.values(selecteditems).every(x => x == [0,1]))
        if (selecteditems.length == 0) {
          setItemerror('At least one Item required to proceed')
          return
        }
        if (!Object.values(selecteditems).every(x => x[0] != 0)) {
          setItemerror('All fields need to be filled with items')
          return
        }
      }
    }
    if (step == 6) {
      console.log(selecteditems)
      if (selectedservice == 'Food Service' || selectedboundary == "Local"){
        if(selectedvehicle !== 0 && reciepient.fname && reciepient.lname && reciepient.phone && (String(reciepient.phone).length >= 7)) {
          checkDeliveryPrice()
        }
        else{
          if (selectedvehicle == 0) {
            setSelectedvehicleerror('Select a delivery vehicle')
          }
          else {
            setSelectedvehicleerror('')
          }
          if (!reciepient.fname) {
            setRecfnameerror('Enter the Reciepient\'s firstname')
          }
          else {
            setRecfnameerror('')
          }
          if (!reciepient.lname) {
            setReclnameerror('Enter the Reciepient\'s lastname')
          }
          else {
            setReclnameerror('')
          }
          if (!reciepient.phone) {
            setRecphoneerror('Enter the Reciepient\'s phone number')
          }
          else if (String(reciepient.phone).length < 7) {
            setRecphoneerror('Reciepient\'s phone number should be at least 7 digits')
          }
          else {
            setRecphoneerror('')
          }
          if (!sender.fname) {
            setSendfnameerror('Enter the Sender\'s firstname')
          }
          else {
            setSendfnameerror('')
          }
          if (!sender.lname) {
            setSendlnameerror('Enter the Sender\'s lastname')
          }
          else {
            setSendlnameerror('')
          }
          if (!sender.phone) {
            setSendphoneerror('Enter the Sender\'s phone number')
          }
          else if (String(sender.phone).length < 7) {
            setSendphoneerror('sender\'s phone number should be at least 7 digits')
          }
          else {
            setSendphoneerror('')
          }
          return
        }
      }
      else{
        if(reciepient.fname && reciepient.lname && reciepient.phone && (String(reciepient.phone).length >= 7)) {
          checkDeliveryPrice()
        }
        else{
          if (!reciepient.fname) {
            setRecfnameerror('Enter the Reciepient\'s firstname')
          }
          else {
            setRecfnameerror('')
          }
          if (!reciepient.lname) {
            setReclnameerror('Enter the Reciepient\'s lastname')
          }
          else {
            setReclnameerror('')
          }
          if (!reciepient.phone) {
            setRecphoneerror('Enter the Reciepient\'s phone number')
          }
          else if (String(reciepient.phone).length < 7) {
            setRecphoneerror('Reciepient\'s phone number should be at least 7 digits')
          }
          else {
            setRecphoneerror('')
          }
          if (!sender.fname) {
            setSendfnameerror('Enter the Sender\'s firstname')
          }
          else {
            setSendfnameerror('')
          }
          if (!sender.lname) {
            setSendlnameerror('Enter the Sender\'s lastname')
          }
          else {
            setSendlnameerror('')
          }
          if (!sender.phone) {
            setSendphoneerror('Enter the Sender\'s phone number')
          }
          else if (String(sender.phone).length < 7) {
            setSendphoneerror('Sender\'s phone number should be at least 7 digits')
          }
          else {
            setSendphoneerror('')
          }
          return
        }
      }
     
    }
    setError('')
    setPackagelisterror('')
    setDirectionerror('')
    setItemerror('')
    setSelectedvehicleerror('')
    setPackageerror('')
    setFromlocationerror('')
    setFromaddresserror('')
    setTolocationerror('')
    setToaddresserror('')
    setSendfnameerror('')
    setSendlnameerror('')
    setSendphoneerror('')
    setRecfnameerror('')
    setReclnameerror('')
    setRecphoneerror('')
    setStep(step => step + 1)
  };

  const backward = () => {
    if (step == 3) {
      setPackageerror('')
    }
    if (step == 4) {
      if (selectedservice == 'Food Service') {
        setStep(step => step - 2)
      }
      if (selectedboundary == 'Local') {
        setStep(step => step - 1)
      }
    }
    if (step == 5) {
      if (selectedservice == 'Food Service') {
        setStep(step => step - 3)
      }
      setItemerror('')
    }
    if (step == 6) {
      if (selectedservice == 'Food Service') {
        setStep(step => step - 1)
      }
      setPackagelisterror('')
    }
    setError('')
    setStep(step => step - 1)
  };

  return (
    <Card>
      {loading && (
        <Backdrop className={classes.backdrop} open>
          <CircularProgress color="inherit" />
        </Backdrop>
      )}
      <CardHeader color="danger">
        <h4 className={classes.cardTitleWhite}>
         Make Delivery Request
        </h4>
      </CardHeader>
      <CardBody>

      <GridContainer >
        <GridItem xs={12} sm={12} md={8}>
        {step === 1 && <>
          <Typography
                          align="center"
                          variant="h5"
                          style={{fontWeight:'bold',color:'#2d698a'}}
                          gutterBottom
                        >
                        
            Delivery Service
            </Typography>
            <Typography
                          align="center"
                          variant="subtitle2"
                          gutterBottom
                        >
              Choose one of the services and click on continue
            </Typography></>}
          {step === 2 && <><Typography
                          align="center"
                          variant="h5"
                          style={{fontWeight:'bold',color:'#2d698a'}}
                          gutterBottom
                        >
            Delivery Boundary
          </Typography>
            <Typography
                          align="center"
                          variant="subtitle2"
                          gutterBottom
                        >
              Choose one of the boundaries and click on continue
            </Typography></>}
            {step === 3 && <><Typography
                          align="center"
                          variant="h5"
                          style={{fontWeight:'bold',color:'#2d698a'}}
                          gutterBottom
                        >
            Inbound / Outbound status
          </Typography>
            <Typography
                          align="center"
                          variant="subtitle2"
                          gutterBottom
                        >
              Enter the boundary of the delivery in reference to The Gambia
            </Typography></>}
          {step === 4 && <><Typography
                          align="center"
                          variant="h5"
                          style={{fontWeight:'bold',color:'#2d698a'}}
                          gutterBottom
                        >
            Delivery Addresses
          </Typography>
            <Typography
                          align="center"
                          variant="subtitle2"
                          gutterBottom
                        >
              Enter the pickup and delivery locations for the delivery request
            </Typography></>}
            {step === 5 && <><Typography
                          align="center"
                          variant="h5"
                          style={{fontWeight:'bold',color:'#2d698a'}}
                          gutterBottom
                        >
            Delivery Packages
          </Typography>
            <Typography
                          align="center"
                          variant="subtitle2"
                          gutterBottom
                        >
              Enter as many packages as you want and click on continue
            </Typography></>}
          {step === 6 && <>
          <Typography
                          align="center"
                          variant="h5"
                          style={{fontWeight:'bold',color:'#2d698a'}}
                          gutterBottom
                        >
            {(selectedservice == 'Food Service' || selectedboundary == "Local") ? "Additional Info" : "Reciepient's Info" }
          </Typography>
            <Typography
                          align="center"
                          variant="subtitle2"
                          gutterBottom
                        >
            {(selectedservice == 'Food Service' || selectedboundary == "Local") ? "Enter mode of delivery vehicle and recipient details" : "Enter the details of the reciepient of the delivery" }
            </Typography></>}
          {step === 7 && <><Typography
                          align="center"
                          variant="h5"
                          style={{fontWeight:'bold',color:'#2d698a'}}
                          gutterBottom
                        >
            Confirmation
          </Typography>
            <Typography
                          align="center"
                          variant="subtitle2"
                          gutterBottom
                        >
              Confirm the details of the delivery before proceeding to make request
            </Typography></>}
          {error &&  <Typography
                          align="center"
                          variant="subtitle2"
                          style={{color:'red'}}
                          gutterBottom
                        >
            {error}
          </Typography>}
          {packageerror &&    <Typography
                          align="center"
                          variant="subtitle2"
                          style={{color:'red'}}
                          gutterBottom
                        >
            {packageerror}
          </Typography>}
          {itemerror &&    <Typography
                          align="center"
                          variant="subtitle2"
                          style={{color:'red'}}
                          gutterBottom
                        >
            {itemerror}
          </Typography>}
          {directionerror &&     <Typography
                          align="center"
                          variant="subtitle2"
                          style={{color:'red'}}
                          gutterBottom
                        >
            {directionerror}
          </Typography>}
          {packagelisterror &&   <Typography
                          align="center"
                          variant="subtitle2"
                          style={{color:'red'}}
                          gutterBottom
                        >
            {packagelisterror}
          </Typography>}
          {step === 1 && <GridContainer>
            {services.map(service => <GridItem  key={service.id} xs={12} sm={6} md={6}>
            <Box className={selectedservice == service.service_name ? classes.selected : classes.outerbordered} direction="column" justifyContent="space-around" onClick={() => setSelectedservice(service.service_name)} style={{ cursor: "pointer",marginTop:'10px' }} >
              <img src={props.imgurl+service.img} className={classes.vehicleimg} />
              <Typography
                          align="center"
                          variant="subtitle2"
                          gutterBottom
                        >
                {service.service_name}
              </Typography>
            </Box>
            </GridItem>)}
          </GridContainer>}

          {step === 2 && <GridContainer>
            {boundries.map(boundary => <GridItem  key={boundary.id} xs={12} sm={6} md={6}>
            <Box className={selectedboundary == boundary.boundary_name ? classes.selected : classes.outerbordered} direction="column" justifyContent="space-around" onClick={() => setSelectedboundary(boundary.boundary_name)} style={{ cursor: "pointer",marginTop:'10px' }}>
              <img src={props.imgurl+boundary.img} className={classes.vehicleimg} />
              <Typography
                          align="center"
                          variant="subtitle2"
                          gutterBottom
                        >
                {boundary.boundary_name}
              </Typography>
            </Box>
            </GridItem>)}
          </GridContainer>}
          {step === 3 && 
          <GridContainer>
            <GridItem  xs={12} sm={6} md={6}>
            <Box className={selecteddirection == 1 ? classes.selected : classes.outerbordered} direction="column" justifyContent="space-around" onClick={() => setSelecteddirection(1)} style={{ cursor: "pointer",marginTop:'10px' }}>
              <img src={outgoing}  className={classes.vehicleimg2} />
              <Typography
                          align="center"
                          variant="subtitle2"
                          gutterBottom
                        >
                Send From The Gambia
              </Typography>
            </Box></GridItem>
            <GridItem  xs={12} sm={6} md={6}>
            <Box className={selecteddirection == 2 ? classes.selected : classes.outerbordered} mb="10px" direction="column" justifyContent="space-around" onClick={() => setSelecteddirection(2)} style={{ cursor: "pointer",marginTop:'10px' }}>
              <img src={incoming}  className={classes.vehicleimg2} />
              <Typography
                          align="center"
                          variant="subtitle2"
                          gutterBottom
                        >
                Send To The Gambia
              </Typography>
            </Box>
            </GridItem>
          </GridContainer>
          }
          
          {step === 4 && <Box  justifyContent="space-around">
            <Box>
            <Typography
                          align="center"
                          variant="subtitle2"
                          gutterBottom
                          style={{marginTop:'50px',fontWeight:'bold',color:'#2d698a'}}
                        >
                  Select Pickup Location
                </Typography>
                <Typography
                          variant="subtitle2"
                          gutterBottom
                        >Select Pickup Location from our list of known locations</Typography>
                {selectedservice == 'Food Service' ?
                  <Select value={fromlocation} onChange={(val) => setFromlocation(val)} menuPortalTarget={document.body} placeholder="Location..."
                    styles={{ menuPortal: base => ({ ...base, zIndex: 9999 }) }} options={restaurants} /> :
                    ( (selectedboundary == 'Local' || selecteddirection == 1) ?
                  <Select value={fromlocation} onChange={(val) => setFromlocation(val)} menuPortalTarget={document.body} placeholder="Location..."
                    styles={{ menuPortal: base => ({ ...base, zIndex: 9999 }) }} options={locallocations} /> :
                    <Select value={fromlocation} onChange={(val) => setFromlocation(val)} menuPortalTarget={document.body} placeholder="Location..."
                    styles={{ menuPortal: base => ({ ...base, zIndex: 9999 }) }} options={intllocations} /> 
                     )}
                {fromlocationerror &&  <Typography
                          align="center"
                          variant="subtitle2"
                          style={{color:'red'}}
                          gutterBottom
                        >
                  {fromlocationerror}
                </Typography>}
                <Typography
                          align="center"
                          variant="subtitle2"
                          gutterBottom
                          style={{marginTop:'20px',fontWeight:'bold',color:'#2d698a'}}
                        >Enter Specific Location</Typography>
                <TextField value={fromaddress} onChange={(e) => setFromaddress(e.target.value)}
                  placeholder="Type in the specific location for easier identification"
                  multiline
                  variant="outlined"
                  style={{width: "100%"}}
                />
                {fromaddresserror &&  <Typography
                          align="center"
                          variant="subtitle2"
                          gutterBottom
                          style={{color:'red'}}
                        >
                  {fromaddresserror}
                </Typography>}
                <Divider style={{marginTop:'50px',background:'#2d698a'}} />
              <Typography
                          align="center"
                          variant="subtitle2"
                          gutterBottom
                          style={{marginTop:'20px',fontWeight:'bold',color:'#2d698a'}}
                        >
                  Select Delivery Location
                </Typography>
                <Typography
                          variant="subtitle2"
                          gutterBottom
                        >Select Delivery Location from our list of known locations</Typography>
                {selectedservice == 'Food Service' ?
                  <Select value={tolocation} onChange={(val) => setTolocation(val)} menuPortalTarget={document.body} placeholder="Location..."
                    styles={{ menuPortal: base => ({ ...base, zIndex: 9999 }) }} options={locallocations} /> :
                    ( (selectedboundary == 'Local' || selecteddirection == 2) ?
                  <Select value={tolocation} onChange={(val) => setTolocation(val)} menuPortalTarget={document.body} placeholder="Location..."
                    styles={{ menuPortal: base => ({ ...base, zIndex: 9999 }) }} options={locallocations} /> :
                    <Select value={tolocation} onChange={(val) => setTolocation(val)} menuPortalTarget={document.body} placeholder="Location..."
                    styles={{ menuPortal: base => ({ ...base, zIndex: 9999 }) }} options={intllocations} /> 
                     )}
                {tolocationerror &&  <Typography
                          align="center"
                          variant="subtitle2"
                          gutterBottom
                          style={{color:'red'}}
                        >
                  {tolocationerror}
                </Typography>}
                <Typography
                          align="center"
                          variant="subtitle2"
                          gutterBottom
                          style={{marginTop:'20px',fontWeight:'bold',color:'#2d698a'}}
                        >Enter Specific Location</Typography>
                <TextField value={toaddress} onChange={(e) => setToaddress(e.target.value)}
                  placeholder="Type in the specific location for easier identification"
                  multiline
                  variant="outlined"
                  style={{width: "100%"}}
                />
                {toaddresserror &&  <Typography
                          align="center"
                          variant="subtitle2"
                          gutterBottom
                          style={{color:'red'}}
                        >
                  {toaddresserror}
                </Typography>}
            </Box>
          </Box>}
          {(step === 5 && selectedboundary == "Local") && 
          <GridContainer>
          
            {packages.map(pack => <GridItem key={pack.id} xs={12} sm={6} md={6} style={{marginTop:'10px'}}>
              <Box className={classes.outerbordered2}>
            <Typography
                          align="center"
                          variant="subtitle2"
                          gutterBottom
                        >
                  <span style={{borderRadius:"5px", marginRight:"5px", padding:"5px", border:"1px dashed #0095e8" }}>
                    {selectedpackages[pack.package_name]}
                  </span>{pack.package_name}{selectedpackages[pack.package_name]!= 1 && 's'}
              </Typography>
              <Box style={{display:'flex',justifyContent:'space-between',flexWrap:'wrap'}}>
                <Button  disabled={!selectedpackages[pack.package_name]} onClick={() => handlePackageDec(pack.package_name)} className={classes.decbutton}>
                  -1
                </Button>
                <img src={props.imgurl+pack.img} className={classes.vehicleimg}/>
                <Button  onClick={() => handlePackageInc(pack.package_name)} className={classes.incbutton}>
                  +1
                </Button>
                </Box>
                </Box>
                </GridItem>)}
          </GridContainer>}
          {(step === 5 && selectedboundary == "International") && 
          
          <Box direction="column" w="100%" h="100%" justifyContent="space-around">
          <GridContainer>
        <GridItem xs={12} sm={12} md={12}>
                {selecteditems.map((item,index) =>  
                <React.Fragment key={index}>
                  <GridContainer>
              <GridItem  xs={12} sm={8} md={8}>
                      <Typography
                          align="center"
                          variant="subtitle2"
                          gutterBottom
                        >
                        Delivery Item
                      </Typography>
                      <Select value={selecteditems[index][0]} onChange={(val) => changeItem(val,index)} menuPortalTarget={document.body} placeholder="Item..."
                    styles={{ menuPortal: base => ({ ...base, zIndex: 9999 }) }} options={intlitems}/>
                     </GridItem>
                     <GridItem xs={12} sm={3} md={3}>
                      <Typography
                          align="center"
                          variant="subtitle2"
                          gutterBottom
                        >
                        Quantity
                      </Typography>
                      <InputSpinner
                        type={'real'}
                        min={1}
                        step={1}
                        value={selecteditems[index][1]}
                        onChange={num=>changeItemQuantity(num,index)}
                        variant={'dark'}
                        size="sm"
                    />
                    </GridItem>
                    <GridItem xs={12} sm={1} md={1}>
                    <Box style={{display:'flex',flexDirection:'row',justifyContent:'center',marginTop:'10px'}}>
                    <Fab onClick={() => removeItem(index)} size="small" color="secondary" aria-label="add" >
                    <FontAwesomeIcon icon={faTimes} />
              </Fab>
              </Box>
              </GridItem>
              </GridContainer>
                  <Divider style={{marginBottom:'10px',marginTop:'10px',background:'#2d698a'}} /></React.Fragment>)
                }
                </GridItem>
                  
              </GridContainer>
              <GridContainer style={{ width: "100%" }}>
        <GridItem xs={12} sm={12} md={12}>
        <Box style={{display:'flex',justifyContent:"space-around",marginTop:'10px'}} alignItems="center">
                  <Button onClick={addItem} className={classes.progbuttons} >
                  <FontAwesomeIcon icon={faPlus} />&nbsp;Add Another Item 
            </Button>
            </Box>
          </GridItem>
          </GridContainer>
        </Box>}

          {step === 6 &&
            <Box mt="10px" maxW="100%" h="100%" justifyContent="space-around">
              <Box BoxDirection="column" alignItems="center" py=".8rem" w="75%" h="100%" p="4">

                {(selectedservice == 'Food Service' || selectedboundary == "Local") && <>
                <Typography
                          align="center"
                          variant="subtitle2"
                          gutterBottom
                          style={{fontWeight:'bold',color:'#2d698a'}}
                        >
                    Delivery Vehicle
                  </Typography>
                  <Typography
                          align="center"
                          variant="subtitle2"
                          gutterBottom
                          style={{color:selectedvehicleerror ? "red" : ""}}
                        >
                  Select Vehicle to deliver Order (We charge based on the size)
                  </Typography>
                  <Box style={{ display: "flex",justifyContent:"space-around",flexWrap:'wrap',width:'100%'}} >
                    {vehicles.map(vehicle =><Box key={vehicle.id} mb="5px" mr="10px" onClick={() => setSelectedVehicle(vehicle.id)} className={selectedvehicle == vehicle.id ? classes.selected : classes.outerbordered}>
                        <img src={props.imgurl+vehicle.img} className={classes.vehicleimg}/>
                        <Typography
                          align="center"
                          variant="subtitle2"
                          gutterBottom
                        >
                          {vehicle.vehicle_name}
                        </Typography>
                      </Box>)
                    }
                    
                  </Box>
                  <Divider style={{marginTop:'50px',background:'#2d698a'}} />
                  </>
                }
              
              <GridContainer style={{ width: "100%" }} justifyContent="space-around">
              <GridItem xs={6} sm={6} md={6}>
                  <Typography
                  align="center"
                  variant="h5"
                  style={{fontWeight:'bold',marginTop:'20px',color:'#2d698a'}}
                  gutterBottom
                >
                    Sender&apos;s Info
                  </Typography>

                <TextField
                              onChange={handleSendChange} 
                              id="fname" 
                              value={sender.fname} 
                              type="text"
                               placeholder="Enter Sender's First Name"
                              style={{ marginTop: 20 }}
                              fullWidth
                              label="First Name" 
                              variant="outlined" 
                              margin="normal"
                              error={sendfnameerror}
                              InputLabelProps={{
                                classes: {
                                  root: classes.label
                                }
                               }}
                              InputProps={{
                                startAdornment: (
                                  <InputAdornment position="start">
                                    <PersonOutlineIcon />
                                  </InputAdornment>
                                )
                              }}
                              helperText={sendfnameerror && sendfnameerror}
                            />
                  
                  <TextField
                              onChange={handleSendChange} 
                              id="lname" 
                              value={sender.lname} 
                              type="text"
                               placeholder="Enter Sender's Last Name"
                              style={{ marginTop: 20 }}
                              fullWidth
                              label="Last Name" 
                              variant="outlined" 
                              margin="normal"
                              error={sendlnameerror}
                              InputLabelProps={{
                                classes: {
                                  root: classes.label
                                }
                               }}
                              InputProps={{
                                startAdornment: (
                                  <InputAdornment position="start">
                                    <PersonOutlineIcon />
                                  </InputAdornment>
                                )
                              }}
                              helperText={sendlnameerror && sendlnameerror}
                            />

                    
<TextField
                              onChange={handleSendChange} 
                              id="phone" 
                              value={sender.phone} 
                              type="number"
                               placeholder="Enter Sender's Phone Number"
                              style={{ marginTop: 20 }}
                              fullWidth
                              label="Phone Number" 
                              variant="outlined" 
                              margin="normal"
                              error={sendphoneerror}
                              InputLabelProps={{
                                classes: {
                                  root: classes.label
                                }
                               }}
                              InputProps={{
                                startAdornment: (
                                  <InputAdornment position="start">
                                    <PhoneIcon />
                                  </InputAdornment>
                                )
                              }}
                              helperText={sendphoneerror && sendphoneerror}
                            />
                            </GridItem>
                            <GridItem  xs={6} sm={6} md={6}>
                  <Typography
                  align="center"
                  variant="h5"
                  style={{fontWeight:'bold',marginTop:'20px',color:'#2d698a'}}
                  gutterBottom
                >
                    Reciepients Info
                  </Typography>

                <TextField
                              onChange={handleRecChange} 
                              id="fname" 
                              value={reciepient.fname} 
                              type="text"
                               placeholder="Enter Recipient's First Name"
                              style={{ marginTop: 20 }}
                              fullWidth
                              label="First Name" 
                              variant="outlined" 
                              margin="normal"
                              error={recfnameerror}
                              InputLabelProps={{
                                classes: {
                                  root: classes.label
                                }
                               }}
                              InputProps={{
                                startAdornment: (
                                  <InputAdornment position="start">
                                    <PersonOutlineIcon />
                                  </InputAdornment>
                                )
                              }}
                              helperText={recfnameerror && recfnameerror}
                            />
                  
                  <TextField
                              onChange={handleRecChange} 
                              id="lname" 
                              value={reciepient.lname} 
                              type="text"
                               placeholder="Enter Recipient's Last Name"
                              style={{ marginTop: 20 }}
                              fullWidth
                              label="Last Name" 
                              variant="outlined" 
                              margin="normal"
                              error={reclnameerror}
                              InputLabelProps={{
                                classes: {
                                  root: classes.label
                                }
                               }}
                              InputProps={{
                                startAdornment: (
                                  <InputAdornment position="start">
                                    <PersonOutlineIcon />
                                  </InputAdornment>
                                )
                              }}
                              helperText={reclnameerror && reclnameerror}
                            />

                    
<TextField
                              onChange={handleRecChange} 
                              id="phone" 
                              value={reciepient.phone} 
                              type="number"
                               placeholder="Enter Recipient's Phone Number"
                              style={{ marginTop: 20 }}
                              fullWidth
                              label="Phone Number" 
                              variant="outlined" 
                              margin="normal"
                              error={recphoneerror}
                              InputLabelProps={{
                                classes: {
                                  root: classes.label
                                }
                               }}
                              InputProps={{
                                startAdornment: (
                                  <InputAdornment position="start">
                                    <PhoneIcon />
                                  </InputAdornment>
                                )
                              }}
                              helperText={recphoneerror && recphoneerror}
                            />
                            </GridItem>
                            </GridContainer>

              </Box>
            </Box>
}
          {step === 7 && <Box alignItems="center" mt="10px" maxW="100%" h="100%" justifyContent="space-around">
            <Typography
                          align="center"
                          variant="h6"
                          style={{fontWeight:'bold',color:'#2d698a'}}
                          gutterBottom
                        >
                    Price: {price == 0 ? "Free" : "GMD " +price}
                  </Typography>
            <Divider style={{marginTop:'10px',marginBottom:'10px',background:'#2d698a'}} />
            <GridContainer>
              <GridItem xs={5} sm={5} md={5}>
            <Typography
                          align="center"
                          variant="h6"
                          style={{fontWeight:'bold',color:'#2d698a'}}
                          gutterBottom
                        >
                    Boundary
                  </Typography>
                  <Typography
                          align="center"
                          variant="subtitle2"
                          gutterBottom
                        >
                  {selectedboundary ? selectedboundary : 'Local'}
                </Typography>
             </GridItem>
             <GridItem  xs={2} sm={2} md={2}>
             <Divider flexItem orientation="vertical" />
             </GridItem>
             <GridItem xs={5} sm={5} md={5}>
              <Typography
                          align="center"
                          variant="h6"
                          style={{fontWeight:'bold',color:'#2d698a'}}
                          gutterBottom
                        >
                    Service
                  </Typography>
                  <Typography
                          align="center"
                          variant="subtitle2"
                          gutterBottom
                        >
                  {selectedservice}
                </Typography>
                </GridItem>
                </GridContainer>
                <Divider style={{marginTop:'10px',marginBottom:'10px',background:'#2d698a'}} />
            <GridContainer>
              <GridItem xs={5} sm={5} md={5}>
            <Typography
                          align="center"
                          variant="h6"
                          style={{fontWeight:'bold',color:'#2d698a'}}
                          gutterBottom
                        >
                    From
                  </Typography>
                  <Typography
                          align="center"
                          variant="subtitle2"
                          gutterBottom
                        >
                  {fromlocation.label}
                </Typography>
                <Typography
                          align="center"
                          variant="caption"
                          display="block"
                          gutterBottom
                        >
                  {fromaddress}
                </Typography>
                </GridItem>
                <GridItem  xs={2} sm={2} md={2}>
                  <Box alignItems="center" style={{display:'flex',height:'100%',flexDirection:'column',justifyContent:'space-around',alignItems:'center'}}>
                <FontAwesomeIcon style={{fontSize:'30px',color:'#2d698a'}} icon={faArrowRight} />
                </Box>
                </GridItem>
                <GridItem xs={5} sm={5} md={5}>
              <Typography
                          align="center"
                          variant="h6"
                          style={{fontWeight:'bold',color:'#2d698a'}}
                          gutterBottom
                        >
                    To
                  </Typography>
                  <Typography
                          align="center"
                          variant="subtitle2"
                          gutterBottom
                        >
                  {tolocation.label}
                </Typography>
                <Typography
                          align="center"
                          variant="caption"
                          display="block"
                          gutterBottom
                        >
                  {toaddress}
                </Typography>
                </GridItem>
                </GridContainer>
                <Divider style={{marginTop:'10px',marginBottom:'10px',background:'#2d698a'}} />
              

            {(selectedpackages['Letter'] != 0  && selectedpackages['Parcel'] != 0 && selectedboundary == "Local") && 
            <><Typography
                          align="center"
                          variant="h6"
                          style={{fontWeight:'bold',color:'#2d698a'}}
                          gutterBottom
                        >
                    Letters
                  </Typography>
                  <Typography
                          align="center"
                          variant="subtitle2"
                          gutterBottom
                        >
                  {selectedpackages['Letter']} letter{(selectedpackages['Letter'] > 1) && 's'}
                </Typography>
              <Divider orientation="vertical" />
              <Typography
                          align="center"
                          variant="h6"
                          style={{fontWeight:'bold',color:'#2d698a'}}
                          gutterBottom
                        >
                    Packages
                  </Typography>
                  <Typography
                          align="center"
                          variant="subtitle2"
                          gutterBottom
                        >
                {selectedpackages['Parcel']} parcel{(selectedpackages['Parcel'] > 1) && 's'}
                </Typography>
                <Divider style={{marginTop:'10px',marginBottom:'10px',background:'#2d698a'}} /></>}
              {(selectedpackages['Letter'] != 0 && selectedpackages['Parcel'] == 0 ) && <GridItem mb="10px" colSpan={6} >
              <Typography
                          align="center"
                          variant="h6"
                          style={{fontWeight:'bold',color:'#2d698a'}}
                          gutterBottom
                        > Letters
                  </Typography>
                  <Typography
                          align="center"
                          variant="subtitle2"
                          gutterBottom
                        > {selectedpackages['Letter']} letter{(selectedpackages['Letter'] > 1) && 's'}
                </Typography>
                <Divider style={{marginTop:'10px',marginBottom:'10px',background:'#2d698a'}} />
              </GridItem>}
              {(selectedpackages['Letter'] == 0 && selectedpackages['Parcel'] != 0 &&  selectedboundary == "Local") && <GridItem mb="10px" colSpan={6} >
              <Typography
                          align="center"
                          variant="h6"
                          style={{fontWeight:'bold',color:'#2d698a'}}
                          gutterBottom
                        >Packages
                  </Typography>
                  <Typography
                          align="center"
                          variant="subtitle2"
                          gutterBottom
                        >{selectedpackages['Parcel']} parcel{(selectedpackages['Parcel'] > 1) && 's'}
                </Typography>
                <Divider style={{marginTop:'10px',marginBottom:'10px',background:'#2d698a'}} />
              </GridItem>}
              <Box style={{ display: "flex",justifyContent:"space-around",flexWrap:'wrap',}} >
                    {selecteditems.map(item =><><Box mb="5px" mr="10px" direction="column" justifyContent="space-around" alignItems="center"  w="100%" h="100%">
                        <img src={props.imgurl+item[0]['value'][1]} className={classes.vehicleimg3}/>
                        <Typography
                          align="center"
                          variant="subtitle2"
                          gutterBottom
                        >
                          Item: {item[0]['label']}
                        </Typography>
                        <Typography
                          align="center"
                          variant="subtitle2"
                          gutterBottom
                        >
                          Quantity: {item[1]}
                        </Typography>
                      </Box>
                  <Box display={{ sm: "block", md: "none" }} direction="column" mb="5px" justifyContent="space-around"  w="100%" h="100%">
                    <Divider />
                    </Box></>)
                    }
                  </Box>
                {selecteditems.length != 0 && <GridItem mb="10px" colSpan={6} ><Divider /></GridItem>}
                <GridContainer>
              <GridItem xs={5} sm={5} md={5}>
              <Typography
                          align="center"
                          variant="h6"
                          style={{fontWeight:'bold',color:'#2d698a'}}
                          gutterBottom
                        > Sender
                  </Typography>
                  <Typography
                          align="center"
                          variant="subtitle2"
                          gutterBottom
                        >
                  Name: {sender.fname} {sender.lname}
                </Typography>
                <Typography
                          align="center"
                          variant="subtitle2"
                          gutterBottom
                        > Telephone: {sender.phone}
                </Typography>
                </GridItem>
                <GridItem xs={2} sm={2} md={2}>
                <Box alignItems="center" style={{display:'flex',height:'100%',flexDirection:'column',justifyContent:'space-around',alignItems:'center'}}>
                <FontAwesomeIcon style={{fontSize:'30px',color:'#2d698a'}} icon={faArrowRight} />
                </Box>
                </GridItem>
                <GridItem xs={5} sm={5} md={5}>
              <Typography
                          align="center"
                          variant="h6"
                          style={{fontWeight:'bold',color:'#2d698a'}}
                          gutterBottom
                        > Recipient
                  </Typography>
                  <Typography
                          align="center"
                          variant="subtitle2"
                          gutterBottom
                        >
                  Name: {reciepient.fname} {reciepient.lname}
                </Typography>
                <Typography
                          align="center"
                          variant="subtitle2"
                          gutterBottom
                        > Telephone: {reciepient.phone}
                </Typography>
                </GridItem>
                </GridContainer>

                <Divider style={{marginTop:'10px',marginBottom:'10px',background:'#2d698a'}} />
            <GridContainer>
            <GridItem xs={12} sm={12} md={12}>
            <Typography
                          align="center"
                          variant="h6"
                          style={{fontWeight:'bold',color:'#2d698a'}}
                          gutterBottom
                        >
                    Payment Options
                  </Typography>
                  {paymenterror &&  <Typography
                          align="center"
                          variant="subtitle2"
                          gutterBottom
                          style={{color:'red'}}
                        >
                  {paymenterror}
                </Typography>}
              </GridItem>
              <GridItem xs={12} sm={12} md={12}>

              <Box align="center">
              <FormControl component="fieldset">
              <RadioGroup aria-label="payment" name="payment" value={selectedpayment} onChange={handlePaymentChange}>
                <FormControlLabel value="unpaid" control={<Radio color="primary" />} label="Cash on Pickup" />
                <FormControlLabel value="paid" control={<Radio color="primary" />} label="Already Paid (Confirmed by Admin)" />
              </RadioGroup>
            </FormControl>
              </Box>
                </GridItem>
                </GridContainer>
          </Box>}
          <Box style={{marginTop:'30px',width:'100%'}}>
          <GridContainer>
              <GridItem xs={6} sm={6} md={6}>
            {step > 1 && <Button onClick={backward} className={classes.progbuttons}  >
            <FontAwesomeIcon icon={faArrowLeft} /> Back
            </Button>}
            </GridItem>
              <GridItem style={{display:'flex',justifyContent:'flex-end'}} xs={6} sm={6} md={6}>
            {step < 7 && <Button className={classes.progbuttons} onClick={forward} >
              Continue <FontAwesomeIcon icon={faArrowRight} />
            </Button>}
            {step == 7 && <Button className={classes.progbuttons} onClick={() => makeDeliveryRequest()} >
              Create&nbsp; <FontAwesomeIcon icon={faCheck} />
            </Button>}
            </GridItem>
            </GridContainer>
          </Box>
        </GridItem>
          <GridItem xs={12} sm={12} md={4}>
        <Box>
        {(selectedservice == 'Food Service' || selectedservice == '') ? <CardBody ps="20px" pe="0px" mb="31px" position="relative">
            <Box direction="column">
              <Box style={{display:'flex',alignItems:"center",justifyContent:"start"}}  minH="78px" mb="5px">
                <Box style={{display:'flex',flexDirection:"column",height:'100%'}}>
                  <Button style={{color:'#4299E1',background:'#F3FEFA',position:'relative'}}
                    
                  >{step > 1 ? <FontAwesomeIcon icon={faCheck} /> : 1}</Button>
                  <Box
                  style={{width:'2px',background:'#EEEEEE',height:'50px',position:'relative',left:'30px'}}
                  ></Box>
                </Box>
                <Box style={{display:'flex',flexDirection:"column",justifyContent:"Box-start",width:'100%'}}>
                <Typography
                          align="center"
                          variant="subtitle2"
                          gutterBottom
                        > Delivery Service
                  </Typography>
                  <Typography
                          align="center"
                          variant="caption"
                          display="block"
                          style={{fontSize:'15px'}}
                          gutterBottom
                        >  Define the Deliery Service
                  </Typography>
                </Box>
              </Box>
              <Box style={{display:'flex',alignItems:"center",justifyContent:"start"}}  minH="78px" mb="5px">
                <Box style={{display:'flex',flexDirection:"column",height:'100%'}}>
                  <Button style={{color:'#4299E1',background:'#F3FEFA',position:'relative'}}
                  >{step > 4 ? <FontAwesomeIcon icon={faCheck} /> : 2}</Button>
                  <Box style={{width:'2px',background:'#EEEEEE',height:'50px',position:'relative',left:'30px'}}
                  ></Box>
                </Box>
                <Box style={{display:'flex',flexDirection:"column",justifyContent:"Box-start",width:'100%'}}>
                <Typography
                          align="center"
                          variant="subtitle2"
                          gutterBottom
                        >  Delivery Addresses
                  </Typography>
                  <Typography
                          align="center"
                          variant="caption"
                          display="block"
                          style={{fontSize:'15px'}}
                          gutterBottom
                        >Define the pick-up and delivery addresses
                  </Typography>
                </Box>
              </Box>
              <Box style={{display:'flex',alignItems:"center",justifyContent:"start"}}  minH="78px" mb="5px">
                <Box style={{display:'flex',flexDirection:"column",height:'100%'}}>
                  <Button style={{color:'#4299E1',background:'#F3FEFA',position:'relative'}}
                  >{step > 6 ? <FontAwesomeIcon icon={faCheck} /> : 3}</Button>
                  <Box style={{width:'2px',background:'#EEEEEE',height:'50px',position:'relative',left:'30px'}}
                  ></Box>
                </Box>
                <Box style={{display:'flex',flexDirection:"column",justifyContent:"Box-start",width:'100%'}}>
                <Typography
                          align="center"
                          variant="subtitle2"
                          gutterBottom
                        >  Additional Info
                  </Typography>
                  <Typography
                          align="center"
                          variant="caption"
                          display="block"
                          style={{fontSize:'15px'}}
                          gutterBottom
                        >  Enter additional details such as the sender recipient of the delivery
                  </Typography>
                </Box>
              </Box>
              <Box style={{display:'flex',alignItems:"center",justifyContent:"start"}}  minH="78px" mb="5px">
                <Box style={{display:'flex',flexDirection:"column",height:'100%'}}>
                  <Button style={{color:'#4299E1',background:'#F3FEFA',position:'relative'}}
                  >{step > 8 ? <FontAwesomeIcon icon={faCheck} /> : 4}</Button>
                  <Box 
                  ></Box>
                </Box>
                <Box style={{display:'flex',flexDirection:"column",justifyContent:"Box-start",width:'100%'}}>
                <Typography
                          align="center"
                          variant="subtitle2"
                          gutterBottom
                        >  Confirm Details
                  </Typography>
                  <Typography
                          align="center"
                          variant="caption"
                          display="block"
                          style={{fontSize:'15px'}}
                          gutterBottom
                        >   Confirm the details before proceeding to make request
                  </Typography>
                </Box>
              </Box>

            </Box>
          </CardBody>
          :
          <CardBody ps="20px" pe="0px" mb="31px" position="relative">
            <Box direction="column">
              <Box style={{display:'flex',alignItems:"center",justifyContent:"start"}}  minH="78px" mb="5px">
                <Box style={{display:'flex',flexDirection:"column",height:'100%'}}>
                  <Button style={{color:'#4299E1',background:'#F3FEFA',position:'relative'}}
                  >{step > 1 ? <FontAwesomeIcon icon={faCheck} /> : 1}</Button>
                  <Box
                  style={{width:'2px',background:'#EEEEEE',height:'50px',position:'relative',left:'30px'}}
                  ></Box>
                </Box>
                <Box style={{display:'flex',flexDirection:"column",justifyContent:"Box-start",width:'100%'}}>
                <Typography
                          align="center"
                          variant="subtitle2"
                          gutterBottom
                        >  Delivery Service
                  </Typography>
                  <Typography
                          align="center"
                          variant="caption"
                          display="block"
                          style={{fontSize:'15px'}}
                          gutterBottom
                        >  Define the Delivery Service
                  </Typography>
                </Box>
              </Box>
              <Box style={{display:'flex',alignItems:"center",justifyContent:"start"}}  minH="78px" mb="5px">
                <Box style={{display:'flex',flexDirection:"column",height:'100%'}}>
                  <Button style={{color:'#4299E1',background:'#F3FEFA',position:'relative'}}
                  >{step > 2 ? <FontAwesomeIcon icon={faCheck} /> : 2}</Button>
                  <Box
                    style={{width:'2px',background:'#EEEEEE',height:'50px',position:'relative',left:'30px'}}
                  ></Box>
                </Box>
                <Box style={{display:'flex',flexDirection:"column",justifyContent:"Box-start",width:'100%'}}>
                <Typography
                          align="center"
                          variant="subtitle2"
                          gutterBottom
                        >  Delivery Boundary
                  </Typography>
                  <Typography
                          align="center"
                          variant="caption"
                          display="block"
                          style={{fontSize:'15px'}}
                          gutterBottom
                        >  Select the boundary of the delivery
                  </Typography>
                </Box>
              </Box>
              
              <Box style={{display:'flex',alignItems:"center",justifyContent:"start"}}  minH="78px" mb="5px">
                <Box style={{display:'flex',flexDirection:"column",height:'100%'}}>
                  <Button style={{color:'#4299E1',background:'#F3FEFA',position:'relative'}}
                  >{step > 4 ? <FontAwesomeIcon icon={faCheck} /> : 3}</Button>
                  <Box
                    style={{width:'2px',background:'#EEEEEE',height:'50px',position:'relative',left:'30px'}}
                  ></Box>
                </Box>
                <Box style={{display:'flex',flexDirection:"column",justifyContent:"Box-start",width:'100%'}}>
                <Typography
                          align="center"
                          variant="subtitle2"
                          gutterBottom
                        >  Delivery Addresses
                  </Typography>
                  <Typography
                          align="center"
                          variant="caption"
                          display="block"
                          style={{fontSize:'15px'}}
                          gutterBottom
                        > Define the pick-up and delivery addresses
                  </Typography>
                </Box>
              </Box>
              <Box style={{display:'flex',alignItems:"center",justifyContent:"start"}}  minH="78px" mb="5px">
                <Box style={{display:'flex',flexDirection:"column",height:'100%'}}>
                  <Button style={{color:'#4299E1',background:'#F3FEFA',position:'relative'}}
                  >{step > 5 ? <FontAwesomeIcon icon={faCheck} /> : 4}</Button>
                  <Box
                    style={{width:'2px',background:'#EEEEEE',height:'50px',position:'relative',left:'30px'}}
                  ></Box>
                </Box>
                <Box style={{display:'flex',flexDirection:"column",justifyContent:"Box-start",width:'100%'}}>
                <Typography
                          align="center"
                          variant="subtitle2"
                          gutterBottom
                        >     Package Details
                  </Typography>
                  <Typography
                          align="center"
                          variant="caption"
                          display="block"
                          style={{fontSize:'15px'}}
                          gutterBottom
                        > Select the packages that are supposed to be delivered 
                  </Typography>
                </Box>
              </Box>
              
              <Box style={{display:'flex',alignItems:"center",justifyContent:"start"}}  minH="78px" mb="5px">
                <Box style={{display:'flex',flexDirection:"column",height:'100%'}}>
                  <Button style={{color:'#4299E1',background:'#F3FEFA',position:'relative'}}
                  >{step > 6 ? <FontAwesomeIcon icon={faCheck} /> : 5}</Button>
                  <Box
                    style={{width:'2px',background:'#EEEEEE',height:'50px',position:'relative',left:'30px'}}
                  ></Box>
                </Box>
                <Box style={{display:'flex',flexDirection:"column",justifyContent:"Box-start",width:'100%'}}>
                <Typography
                          align="center"
                          variant="subtitle2"
                          gutterBottom
                        > Additional Info
                  </Typography>
                  <Typography
                          align="center"
                          variant="caption"
                          display="block"
                          style={{fontSize:'15px'}}
                          gutterBottom
                        >  Enter additional details such as the sender and recipient of the delivery
                  </Typography>
                </Box>
              </Box>
              <Box style={{display:'flex',alignItems:"center",justifyContent:"start"}}  minH="78px" mb="5px">
                <Box style={{display:'flex',flexDirection:"column",height:'100%'}}>
                  <Button style={{color:'#4299E1',background:'#F3FEFA',position:'relative'}}
                  >{step > 8 ? <FontAwesomeIcon icon={faCheck} /> : 6}</Button>
                  <Box
                   style={{width:'2px',background:'#EEEEEE',height:'0px',position:'relative',left:'30px'}}
                  ></Box>
                </Box>
                <Box style={{display:'flex',flexDirection:"column",justifyContent:"Box-start",width:'100%'}}>
                <Typography
                          align="center"
                          variant="subtitle2"
                          gutterBottom
                        >  Confirm Details
                  </Typography>
                  <Typography
                          align="center"
                          variant="caption"
                          display="block"
                          style={{fontSize:'15px'}}
                          gutterBottom
                        >  Confirm the details before proceeding to make request
                  </Typography>
                </Box>
              </Box>

            </Box>
          </CardBody>}
          
        </Box>
        </GridItem>
      </GridContainer>
      </CardBody>
      </Card>
  );
}
Make_Request.propTypes = {
  url: PropTypes.string,
  imgurl: PropTypes.string,
  history: PropTypes.any,
  exit: PropTypes.string,
};
