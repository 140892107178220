/*eslint-disable*/
import React from "react";
import PropTypes from "prop-types";
import { makeStyles } from "@material-ui/core/styles";
import ListItem from "@material-ui/core/ListItem";
import Typography from '@material-ui/core/Typography';
import Link from '@material-ui/core/Link';
import List from "@material-ui/core/List";
import styles from "assets/jss/material-dashboard-react/components/footerStyle.js";

const useStyles = makeStyles(styles);

export default function Footer(props) {
  const classes = useStyles();
  return (
    <footer className={classes.footer}>
      <div className={classes.container}>
        <div className={classes.left}>

        </div>
        <Typography variant="body2" color="textSecondary" align="right">
      {'Copyright © '}
      <Link color="inherit" href="https://cayorenterprises.com">
        CAYOR ENTERPRISES LTD
      </Link>{' '}
      {new Date().getFullYear()}
      {'.'}
    </Typography>
      </div>
    </footer>
  );
}
