import React from "react";import { makeStyles } from "@material-ui/core/styles";
import PropTypes from "prop-types";
import { NavLink } from "react-router-dom";
import Person from "@material-ui/icons/Person";
import PowerSettingsNewIcon from '@material-ui/icons/PowerSettingsNew';
import Button from "components/CustomButtons/Button.js";

import styles from "assets/jss/material-dashboard-react/components/headerLinksStyle.js";

const useStyles = makeStyles(styles);

export default function AdminNavbarLinks() {
  const classes = useStyles();
  return (
    <div>
      <div className={classes.searchWrapper}>
        <NavLink title="Edit Profile" style={{ color:'black' }} to={`/admin/profile`}>
      <Button
        color="transparent"
        aria-label="Dashboard"
        className={classes.buttonLink}
      >
        <Person className={classes.icons} />
      </Button>
      </NavLink>

      <NavLink title="Logout" style={{ color:'black' }} to={`/admin/logout`}>
      <Button
        color="transparent"
        aria-label="Dashboard"
        className={classes.buttonLink}
      >
        <PowerSettingsNewIcon className={classes.icons} />
      </Button>
      </NavLink>
      </div>
      
    </div>
  );
}

AdminNavbarLinks.propTypes = {
  handleDrawerToggle: PropTypes.func
};

